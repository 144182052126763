import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../../../environments/environment';
import { CashCloseComponent } from '../../../../common/components/cash-close/cash-close.component';
import { CashClosingDetailsComponent } from '../../../../common/components/cash-closing-details/cash-closing-details.component';
import { ClosedShiftsSelectorDialogComponent } from '../../../../common/components/closed-shifts-selector-dialog/closed-shifts-selector-dialog.component';
import { GenericGridComponent } from '../../../../common/components/generic-grid/generic-grid.component';
import { LoadingService } from '../../../../common/components/loading/loading.service';
import { AlertifyService } from '../../../../common/services/alertify.service';
import { AuthenticationService } from '../../../../interceptors/authentication.service';
import { CashClosingDetailItem, ShiftCashClose } from '../../../../models/ComponentModels';
import { LocalData } from '../../../../models/LocalData';
import { CashCloseVM, CashClosingVM, LoginResponse, MultipleShiftCashClosing, ShiftVM } from '../../../../models/view-models';
import { CashClosingService } from '../../../../services/cash-closing.service';
import { ShiftService } from '../../../../services/shift.service';

@Component({
  selector: 'multiple-shift-cash-close',
  templateUrl: './multiple-shift-cash-close.component.html',
  styleUrls: ['./multiple-shift-cash-close.component.scss']
})
export class MultipleShiftCashCloseComponent implements OnInit {

  cashCloseList: CashCloseVM[] = [];
  selectedCashClose: CashCloseVM;
  cashClosingList: CashClosingVM[] = [];
  cashClosing: CashClosingVM;
  createDate: Date = new Date();

  @ViewChild("grid", { read: GenericGridComponent })
  public grid: GenericGridComponent;
  userId: string;
  userName: string;
  showDetails: boolean = false;
  formTitle: string;
  userIsAdmin: boolean = false;
  showMulti: boolean = false;
  @ViewChild("shiftsSelector", { read: ClosedShiftsSelectorDialogComponent })
  public shiftsSelector: ClosedShiftsSelectorDialogComponent;
  multipleShiftCashclosing: MultipleShiftCashClosing;
  shiftCashCloses: ShiftCashClose[] = [];
  displayCashClosing: CashClosingVM;
  generalCashclosingTitle: string = "Corte General";
  @ViewChild("multiCashCloseDetails", { read: CashCloseComponent, static: true })
  public multiCashCloseDetails: CashCloseComponent;
  isGeneral: boolean = false;

  showPrintButton: boolean = false;

  constructor(private cashClosingService: CashClosingService,
    private datePipe: DatePipe,
    private shiftService: ShiftService,
    private authService: AuthenticationService,
    private router: Router,
    private loading: LoadingService,
    private alertify: AlertifyService) {

    let usr: LoginResponse = this.authService.SessionUserValue();
    this.userId = usr.UUID;
    this.userName = usr.User;
  }

  ngOnInit(): void {
    this.userIsAdmin = this.authService.SessionUserValue().Rol === LocalData.ADMIN_ROL;
    this.loadData();
  }

  loadData() {
    this.loading.show();
    this.cashClosingService.getMultipleShiftCashClose()
      .subscribe((response: CashCloseVM[]) => {
        if (response) {
          this.cashCloseList = response;
        }
        this.setGridOptions();
        this.loading.hide();
      }, (err) => {
        this.loading.hide();
      });
  }

  setGridOptions() {
    this.grid.setData(this.cashCloseList);
    this.grid.options.toolbarTitle = "Cortes";
    this.grid.options.paging = false;
    this.grid.options.hideActionButtons = true;
    this.grid.options.showToolbar = true;
    this.grid.options.rowClick = true;
    this.grid.columns = [
      { field: "CreateDate", title: "Fecha", dateType: true },
      { field: "CreateDate", title: "Hora", timeType: true },
      { field: "UserName", title: "Usuario" },
      { field: "TotalSales", title: "Ventas", currencyType: true },
      { field: "TotalExpenses", title: "Gastos", currencyType: true },
      { field: "StartingCash", title: "Fondo Inicial", currencyType: true },
      { field: "TotalCash", title: "Efectivo", currencyType: true },
    ];
    this.grid.setGridOptions();
  }

  select(item: CashCloseVM) {
    this.showPrintButton = true;
    this.selectedCashClose = item;
    this.formTitle = "Detalles de Corte";
    this.showDetails = true;
    this.loading.show();
    this.cashClosingService.getFromIdentifier(this.selectedCashClose.UUID)
      .subscribe((response: MultipleShiftCashClosing) => {
        if (response) {
          this.multipleShiftCashclosing = response;
          this.createDropdownData();
          this.showMulti = true;
          this.multiCashCloseDetails.cashClosing = this.multipleShiftCashclosing.GeneralCashClosing;
          this.multiCashCloseDetails.formTitle = this.generalCashclosingTitle;
          this.multiCashCloseDetails.isGeneral = true;
          this.isGeneral = true;
          this.setGeneralCashClosingShiftDates();
          this.multiCashCloseDetails.setInfo();
        }
        this.loading.hide();
      }, (err) => {
        this.loading.hide();
      });
  }

  save() {
    this.loading.show();
    this.cashClosing = this.multipleShiftCashclosing.GeneralCashClosing;
    this.cashClosing.CashClose = {
      UserId: this.userId,
      UserName: this.userName,
      ShiftId: '',
      TotalSales: this.cashClosing.TotalSales,
      TotalExpenses: this.cashClosing.TotalExpenses,
      TotalCash: this.cashClosing.EndingCash,
      StartingCash: this.cashClosing.StartingCash,
      CreateDate: new Date(),
      IsMultipleShift: true,
      Shifts: this.getMultipleShifts()
    };
    this.cashClosingService.createMultipleShiftCashclose(this.cashClosing)
      .subscribe((response: CashCloseVM) => {
        if (response) {
          this.openTicket(response.TicketUrl);
          this.cancel();
          this.grid.addRow(response);
        }
        this.loading.hide();
      }, (err) => {
        this.loading.hide();
      });
  }

  cancel() {
    this.multipleShiftCashclosing = null;
    this.shiftCashCloses = [];
    this.showMulti = false;
    this.isGeneral = false;
    this.showPrintButton = false;
  }

  openTicket(filename: string): void {
    const url = `${environment.cashClosingUrl}/${filename}`;
    window.open(url, '_blank');
  }

  // multiple shifts cash close
  openShiftSelector() {
    this.shiftsSelector.open();
  }

  createFromMultipleShifts(selectedShifts: ShiftVM[]) {
    if (selectedShifts.length > 0) {
      this.loading.show();
      const ids: string[] = [];
      selectedShifts.forEach(s => {
        ids.push(s.UUID);
      });
      this.cashClosingService.getCashClosingFromMultipleShifts(ids)
        .subscribe((response: MultipleShiftCashClosing) => {
          if (response) {
            this.multipleShiftCashclosing = response;
            this.createDropdownData();
            this.showMulti = true;
            this.multiCashCloseDetails.cashClosing = this.multipleShiftCashclosing.GeneralCashClosing;
            this.multiCashCloseDetails.formTitle = this.generalCashclosingTitle;
            this.multiCashCloseDetails.isGeneral = true;
            this.isGeneral = true;
            this.setGeneralCashClosingShiftDates();
            this.multiCashCloseDetails.setInfo();
          }
          this.loading.hide();
        }, (err) => {
          this.loading.hide();
        });
    }
  }

  createDropdownData() {
    this.shiftCashCloses.push({
      ShiftId: null,
      CreateDate: this.generalCashclosingTitle
    });
    this.multipleShiftCashclosing.ShiftsCashClosing.forEach(s => {
      this.shiftCashCloses.push({
        ShiftId: s.Shift.UUID,
        CreateDate: this.datePipe.transform(s.Shift.CreateDate, 'dd/MM/yyyy HH:mm:ss')
      });
    });
  }

  onShiftSelectionChange(value: ShiftCashClose) {
    this.loading.show();
    if (value.CreateDate === this.generalCashclosingTitle) {
      this.multiCashCloseDetails.cashClosing = this.multipleShiftCashclosing.GeneralCashClosing;
      this.multiCashCloseDetails.formTitle = this.generalCashclosingTitle;
      this.multiCashCloseDetails.isGeneral = true;
      this.isGeneral = true;
    } else {
      this.multiCashCloseDetails.cashClosing = this.multipleShiftCashclosing.ShiftsCashClosing.find(item => item.Shift.UUID == value.ShiftId);
      this.multiCashCloseDetails.formTitle = `Corte de ${value.CreateDate}`;
      this.multiCashCloseDetails.isGeneral = false;
      this.isGeneral = false;
    }
    this.multiCashCloseDetails.setInfo();
    this.loading.hide();
  }

  setGeneralCashClosingShiftDates() {
    this.multiCashCloseDetails.resumeShifts = [];
    this.multipleShiftCashclosing.ShiftsCashClosing.forEach(s => {
      this.multiCashCloseDetails.resumeShifts.push(s.Shift);
    });
  }

  getMultipleShifts(): ShiftVM[] {
    let result: ShiftVM[] = [];
    this.multipleShiftCashclosing.ShiftsCashClosing.forEach(scc => {
      result.push(scc.Shift);
    });
    return result;
  }

  print() {
    this.loading.show();
    this.cashClosingService.getByIdentifier(this.selectedCashClose.UUID)
      .subscribe((response: CashCloseVM) => {
        if (response) {
          this.openTicket(response.TicketUrl);
        }
        this.loading.hide();
      });
  }

}
