
<form [formGroup]="passForm"
      class="__entityForm">

  <h4>
    Cambiar Contraseña
  </h4>

  <kendo-floatinglabel text="Nombre de Usuario">
    <input kendoTextBox name="Username"
           class="form-control"
           type="text"
           [readOnly]="true"
           aria-label="Nombre de Usuario"
           placeholder="Nombre de Usuario"
           [(ngModel)]="username"
           [ngModelOptions]="{standalone: true}"/>
  </kendo-floatinglabel>

  <kendo-floatinglabel text="Nueva Contraseña"
                       *ngIf="!showPassword">
    <input kendoTextBox name="NewPassword"
           #pass
           class="form-control"
           [ngClass]="{'is-invalid': passForm.get('NewPassword').errors && passForm.get('NewPassword').touched}"
           type="password"
           aria-label="Nueva Contraseña"
           placeholder="Nueva Contraseña"
           formControlName="NewPassword" />
  </kendo-floatinglabel>

  <kendo-floatinglabel text="Nueva Contraseña"
                       *ngIf="showPassword">
    <input kendoTextBox name="NewPassword"
           #pass
           class="form-control"
           [ngClass]="{'is-invalid': passForm.get('NewPassword').errors && passForm.get('NewPassword').touched}"
           type="text"
           aria-label="Nueva Contraseña"
           placeholder="Nueva Contraseña"
           formControlName="NewPassword" />
  </kendo-floatinglabel>

  <div class="row">
    <div class="col-6">
      <label class="form-check-label">
        <input type="checkbox" kendoCheckBox
               [(ngModel)]="showPassword"
               [ngModelOptions]="{standalone: true}" />&nbsp;&nbsp;&nbsp;
        Mostrar contraseña
      </label>
    </div>
    <div class="col-6">
      <button class="btn btn-outline-secondary btn-block"
              (click)="generatePassword()">
        Generar Contraseña
      </button>
    </div>
  </div>

  <div class="d-flex justify-content-end __formButtonsContainer">
    <button class="btn btn-primary"
            type="submit"
            [disabled]="!isValidForm()"
            (click)="save()">
      Guardar
    </button>

    <button class="btn btn-secondary"
            type="button"
            (click)="cancel()">
      Cancelar
    </button>
  </div>

</form>
