import { Component, Output, EventEmitter } from '@angular/core';
import { IntlService } from '@progress/kendo-angular-intl';

@Component({
  selector: 'app-date-range-selector',
  templateUrl: './date-range-selector.component.html',
  styleUrls: ['./date-range-selector.component.scss']
})
export class DateRangeSelectorComponent {

  @Output() selectStartDate: EventEmitter<Date> = new EventEmitter<Date>();
  @Output() selectEndDate: EventEmitter<Date> = new EventEmitter<Date>();

  public startDate: Date = new Date();
  public endDate: Date = new Date();

  constructor() { }

  public onStartDateChange(dt: Date) {
    this.startDate = dt;
    this.selectStartDate.emit(this.startDate);
  }

  public onEndDateChange(dt: Date) {
    this.endDate = dt;
    this.selectStartDate.emit(this.endDate);
  }

  reset() {
    this.startDate = new Date();
    this.endDate = new Date();
  }

  areDatesValid(): boolean {
    if (this.startDate.getTime() < this.endDate.getTime()) {
      return true;
    }
    return false;
  }




}
