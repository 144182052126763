import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AlertifyService } from '../common/services/alertify.service';
import { SaleVM, OperationResult, TicketReferenceVM, SalesOnCreditVM, ModifyTicketDto, CancelTicketDto, TicketVM } from '../models/view-models';
import { map } from 'rxjs/operators';
import { ErrorService } from '../interceptors/error.service';
import { DatePipe } from '@angular/common';
import { LocalData } from '../models/LocalData';

@Injectable({
  providedIn: 'root'
})
export class SalesService {

  apiUrl: string = `${environment.apiUrl}/sale`;

  constructor(private http: HttpClient,
    private error: ErrorService,
    private datePipe: DatePipe,
    private alertify: AlertifyService) { }

  insert(sale: SaleVM) {
    return this.http.post(this.apiUrl, sale)
      .pipe(map((response: OperationResult<SaleVM>) => {
        if (response.Success) {
          this.alertify.success("Se registró la venta");
          return response.Data;
        } else {
          this.error.logError(response);
          localStorage.setItem(LocalData.SALE_ERROR, JSON.stringify(response));
          return null;
        }
      }));
  }

  update(sale: SaleVM) {
    return this.http.put(this.apiUrl, sale)
      .pipe(map((response: OperationResult<SaleVM>) => {
        if (response.Success) {
          this.alertify.success("Se editó la venta");
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }

  delete(uuid: string) {
    const uri = `${this.apiUrl}?uuid=${uuid}`;
    return this.http.delete(uri)
      .pipe(map((response: OperationResult<boolean>) => {
        if (response.Success) {
          this.alertify.success("Se eliminó la venta");
          return response.Data;
        } else {
          this.error.logError(response);
          return false;
        }
      }));
  }

  cancelSale(cancelation: CancelTicketDto) {
    const uri = `${this.apiUrl}/CancelSale`;
    return this.http.put(uri, cancelation)
      .pipe(map((response: OperationResult<TicketVM>) => {
        if (response.Success) {
          this.alertify.success(`Se canceló el ticket #${cancelation.Sale.TicketId}`);
          return response.Data;
        } else {
          this.error.logError(response);
          return false;
        }
      }));
  }

  returnProducts(saleReturns: ModifyTicketDto) {
    const uri = `${this.apiUrl}/ReturnProducts`;
    return this.http.post(uri, saleReturns)
      .pipe(map((response: OperationResult<TicketVM[]>) => {
        if (response.Success) {
          this.alertify.success(`Se realizó la devolución de productos`);
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }

  modifyTicket(modify: ModifyTicketDto) {
    const uri = `${this.apiUrl}/ModifyTicket`;
    return this.http.put(uri, modify)
      .pipe(map((response: OperationResult<TicketVM>) => {
        if (response.Success) {
          this.alertify.success(`Se modificó el ticket #${modify.CurrentSale.TicketId}`);
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }

  createTicketReferences() {
    const uri = `${this.apiUrl}/CreateTicketReferences`;
    return this.http.get(uri).pipe(
      map((response: OperationResult<TicketReferenceVM>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }

  getAll() {
    return this.http.get(this.apiUrl)
      .pipe(map((response: OperationResult<SaleVM[]>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }

  getSalesFromDate(date: Date) {
    let dt: string = this.formatDateToString(date);
    const uri = `${this.apiUrl}/GetSalesFromDate?date=${dt}`;
    return this.http.get(uri).pipe(
      map((response: OperationResult<SaleVM[]>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.error.logError(response);
        }
      }));
  }

  getTodaySales() {
    let date = this.datePipe.transform(new Date(), 'yyyy-M-dd');
    const uri = `${this.apiUrl}/GetSalesFromDate?date=${date}`;
    return this.http.get(uri)
      .pipe(map((response: OperationResult<SaleVM[]>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }

  getSalesFromPaymentMethodCode(pmc: string) {
    const uri = `${this.apiUrl}/GetSalesFromPaymentMethodCode?paymentMethodCode=${pmc}`;
    return this.http.get(uri).pipe(
      map((response: OperationResult<SaleVM[]>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }
  
  getUnpaidSalesFromCustomer(customerId: string) {
    const uri = `${this.apiUrl}/GetUnpaidSalesFromCustomer?customerId=${customerId}`;
    return this.http.get(uri).pipe(
      map((response: OperationResult<SaleVM[]>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }

  getSalesOnCredit() {
    const uri = `${this.apiUrl}/GetSalesOnCredit`;
    return this.http.get(uri).pipe(
      map((response: OperationResult<SalesOnCreditVM>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }

  getById(uuid: string) {
    const uri = `${this.apiUrl}/GetByIdentifier?uuid=${uuid}`;
    return this.http.get(uri)
      .pipe(map((response: OperationResult<SaleVM>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }
  
  getByTicketId(ticketId: string) {
    const uri = `${this.apiUrl}/GetByTicketId?ticketId=${ticketId}`;
    return this.http.get(uri)
      .pipe(map((response: OperationResult<SaleVM>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }

  getLastTicket() {
    const uri = `${this.apiUrl}/GetLastTicket`;
    return this.http.get(uri).pipe(
      map((response: OperationResult<TicketReferenceVM>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }

  private formatDateToString(date: Date): string {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} 12:00:00`;
  }

  //downloadPdf(filename: string) {
  //  console.clear();
  //  const url = `${environment.ticketsUrl}/${filename}`;
  //  this.http.get(url).subscribe((response: Response) => {
  //    const blob = new Blob([response.body]);
  //    const link = document.createElement('a');
  //    link.href = window.URL.createObjectURL(blob);
  //    link.download = 'my-file.pdf';
  //    link.click();
  //  });
  //}

}
