
<div>

  <h3>
    CARDEX
  </h3>

  <hr />
  <br />

  <app-generic-grid #grid></app-generic-grid>

</div>
