import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { AlertifyService } from '../common/services/alertify.service';
import { ClientLoginService } from '../services/client-login.service';
import { Router } from '@angular/router';
import { OperationResult } from '../models/Common';
import { LoadingService } from '../common/components/loading/loading.service';
import { catchError, retry } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private alertify: AlertifyService,
    private loading: LoadingService,
    private authService: ClientLoginService,
    private router: Router) { }

  intercept(request: HttpRequest<any>,
    next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
        retry(0),
        catchError((error: HttpErrorResponse) => {
          let errorMessage = '';
          // client-side error
          //#region client-side error
          if (error.error instanceof ErrorEvent) {
            errorMessage = `Error: ${error.error.message}`;
            //#endregion
          } else {

            // server-side error
            let errorDetails: OperationResult<any> = error.error;

            if (errorDetails.Details) {
              errorMessage = errorDetails.Details[0].Message;
            }

            switch (error.status) {
              case 400: this.showWarningMessage(`Error en la petición`);
                this.showWarningMessage(errorMessage);
                break;
              case 401: this.router.navigate(['home/unauthorized']);
                break;
              case 403: this.router.navigate(['/home/access-denied']);
                this.showWarningMessage(`No tiene permisos para realizar esta acción`);
                break;
              case 404: this.showWarningMessage(`No hay resultados`);
                //this.showWarningMessage(errorMessage);
                break;
              case 406: this.showWarningMessage(`No aceptado`);
                //this.showWarningMessage(errorMessage);
                break;
              case 409: this.showWarningMessage("Registro duplicado");
                //this.showWarningMessage(errorMessage);
                break;
              default: this.showErrorMessage(`Error Code: ${error.status}`);
                //this.showWarningMessage(errorMessage);
                break;
            }
            this.loading.hide();
          }

          /*console.log(error); */
          return throwError(errorMessage);
        }));
  }

  showWarningMessage(msg: string) {
    this.alertify.warning(msg);
  }

  showErrorMessage(msg: string) {
    this.alertify.error(msg);
    //console.log(msg);
  }
}
