
<form [formGroup]="comboForm">
  <kendo-floatinglabel text="{{label}}">
    <kendo-dropdownlist #combo
                        formControlName="comboValue"
                        [data]="data"
                        [disabled]="disabled"
                        [textField]="'Code'"
                        [valueField]="'UUID'"
                        required
                        (selectionChange)="selectionChange($event)">
    </kendo-dropdownlist>
  </kendo-floatinglabel>
</form>
