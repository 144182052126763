import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../common/service-pay-environments/env';
import { ClientLoginRequestDto, ClientLoginResponseDto } from '../models/Client';
import { OperationResult } from '../models/Common';
import { LoginRequestVM, LoginResponse } from '../models/Login';
import { ErrorService } from './error.service';

@Injectable({
  providedIn: 'root'
})
export class ClientLoginService {

  private clientInfoTokenSubject: BehaviorSubject<ClientLoginResponseDto> | undefined;
  public sessionClientInfoToken: Observable<ClientLoginResponseDto> | undefined;

  private apiUrl: string = `${environment.apiUrl}/Account`;

  constructor(private http: HttpClient,
    private error: ErrorService) {
    this.clientInfoTokenSubject = new BehaviorSubject<ClientLoginResponseDto>(
      JSON.parse(localStorage.getItem('sp-user-info') as string));
    this.sessionClientInfoToken = this.clientInfoTokenSubject.asObservable();
  }

  public sessionClientValue(): ClientLoginResponseDto | null {
    if (this.clientInfoTokenSubject?.value) {
      return this.clientInfoTokenSubject.value;
    }
    return null;
  }

  clientLogin(clientInfo: ClientLoginRequestDto): Observable<boolean> {
    const uri = `${this.apiUrl}/ClientLogin`;
    return this.http.post(uri, clientInfo)
      .pipe(map((res: any) => {
        const response = res as OperationResult<ClientLoginResponseDto>;
        if (response.Success) {
          localStorage.setItem('sp-user-info', JSON.stringify(response.Data));
          this.clientInfoTokenSubject?.next(response.Data);
          return true;
        } else {
          this.error.logError(response);
          return false;
        }
      }));
  }

  logout() {
    localStorage.removeItem('sp-user-info');
    this.clientInfoTokenSubject?.next(null as any);
  }
}
