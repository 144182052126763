import { Component, ElementRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProductToSale } from '../../../../../models/view-models';

@Component({
  selector: 'modal-common-product',
  templateUrl: './common-product.component.html',
  styleUrls: ['./common-product.component.scss']
})
export class CommonProductComponent {

  showDialog: boolean = false;
  dialogForm: FormGroup;
  @ViewChild("description") description: ElementRef;
  product: ProductToSale;
  @Output() commonProductEmiter: EventEmitter<ProductToSale> = new EventEmitter<ProductToSale>();
  @Output() modalClose: EventEmitter<any> = new EventEmitter<any>();
  total: number = 0;

  constructor(private fb: FormBuilder) { }

  public openDialog() {
    this.showDialog = true;
    this.dialogForm = this.fb.group({
      Name: ['', [Validators.required, Validators.minLength(2)]],
      RetailPrice: [0, [Validators.required]],
      Quantity: [1, [Validators.required]],
    });
    this.total = 0;
    this.onFormChanges();

    setTimeout(() => {
      this.description.nativeElement.focus();
    }, 0);
  }

  onFormChanges() {
    this.dialogForm.valueChanges
      .subscribe(value => {
        this.total = value.RetailPrice * value.Quantity;
      });
  }

  isValidForm(): boolean { return this.dialogForm.valid; }

  closeDialog() {
    this.showDialog = false;
    this.modalClose.emit();
  }

  createProduct() {
    this.product = Object.assign({
      Code: "N / A",
      CategoryName: "Producto común",
      UnitName: "Pieza",
      Existence: 0,
      Total: this.total,
      UsesInventory: false,
    }, this.dialogForm.value);
    this.commonProductEmiter.emit(this.product);
    this.showDialog = false;
  }


}
