import { Component, ViewChild, Output, EventEmitter, ElementRef, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoadingService } from '../../../../common/components/loading/loading.service';
import { AlertifyService } from '../../../../common/services/alertify.service';
import { ChangePasswordRequest, UserVM } from '../../../../models/view-models';
import { UsersService } from '../../../../services/users.service';

@Component({
  selector: 'app-change-password-form',
  templateUrl: './change-password-form.component.html',
  styleUrls: ['./change-password-form.component.scss']
})
export class ChangePasswordFormComponent implements OnInit {

  user: UserVM;
  username: string = "";
  passForm: FormGroup;
  @ViewChild("pass") pass: ElementRef;
  showPassword: boolean = false;

  @Output() onPasswordChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onCancel: EventEmitter<any> = new EventEmitter<any>();

  constructor(private usersService: UsersService,
    private fb: FormBuilder,
    private alertify: AlertifyService,
    private loading: LoadingService) { }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.passForm = this.fb.group({
      NewPassword: ['', [Validators.required, Validators.minLength(4)]],
    });
  }

  isValidForm(): boolean {
    return this.passForm.valid;
  }

  setFocusOnInput() {
    setTimeout(() => {
      this.pass.nativeElement.focus();
      this.pass.nativeElement.select();
    }, 0);
  }

  save() {
    if (this.isValidForm()) {
      this.loading.show();
      let changeRequest: ChangePasswordRequest = Object.assign({
        UserId: this.user.UUID,
        Username: this.user.Username
      }, this.passForm.value);
      this.usersService.changePassword(changeRequest)
        .subscribe((response: boolean) => {
          if (response) {
            this.onPasswordChanged.emit(true);
            this.cancel();
          }
          this.loading.hide();
        }, (err) => {
          this.loading.hide();
        });
    }
  }

  cancel() {
    this.initForm();
    this.username = "";
    this.onCancel.emit();
  }

  open(userInfo: UserVM) {
    this.user = userInfo;
    this.username = this.user.Username;
    this.initForm();
    this.setFocusOnInput();
  }

  generatePassword() {
    let randomPass: string = "";
    let str: string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ' +
      'abcdefghijklmnopqrstuvwxyz0123456789';
    for (var i = 0; i < 8; i++) {
      var char = Math.floor(Math.random()
        * str.length + 1);
      randomPass += str.charAt(char)
    }

    this.passForm.controls["NewPassword"].setValue(randomPass);
  }

}
