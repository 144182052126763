import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GenericGridComponent } from '../../../../common/components/generic-grid/generic-grid.component';
import { LoadingService } from '../../../../common/components/loading/loading.service';
import { MultipleRolSelectorComponent } from '../../../../common/components/multiple-rol-selector/multiple-rol-selector.component';
import { AlertifyService } from '../../../../common/services/alertify.service';
import { LocalData } from '../../../../models/LocalData';
import { PermissionType } from '../../../../models/PermissionType';
import { RolPermissionVM } from '../../../../models/view-models';
import { RolPermissionService } from '../../../../services/rol-permission.service';
import { RolService } from '../../../../services/rol.service';

@Component({
  selector: 'app-action-permissions',
  templateUrl: './action-permissions.component.html',
  styleUrls: ['./action-permissions.component.scss']
})
export class ActionPermissionsComponent implements OnInit, AfterViewInit {

  permissions: RolPermissionVM[] = [];
  permission: RolPermissionVM;
  entityForm: FormGroup;
  showForm: boolean = false;
  isEdition: boolean = false;

  @ViewChild("grid", { read: GenericGridComponent, static: false })
  public grid: GenericGridComponent;

  @ViewChild("rolSelector", { read: MultipleRolSelectorComponent, static: false })
  public rolSelector: MultipleRolSelectorComponent;

  @ViewChild("name") name: ElementRef;
  @ViewChild("code") code: ElementRef;

  disabledAddButton: boolean = false;
  routeName: string = "";

  constructor(private permissionService: RolPermissionService,
    private fb: FormBuilder,
    private rolService: RolService,
    private alertify: AlertifyService,
    private loading: LoadingService) { }


  ngAfterViewInit(): void {
    this.loadData();
  }

  ngOnInit(): void {
    this.initForm();
    this.rolService.loadAndSaveData();
  }

  initForm() {
    this.entityForm = this.fb.group({
      Code: ['', [Validators.required, Validators.minLength(2)]],
      Name: ['', [Validators.required, Validators.minLength(2)]],
      AuthorizationRequired: [false]
    });
  }

  loadData() {
    this.loading.show();
    this.permissionService.getActionPermissions()
      .subscribe((response: RolPermissionVM[]) => {
        if (response) {
          response.forEach(p => {
            p.Roles = this.rolService.getRolNames(p.RolCodes);
          });
          this.permissions = response;
        }
        this.setGridOptions();
        this.loading.hide();
      }, (err) => {
        this.setGridOptions();
        this.loading.hide();
      });
  }

  setGridOptions() {
    this.grid.setData(this.permissions);
    this.grid.options.toolbarTitle = "Permisos";
    this.grid.options.hideActionButtons = true;
    this.grid.options.rowClick = true;
    this.grid.columns = [
      { field: "Code", title: "Código" },
      { field: "Name", title: "Nombre" },
      { field: "Roles", title: "Roles" },
      { field: "AuthorizationRequired", title: "Requiere autorización", booleanType: true },
    ];
    this.grid.setGridOptions();
  }

  setFormVisibility = () => this.showForm = !this.showForm;

  addNew() {
    this.isEdition = false;
    this.setFormVisibility();
    setTimeout(() => {
      this.code.nativeElement.focus();
    }, 0);
  }

  isValidForm(): boolean {
    return this.entityForm.valid
      && this.isRolSelectorValidForm();
  }

  isEditionValidForm(): boolean {
    return this.entityForm.valid
      && this.isRolSelectorValidForm();
  }

  isRolSelectorValidForm(): boolean {
    if (this.rolSelector) {
      return this.rolSelector.isValid();
    }
    return false;
  }

  save() {
    if (this.isValidForm()) {
      this.loading.show();
      const entity: RolPermissionVM = Object.assign({
        PermissionType: PermissionType.ACTION,
        Route: '',
        Roles: this.rolSelector.getSelectedCodes().toString(),
        RolCodes: this.rolSelector.getSelectedCodes(),
      }, this.entityForm.value);

      this.permissionService.insert(entity)
        .subscribe((response: RolPermissionVM) => {
          if (response) {
            response.Roles = this.rolService.getRolNames(response.RolCodes);
            this.grid.addRow(response);
            this.cancel();
            this.loading.hide();
          }
        }, (err) => {
          this.loading.hide();
        });
    }
  }

  cancel() {
    this.rolSelector.reset();
    this.initForm();
    this.setFormVisibility();
  }

  select(value: RolPermissionVM) {
    this.permission = value;
    this.entityForm.setValue({
      Code: this.permission.Code,
      Name: this.permission.Name,
      AuthorizationRequired: this.permission.AuthorizationRequired,
    });
    this.rolSelector.setSelectedCodes(this.permission.RolCodes);
    this.isEdition = true;
    this.setFormVisibility();
    setTimeout(() => {
      this.name.nativeElement.focus();
    }, 0);
  }

  update() {
    if (this.isEditionValidForm()) {
      this.loading.show();
      const entity: RolPermissionVM = Object.assign({
        UUID: this.permission.UUID,
        PermissionType: PermissionType.ACTION,
        Route: '',
        Roles: this.rolSelector.getSelectedCodes().toString(),
        RolCodes: this.rolSelector.getSelectedCodes(),
      }, this.entityForm.value);
      this.permissionService.update(entity)
        .subscribe((response: RolPermissionVM) => {
          if (response) {
            response.Roles = this.rolService.getRolNames(response.RolCodes);
            this.grid.updateRowByUUID(response);
            this.cancel();
            this.loading.hide();
          }
        }, (err) => {
          this.loading.hide();
        });
    }
  }

  delete(value: RolPermissionVM) {
    this.alertify.confirm(
      `Eliminar permiso ${value.Name}`,
      () => {
        this.permissionService.delete(value.UUID)
          .subscribe((response: boolean) => {
            if (response) {
              this.grid.deleteRow(value.UUID);
              this.loading.hide();
            }
          }, (err) => {
            this.loading.hide();
          });
      });
  }

}
