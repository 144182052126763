import { ElementRef } from '@angular/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GenericGridComponent } from '../../../common/components/generic-grid/generic-grid.component';
import { LoadingService } from '../../../common/components/loading/loading.service';
import { AlertifyService } from '../../../common/services/alertify.service';
import { ExternalCredentialVM } from '../../../models/view-models';
import { ExternalCredentialsService } from '../../../services/external-credentials.service';

@Component({
  selector: 'app-external-credentials',
  templateUrl: './external-credentials.component.html',
  styleUrls: ['./external-credentials.component.scss']
})
export class ExternalCredentialsComponent implements OnInit {

  data: ExternalCredentialVM[] = [];
  externalcredential!: ExternalCredentialVM;
  entityForm!: FormGroup;
  isEdition: boolean = false;
  showPassword: boolean = false;

  @ViewChild("grid", { read: GenericGridComponent, static: false })
  public grid: GenericGridComponent;

  @ViewChild("name") name!: ElementRef;
  @ViewChild("username") username!: ElementRef;
  showForm: boolean = false;

  constructor(private edService: ExternalCredentialsService,
    private fb: FormBuilder,
    private alertify: AlertifyService,
    private loading: LoadingService) { }

  ngOnInit(): void {
    this.loadData();
    this.initForm();
  }

  loadData() {
    this.loading.show();
    this.edService.getAll()
      .subscribe((response: ExternalCredentialVM[]) => {
        if (response) {
          this.data = response;
          this.setGridOptions();
        }
        this.loading.hide();
      });
  }

  setFormVisibility = () => this.showForm = !this.showForm;

  addNew() {
    this.isEdition = false;
    this.setFormVisibility();
    this.focusOnNameInput();
  }

  focusOnNameInput = () => setTimeout(() => {
    this.name.nativeElement.focus();
  });

  focusOnUsernameInput = () => setTimeout(() => {
    this.username.nativeElement.focus();
    this.username.nativeElement.select();
  });

  initForm() {
    this.entityForm = this.fb.group({
      Name: ['', [Validators.required]],
      Username: ['', [Validators.required]],
      Password: ['', [Validators.required]],
      SecretKey: ['']
    });
  }

  isValidForm(): boolean {
    return this.entityForm.valid;
  }

  setGridOptions() {
    this.grid.setData(this.data);
    this.grid.options.toolbarTitle = "Credenciales";
    this.grid.options.showDetailsButton = false;
    this.grid.columns = [
      { field: "UUID", title: "Identificador" },
      { field: "Name", title: "Nombre" },
      { field: "Username", title: "Nombre de Usuario" },
    ];
    this.grid.setGridOptions();
  }

  save() {
    if (this.isValidForm()) {
      this.loading.show();
      this.edService.insert(this.entityForm.value)
        .subscribe((response: ExternalCredentialVM) => {
          if (response) {
            this.grid.addRow(response);
            this.cancel();
          }
          this.loading.hide();
        });
    }
  }

  update() {
    if (this.isValidForm()) {
      this.loading.show();
      const updatedEntity: ExternalCredentialVM = Object.assign({
        UUID: this.externalcredential.UUID as string
      }, this.entityForm.value);
      this.edService.update(updatedEntity)
        .subscribe((response: ExternalCredentialVM) => {
          if (response) {
            this.grid.updateRowByUUID(response);
            this.cancel();
          }
          this.loading.hide();
        });
    }
  }

  cancel() {
    this.setFormVisibility();
    this.entityForm.reset();
  }

  edit(value: ExternalCredentialVM) {
    this.externalcredential = value;
    this.entityForm.setValue({
      Name: this.externalcredential.Name,
      Username: this.externalcredential.Username,
      Password: this.externalcredential.Password,
      SecretKey: this.externalcredential.SecretKey,
    });
    this.isEdition = true;
    this.setFormVisibility();
    this.focusOnUsernameInput();
  }

  delete(value: ExternalCredentialVM) {
    this.alertify.confirm(
      `Eliminar credencial: ${value.Name}`,
      () => {
        this.edService.delete(value.UUID as string)
          .subscribe((response: boolean) => {
            if (response) {
              this.grid.deleteRow(value.UUID as string);
            }
            this.loading.hide();
          });
      });
  }

}
