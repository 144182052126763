import { Component } from '@angular/core';
import { AuthenticationService } from '../../../interceptors/authentication.service';
import { LocalData } from '../../../models/LocalData';

@Component({
  selector: 'app-cash-closing',
  templateUrl: './cash-closing.component.html',
  styleUrls: ['./cash-closing.component.scss']
})
export class CashClosingComponent {

  userIsAdmin: boolean = false;

  constructor(private authService: AuthenticationService) {
    this.userIsAdmin = this.authService.SessionUserValue().Rol === LocalData.ADMIN_ROL;
  }

}
