
<nav class="sidebar"
     [ngClass]="{sidebarPushRight: isActive, collapsed: collapsed}">
  <div class="list-group">

    <a routerLink="/settings/general-settings"
       [routerLinkActive]="['router-link-active']"
       class="list-group-item">
      General
    </a>

    <a routerLink="/settings/customize-settings"
       [routerLinkActive]="['router-link-active']"
       class="list-group-item">
      Personalización
    </a>

    <a (click)="logout()"
       style="cursor:pointer;"
       class="list-group-item">
      Cerrar Sesión
    </a>

  </div>
  <div class="toggle-button"
       aria-label="Contraer Menu"
       [ngClass]="{collapsed: collapsed}"
       (click)="toggleCollapsed()">
    <i class="fa fa-fw fa-angle-double-{{collapsed?'right':'left'}}"></i>&nbsp;
    <!--<span class="sr-only">Ocultar Menu</span>-->
    <span>Contraer Menu</span>
  </div>
</nav>
