import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { GenericGridComponent } from '../../../common/components/generic-grid/generic-grid.component';
import { LoadingService } from '../../../common/components/loading/loading.service';
import { ProductCategorySelectorComponent } from '../../../common/components/product-category-selector/product-category-selector.component';
import { AlertifyService } from '../../../common/services/alertify.service';
import { CategoryVM, ProductVM } from '../../../models/view-models';
import { ProductsService } from '../../../services/products.service';

@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.scss']
})
export class InventoryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void { }

}
