
<app-loading></app-loading>

<app-menu></app-menu>

<navigation-shortcuts></navigation-shortcuts>

<div class="container-fluid">
  <router-outlet></router-outlet>
</div>
