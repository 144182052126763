
<div [hidden]="showForm">

  <h3>
    Categorías de Productos
  </h3>

  <button class="btn btn-primary"
          (click)="addNew()">
    Agregar <i class="fa fa-plus"></i>
  </button>

  <hr />
  <br />

  <app-generic-grid #grid
                    (rowToEdit)="edit($event)"
                    (rowToDelete)="delete($event)">
  </app-generic-grid>

</div>

<div [hidden]="!showForm">

  <form [formGroup]="entityForm"
        class="__entityForm">

    <h4 *ngIf="isEdition">
      Editar Categoría
    </h4>

    <h4 *ngIf="!isEdition">
      Agregar Categoría
    </h4>


    <kendo-floatinglabel text="Código">
      <input kendoTextBox name="Code"
             #code
             class="form-control"
             [readOnly]="isEdition"
             maxlength="10"
             [ngClass]="{'is-invalid': entityForm.get('Code').errors && entityForm.get('Code').touched}"
             type="text"
             aria-label="Código"
             placeholder="Código"
             formControlName="Code" />
    </kendo-floatinglabel>

    <kendo-floatinglabel text="Nombre">
      <input kendoTextBox name="Name"
             #name
             class="form-control"
             [ngClass]="{'is-invalid': entityForm.get('Name').errors && entityForm.get('Name').touched}"
             type="text"
             aria-label="Nombre"
             placeholder="Nombre"
             formControlName="Name" />
    </kendo-floatinglabel>

    <kendo-floatinglabel text="Descripción">
      <input kendoTextBox name="Description"
             class="form-control"
             type="text"
             aria-label="Descripción"
             placeholder="Descripción"
             formControlName="Description" />
    </kendo-floatinglabel>

    <div class="d-flex justify-content-end __formButtonsContainer">
      <button class="btn btn-primary"
              *ngIf="!isEdition"
              type="submit"
              [disabled]="!isValidForm()"
              (click)="save()">
        Guardar
      </button>
      <button class="btn btn-primary"
              *ngIf="isEdition"
              type="submit"
              [disabled]="!isValidForm()"
              (click)="update()">
        Guardar cambios
      </button>

      <button class="btn btn-secondary"
              type="button"
              (click)="cancel()">
        Cancelar
      </button>
    </div>

  </form>

</div>
