
<div class="row">
  <div class="col-md-6 col-sm-12">
    <form [formGroup]="stateForm">
      <kendo-floatinglabel text="Estado">
        <kendo-dropdownlist #state
                            formControlName="stateValue"
                            [data]="states"
                            [disabled]="stateDisabled"
                            [textField]="'Name'"
                            [valueField]="'UUID'"
                            required
                            (selectionChange)="stateSelectionChange($event)">
        </kendo-dropdownlist>
      </kendo-floatinglabel>
    </form>
  </div>
  <div class="col-md-6 col-sm-12">
    <form [formGroup]="cityForm">
      <kendo-floatinglabel text="Ciudad / Municipio">
        <kendo-dropdownlist #city
                            formControlName="cityValue"
                            [data]="cities"
                            [disabled]="cityDisabled"
                            [textField]="'Name'"
                            [valueField]="'UUID'"
                            required
                            (selectionChange)="citySelectionChange($event)">
        </kendo-dropdownlist>
      </kendo-floatinglabel>
    </form>
  </div>
</div>
