import { Component, ElementRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProductToSale } from '../../../../../models/view-models';

@Component({
  selector: 'modal-multiple-insertion',
  templateUrl: './multiple-insertion.component.html',
  styleUrls: ['./multiple-insertion.component.scss']
})
export class MultipleInsertionComponent {

  showDialog: boolean = false;
  codeForm: FormGroup;
  dialogForm: FormGroup;
  product: ProductToSale;
  showProductDetails: boolean = false;
  @ViewChild("code") code: ElementRef;
  @ViewChild("quantity") quantity: ElementRef;

  @Output() searchCode: EventEmitter<string> = new EventEmitter<string>();
  @Output() multipleInsertionEmiter: EventEmitter<ProductToSale> = new EventEmitter<ProductToSale>();
  @Output() modalClose: EventEmitter<any> = new EventEmitter<any>();

  disabledInput: boolean = false;
  total: number = 0;

  constructor(private fb: FormBuilder) { }

  initCodeForm() {
    this.codeForm = this.fb.group({
      Code: ['', [Validators.required]]
    });
  }
  initDialogForm() {
    this.dialogForm = this.fb.group({
      Quantity: ['', [Validators.required]],
    });
  }

  focusOnCodeInput() {
    setTimeout(() => {
      this.code.nativeElement.focus();
    }, 0);
  }

  focusOnQuantityInput() {
    setTimeout(() => {
      this.quantity.nativeElement.focus();
    }, 0);
  }

  isCodeFormValid(): boolean { return this.codeForm.valid; }
  isDialogFormValid(): boolean { return this.dialogForm.valid; }

  onFormChanges() {
    this.dialogForm.valueChanges
      .subscribe(value => {
        this.total = value.Quantity * this.product.RetailPrice;
      });
  }

  public openDialog() {
    this.showDialog = true;
    this.initCodeForm();
    this.initDialogForm();

    setTimeout(() => {
      this.code.nativeElement.focus();
    }, 0);
  }

  searchProduct() {
    if (this.isCodeFormValid()) {
      let _code: string = this.codeForm.value["Code"];
      this.searchCode.emit(_code);
    }
  }

  returnProduct() {
    if (this.isDialogFormValid()) {
      this.product.Quantity = this.dialogForm.value["Quantity"];
      this.product.Total = this.total;
      this.total = 0;
      this.multipleInsertionEmiter.emit(this.product);
      this.showDialog = false;
    }
  }

  closeDialog() {
    this.showProductDetails = false;
    this.showDialog = false;
    this.modalClose.emit();
  }

  

}
