import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared.module';
import { CommonComponentsModule } from '../common/common-components.module';

import { SettingsRoutingModule } from './settings-routing.module';

import { SettingsComponent } from './settings.component';
import { FooterComponent } from './dashboard/footer/footer.component';
import { HeaderComponent } from './dashboard/header/header.component';
import { SidebarComponent } from './dashboard/sidebar/sidebar.component';
import { GeneralSettingsComponent } from './components/general-settings/general-settings.component';
import { UnitsComponent } from './components/units/units.component';
import { CategoriesComponent } from './components/categories/categories.component';
import { PaymentMethodsComponent } from './components/payment-methods/payment-methods.component';
import { ChangePasswordFormComponent } from './components/users/change-password-form/change-password-form.component';
import { ShiftsComponent } from './components/shifts/shifts.component';
import { ProductsSettingsComponent } from './components/products-settings/products-settings.component';
import { DeletedProductsComponent } from './components/products-settings/deleted-products/deleted-products.component';
import { ImportProductsComponent } from './components/products-settings/import-products/import-products.component';
import { SandBoxComponent } from './components/sand-box/sand-box.component';
import { UsersComponent } from './components/users/users.component';
import { RolPermissionsComponent } from './components/rol-permissions/rol-permissions.component';
import { PathPermissionsComponent } from './components/rol-permissions/path-permissions/path-permissions.component';
import { ActionPermissionsComponent } from './components/rol-permissions/action-permissions/action-permissions.component';
import { ApiEndpointsComponent } from './components/rol-permissions/api-endpoints/api-endpoints.component';
import { TicketSettingsComponent } from './components/ticket-settings/ticket-settings.component';
import { CustomizeSettingsComponent } from './components/customize-settings/customize-settings.component';
import { ExternalCredentialsComponent } from './components/external-credentials/external-credentials.component';
import { AppLogsComponent } from './components/app-logs/app-logs.component';
import { CurrenciesComponent } from './components/currencies/currencies.component';
import { RemoveRecordsComponent } from './components/remove-records/remove-records.component';


@NgModule({
  declarations: [
    SettingsComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    GeneralSettingsComponent,
    UnitsComponent,
    CategoriesComponent,
    PaymentMethodsComponent,
    TicketSettingsComponent,
    CustomizeSettingsComponent,
    UsersComponent,
    RolPermissionsComponent,
    PathPermissionsComponent,
    ActionPermissionsComponent,
    ApiEndpointsComponent,
    ChangePasswordFormComponent,
    SandBoxComponent,
    ShiftsComponent,
    ProductsSettingsComponent,
    DeletedProductsComponent,
    ImportProductsComponent,
    ExternalCredentialsComponent,
    AppLogsComponent,
    CurrenciesComponent,
    RemoveRecordsComponent,
  ],
  imports: [
    CommonModule,
    SettingsRoutingModule,
    SharedModule,
    CommonComponentsModule,
  ]
})
export class SettingsModule { }
