import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModulesModule } from '../shared-modules.module';
import { LoadingComponent } from './components/loading/loading.component';
import { SearchPipe } from './pipes/search.pipe';
import { ServiceTypeFilterPipe } from './pipes/service-type-filter.pipe';
import { ServiceTypeSelectorComponent } from './components/service-type-selector/service-type-selector.component';
import { GenericGridComponent } from './components/generic-grid/generic-grid.component';
import { LabeledInputComponent } from './components/labeled-input/labeled-input.component';
import { AmountSelectorComponent } from './components/amount-selector/amount-selector.component';
import { GenericListComponent } from './components/generic-list/generic-list.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { NotFoundComponent } from './components/not-found/not-found.component';



@NgModule({
  declarations: [
    LoadingComponent,
    SearchPipe,
    ServiceTypeFilterPipe,
    ServiceTypeSelectorComponent,
    GenericGridComponent,
    LabeledInputComponent,
    AmountSelectorComponent,
    GenericListComponent,
    UnauthorizedComponent,
    NotFoundComponent
  ],
  imports: [
    CommonModule,
    SharedModulesModule,
  ],
  exports: [
    LoadingComponent,
    SearchPipe,
    ServiceTypeFilterPipe,
    ServiceTypeSelectorComponent,
    GenericGridComponent,
    LabeledInputComponent,
    AmountSelectorComponent,
    GenericListComponent,
    UnauthorizedComponent,
    NotFoundComponent,
  ]
})
export class CommonComponentsModule { }
