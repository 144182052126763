
  <nav class="navbar navbar-expand-lg fixed-top">
    <b class="navbar-brand"
       style="cursor:pointer;"
       routerLink="/home/pos/sales">
      Cerrar <i class="fa fa-close"></i>
    </b>
    <button class="navbar-toggler"
            type="button"
            (click)="toggleSidebar()">
      <i class="fa fa-bars fa-2x text-muted" aria-hidden="true"></i>
    </button>
    <div class="collapse navbar-collapse">
      <!--<ul class="navbar-nav ml-auto">-->
      <ul class="navbar-nav mr-auto">
      </ul>
    </div>
  </nav>
