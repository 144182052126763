//#region imports
import { Component, Output, EventEmitter, ViewChild, OnInit, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GenericGridComponent } from '../../../common/components/generic-grid/generic-grid.component';
import { HeaderDetailsComponent } from '../../../common/components/header-details/header-details.component';
import { LoadingService } from '../../../common/components/loading/loading.service';
import { ProductsListSelectorComponent } from '../../../common/components/products-list-selector/products-list-selector.component';
import { AlertifyService } from '../../../common/services/alertify.service';
import { AuthenticationService } from '../../../interceptors/authentication.service';
import { LocalData } from '../../../models/LocalData';
import { MovementVM, ProductToSale, SaleDetailsVM, SaleVM, ModifyTicketDto, PaymentMethodVM, CancelTicketDto, TicketVM, LoginResponse } from '../../../models/view-models';
import { MovementsService } from '../../../services/movements.service';
import { SalesService } from '../../../services/sales.service';
import { ReturnProductsComponent } from './return-products/return-products.component';
import { environment } from '../../../../environments/environment';
import { ModifyTicketComponent } from './modify-ticket/modify-ticket.component';
import { PaymentMethodSelectorComponent } from '../../../common/components/payment-method-selector/payment-method-selector.component';
import { CustomerSelectorComponent } from '../../../common/components/customer-selector/customer-selector.component';
import { SaleStatus } from '../../../models/SaleStatus';
import { DecimalPipe } from '@angular/common';
import { ShiftService } from '../../../services/shift.service';
import { ActionPermissionValidatorService } from '../../../services/action-permission-validator.service';
import { AuthorizationWindowComponent } from '../../../common/components/authorization-window/authorization-window.component';
import { ActionPermissionCode } from '../../../models/ActionPermissionCode';
import { CurrencyService } from '../../../services/currency.service';
//#endregion

@Component({
  selector: 'app-sales-of-the-day',
  templateUrl: './sales-of-the-day.component.html',
  styleUrls: ['./sales-of-the-day.component.scss']
})
export class SalesOfTheDayComponent implements OnInit {

  sales: SaleVM[] = [];
  saleDetails: SaleDetailsVM[] = [];
  selectedSale: SaleVM;
  newSale: SaleVM;
  searchForm: FormGroup;

  movements: MovementVM[] = [];

  @ViewChild("salesGrid", { read: GenericGridComponent, static: false })
  public salesGrid: GenericGridComponent;

  salesDate: Date = new Date();

  showDetails: boolean = false;

  @ViewChild("detailsGrid", { read: GenericGridComponent, static: false })
  public detailsGrid: GenericGridComponent;

  disabledInput: boolean = false;

  @ViewChild("headerDetails", { read: HeaderDetailsComponent })
  public headerDetails: HeaderDetailsComponent;

  isCredit: boolean = false;
  isCard: boolean = false;
  isMulti: boolean = false;
  isCurrency: boolean = false;

  showReturns: boolean = false;
  @ViewChild("returnProducts", { read: ReturnProductsComponent })
  public returnProducts: ReturnProductsComponent;

  userId: string;
  userName: string;

  showModify: boolean = false;
  @ViewChild("modifyTicket", { read: ModifyTicketComponent })
  public modifyTicket: ModifyTicketComponent;

  chargeDialogOpen: boolean = false;
  @ViewChild("paymentMethodSelector", { read: PaymentMethodSelectorComponent, static: false })
  public paymentMethodSelector: PaymentMethodSelectorComponent;
  paymentMethods: PaymentMethodVM[] = [];
  @ViewChild("customerSelector", { read: CustomerSelectorComponent, static: false })
  public customerSelector: CustomerSelectorComponent;
  chargeForm: FormGroup;
  @ViewChild("paid") paid: ElementRef;
  @ViewChild("reference") reference: ElementRef;
  change: number = 0;
  hideReferenceInput: boolean = true;
  hideCustomerSelector: boolean = true;
  isPaid: boolean = true;
  total: number = 0;

  updatedProducts: ProductToSale[] = [];

  saleStatus: string;
  detailsType: string = "";

  showCashDialog: boolean = false;
  cashDialogTitle: string = "";
  selectedMovement: MovementVM;

  cancelTicketButtonDisabled: boolean = false;
  returnProductsButtonDisabled: boolean = false;
  modifyTicketButtonDisabled: boolean = false;

  saleContainsUniqueProduct: boolean = false;

  isMultiplePaymentMethod: boolean = false;
  isCreditPaymentMethod: boolean = false;
  isCurrencyPaymentMethod: boolean = false;

  @ViewChild("authorizationWindow", { read: AuthorizationWindowComponent })
  public authorizationWindow: AuthorizationWindowComponent;

  selectedTicketId: number | null;

  constructor(private salesService: SalesService,
    private movementsService: MovementsService,
    private authService: AuthenticationService,
    private shiftService: ShiftService,
    private actionValidatorService: ActionPermissionValidatorService,
    private fb: FormBuilder,
    private decimalPipe: DecimalPipe,
    private alertify: AlertifyService,
    private loading: LoadingService,
    private currencyService: CurrencyService) {
    let usr: LoginResponse = this.authService.SessionUserValue();
    this.userId = usr.UUID;
    this.userName = usr.User;
  }

  ngOnInit(): void {
    this.initSearchForm();
    this.cancelTicketButtonDisabled = this.actionValidatorService.cancelTicket();
    this.returnProductsButtonDisabled = this.actionValidatorService.returnTicket();
    this.modifyTicketButtonDisabled = this.actionValidatorService.modifyTicket();
    this.loadData();
  }

  initSearchForm() {
    this.searchForm = this.fb.group({
      ticket: ['', [Validators.required]]
    });
  }

  isValidForm(): boolean {
    return this.searchForm.valid;
  }

  disableActionButtons(): boolean {
    if (this.selectedSale) {
      if (this.selectedSale.Status !== SaleStatus.Active) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  }

  loadData() {
    this.loading.show();
    this.movements = [];
    this.movementsService.getMovementsFromDate(this.salesDate)
      .subscribe((response: MovementVM[]) => {
        if (response) {
          this.movements = response;
        }
        this.setGridOptions();
        this.loading.hide();
      }, (err) => {
        this.loading.hide();
        console.log(err);
      });
  }

  setGridOptions() {
    this.salesGrid.setData(this.movements);
    this.salesGrid.options.toolbarTitle = "Movimientos del Día";
    this.salesGrid.options.paging = false;
    this.salesGrid.options.hideActionButtons = true;
    this.salesGrid.options.rowClick = true;
    this.salesGrid.options.showDeleteButton = false;
    this.salesGrid.columns = [
      { field: "Ticket", title: "Ticket" },
      { field: "CreateDate", title: "Hora", timeType: true },
      { field: "UserName", title: "Usuario" },
      { field: "MovementTypeName", title: "Tipo" },
      { field: "PaymentMethodName", title: "Método de Pago" },
      { field: "Amount", title: "Monto", currencyType: true },
    ];
    this.salesGrid.setGridOptions();
  }

  onDateChange(selectedDate: Date) {
    this.salesDate = selectedDate;
    this.loadData();
  }

  select(mov: MovementVM) {
    this.selectedTicketId = mov.Ticket;
    this.detailsType = mov.MovementTypeName;
    if (mov.MovementType === 'I' || mov.MovementType === 'O') {
      this.openCashDialog(mov);
    } else {
      this.getSaleById(mov.SaleId);
    }
    
    
  }

  getSaleById(saleId: string) {
    this.loading.show();
    this.isCard = false;
    this.isCredit = false;
    this.isMulti = false;
    this.isCurrency = false;
    this.salesService.getById(saleId)
      .subscribe((response: SaleVM) => {
        if (response) {
          this.selectedSale = response;
          this.isCreditPaymentMethod = this.selectedSale.PaymentMethodCode === LocalData.PAYMENT_METHOD_CREDIT;
          this.isMultiplePaymentMethod = this.selectedSale.PaymentMethodCode === LocalData.PAYMENT_METHOD_MULTIPLE;
          this.isCurrencyPaymentMethod = this.currencyService.getLocalCurrency(this.selectedSale.CurrencyCode) != null;

          this.validateUniqueProducts();
          this.setSaleHeaderDetails();
          this.setSaleStatus(response.Status)
        }
        this.setDetailsGridOptions();
        this.loading.hide();
        this.showDetails = true;
      }, (err) => {
        this.loading.hide();
      });

  }

  setSaleHeaderDetails() {
    this.headerDetails.setHeaders([
      { Title: "Ticket", Value: this.selectedSale.TicketId },
      { Title: "Fecha", Value: this.selectedSale.CreateDate, IsDate: true },
      { Title: "Hora", Value: this.selectedSale.CreateDate, IsTime: true },
      { Title: "Usuario", Value: this.selectedSale.Username },
      { Title: "Método Pago", Value: this.selectedSale.PaymentMethodName },
      { Title: "Total", Value: this.selectedSale.Total, IsCurrency: true },
      { Title: "Pagado", Value: this.selectedSale.Paid, IsCurrency: true },
      { Title: "Cambio", Value: this.selectedSale.Change, IsCurrency: true },
    ]);

    if (this.selectedSale.PaymentMethodCode === LocalData.PAYMENT_METHOD_CREDIT) {
      this.isCredit = true;
    }
    if (this.selectedSale.PaymentMethodCode === LocalData.PAYMENT_METHOD_CARD
      || this.selectedSale.PaymentMethodCode === LocalData.PAYMENT_METHOD_EBANK) {
      this.isCard = true;
    }
    if (this.selectedSale.PaymentMethodCode === LocalData.PAYMENT_METHOD_MULTIPLE) {
      this.isMulti = true;
    }

    if (this.currencyService.getLocalCurrency(this.selectedSale.CurrencyCode) != null) {
      this.isCurrency = true;
    }
  }

  setDetailsGridOptions() {
    this.detailsGrid.setData(this.selectedSale.SaleDetails);
    this.detailsGrid.options.toolbarTitle = "Productos";
    this.detailsGrid.options.hideActionButtons = true;
    this.detailsGrid.options.showToolbar = false;
    this.detailsGrid.options.paging = false;
    this.detailsGrid.columns = [
      { field: "ProductCode", title: "Código" },
      { field: "ProductName", title: "Producto" },
      { field: "Quantity", title: "Cantidad" },
      { field: "UnitName", title: "Unidad" },
      { field: "UnitPrice", title: "Precio", currencyType: true },
      { field: "TotalPrice", title: "Total", currencyType: true },
    ];
    this.detailsGrid.setGridOptions();
  }

  validateUniqueProducts() {
    this.saleContainsUniqueProduct = false;
    this.selectedSale.SaleDetails.forEach(item => {
      if (item.IsUnique) {
        this.saleContainsUniqueProduct = true;
      }
    });
  }

  closeDetails() {
    this.selectedTicketId = null;
    this.disabledInput = false;
    this.isCredit = false;
    this.isCard = false;
    this.isMulti = false;
    this.initSearchForm();
    this.showDetails = false;
    this.selectedSale = null;
  }

  searchTicket() {
    this.loading.show();
    this.disabledInput = true;
    let ticketId = this.searchForm.controls["ticket"].value;
    this.salesService.getByTicketId(ticketId)
      .subscribe((response: SaleVM) => {
        if (response) {
          this.selectedSale = response;
          this.setSaleHeaderDetails();
          this.setSaleStatus(response.Status)
          this.setDetailsGridOptions();
          this.showDetails = true;
        } else {
          this.alertify.warning("No se encontró el ticket " + ticketId);
          this.disabledInput = false;
        }
        this.loading.hide();
      }, (err) => {
        this.loading.hide();
      });
  }

  showCancelTicketConfirmation() {
    if (!this.cancelTicketButtonDisabled) {
      this.alertify.confirm(
        `Cancelar ticket #${this.selectedSale.TicketId}`,
        () => {
          this.cancelTicket();
        });
    } 
  }

  cancelTicket() {
    if (this.actionValidatorService.requiresAuthorization(ActionPermissionCode.CANCELATION)
      && !this.actionValidatorService.canExecuteAction(ActionPermissionCode.CANCELATION)) {
      this.authorizationWindow.openAuthorizationWindowForTicketCancelation(this.selectedSale);
    } else {
      this.loading.show();
      let cancelation: CancelTicketDto = {
        UserId: this.userId,
        Sale: this.selectedSale,
        ShiftId: this.shiftService.getActiveShiftId()
      };
      this.salesService.cancelSale(cancelation)
        .subscribe((response: TicketVM) => {
          if (response) {
            this.openTicket(response.Url);
            this.isCredit = false;
            this.isCard = false;
            this.isMulti = false;
            this.showDetails = false;
            this.selectedSale = null;
            this.loadData();
          }
          this.loading.hide();
        }, (err) => {
          this.loading.hide();
        });
    }
  }

  showReturnProducts() {
    if (!this.returnProductsButtonDisabled) {
      this.returnProducts.products = this.selectedSale.SaleDetails;
      this.returnProducts.setProductsList();
      this.showReturns = true;
    }
  }

  reset() { }

  rpGetSelectedProducts(selectedProducts: SaleDetailsVM[]) {
    if (this.actionValidatorService.requiresAuthorization(ActionPermissionCode.RETURNS)
      && !this.actionValidatorService.canExecuteAction(ActionPermissionCode.RETURNS)) {
      this.authorizationWindow.openAuthorizationWindowForReturnProducts(this.selectedSale, selectedProducts, this.returnProducts.getUnselectedProducts());
      this.showReturns = false;
    } else {
      this.loading.show();
      let saleReturns: ModifyTicketDto = {
        UserId: this.userId,
        CurrentSale: this.selectedSale,
        NewSale: this.createNewSale()
      };

      this.salesService.returnProducts(saleReturns)
        .subscribe((response: TicketVM[]) => {
          if (response) {
            response.forEach(item => {
              this.openTicket(item.Url);
            });
            this.isCard = false;
            this.isCredit = false;
            this.isMulti = false;
            this.showReturns = false;
            this.showDetails = false;
            this.selectedSale = null;
            this.loadData();
          }
        }, (err) => {
          this.loading.hide();
        });
    }
  }

  rpCancel = () => this.showReturns = false;

  openTicket(filename: string): void {
    const url = `${environment.ticketsUrl}/${filename}`;
    window.open(url, '_blank');
  }

  showModifyTicket() {
    if (!this.modifyTicketButtonDisabled) {
      this.modifyTicket.selectedSale = this.selectedSale;
      this.modifyTicket.getData();
      this.showModify = true;
    }
  }

  mtSave(products: ProductToSale[]) {
    this.updatedProducts = products;
    this.total = this.modifyTicket.total;
    this.chargeDialogOpen = true;
    this.initChargeForm();
    

  }

  mtCancel = () => this.showModify = false;

  initChargeForm() {
    this.chargeForm = this.fb.group({
      Paid: [this.setFormatNumber(this.selectedSale.Total), [Validators.required]],
      BankReference: [''],
    });
    this.change = 0;
    this.onChargeFormChanges();
    this.focusOnPaidInput();
  }

  setFormatNumber(value: any) {
    let tmp = this.decimalPipe.transform(value, '1.2-2', 'mx');
    tmp = tmp.replace(',', '');
    return tmp;
  }

  onChargeFormChanges() {
    this.chargeForm.valueChanges
      .subscribe(value => {
        if (this.hideCustomerSelector) {
          this.change = value.Paid - this.total;
        }
      });
  }

  getSaleDetails(): SaleDetailsVM[] {
    let details: SaleDetailsVM[] = [];

    this.updatedProducts.forEach((item: ProductToSale) => {
      details.push({
        ProductId: item.UUID,
        ProductName: item.Name,
        UsesInventory: item.UsesInventory,
        Quantity: item.Quantity,
        UnitId: item.UnitId,
        UnitName: item.UnitName,
        CategoryId: item.CategoryId,
        CategoryName: item.CategoryName,
        UnitPrice: item.RetailPrice,
        TotalPrice: item.Quantity * item.RetailPrice,
        ShiftId: this.shiftService.getActiveShiftId()
      });
    });

    return details;
  }

  paymentMethodSelect(method: PaymentMethodVM) {
    this.hideReferenceInput = method.Code !== LocalData.PAYMENT_METHOD_CARD;
    this.hideCustomerSelector = method.Code !== LocalData.PAYMENT_METHOD_CREDIT;
    this.isPaid = this.hideCustomerSelector;

    if (method.Code === LocalData.PAYMENT_METHOD_CARD) {
      this.chargeForm.controls["Paid"].setValue(this.total);
      this.focusOnReferenceInput();
    }
  }

  focusOnPaidInput() {
    setTimeout(() => {
      this.paid.nativeElement.focus();
      this.paid.nativeElement.select();
    }, 0);
  }

  focusOnReferenceInput() {
    setTimeout(() => {
      this.reference.nativeElement.focus();
    }, 0);
  }

  isChargeValidForm(): boolean {
    return this.chargeForm.valid
      && this.total > 0
      && this.change >= 0
      && this.customerCreditValid();
  }

  customerCreditValid(): boolean {
    if (!this.hideCustomerSelector) {
      return this.customerSelector.isValidForm();
    } else {
      return true;
    }
  }

  createCharge() {
    if (this.isChargeValidForm()
      && this.paymentMethodSelector.isValidForm()) {
      let newSale: SaleVM = Object.assign({
        UserId: this.userId,
        Username: this.userName,
        PaymentMethodCode: this.paymentMethodSelector.getItemCode(),
        PaymentMethodName: this.paymentMethodSelector.getItemName(),
        Total: this.total,
        Change: this.change,
        CreateDate: new Date(),
        SaleDetails: this.getSaleDetails(),
        TicketId: this.selectedSale.TicketId,
        CustomerId: this.customerSelector.getItemId(),
        CustomerName: this.customerSelector.getItemFullName(),
        IsPaid: this.isPaid,
        Status: SaleStatus.Active,
        ShiftId: this.shiftService.getActiveShiftId()
      }, this.chargeForm.value);

      let modifiedTicket: ModifyTicketDto = {
        UserId: this.userId,
        CurrentSale: this.selectedSale,
        NewSale: newSale
      };

      if (this.actionValidatorService.requiresAuthorization(ActionPermissionCode.MODIFY)
        && !this.actionValidatorService.canExecuteAction(ActionPermissionCode.MODIFY)) {
        this.chargeDialogOpen = false;
        this.showModify = false;
        this.authorizationWindow.openAuthorizationWindowForModifyTicket(modifiedTicket);
      } else {
        this.loading.show();
        this.salesService.modifyTicket(modifiedTicket)
          .subscribe((response: TicketVM) => {
            if (response) {
              this.chargeDialogOpen = false;
              this.openTicket(response.Url);
              this.showModify = false;
              this.showDetails = false;
              //this.selectedSale = null;
              this.closeDetails();
              this.loadData();
            }
          }, (err) => {
            this.loading.hide();
          });
      }
    }
  }

  closeChargeDialog = () => this.chargeDialogOpen = false;

  setSaleStatus(ss: string) {
    switch (ss) {
      case 'A': this.saleStatus = "Activo";
        break;
      case 'C': this.saleStatus = "Cancelado";
        break;
      case 'R': this.saleStatus = "Devuelto";
        break;
      case 'M': this.saleStatus = "Modificado";
        break;
      default: return "";
    }
  }

  createNewSale(): SaleVM {
    let newSaleProducts: SaleDetailsVM[] = this.returnProducts.getUnselectedProducts();
    let total: number = 0;
    newSaleProducts.forEach(item => {
      total = total + item.TotalPrice;
    });
    return {
      UserId: this.userId,
      Username: this.userName,
      PaymentMethodCode: this.selectedSale.PaymentMethodCode,
      PaymentMethodName: this.selectedSale.PaymentMethodName,
      MultiplePayments: this.selectedSale.MultiplePayments,
      Total: total,
      Paid: this.selectedSale.Total,
      Change: this.selectedSale.Total - total,
      BankReference: this.selectedSale.BankReference,
      SaleDetails: newSaleProducts,
      CustomerId: this.selectedSale.CustomerId,
      CustomerName: this.selectedSale.CustomerName,
      IsPaid: this.selectedSale.IsPaid,
      Status: SaleStatus.Active,
      ShiftId: this.selectedSale.ShiftId,
    };
  }

  openCashDialog(mov: MovementVM) {
    this.selectedMovement = mov;
    this.cashDialogTitle = this.selectedMovement.MovementTypeName;
    this.showCashDialog = true;    
  }

  closeCashDialog() {
    this.showCashDialog = false;
    this.selectedMovement = null;
  }

  openCashTicket() {
    alert("Abriendo ticket");
  }

  authorizedTicketCanceled(value: boolean) {
    if (value) {
      this.isCredit = false;
      this.isCard = false;
      this.isMulti = false;
      this.showDetails = false;
      this.selectedSale = null;
      this.loadData();
    }
  }
  
  authorizedProductsReturned(value: boolean) {
    if (value) {
      this.isCard = false;
      this.isCredit = false;
      this.isMulti = false;
      this.showReturns = false;
      this.showDetails = false;
      this.selectedSale = null;
      this.loadData();
    }
  }

  authorizedTicketModified(value: boolean) {
    if (value) {
      this.showDetails = false;
      this.closeDetails();
      this.loadData();
    }
  }


}
