
<h3>Cortes</h3>

<hr />
<br />

<kendo-tabstrip>
  <kendo-tabstrip-tab title="Turno Simple" [selected]="true">
    <ng-template kendoTabContent>
      <br />
      <single-shift-cash-close></single-shift-cash-close>
    </ng-template>
  </kendo-tabstrip-tab>
  <kendo-tabstrip-tab title="Turnos Múltiples" [disabled]="!userIsAdmin">
    <ng-template kendoTabContent>
      <br />
      <multiple-shift-cash-close></multiple-shift-cash-close>
    </ng-template>
  </kendo-tabstrip-tab>
</kendo-tabstrip>
