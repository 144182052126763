import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AlertifyService } from '../common/services/alertify.service';
import { RolVM, OperationResult } from '../models/view-models';
import { map } from 'rxjs/operators';
import { ErrorService } from '../interceptors/error.service';
import { LocalData } from '../models/LocalData';

@Injectable({
  providedIn: 'root'
})
export class RolService {

  apiUrl: string = `${environment.apiUrl}/Rol`;

  constructor(private http: HttpClient,
    private error: ErrorService,
    private alertify: AlertifyService) { }

  update(rol: RolVM) {
    return this.http.put(this.apiUrl, rol)
      .pipe(map((response: OperationResult<RolVM>) => {
        if (response.Success) {
          this.alertify.success("Se editó el rol");
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }

  getAll() {
    return this.http.get(this.apiUrl)
      .pipe(map((response: OperationResult<RolVM[]>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }

  getById(uuid: string) {
    const uri = `${this.apiUrl}/GetByIdentifier?uuid=${uuid}`;
    return this.http.get(uri)
      .pipe(map((response: OperationResult<RolVM>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }

  // load data and save it to local
  loadAndSaveData() {
    if (localStorage.getItem(LocalData.ROLES) === null) {
      this.getAll()
        .subscribe((response: RolVM[]) => {
          if (response) {
            response.sort((m1, m2) => {
              if (m1.Name > m2.Name) { return 1; }
              else if (m1.Name < m2.Name) { return 1; }
              else { return 0; }
            });
            localStorage.setItem(LocalData.ROLES, JSON.stringify(response));
          }
        });
    }
  }

  getRolNames(_roles: string[]): string {
    let result: string[] = [];
    _roles.forEach(rol => {
      switch (rol) {
        case "ADMIN": result.push("Administrador");
          break;
        case "CASHIER": result.push("Cajero");
          break;
        case "SUPERVISOR": result.push("Supervisor");
          break;
        default:
      }
    });

    return result.toString();
  }
}
