import { Component, OnInit } from '@angular/core';
import { SettingsItem } from '../../../models/ComponentModels';

@Component({
  selector: 'app-customize-settings',
  templateUrl: './customize-settings.component.html',
  styleUrls: ['./customize-settings.component.scss']
})
export class CustomizeSettingsComponent implements OnInit {

  items: SettingsItem[] = [
    {
      Title: 'Ticket de Venta',
      Url: '/settings/ticket-settings',
      Icon: 'fa-ticket'
    },
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
