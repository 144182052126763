import { Component } from '@angular/core';

@Component({
  selector: 'service-pay',
  templateUrl: './service-pay.component.html',
  styleUrls: ['./service-pay.component.scss']
})
export class ServicePayComponent {

  title = 'frontend';

  constructor() { }
}
