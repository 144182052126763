import { Component, OnInit, ViewChild } from '@angular/core';
import { DateRangeSelectorComponent } from '../../../../common/components/date-range-selector/date-range-selector.component';
import { GenericGridComponent } from '../../../../common/components/generic-grid/generic-grid.component';
import { LoadingService } from '../../../../common/components/loading/loading.service';
import { AlertifyService } from '../../../../common/services/alertify.service';
import { AuthenticationService } from '../../../../interceptors/authentication.service';
import { ReportType } from '../../../../models/ReportType';
import { CARDEXReport, LoginResponse, ReportRequestVM } from '../../../../models/view-models';
import { ReportsService } from '../../../../services/reports.service';

@Component({
  selector: 'app-cardex-report',
  templateUrl: './cardex-report.component.html',
  styleUrls: ['./cardex-report.component.scss']
})
export class CARDEXReportComponent implements OnInit {

  reportRequest: ReportRequestVM;
  userId: string;
  report: CARDEXReport[] = [];
  showReport: boolean = false;

  @ViewChild("dateRangeSelector", { read: DateRangeSelectorComponent })
  public dateRangeSelector: DateRangeSelectorComponent;

  @ViewChild("reportGrid", { read: GenericGridComponent, static: true })
  public reportGrid: GenericGridComponent;

  constructor(private reportsService: ReportsService,
    private authService: AuthenticationService,
    private alertify: AlertifyService,
    private loading: LoadingService) {
    let usr: LoginResponse = this.authService.SessionUserValue();
    this.userId = usr.UUID;
  }

  ngOnInit(): void {
  }

  isValidForm(): boolean {
    if (this.dateRangeSelector) {
      return this.dateRangeSelector.areDatesValid();
    }
    return true;
  }

  createReport() {
    if (this.isValidForm()) {
      this.loading.show();
      this.reportRequest = {
        StartDate: this.dateRangeSelector.startDate,
        EndDate: this.dateRangeSelector.endDate,
        userId: this.userId,
        ReportType: ReportType.CARDEX
      };
      this.reportsService.createCARDEXReport(this.reportRequest)
        .subscribe((response: CARDEXReport[]) => {
          if (response) {
            this.report = response;
            this.showReport = true;
          }
          this.setGridOptions();
          this.loading.hide();
        }, (err) => {
          this.loading.hide();
        });
    } else {
      this.alertify.warning("La fecha inicial debe ser menor que la fecha final")
    }
  }

  setGridOptions() {
    this.reportGrid.setData(this.report);
    this.reportGrid.options.toolbarTitle = "Reporte de CARDEX";
    this.reportGrid.options.hideActionButtons = true;
    this.reportGrid.options.paging = false;
    this.reportGrid.columns = [
      { field: "Date", title: "Fecha", dateType: true },
      { field: "ProductName", title: "Producto" },
      { field: "ProductCode", title: "Código" },
      { field: "MovementName", title: "Tipo Operación" },
      { field: "InitialQuantity", title: "Cantidad Inicial", numberType: true },
      { field: "Quantity", title: "Cantidad", numberType: true },
      { field: "EndingQuantity", title: "Cantidad Final", numberType: true },
    ];
    this.reportGrid.setGridOptions();
  }

}
