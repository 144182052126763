import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingService } from '../../../common/components/loading/loading.service';
import { AlertifyService } from '../../../common/services/alertify.service';
import { AuthenticationService } from '../../../interceptors/authentication.service';
import { LoginResponse } from '../../../models/view-models';
import { ConfigurationService } from '../../../services/configuration.service';

@Component({
  selector: 'app-remove-records',
  templateUrl: './remove-records.component.html',
  styleUrls: ['./remove-records.component.scss']
})
export class RemoveRecordsComponent {

  continue: boolean = false;

  allowed: boolean = false;

  constructor(private configurationService: ConfigurationService,
    private loading: LoadingService,
    private authService: AuthenticationService,
    private router:Router,
    private alertify: AlertifyService) {
    let usr: LoginResponse = this.authService.SessionUserValue();
    if (usr.User !== 'developer') {
      this.router.navigate(["/settings/general-settings"]);
    } else {
      this.allowed = true;
    }
  }

  removeRecords() {
    this.alertify.confirm(
      '¿Está seguro de que desea eliminar los registros?<br /><br />Esta acción no se puede deshacer.',
      () => {
        this.loading.show();
        this.configurationService.truncateOperationTables()
          .subscribe((response: boolean) => {
            if (response) {
              this.alertify.success("Se eliminaron los registros exitosamente");
            }
            this.loading.hide();
          });
      });
  }

}
