
<kendo-dialog title="Confirmar Eliminación"
              *ngIf="showDialog"
              (close)="closeDialog()"
              [minWidth]="250"
              [width]="600">

  <p>{{ msg || '' }}</p>

  <form [formGroup]="dialogForm"
        class="__entityForm">

    <kendo-floatinglabel text="Motivo de eliminación">
      <textarea kendo-textarea name="Reason"
                #reason
                class="form-control"
                aria-label="Motivo de eliminación"
                placeholder="Motivo de eliminación"
                maxlength="250"
                formControlName="Reason"></textarea>
    </kendo-floatinglabel>

    <hr />

    <kendo-dialog-actions>
      <div class="d-flex justify-content-between align-items-center">
        <button class="btn btn-primary"
                type="submit"
                [disabled]="!isValidForm()"
                (click)="confirmDeletion()">
          Eliminar
        </button>
        <button class="btn btn-primary"
                type="button"
                (click)="closeDialog()">
          Cancelar
        </button>
      </div>
    </kendo-dialog-actions>
  </form>

</kendo-dialog>
