
<kendo-window title="Autorización requerida"
              *ngIf="opened"
              (close)="closeWindow()"
                    [resizable]="false"
                    [draggable]="false"
              [minWidth]="600"
              [minHeight]="450"
              [width]="screenWidth"
              [height]="screenHeight">

  <div class="row">
    <div class="col-md-8 col-sm-12 mx-auto">

      <!-- authorize movement -->
      <div *ngIf="authorizeMovement">
        <h3>
          Crear {{ movement.MovementTypeName }}
        </h3>
        <hr />

        <div class="list-group">
          <div class="list-group-item d-flex justify-content-between align-items-center">
            <b>Monto</b>
            <span class="badge">{{ movement.Amount | currency }}</span>
          </div>
          <div class="list-group-item list-group-item-action flex-column align-items-start">
            <div class="d-flex w-100 justify-content-between">
              <b class="mb-1">
                Concepto
              </b>
            </div>
            <p class="mb-1">
              {{ movement.Concept }}
            </p>
          </div>
        </div>

      </div>

      <!-- authorize ticket cancelation -->
      <div *ngIf="authorizeTicketCancelation">
        <h3>
          Cancelar Ticket
        </h3>
        <hr />

        <div class="list-group">
          <div class="list-group-item d-flex justify-content-between align-items-center">
            <b>Ticket</b>
            <span class="badge">{{ saleToCancel.TicketId }}</span>
          </div>
          <div class="list-group-item d-flex justify-content-between align-items-center">
            <b>Total</b>
            <span class="badge">{{ saleToCancel.Total | currency }}</span>
          </div>
          <div class="list-group-item d-flex justify-content-between align-items-center">
            <b>Método de Pago</b>
            <span class="badge">{{ saleToCancel.PaymentMethodName }}</span>
          </div>
        </div>

      </div>

      <!-- authorize return products -->
      <div *ngIf="authorizeReturnProducts">
        <h3>
          Devolver Productos
        </h3>
        <hr />

        <h5>Información de la Venta</h5>
        <div class="list-group">
          <div class="list-group-item d-flex justify-content-between align-items-center">
            <b>Ticket</b>
            <span class="badge">{{ selectedSale.TicketId }}</span>
          </div>
          <div class="list-group-item d-flex justify-content-between align-items-center">
            <b>Total</b>
            <span class="badge">{{ selectedSale.Total | currency }}</span>
          </div>
          <div class="list-group-item d-flex justify-content-between align-items-center">
            <b>Método de Pago</b>
            <span class="badge">{{ selectedSale.PaymentMethodName }}</span>
          </div>
        </div>

        <hr />

        <h5>Productos a devolver</h5>
        <div class="list-group">
          <div class="list-group-item d-flex justify-content-between align-items-center"
               *ngFor="let product of productsToReturn">
            <b>{{ product.ProductName }}</b>
            <span class="badge">{{ product.TotalPrice | currency }}</span>
          </div>
        </div>

      </div>

      <!-- authorize ticket modification -->
      <div *ngIf="authorizeModifyTicket">
        <h3>
          Modificar Ticket
        </h3>
        <hr />

        <h5>Información de la Venta</h5>
        <div class="list-group">
          <div class="list-group-item d-flex justify-content-between align-items-center">
            <b>Ticket</b>
            <span class="badge">{{ modifiedTicket.CurrentSale.TicketId }}</span>
          </div>
          <div class="list-group-item d-flex justify-content-between align-items-center">
            <b>Total Anterior</b>
            <span class="badge">{{ modifiedTicket.CurrentSale.Total | currency }}</span>
          </div>
          <div class="list-group-item d-flex justify-content-between align-items-center">
            <b>Método de Pago Anterior</b>
            <span class="badge">{{ modifiedTicket.CurrentSale.PaymentMethodName }}</span>
          </div>
          <div class="list-group-item d-flex justify-content-between align-items-center">
            <b>Total Actual</b>
            <span class="badge">{{ modifiedTicket.NewSale.Total | currency }}</span>
          </div>
          <div class="list-group-item d-flex justify-content-between align-items-center">
            <b>Método de Pago Actual</b>
            <span class="badge">{{ modifiedTicket.NewSale.PaymentMethodName }}</span>
          </div>
        </div>

        <hr />

        <h5>Productos a devolver</h5>
        <div class="list-group">
          <div class="list-group-item d-flex justify-content-between align-items-center"
               *ngFor="let product of productsToReturn">
            <b>{{ product.ProductName }}</b>
            <span class="badge">{{ product.TotalPrice | currency }}</span>
          </div>
        </div>

      </div>

      <!-- loading -->
      <div class="text-center"
           [hidden]="!loading">
        <i class="fa fa-spinner fa-spin fa-3x"></i>
        <br />
        <b>Espere...</b>
      </div>

      <form [formGroup]="dialogForm"
            [hidden]="loading"
            class="__entityForm">

        <div class="row">
          <div class="col-md-6 col-sm-12">
            <kendo-floatinglabel text="Usuario">
              <input kendoTextBox name="user"
                     #user
                     class="form-control"
                     type="text"
                     aria-label="Usuario / Código"
                     placeholder="Usuario"
                     formControlName="user" />
            </kendo-floatinglabel>
          </div>
          <div class="col-md-6 col-sm-12">
            <kendo-floatinglabel text="Contraseña">
              <input kendoTextBox name="password"
                     class="form-control"
                     type="password"
                     aria-label="Contraseña"
                     placeholder="Contraseña"
                     formControlName="password" />
            </kendo-floatinglabel>
          </div>
          <div class="col-12" *ngIf="authorizeError">
            <p class="bg-warning">
              Error al iniciar sesión
            </p>
          </div>
        </div>

        <div class="k-actions k-actions-end">
          <button class="btn btn-primary"
                  type="submit"
                  [disabled]="!isValidForm() || buttonDisabled"
                  (click)="authorize()">
            Autorizar
          </button>
          <button class="btn btn-primary"
                  type="button"
                  (click)="closeWindow()">
            Cancelar
          </button>
        </div>

      </form>
    </div>
  </div>


</kendo-window>
