import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { StateVM, CityVM } from '../../../models/view-models';
import { StatesAndCitiesService } from '../../services/states-and-cities.service';
import { LoadingService } from '../loading/loading.service';

@Component({
  selector: 'app-state-and-city-selector',
  templateUrl: './state-and-city-selector.component.html',
  styleUrls: ['./state-and-city-selector.component.scss']
})
export class StateAndCitySelectorComponent implements OnInit {

  public states: StateVM[] = [];
  public selectedState: StateVM;
  stateDisabled: boolean = true;

  public stateForm: FormGroup = new FormGroup({
    stateValue: new FormControl()
  });

  public cities: CityVM[] = [];
  public selectedCity: CityVM;
  cityDisabled: boolean = true;

  public cityForm: FormGroup = new FormGroup({
    cityValue: new FormControl()
  });

  constructor(private dataService: StatesAndCitiesService,
    private loading: LoadingService) { }

  ngOnInit(): void {
    this.loadStates();
  }

  // states methods

  loadStates() {
    this.dataService.getStates()
      .subscribe((response: StateVM[]) => {
        if (response) {
          response.sort((s1, s2) => {
            if (s1.Name > s2.Name) { return 1; }
            else if (s1.Name < s2.Name) { return -1; }
            else { return 0; }
          });
          this.states = response;
        }
        this.stateDisabled = this.states.length <= 0;
        this.cityDisabled = this.cities.length <= 0;        
      });
  }

  isStateValidForm(): boolean {
    return this.stateForm.valid;
  }

  getState(): StateVM {
    if (this.isStateValidForm()) {
      return this.selectedState;
    }
    return null;
  }

  getStateId(): string {
    if (this.isStateValidForm()) {
      return this.selectedState.UUID;
    }
    return "";
  }

  getStateName(): string {
    if (this.isStateValidForm()) {
      return this.selectedState.Name;
    }
    return "";
  }

  stateSelectionChange(value: StateVM) {
    this.selectedState = value;
    this.loadCities(this.selectedState.UUID);
  }

  setStateById(uuid: string) {
    if (this.states && this.states.length > 0 && uuid != null && uuid != "") {
      this.selectedState = this.states.find(item => item.UUID === uuid);
      this.stateForm.setValue({
        stateValue: this.selectedState
      });
    }
  }

  // cities methods

  loadCities(stateId: string) {
    this.loading.show();
    this.dataService.getCitiesFromState(stateId)
      .subscribe((response: CityVM[]) => {
        if (response) {
          this.cities = response;
        }
        this.cityDisabled = this.states.length <= 0;
        this.loading.hide();
      });
  }

  isCityValidForm(): boolean {
    return this.cityForm.valid;
  }

  getCity(): CityVM {
    if (this.isCityValidForm()) {
      return this.selectedCity;
    }
    return null;
  }

  getCityId(): string {
    if (this.isCityValidForm()) {
      return this.selectedCity.UUID;
    }
    return "";
  }

  getCityName(): string {
    if (this.isCityValidForm()) {
      return this.selectedCity.Name;
    }
    return "";
  }

  citySelectionChange(value: CityVM) {
    this.selectedCity = value;
  }

  setCityById(uuid: string) {
    if (this.cities && this.cities.length > 0 && uuid != null && uuid != "") {
      this.selectedCity = this.cities.find(item => item.UUID === uuid);
      this.cityForm.setValue({
        cityValue: this.selectedCity
      });
    }
  }

  reset() {
    this.stateForm.reset();
    this.selectedState = null;
    this.cityForm.reset();
    this.selectedCity = null;
  }


}
