
<kendo-dialog title="Iniciar turno"
              *ngIf="dialogOpen">

  <h2 class="text-center">
    Bienvenido {{ username || '' }}
  </h2>

  <form [formGroup]="cashForm"
        class="__entityForm">

    <kendo-floatinglabel text="Dinero en Caja">
      <input kendoTextBox name="Cash"
             #cash
             class="form-control"
             type="number"
             aria-label="Dinero en Caja"
             placeholder="Dinero en Caja"
             formControlName="Cash" />
    </kendo-floatinglabel>

    <kendo-dialog-actions>
      <button class="btn btn-primary btn-block"
              type="submit"
              [disabled]="!isValidForm() || createButtonDisabled"
              (click)="onAccept()">
        Aceptar
      </button>
      <br />
    </kendo-dialog-actions>
  </form>

  <div class="text-center">
    <a class="logout"
       (click)="logout()">
      Cerrar Sesión
    </a>
    <a class="logout"
       routerLink="/settings/general-settings">
      Configuración
    </a>
  </div>

</kendo-dialog>
