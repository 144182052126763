import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationDialogService {

    private _show = false;
    dialogStatus = new Subject();

    get show(): boolean {
        return this._show;
    }

    set show(value: boolean) {
        this._show = value;
        this.dialogStatus.next(value);
    }

    open() {
        this.show= true;
  }

  close() {
        this.show = false;
    }


}
