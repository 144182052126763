
<kendo-dialog title="Agregar Producto Común"
              *ngIf="showDialog"
              (close)="closeDialog()"
              [minWidth]="250"
              [width]="450">

  <form [formGroup]="dialogForm"
        class="__entityForm">

    <kendo-floatinglabel text="Descripción">
      <input kendoTextBox name="Name"
             #description
             class="form-control"
             type="text"
             aria-label="Descripción"
             placeholder="Descripción"
             formControlName="Name" />
    </kendo-floatinglabel>

    <kendo-floatinglabel text="Precio">
      <input kendoTextBox name="RetailPrice"
             class="form-control"
             type="number"
             aria-label="Precio"
             placeholder="Precio"
             formControlName="RetailPrice" />
    </kendo-floatinglabel>

    <kendo-floatinglabel text="Cantidad">
      <input kendoTextBox name="Quantity"
             class="form-control"
             type="number"
             aria-label="Cantidad"
             placeholder="Cantidad"
             formControlName="Quantity" />
    </kendo-floatinglabel>

    <p class="form-control d-flex justify-content-between align-items-center">
      <b>Total:</b>
      <span>{{ total | currency }}</span>
    </p>

    <hr />

    <kendo-dialog-actions>
      <div class="d-flex justify-content-between align-items-center">
        <button class="btn btn-primary"
                type="submit"
                [disabled]="!isValidForm()"
                (click)="createProduct()">
          Agregar
        </button>
        <button class="btn btn-primary"
                type="button"
                (click)="closeDialog()">
          Cancelar
        </button>
      </div>
    </kendo-dialog-actions>
  </form>

</kendo-dialog>
