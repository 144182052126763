import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe, DecimalPipe } from '@angular/common';
import { ListDetailsComponent } from '../../../../common/components/list-details/list-details.component';
import { LoadingService } from '../../../../common/components/loading/loading.service';
import { AlertifyService } from '../../../../common/services/alertify.service';
import { SaleDetailsVM, SalesOnCreditVM, SaleVM, CustomerMovementVM, PaymentMethodVM, SaleBalanceDto, CurrencyVM, LoginResponse } from '../../../../models/view-models';
import { SalesService } from '../../../../services/sales.service';
import { LocalData } from '../../../../models/LocalData';
import { CustomerMovementService } from '../../../../services/customer-movement.service';
import { environment } from '../../../../../environments/environment';
import { HeaderDetailsComponent } from '../../../../common/components/header-details/header-details.component';
import { HeaderDetailsItem } from '../../../../models/ComponentModels';
import { ShiftService } from '../../../../services/shift.service';
import { MultiplePaymentMethodComponent } from '../../../../common/components/multiple-payment-method/multiple-payment-method.component';
import { CurrencyService } from '../../../../services/currency.service';
import { AuthenticationService } from '../../../../interceptors/authentication.service';
import { PaymentMethodButtonSelectorComponent } from '../../../../common/components/payment-method-button-selector/payment-method-button-selector.component';
import { FilteredGridComponent } from '../../../../common/components/filtered-grid/filtered-grid.component';
import { GenericGridComponent } from '../../../../common/components/generic-grid/generic-grid.component';

@Component({
  selector: 'app-sales-on-credit',
  templateUrl: './sales-on-credit.component.html',
  styleUrls: ['./sales-on-credit.component.scss']
})
export class SalesOnCreditComponent implements OnInit {

  sales: SaleVM[] = [];
  saleBalance: SaleBalanceDto;
  saleDetails: SaleDetailsVM[] = [];
  entityForm: FormGroup;
  showDetails: boolean = false;
  showForm: boolean = false;
  isEdition: boolean = false;

  @ViewChild("grid", { read: FilteredGridComponent, static: true })
  public grid: FilteredGridComponent;

  @ViewChild("detailsGrid", { read: GenericGridComponent, static: true})
  public detailsGrid: GenericGridComponent;

  totalCredits: number = 0;

  @ViewChild("payDetails", { read: ListDetailsComponent })
  public payDetails: ListDetailsComponent;

  @ViewChild("paymentMethodSelector", { read: PaymentMethodButtonSelectorComponent})
  public paymentMethodSelector: PaymentMethodButtonSelectorComponent;

  hideReferenceInput: boolean = true;
  @ViewChild("reference") reference: ElementRef;
  @ViewChild("payButton") payButton: ElementRef;

  @ViewChild("creditsDetails", { read: ListDetailsComponent })
  public creditsDetails: ListDetailsComponent;

  showCollapse: boolean = false;
  allowPaying: boolean = false;

  @ViewChild("saleBalanceDetails", { read: HeaderDetailsComponent })
  public saleBalanceDetails: HeaderDetailsComponent;

  showMovementsDialog: boolean = false;
  @ViewChild("movGrid", { read: GenericGridComponent, static: true })
  public movGrid: GenericGridComponent;

  @ViewChild("multiplePay", { read: MultiplePaymentMethodComponent })
  public multiplePay: MultiplePaymentMethodComponent;
  hideMultiplePay: boolean = true;

  isCurrencyPaymentMethod: boolean = false;
  currencyCode: string = "";
  currencyTotal: number = 0;
  currencyValue: number = 0;

  username: string;

  selectedTicketId: number | null;

  constructor(private salesService: SalesService,
    private customerMovementService: CustomerMovementService,
    private currencyService: CurrencyService,
    private shiftService: ShiftService,
    private fb: FormBuilder,
    private alertify: AlertifyService,
    private datePipe: DatePipe,
    private decimalPipe: DecimalPipe,
    private loading: LoadingService,
  private authService:AuthenticationService) {
    let usr: LoginResponse = this.authService.SessionUserValue();
    this.username = usr.User;
  }

  ngOnInit(): void {
    this.loadData();
  }

  initForm() {
    this.entityForm = this.fb.group({
      Amount: [this.setFormatNumber(0), [Validators.required]],
      BankReference: ['']
    });
  }

  loadData() {
    this.loading.show();
    this.salesService.getSalesOnCredit()
      .subscribe((response: SalesOnCreditVM) => {
        if (response) {
          this.sales = response.Sales;
          this.totalCredits = response.Total;
          this.creditsDetails.items = [
            { Title: "Créditos", Value: response.TotalCredits, IsCurrency: true },
            { Title: "Pagos", Value: response.TotalPayments, IsCurrency: true },
            { Title: "Abonos", Value: response.TotalDeposits, IsCurrency: true },
          ];
        }
        this.setGridOptions();
        this.loading.hide();
      }, (err) => {
        this.loading.hide();
      });
  }

  setGridOptions() {
    this.grid.setData(this.sales);
    this.grid.options.toolbarTitle = "Créditos";
    this.grid.options.hideActionButtons = true;
    this.grid.options.rowClick = true;
    this.grid.columns = [
      { field: "TicketId", title: "Ticket" },
      { field: "CreateDate", title: "Fecha", dateType: true, cssClass: "text-center" },
      { field: "CreateDate", title: "Hora", timeType: true, cssClass: "text-center" },
      { field: "CustomerName", title: "Cliente" },
      { field: "Total", title: "Monto", currencyType: true, cssClass: "text-center" },
      { field: "IsPaid", title: "Pagado", booleanType: true, cssClass: "text-center" },
    ];
    this.grid.filterableColumns = [
      { field: "TicketId", operator: 'eq' },
      { field: "CustomerName", operator: 'contains' },
      { field: "Total", operator: 'eq' },
    ];
    this.grid.setGridOptions();
  }

  select(sale: SaleVM) {
    this.selectedTicketId = sale.TicketId;
    this.loading.show();
    this.customerMovementService.getSaleBalance(sale.UUID)
      .subscribe((response: SaleBalanceDto) => {
        if (response) {
          this.saleBalance = response;
          this.allowPaying = this.saleBalance.Sale.IsPaid;
          this.initForm();
          this.saleDetails = response.Sale.SaleDetails;
          this.setDetailsGridOptions();
          this.setHeaderSaleBalanceDetails();
          this.showDetails = true;
        }
        this.loading.hide();
      }, (err) => {
          this.loading.hide();
      });
  }

  setHeaderSaleBalanceDetails() {
    this.saleBalanceDetails.setHeaders([
      { Title: "Total de Compra", Value: this.saleBalance.TotalSale, IsCurrency: true },
      {
        Title: "Total de Pagos",
        Value: this.saleBalance.TotalMovements,
        IsCurrency: true,
        Id: "paid",
        Pointer: true
      },
      { Title: "Pendiente de Pago", Value: this.saleBalance.TotalPending, IsCurrency: true },

    ]);
  }

  onHeaderItemClick(item: HeaderDetailsItem) { }

  closeMovementsDialog() {
    this.showMovementsDialog = false;
  }

  setDetailsGridOptions() {
    this.detailsGrid.setData(this.saleBalance.Sale.SaleDetails);
    this.detailsGrid.options.toolbarTitle = "Productos";
    this.detailsGrid.options.hideActionButtons = true;
    this.detailsGrid.options.showToolbar = false;
    this.detailsGrid.options.paging = false;
    this.detailsGrid.columns = [
      { field: "ProductCode", title: "Código" },
      { field: "ProductName", title: "Producto" },
      { field: "Quantity", title: "Cantidad" },
      { field: "UnitName", title: "Unidad" },
      { field: "UnitPrice", title: "Precio", currencyType: true },
      { field: "TotalPrice", title: "Total", currencyType: true },
    ];
    this.detailsGrid.setGridOptions();
  }

  setFormVisibility = () => this.showForm = !this.showForm;

  payTicket() {
    this.payDetails.items = [
      { Title: "Ticket", Value: this.saleBalance.Sale.TicketId.toString() },
      { Title: "Fecha", Value: this.setStringDate(new Date(this.saleBalance.Sale.CreateDate)) },
      { Title: "Total de Venta", Value: this.saleBalance.Sale.Total, IsCurrency: true },
      { Title: "Total de Pagos", Value: this.saleBalance.TotalMovements, IsCurrency: true },
      { Title: "Pendiente de Pago", Value: this.saleBalance.TotalPending, IsCurrency: true },
    ];
    this.setFormVisibility();
    this.paymentMethodSelector.reset();
    this.paymentMethodSelector.hideCreditPaymentMethod();
    //this.multiplePay.hidePaymentMethod(LocalData.PAYMENT_METHOD_CREDIT);
    this.paymentMethodSelect(this.paymentMethodSelector.selectedItem);
  }

  setStringDate(date: Date): string {
    return this.datePipe.transform(date, 'dd/MM/yyyy hh:mm:ss');
  }

  setFormatNumber(value: any) {
    let tmp = this.decimalPipe.transform(value, '1.2-2', 'mx');
    tmp = tmp.replace(',', '');
    return tmp;
    //let result: number = parseFloat(tmp);
    //return result;
  }

  paymentMethodSelect(method: PaymentMethodVM) {
    if (method.IsCurrency) {
      this.paymentMethodIsCurrency(method.Code);
    }

    if (method.Code === LocalData.PAYMENT_METHOD_MULTIPLE) {
      this.entityForm.controls["Amount"].setValue(0);
      this.multiplePay.focusOnCash();
    } else {
      this.entityForm.controls["Amount"].setValue(this.saleBalance.TotalPending);
    }
    
    
    try {
      switch (method.Code) {
        case LocalData.PAYMENT_METHOD_CASH: this.hideBankReferenceAndMultiplePayment();
          break;
        case LocalData.PAYMENT_METHOD_VOUCHER: this.hideBankReferenceAndMultiplePayment();
          break;
        case LocalData.PAYMENT_METHOD_CARD: this.hideReferenceInput = false;
          this.focusOnReferenceInput();
          break;
        case LocalData.PAYMENT_METHOD_EBANK: this.hideReferenceInput = false;
          this.focusOnReferenceInput();
          break;
        case LocalData.PAYMENT_METHOD_MULTIPLE: this.hideMultiplePay = false;
          this.entityForm.controls["Amount"].setValue(0);
          break;
        default: this.hideBankReferenceAndMultiplePayment();
          break;
      }
    } catch (e) {
      alert(e);
    }
    
  }

  hideBankReferenceAndMultiplePayment() {
    this.hideReferenceInput = true;
    this.hideMultiplePay = true;
  }

  focusOnReferenceInput() {
    setTimeout(() => {
      this.reference.nativeElement.focus();
    }, 0);
  }

  isValidForm(): boolean {
    if (this.entityForm.valid) {
      if (this.entityForm.controls["Amount"].value >= this.saleBalance.TotalPending) {
        return true;
      }
    }
    return false;
  }

  save() {
    this.loading.show();
    let newMovement: CustomerMovementVM = Object.assign({
      CustomerId: this.saleBalance.Sale.CustomerId,
      SaleId: this.saleBalance.Sale.UUID,
      MovementType: 'P',
      PaymentMethodCode: this.paymentMethodSelector.getItemCode(),
      ShiftId: this.shiftService.getActiveShiftId(),
      Username: this.username
    }, this.entityForm.value);

    if (newMovement.PaymentMethodCode === LocalData.PAYMENT_METHOD_MULTIPLE) {
      newMovement.MultiplePayments = this.multiplePay.getCustomerPayments();
    }

    this.customerMovementService.createPay(newMovement)
      .subscribe((response: CustomerMovementVM) => {
        if (response) {
          this.openTicket(response.TicketUrl);
          this.reset();
          this.showDetails = false;
          this.loadData();
        }
      }, (err) => {
          this.loading.hide();
      });
    
  }

  reset() {
    //this.paymentMethodSelector.setComboToDefaultMethod();
    this.entityForm.reset();
    this.payDetails.items = [];
    this.hideReferenceInput = true;
    this.hideMultiplePay = true;
    this.multiplePay.reset();
    this.showForm = false;
  }

  openCreditsDetailDialog() {
    this.showCollapse = true;
  }

  closeDialog() {
    this.showCollapse = false;
  }

  openTicket(filename: string): void {
    const url = `${environment.ticketsUrl}/${filename}`;
    window.open(url, '_blank');
  }

  onMultiplePaymentChange(value: number) {
    this.entityForm.controls["Amount"].setValue(value);
  }

  paymentMethodIsCurrency(code: string) {
    let currency: CurrencyVM = this.currencyService.getLocalCurrency(code);
    this.currencyValue = currency.Exchange;
    this.currencyTotal = this.saleBalance.TotalPending / currency.Exchange;
    this.currencyCode = currency.Code;
    this.isCurrencyPaymentMethod = true;

  }


}
