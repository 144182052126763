import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AlertifyService } from '../common/services/alertify.service';
import { RegisterRequestVM, OperationResult, UserVM, ChangePasswordRequest } from '../models/view-models';
import { map } from 'rxjs/operators';
import { ErrorService } from '../interceptors/error.service';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  apiUrl: string = `${environment.apiUrl}/User`;

  constructor(private http: HttpClient,
    private error: ErrorService,
    private alertify: AlertifyService) { }

  insert(user: RegisterRequestVM) {
    return this.http.post(this.apiUrl, user)
      .pipe(map((response: OperationResult<boolean>) => {
        if (response.Success) {
          this.alertify.success("Se agregó el usuario");
          return response.Data;
        } else {
          this.error.logError(response);
          return false;
        }
      }));
  }

  update(user: UserVM) {
    return this.http.put(this.apiUrl, user)
      .pipe(map((response: OperationResult<boolean>) => {
        if (response.Success) {
          this.alertify.success("Se editó el usuario");
          return response.Data;
        } else {
          this.error.logError(response);
          return false;
        }
      }));
  }

  delete(uuid: string) {
    const uri = `${this.apiUrl}?uuid=${uuid}`;
    return this.http.delete(uri)
      .pipe(map((response: OperationResult<boolean>) => {
        if (response.Success) {
          this.alertify.success("Se eliminó la categoría");
          return response.Data;
        } else {
          this.error.logError(response);
          return false;
        }
      }));
  }

  changePassword(changeRequest: ChangePasswordRequest) {
    const uri = `${this.apiUrl}/ChangePassword`;
    return this.http.put(uri, changeRequest)
      .pipe(map((response: OperationResult<boolean>) => {
        if (response.Success) {
          this.alertify.success("Se modificó la contraseña");
          return response.Data;
        } else {
          this.error.logError(response);
          return false;
        }
      }));
  }

  getAll() {
    return this.http.get(this.apiUrl)
      .pipe(map((response: OperationResult<UserVM[]>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }

  //getById(uuid: string) {
  //  const uri = `${this.apiUrl}/GetByIdentifier?uuid=${uuid}`;
  //  return this.http.get(uri)
  //    .pipe(map((response: OperationResult<CategoryVM>) => {
  //      if (response.Success) {
  //        return response.Data;
  //      } else {
  //        this.error.logError(response);
  //        return null;
  //      }
  //    }));
  //}
}
