import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AlertifyService } from '../common/services/alertify.service';
import { ProductVM, OperationResult, ProductToSale, SaleDetailsVM, SimpleProduct, SimpleProduct2 } from '../models/view-models';
import { map } from 'rxjs/operators';
import { ErrorService } from '../interceptors/error.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  private productsSubject: BehaviorSubject<SimpleProduct2[]> = new BehaviorSubject<SimpleProduct2[]>([]);

  apiUrl: string = `${environment.apiUrl}/Product`;

  constructor(private http: HttpClient,
    private error: ErrorService,
    private alertify: AlertifyService) {
    this.loadSimpleProducts();
  }

  insert(product: ProductVM) {
    return this.http.post(this.apiUrl, product)
      .pipe(map((response: OperationResult<ProductVM>) => {
        if (response.Success) {
          this.alertify.success("Se agregó el producto");
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }

  update(product: ProductVM) {
    return this.http.put(this.apiUrl, product)
      .pipe(map((response: OperationResult<ProductVM>) => {
        if (response.Success) {
          this.alertify.success("Se editó el producto");
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }
  
  restoreProduct(product: ProductVM) {
    const uri = `${this.apiUrl}/RestoreProduct`;
    return this.http.put(uri, product)
      .pipe(map((response: OperationResult<boolean>) => {
        if (response.Success) {
          this.alertify.success("Se restauró el producto");
          return response.Data;
        } else {
          this.error.logError(response);
          return false;
        }
      }));
  }

  delete(uuid: string) {
    const uri = `${this.apiUrl}?uuid=${uuid}`;
    return this.http.delete(uri)
      .pipe(map((response: OperationResult<boolean>) => {
        if (response.Success) {
          this.alertify.success("Se eliminó el producto");
          return response.Data;
        } else {
          this.error.logError(response);
          return false;
        }
      }));
  }

  getAll() {
    return this.http.get(this.apiUrl)
      .pipe(map((response: OperationResult<ProductVM[]>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }

  getById(uuid: string) {
    const uri = `${this.apiUrl}/GetByIdentifier?uuid=${uuid}`;
    return this.http.get(uri)
      .pipe(map((response: OperationResult<ProductVM>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }

  getByCategory(categoryId: string) {
    const uri = `${this.apiUrl}/GetByCategory?categoryId=${categoryId}`;
    return this.http.get(uri)
      .pipe(map((response: OperationResult<ProductVM[]>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }

  getSimpleProducts() {
    const uri = `${this.apiUrl}/GetSimpleProducts`;
    return this.http.get(uri)
      .pipe(map((response: OperationResult<SimpleProduct[]>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }

  getSimpleProducts2() {
    const uri = `${this.apiUrl}/GetSimpleProducts2`;
    return this.http.get(uri)
      .pipe(map((response: OperationResult<SimpleProduct2[]>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }

  getByCode(code: string) {
    const uri = `${this.apiUrl}/GetByCode?code=${code}`;
    return this.http.get(uri)
      .pipe(map((response: OperationResult<ProductVM>) => {
        if (response.Success) {
          return response.Data;
        } else {
          if (response.StatusCode === 404) {
            this.alertify.warning("No se encuentra el producto");
          }
          return null;
        }
      }));
  }

  getProductsFromSale(saleId: string) {
    const uri = `${this.apiUrl}/GetProductsFromSale?saleId=${saleId}`;
    return this.http.get(uri)
      .pipe(map((response: OperationResult<ProductVM[]>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }

  getNewGuid() {
    const uri = `${this.apiUrl}/GetNewGuid`;
    return this.http.get(uri)
      .pipe(map((response: OperationResult<string>) => {
        if (response.Success) {
          return response.Data;
        } else {
          //this.error.logError(response);
          return null;
        }
      }));
  }

  // Inventory
  getInventoryProducts() {
    const uri = `${this.apiUrl}/GetInventoryProducts`;
    return this.http.get(uri)
      .pipe(map((response: OperationResult<ProductVM[]>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }

  getDeletedProducts() {
    const uri = `${this.apiUrl}/GetDeletedProducts`;
    return this.http.get(uri)
      .pipe(map((response: OperationResult<ProductVM[]>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }


  // Converting
  createProductToSale(product: ProductVM, quantity: number = 1): ProductToSale {
    return {
      UUID: product.UUID,
      Code: product.Code,
      CategoryId: product.CategoryId,
      CategoryName: product.CategoryName,
      UnitId: product.UnitId,
      UnitName: product.UnitName,
      Name: product.Name,
      RetailPrice: product.RetailPrice,
      WholesalePrice: product.WholesalePrice,
      Price: product.RetailPrice,
      UsesInventory: product.UsesInventory,
      Existence: product.Quantity,
      Quantity: quantity,
      Total: product.RetailPrice * quantity,
      UseRetailPrice: true
    };
  }

  createProductsToSale(products: ProductVM[], details: SaleDetailsVM[]): ProductToSale[] {
    let result: ProductToSale[] = [];

    products.forEach(item => {
      result.push(this.createProductToSale(item,
        details.find(d => d.ProductId === item.UUID).Quantity));
    });

    return result;
  }

  createNonUniqueProductsToSale(products: ProductVM[], details: SaleDetailsVM[]): ProductToSale[] {
    let result: ProductToSale[] = [];
    products.forEach(item => {
      if (!item.IsUnique) {
        result.push(this.createProductToSale(item,
          details.find(d => d.ProductId === item.UUID).Quantity));
      }
    });

    return result;
  }

  private loadSimpleProducts() {
    const uri = `${this.apiUrl}/GetSimpleProducts2`;
    this.http.get<OperationResult<SimpleProduct2[]>>(uri)
      .subscribe((response) => {
      this.productsSubject.next(response.Data);
    });
  }

  getProductsList(): Observable<SimpleProduct2[]> {
    return this.productsSubject.asObservable();
  }

}
