
<h3>
  Ticket de Venta
</h3>

<div class="row">

  <!-- ticket settings -->
  <div class="col-md-6">

    <app-ticket-size-selector #sizeSelector></app-ticket-size-selector>

    <form [formGroup]="entityForm"
          *ngIf="showForm"
          class="__entityForm">

      <div class="list-group">
        <div class="list-group-item d-flex justify-content-between align-items-center">
          <b>Encabezado</b>
          <kendo-switch formControlName="ShowHeader"
                        [onLabel]="'Mostrar'"
                        [offLabel]="'Ocultar'">
          </kendo-switch>
        </div>
      </div>
      <kendo-floatinglabel text="Encabezado"
                           *ngIf="ticketSettings.ShowHeader">
        <input kendoTextBox name="Header"
               class="form-control"
               maxlength="30"
               type="text"
               aria-label="Encabezado"
               placeholder="Encabezado"
               formControlName="Header" />
      </kendo-floatinglabel>

      <div class="list-group">
        <div class="list-group-item d-flex justify-content-between align-items-center">
          <b>Logo</b>
          <kendo-switch formControlName="ShowLogo"
                        [onLabel]="'Mostrar'"
                        [offLabel]="'Ocultar'">
          </kendo-switch>
        </div>
        <div class="list-group-item d-flex justify-content-between align-items-center">
          <app-logo-upload (onFileUpload)="onUploadSuccess($event)">
          </app-logo-upload>
        </div>
      </div>

      <div class="list-group">
        <div class="list-group-item d-flex justify-content-between align-items-center">
          <b>Fecha</b>
          <kendo-switch formControlName="ShowDate"
                        [onLabel]="'Mostrar'"
                        [offLabel]="'Ocultar'">
          </kendo-switch>
        </div>
      </div>

      <div class="list-group">
        <div class="list-group-item d-flex justify-content-between align-items-center">
          <b>Hora</b>
          <kendo-switch formControlName="ShowTime"
                        [onLabel]="'Mostrar'"
                        [offLabel]="'Ocultar'">
          </kendo-switch>
        </div>
      </div>


      <div class="list-group">
        <div class="list-group-item d-flex justify-content-between align-items-center">
          <b>Domicilio</b>
          <kendo-switch formControlName="ShowAddress"
                        [onLabel]="'Mostrar'"
                        [offLabel]="'Ocultar'">
          </kendo-switch>
        </div>
      </div>
      <kendo-floatinglabel text="Domicilio"
                           *ngIf="ticketSettings.ShowAddress">
        <input kendoTextBox name="Address"
               class="form-control"
               maxlength="30"
               type="text"
               aria-label="Domicilio"
               placeholder="Domicilio"
               formControlName="Address" />
      </kendo-floatinglabel>


      <div class="list-group">
        <div class="list-group-item d-flex justify-content-between align-items-center">
          <b>Teléfono</b>
          <kendo-switch formControlName="ShowTelephone"
                        [onLabel]="'Mostrar'"
                        [offLabel]="'Ocultar'">
          </kendo-switch>
        </div>
      </div>
      <kendo-floatinglabel text="Teléfono"
                           *ngIf="ticketSettings.ShowTelephone">
        <input kendoTextBox name="Telephone"
               class="form-control"
               maxlength="30"
               type="text"
               aria-label="Teléfono"
               placeholder="Teléfono"
               formControlName="Telephone" />
      </kendo-floatinglabel>

      <div class="list-group">
        <div class="list-group-item d-flex justify-content-between align-items-center">
          <b>Correo Electrónico</b>
          <kendo-switch formControlName="ShowEmail"
                        [onLabel]="'Mostrar'"
                        [offLabel]="'Ocultar'">
          </kendo-switch>
        </div>
      </div>
      <kendo-floatinglabel text="Correo Electrónico"
                           *ngIf="ticketSettings.ShowEmail">
        <input kendoTextBox name="Email"
               class="form-control"
               maxlength="30"
               type="email"
               aria-label="Correo Electrónico"
               placeholder="Correo Electrónico"
               formControlName="Email" />
      </kendo-floatinglabel>

      <div class="list-group">
        <div class="list-group-item d-flex justify-content-between align-items-center">
          <b>Contacto</b>
          <kendo-switch formControlName="ShowContact"
                        [onLabel]="'Mostrar'"
                        [offLabel]="'Ocultar'">
          </kendo-switch>
        </div>
      </div>
      <kendo-floatinglabel text="Contacto"
                           *ngIf="ticketSettings.ShowContact">
        <input kendoTextBox name="Contact"
               class="form-control"
               maxlength="30"
               type="text"
               aria-label="Contacto"
               placeholder="Contacto"
               formControlName="Contact" />
      </kendo-floatinglabel>

      <div class="list-group">
        <div class="list-group-item d-flex justify-content-between align-items-center">
          <b>Línea Adicional</b>
          <kendo-switch formControlName="ShowExtraLine"
                        [onLabel]="'Mostrar'"
                        [offLabel]="'Ocultar'">
          </kendo-switch>
        </div>
      </div>
      <kendo-floatinglabel text="Línea Adicional"
                           *ngIf="ticketSettings.ShowExtraLine">
        <input kendoTextBox name="ExtraLine"
               class="form-control"
               maxlength="30"
               type="text"
               aria-label="Línea Adicional"
               placeholder="Línea Adicional"
               formControlName="ExtraLine" />
      </kendo-floatinglabel>

      <div class="list-group">
        <div class="list-group-item d-flex justify-content-between align-items-center">
          <b>Pié de Ticket</b>
          <kendo-switch formControlName="ShowFooter"
                        [onLabel]="'Mostrar'"
                        [offLabel]="'Ocultar'">
          </kendo-switch>
        </div>
      </div>
      <kendo-floatinglabel text="Pié de Ticket"
                           *ngIf="ticketSettings.ShowFooter">
        <input kendoTextBox name="Footer"
               class="form-control"
               maxlength="30"
               type="text"
               aria-label="Pié de ticket"
               placeholder="Pié de ticket"
               formControlName="Footer" />
      </kendo-floatinglabel>

      <div class="list-group">
        <div class="list-group-item d-flex justify-content-between align-items-center">
          <b>Línea Adicional</b>
          <kendo-switch formControlName="ShowAfterFooterLine"
                        [onLabel]="'Mostrar'"
                        [offLabel]="'Ocultar'">
          </kendo-switch>
        </div>
      </div>
      <kendo-floatinglabel text="Línea Adicional"
                           *ngIf="ticketSettings.ShowAfterFooterLine">
        <input kendoTextBox name="AfterFooterLine"
               class="form-control"
               maxlength="30"
               type="text"
               aria-label="Línea Adicional"
               placeholder="Línea Adicional"
               formControlName="AfterFooterLine" />
      </kendo-floatinglabel>

      <div class="list-group">
        <div class="list-group-item d-flex justify-content-between align-items-center">
          Porcentaje de los márgenes izquierdo y derecho          
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-sm-12">
          <kendo-floatinglabel text="Margen Izquierdo">
            <input kendoTextBox name="LeftMargin"
                   class="form-control"
                   type="number"
                   aria-label="Margen Izquierdo"
                   placeholder="Margen Izquierdo"
                   formControlName="LeftMargin" />
          </kendo-floatinglabel>
        </div>
        <div class="col-md-6 col-sm-12">
          <kendo-floatinglabel text="Margen Derechho">
            <input kendoTextBox name="RightMargin"
                   class="form-control"
                   type="number"
                   aria-label="Margen Derecho"
                   placeholder="Margen Derecho"
                   formControlName="RightMargin" />
          </kendo-floatinglabel>
        </div>
      </div>

      <div class="d-flex justify-content-end __formButtonsContainer">
        <button class="btn btn-primary"
                type="submit"
                (click)="update()">
          Guardar cambios
        </button>

        <button class="btn btn-secondary"
                type="button"
                (click)="undo()">
          Deshacer cambios
        </button>
      </div>

    </form>

  </div>

  <!-- ticket preview -->
  <div class="col-md-6">
    <h5>Vista Previa</h5>

    <div class="__preview"
         *ngIf="showForm">

      <div class="__ticketHeader"
           *ngIf="ticketSettings.ShowHeader">
        {{ ticketSettings.Header }}
      </div>

      <div class="__ticketLogo"
           *ngIf="ticketSettings.ShowLogo">
        <img [src]="ticketSettings.LogoUrl" />
      </div>

      <div class="row">
        <div class="__ticketDate col-6"
             *ngIf="ticketSettings.ShowDate">
          {{ datetime | date: 'dd/MM/yyyy'}}
        </div>
        <div class="__ticketTime col-6"
             *ngIf="ticketSettings.ShowTime">
          {{ datetime | date: 'HH:mm:ss'}}
        </div>
      </div>

      <div class="__ticketAddress"
           *ngIf="ticketSettings.ShowAddress">
        {{ ticketSettings.Address }}
      </div>

      <div class="row">
        <div class="__ticketTelephone col-6"
             *ngIf="ticketSettings.ShowTelephone">
          {{ ticketSettings.Telephone }}
        </div>

        <div class="__ticketEmail col-6"
             *ngIf="ticketSettings.ShowEmail">
          {{ ticketSettings.Email }}
        </div>
      </div>

      <div class="__ticketContact"
           *ngIf="ticketSettings.ShowContact">
        {{ ticketSettings.Contact }}
      </div>

      <div class="__ticketExtraLine"
           *ngIf="ticketSettings.ShowExtraLine">
        {{ ticketSettings.ExtraLine }}
      </div>

      <div class="row">
        <div class="col-6">
          <p>Nombre del producto</p>
        </div>
        <div class="col-6 text-right">
          <p> $ 58.99</p>
        </div>
      </div>

      <div class="row"
           style="border-bottom: 1px solid #000">
        <div class="col-6">
          <p>3 piezas</p>
        </div>
        <div class="col-6 text-right">
          <p> $ 176.97</p>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <p>
            <b>TOTAL</b>
          </p>
        </div>
        <div class="col-6 text-right">
          <p> $ 176.97</p>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <p>
            Pagado
          </p>
        </div>
        <div class="col-6 text-right">
          <p> $ 200.00</p>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <p>
            Método de Pago
          </p>
        </div>
        <div class="col-6 text-right">
          <p>Efectivo</p>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <p>
            Su cambio
          </p>
        </div>
        <div class="col-6 text-right">
          <p> $ 23.03</p>
        </div>
      </div>

      <div class="__ticketFooter"
           *ngIf="ticketSettings.ShowFooter">
        {{ ticketSettings.Footer }}
      </div>

      <div class="__ticketAfterFooterLine"
           *ngIf="ticketSettings.ShowAfterFooterLine">
        {{ ticketSettings.AfterFooterLine }}
      </div>

      <div class="__ticketBarcode">
        <img src="./assets/img/barcode.gif"
             alt="Código de Barras" />
      </div>

    </div>
  </div>
</div>
