
<div class="user-info-container">
  <button class="btn btn-outline-secondary"
          (click)="toggleDropdown()">
    <i class="fa fa-user"></i> {{ username }}
  </button>
  <ul [ngClass]="{'show': showOptions}"
      class="list-group">
    <button class="list-group-item d-flex justify-content-between align-items-center"
            (click)="logout()">
      Cerrar sesión
    </button>
  </ul>
</div>
