
<kendo-dialog title="Advertencia"
              *ngIf="showDialog"
              (close)="closeDialog()"
              [minWidth]="250"
              [width]="450">

  <h4>
    El cliente ha excedido su límite de crédito
  </h4>

  <div class="list-group">
    <div class="list-group-item d-flex justify-content-between align-items-center">
      <b>Cliente</b>
      <span class="badge">
        {{ customer.Fullname }}
      </span>
    </div>
    <div class="list-group-item d-flex justify-content-between align-items-center">
      <b>Límite de Crédito</b>
      <span class="badge">
        {{ customer.CreditLimit | currency }}}}
      </span>
    </div>
    <div class="list-group-item d-flex justify-content-between align-items-center">
      <b>Saldo Actual</b>
      <span class="badge">
        {{ customer.Balance | currency }}}}
      </span>
    </div>
  </div>

  <hr />

  <kendo-dialog-actions>
    <div class="d-flex justify-content-between align-items-center">
      <button class="btn btn-primary"
              type="submit"
              #btn1
              (click)="goToCustomer()">
        Ver detalles de Cliente
      </button>
      <button class="btn btn-primary"
              type="button"
              (click)="closeDialog()">
        Cerrar
      </button>
    </div>
  </kendo-dialog-actions>

</kendo-dialog>
