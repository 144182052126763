import { Component, Output, EventEmitter } from '@angular/core';
import { AuthenticationService } from '../../../interceptors/authentication.service';
import { LocalData } from '../../../models/LocalData';
import { LoginResponse, ShiftVM } from '../../../models/view-models';
import { ShiftService } from '../../../services/shift.service';
import { LoadingService } from '../loading/loading.service';

@Component({
  selector: 'app-shift-selector-dialog',
  templateUrl: './shift-selector-dialog.component.html',
  styleUrls: ['./shift-selector-dialog.component.scss']
})
export class ShiftSelectorDialogComponent {

  shifts: ShiftVM[] = [];
  userInfo: LoginResponse;
  hasResults: boolean = false;
  showDialog: boolean = false;
  username: string = "";
  @Output() onShiftSelect: EventEmitter<any> = new EventEmitter<any>();

  constructor(private shiftService: ShiftService,
    private authService: AuthenticationService,
    private loading: LoadingService) { }

  public open() {
    if (!this.shiftService.activeShiftExists()) {
      this.loading.show();
      this.shiftService.getActiveShifts()
        .subscribe((response: ShiftVM[]) => {
          if (response) {
            this.shifts = response;
            this.userInfo = this.authService.SessionUserValue();
            this.username = this.userInfo.User;
            this.showDialog = true;
          }
          this.loading.hide();
        }, (err) => {
          this.loading.hide();
        });
    } else {
      this.onShiftSelect.emit(true);
    }
  }

  select(value: ShiftVM) {
    localStorage.setItem(LocalData.ACTIVE_SHIFT, JSON.stringify(value));
    this.onShiftSelect.emit(true);
    this.showDialog = false;
  }

  closeDialog() {
    this.shifts = [];
    this.showDialog = false;
  }

}
