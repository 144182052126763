
<h3>
  Reportes
</h3>

<hr />
<br />

<kendo-tabstrip>

  <kendo-tabstrip-tab [title]="'Ventas por Rango de Fecha'" [selected]="true">
    <ng-template kendoTabContent>
      <br />
      <app-date-range-report></app-date-range-report>
    </ng-template>
  </kendo-tabstrip-tab>

  <!-- CARDEX Reports -->
  <kendo-tabstrip-tab [title]="'Reporte de CARDEX'">
    <ng-template kendoTabContent>
      <br />
      <app-cardex-report></app-cardex-report>
    </ng-template>
  </kendo-tabstrip-tab>
    

</kendo-tabstrip>
