import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AlertifyService } from '../common/services/alertify.service';
import { SavedTicketVM, OperationResult } from '../models/view-models';
import { map } from 'rxjs/operators';
import { ErrorService } from '../interceptors/error.service';

@Injectable({
  providedIn: 'root'
})
export class SavedTicketService {

  apiUrl: string = `${environment.apiUrl}/SavedTicket`;

  constructor(private http: HttpClient,
    private error: ErrorService,
    private alertify: AlertifyService) { }

  save(savedTicket: SavedTicketVM) {
    return this.http.post(this.apiUrl, savedTicket)
      .pipe(map((response: OperationResult<SavedTicketVM>) => {
        if (response.Success) {
          this.alertify.success(`Se guardó el ticket ${savedTicket.TicketId}`);
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }

  update(savedTicket: SavedTicketVM) {
    return this.http.put(this.apiUrl, savedTicket)
      .pipe(map((response: OperationResult<SavedTicketVM>) => {
        if (response.Success) {
          this.alertify.success("Se editó la ticket");
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }

  delete(ticketId: number) {
    const uri = `${this.apiUrl}?ticketId=${ticketId}`;
    return this.http.delete(uri)
      .pipe(map((response: OperationResult<boolean>) => {
        if (response.Success) {
          this.alertify.success("Se eliminó la ticket");
          return response.Data;
        } else {
          this.error.logError(response);
          return false;
        }
      }));
  }

  getAll() {
    return this.http.get(this.apiUrl)
      .pipe(map((response: OperationResult<SavedTicketVM[]>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }

  getById(ticketId: number) {
    const uri = `${this.apiUrl}/GetById?ticketId=${ticketId}`;
    return this.http.get(uri)
      .pipe(map((response: OperationResult<SavedTicketVM>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }

  getSavedTickets() {
    return this.http.get(this.apiUrl)
      .pipe(map((response: OperationResult<SavedTicketVM[]>) => {        
        if (response.Success) {
          return response.Data;
        } else {
          //this.error.logError(response);
          return null;
        }
      }));
  }
}
