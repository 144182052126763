
<div [hidden]="showForm">

  <h3>
    Permisos de Acciones
  </h3>

  <!--<button class="btn btn-primary"
          (click)="addNew()"
          [disabled]="disabledAddButton">
    Agregar <i class="fa fa-plus"></i>
  </button>-->

  <hr />
  <br />

  <app-generic-grid #grid
                    (onCellClicked)="select($event)">
  </app-generic-grid>

</div>

<div [hidden]="!showForm">

  <form [formGroup]="entityForm"
        class="__entityForm">

    <h4 *ngIf="isEdition">
      Editar Permiso
    </h4>

    <h4 *ngIf="!isEdition">
      Agregar Permiso
    </h4>

    <kendo-floatinglabel text="Código">
      <input kendoTextBox name="Code"
             #code
             class="form-control"
             [ngClass]="{'is-invalid': entityForm.get('Code').errors && entityForm.get('Code').touched}"
             type="text"
             [readOnly]="isEdition"
             aria-label="Código"
             placeholder="Código"
             formControlName="Code" />
    </kendo-floatinglabel>

    <kendo-floatinglabel text="Nombre">
      <input kendoTextBox name="Name"
             #name
             class="form-control"
             [ngClass]="{'is-invalid': entityForm.get('Name').errors && entityForm.get('Name').touched}"
             type="text"
             aria-label="Nombre"
             placeholder="Nombre"
             formControlName="Name" />
    </kendo-floatinglabel>

    <br /><br />

    <label class="form-check-label">
      Requiere autorización
      <input type="checkbox" kendoCheckBox
             formControlName="AuthorizationRequired" />
    </label>

    <br /><br />
    <app-multiple-rol-selector #rolSelector></app-multiple-rol-selector>

    <div class="d-flex justify-content-end __formButtonsContainer">
      <button class="btn btn-primary"
              *ngIf="!isEdition"
              type="submit"
              [disabled]="!isValidForm()"
              (click)="save()">
        Guardar
      </button>
      <button class="btn btn-primary"
              *ngIf="isEdition"
              type="submit"
              [disabled]="!isEditionValidForm()"
              (click)="update()">
        Guardar cambios
      </button>
      <button class="btn btn-secondary"
              type="button"
              (click)="cancel()">
        Cancelar
      </button>
    </div>
  </form>
</div>
