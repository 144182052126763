
<div class="login-container">
  <div class="login bg-warning">
    <div class="jumbotron text-center">
      <i class="fa fa-warning fa-5x"></i>
      <br /><br />
      <h2>Acceso denegado</h2>
      <br /><br />
      <button class="btn btn-secondary"
              (click)="logout()">
        Cerrar sesión
      </button>
    </div>
    
  </div>
</div>
