
<div [hidden]="showForm">

  <h3>
    Tickets
  </h3>

  <hr />
  <br />

  <app-generic-grid #grid
                    (rowToPrint)="printTicket($event)">
  </app-generic-grid>

</div>


