
<kendo-dialog title="Tickets Guardados"
              *ngIf="showDialog"
              (close)="closeDialog()"
              [minWidth]="250"
              [width]="450"
              [height]="450">

  <ng-container *ngIf="tickets.length > 0">
    <input type="text"
           value="{{ tickets[selectedElementIndex].TicketId || '' }}"
           style="opacity: 0; position: absolute; top: -9999px; left: -9999px;" #inputElement />
  </ng-container>

  <div class="jumbotron text-center"
       *ngIf="!hasResults">
    <h4>No hay tickets guardados</h4>
  </div>

  <div class="list-group"
       *ngIf="hasResults"
       (keydown)="handleKeyDown($event)"
       tabindex="0">
    <div class="list-group-item list-group-item-action flex-column align-items-start"
         style="cursor:pointer;"
         *ngFor="let ticket of tickets; let index = index;"
         [ngClass]="{ 'active': index === selectedElementIndex }"
         (click)="selectTicket(ticket)">
      <div class="d-flex w-100 justify-content-between">
        <h5 class="mb-1">
          {{ ticket.TicketId }}
        </h5>
        <small class="text-muted">
          {{ ticket.Total | currency }}
        </small>
      </div>
      <p class="mb-1">
        {{ ticket.Date | date: 'dd/MM/yyyy' }}'}
      </p>
    </div>
  </div>

  
</kendo-dialog>
