import { Pipe, PipeTransform } from '@angular/core';
import { ServicePayVM } from '../../models/ServicePay';

@Pipe({
  name: 'serviceTypeFilter'
})
export class ServiceTypeFilterPipe implements PipeTransform {

  typeService: string = 'Service';
  typePrepaid: string = 'Prepaid';

  transform(services: ServicePayVM[], type: string): ServicePayVM[] {
    if (!services)
      return [];
    if (type == "")
      return services;
    return services.filter(s => s.Type == type);
  }

}
