import { HttpClient } from '@angular/common/http';
import { asNativeElements, Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AlertifyService } from '../common/services/alertify.service';
import { PaymentMethodVM, OperationResult } from '../models/view-models';
import { catchError, map, tap } from 'rxjs/operators';
import { ErrorService } from '../interceptors/error.service';
import { LocalData } from '../models/LocalData';
import { CurrencyService } from './currency.service';
import { BehaviorSubject, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PaymentMethodService {

  private paymentMethodsSubject: BehaviorSubject<PaymentMethodVM[]> = new BehaviorSubject<PaymentMethodVM[]>([]);
  private loaded: boolean = false;

  apiUrl: string = `${environment.apiUrl}/PaymentMethod`;

  constructor(private http: HttpClient,
    private currencyService: CurrencyService,
    private error: ErrorService,
    private alertify: AlertifyService) {
    this.loadPaymentMethods();
  }

  insert(paymentMethod: PaymentMethodVM) {
    return this.http.post(this.apiUrl, paymentMethod)
      .pipe(map((response: OperationResult<PaymentMethodVM>) => {
        if (response.Success) {
          this.alertify.success("Se agregó el método de pago");
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }

  update(paymentMethod: PaymentMethodVM) {
    return this.http.put(this.apiUrl, paymentMethod)
      .pipe(map((response: OperationResult<PaymentMethodVM>) => {
        if (response.Success) {
          this.alertify.success("Se editó el método de pago");
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }

  delete(uuid: string) {
    const uri = `${this.apiUrl}?uuid=${uuid}`;
    return this.http.delete(uri)
      .pipe(map((response: OperationResult<boolean>) => {
        if (response.Success) {
          this.alertify.success("Se eliminó el método de pago");
          return response.Data;
        } else {
          this.error.logError(response);
          return false;
        }
      }));
  }

  getAll() {
    return this.http.get(this.apiUrl)
      .pipe(map((response: OperationResult<PaymentMethodVM[]>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }

  getActives() {
    const uri = `${this.apiUrl}/GetActives`;
    return this.http.get(uri)
      .pipe(map((response: OperationResult<PaymentMethodVM[]>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }

  getById(uuid: string) {
    const uri = `${this.apiUrl}/GetByIdentifier?uuid=${uuid}`;
    return this.http.get(uri)
      .pipe(map((response: OperationResult<PaymentMethodVM>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }

  // load data and save it to local
  loadAndSaveData() {
    this.getActives()
      .subscribe((response: PaymentMethodVM[]) => {
        if (response) {          
          localStorage.setItem(LocalData.PAYMENT_METHODS, JSON.stringify(response));
          this.currencyService.loadAndSaveData(response.filter(item => item.IsCurrency === true));
        }
      });
  }

  getPaymentMethodName(code: string): string {
    if (localStorage.getItem(LocalData.PAYMENT_METHODS) !== null) {
      let pm: PaymentMethodVM[] = JSON.parse(localStorage.getItem(LocalData.PAYMENT_METHODS));
      return pm.find(item => item.Code === code).Name;
    }
    return code;
  }

  private loadPaymentMethods() {
    this.http.get<OperationResult<PaymentMethodVM[]>>(this.apiUrl)
      .subscribe((response) => {
        this.paymentMethodsSubject.next(response.Data);
        this.loaded = true;
      });
  }

  getPaymentMethodsList(): Observable<PaymentMethodVM[]> {
    if (this.loaded) {
      return this.paymentMethodsSubject.asObservable();
    }
    return this.http.get<OperationResult<PaymentMethodVM[]>>(this.apiUrl)
      .pipe(
        tap((response: OperationResult<PaymentMethodVM[]>) => {
          if (response.Success) {
            this.paymentMethodsSubject.next(response.Data);
            this.loaded = true;
          }
        }),
        map((response: OperationResult<PaymentMethodVM[]>) => response.Data), // Mapear para retornar solo los datos
        catchError(() => {
          return of([]);
        })
      );
  }
}
