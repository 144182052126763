import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Router } from '@angular/router';
import { ShortcutInput, ShortcutEventOutput, KeyboardShortcutsComponent, AllowIn } from "ng-keyboard-shortcuts";  

@Component({
  selector: 'navigation-shortcuts',
  template: `
      <ng-keyboard-shortcuts [shortcuts]="navigationShortcuts"></ng-keyboard-shortcuts>
      <ng-keyboard-shortcuts-help [key]="'f9'"
        [closeKey]="'escape'"
        [title]="'Ayuda'">
      </ng-keyboard-shortcuts-help>
      `
})
export class NavigationShortcutsComponent implements OnInit {

  navigationShortcuts: ShortcutInput[] = [
    {
      key: ["F1"],
      preventDefault: true,
      allowIn: [AllowIn.Textarea, AllowIn.Input],
      label: "Navegación",
      description: "Ventas",
      command: (e) => {
        this.router.navigate(["/home/pos/sales"]);
      }
    },
    {
      key: ["F2"],
      preventDefault: true,
      allowIn: [AllowIn.Textarea, AllowIn.Input],
      label: "Navegación",
      description: "Créditos",
      command: (e) => {
        this.router.navigate(["/home/pos/credits"]);
      }
    },
    {
      key: ["F3"],
      preventDefault: true,
      allowIn: [AllowIn.Textarea, AllowIn.Input],
      label: "Navegación",
      description: "Productos",
      command: (e) => {
        this.router.navigate(["/home/products"]);
      }
    },
    {
      key: ["F4"],
      preventDefault: true,
      allowIn: [AllowIn.Textarea, AllowIn.Input],
      label: "Navegación",
      description: "Inventario",
      command: (e) => {
        this.router.navigate(["/home/inventory"]);
      }
    },
  ];

  @ViewChild(KeyboardShortcutsComponent) private keyboard: KeyboardShortcutsComponent;

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

}
