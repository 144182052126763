import { Component, OnInit, ViewChild } from '@angular/core';
import { GenericGridComponent } from '../../../../common/components/generic-grid/generic-grid.component';
import { LoadingService } from '../../../../common/components/loading/loading.service';
import { AlertifyService } from '../../../../common/services/alertify.service';
import { ProductVM } from '../../../../models/view-models';
import { ProductsService } from '../../../../services/products.service';
import { UploadService } from '../../../../services/upload.service';

@Component({
  selector: 'app-deleted-products',
  templateUrl: './deleted-products.component.html',
  styleUrls: ['./deleted-products.component.scss']
})

export class DeletedProductsComponent implements OnInit {

  data: ProductVM[] = [];

  @ViewChild("grid", { read: GenericGridComponent, static: false })
  public grid: GenericGridComponent;

  constructor(private productsService: ProductsService,
    private alertify: AlertifyService,
    private uploadService: UploadService,
    private loading: LoadingService) { }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.loading.show();
    this.productsService.getDeletedProducts()
      .subscribe((response: ProductVM[]) => {
        if (response) {
          this.data= response;
        }
        this.setGridOptions();
        this.loading.hide();
      }, (err) => {
        this.loading.hide();
      });
  }

  setGridOptions() {
    this.grid.setData(this.data);
    this.grid.options.toolbarTitle = "Productos Eliminados";
    this.grid.options.showDetailsButton = false;
    this.grid.options.showDeleteButton = false;
    this.grid.options.showEditButton = false;
    this.grid.options.showRestoreButton = true;
    this.grid.columns = [
      { field: "Code", title: "Código" },
      { field: "Name", title: "Nombre" },
      { field: "Description", title: "Descripción" },
      { field: "CategoryName", title: "Categoría" },
      { field: "RetailPrice", title: "Precio", currencyType: true },
    ];
    this.grid.setGridOptions();
  }

  restoreProduct(product: ProductVM) {
    this.alertify.confirm(
      `Restaurar producto: ${product.Name}`,
      () => {
        this.loading.show();
        this.productsService.restoreProduct(product)
          .subscribe((response: boolean) => {
            if (response) {
              this.grid.deleteRow(product.UUID);
            }
            this.loading.hide();
          }, (err) => {
            console.log(err);
            this.loading.hide();
          });
      }
    );
  }

}
