
<form [formGroup]="entityForm"
      class="__entityForm">

  <h4 *ngIf="isEdition">
    Editar Producto Único
  </h4>

  <h4 *ngIf="!isEdition">
    Agregar Producto Único
  </h4>


  <kendo-floatinglabel text="Código">
    <input kendoTextBox name="Code"
           #code
           class="form-control"
           [readOnly]="isEdition"
           maxlength="50"
           [ngClass]="{'is-invalid': entityForm.get('Code').errors && entityForm.get('Code').touched}"
           type="text"
           aria-label="Código"
           placeholder="Código"
           formControlName="Code" />
  </kendo-floatinglabel>

  <kendo-floatinglabel text="Nombre">
    <input kendoTextBox name="Name"
           #name
           class="form-control"
           [ngClass]="{'is-invalid': entityForm.get('Name').errors && entityForm.get('Name').touched}"
           type="text"
           aria-label="Nombre"
           placeholder="Nombre"
           formControlName="Name" />
  </kendo-floatinglabel>

  <app-category-selector #categorySelector></app-category-selector>

  <app-unit-selector #unitSelector></app-unit-selector>

  <kendo-floatinglabel text="Precio de Costo">
    <input kendoTextBox name="CostPrice"
           class="form-control"
           [ngClass]="{'is-invalid': entityForm.get('CostPrice').errors && entityForm.get('CostPrice').touched}"
           type="number"
           aria-label="Precio de Costo"
           placeholder="Precio de Costo"
           formControlName="CostPrice" />
  </kendo-floatinglabel>

  <kendo-floatinglabel text="Precio de Menudeo">
    <input kendoTextBox name="RetailPrice"
           class="form-control"
           (focusout)="retailPriceFocusout()"
           [ngClass]="{'is-invalid': entityForm.get('RetailPrice').errors && entityForm.get('RetailPrice').touched}"
           type="number"
           aria-label="Precio de Menudeo"
           placeholder="Precio de Menudeo"
           formControlName="RetailPrice" />
  </kendo-floatinglabel>

  <p class="form-control d-flex justify-content-between align-items-center">
    <b>Ganancia</b>
    <span>
      {{profit}} %
    </span>
  </p>

  <kendo-floatinglabel text="Precio de Mayoreo">
    <input kendoTextBox name="WholesalePrice"
           class="form-control"
           [ngClass]="{'is-invalid': entityForm.get('WholesalePrice').errors && entityForm.get('WholesalePrice').touched}"
           type="number"
           aria-label="Precio de Mayoreo"
           placeholder="Precio de Mayoreo"
           formControlName="WholesalePrice" />
  </kendo-floatinglabel>

  
  <div>

    <kendo-floatinglabel text="Existencia">
      <input kendoTextBox name="Quantity"
             class="form-control"
             [readOnly]="isEdition"
             type="number"
             aria-label="Existencia"
             placeholder="Existencia"
             formControlName="Quantity" />
    </kendo-floatinglabel>

    <div class="row">
      <div class="col-6">
        <kendo-floatinglabel text="Cantidad Mínima">
          <input kendoTextBox name="MinimumQuantity"
                 [readOnly]="true"
                 class="form-control"
                 type="number"
                 aria-label="Cantidad Mínima"
                 placeholder="Cantidad Mínima"
                 [(ngModel)]="minQty"
                 [ngModelOptions]="{standalone: true}"/>
        </kendo-floatinglabel>
      </div>
      <div class="col-6">
        <kendo-floatinglabel text="Cantidad Máxima">
          <input kendoTextBox name="MaximumQuantity"
                 class="form-control"
                 [readOnly]="true"
                 type="number"
                 aria-label="Cantidad Máxima"
                 placeholder="Cantidad Máxima"
                 [(ngModel)]="maxQty"
                 [ngModelOptions]="{standalone: true}"/>
        </kendo-floatinglabel>
      </div>
    </div>

  </div>

  <kendo-floatinglabel text="Descripción">
    <input kendoTextBox name="Description"
           class="form-control"
           type="text"
           aria-label="Descripción"
           placeholder="Descripción"
           formControlName="Description" />
  </kendo-floatinglabel>

  <div class="d-flex justify-content-end __formButtonsContainer">
    <button class="btn btn-primary"
            *ngIf="!isEdition"
            type="submit"
            [disabled]="!isValidForm()"
            (click)="save()">
      Guardar
    </button>
    <button class="btn btn-primary"
            *ngIf="isEdition"
            type="submit"
            [disabled]="!isValidForm()"
            (click)="update()">
      Guardar cambios
    </button>

    <button class="btn btn-secondary"
            type="button"
            (click)="cancel()">
      Cancelar
    </button>
  </div>

</form>
