
<div [hidden]="showForm">

  <h3>
    Clientes
  </h3>

  <button class="btn btn-primary"
          (click)="addNew()">
    Agregar <i class="fa fa-plus"></i>
  </button>

  <hr />
  <br />

  <app-filtered-grid #grid
                    (rowToEdit)="edit($event)"
                    (rowToDelete)="delete($event)">
  </app-filtered-grid>

</div>

<div [hidden]="!showForm">

  <form [formGroup]="entityForm"
        class="__entityForm">

    <h4 *ngIf="isEdition">
      Editar Cliente
    </h4>

    <h4 *ngIf="!isEdition">
      Agregar Cliente
    </h4>


    <kendo-floatinglabel text="Nombre (s)">
      <input kendoTextBox name="Names"
             #name
             class="form-control"
             [ngClass]="{'is-invalid': entityForm.get('Names').errors && entityForm.get('Names').touched}"
             type="text"
             aria-label="Nombre (s)"
             placeholder="Nombre (s)"
             formControlName="Names" />
    </kendo-floatinglabel>

    <kendo-floatinglabel text="Apellido (s)">
      <input kendoTextBox name="LastNames"
             class="form-control"
             [ngClass]="{'is-invalid': entityForm.get('LastNames').errors && entityForm.get('LastNames').touched}"
             type="text"
             aria-label="Apellido (s)"
             placeholder="Apellido (s)"
             formControlName="LastNames" />
    </kendo-floatinglabel>

    <br /><br />

    <!-- contact info -->

    <button (click)="onContactToggle()"
            class="d-flex justify-content-between align-items-center">
      <span>Datos de Contacto</span>
      <i class="fa fa-angle-down"
         *ngIf="!showContactForm"></i>
      <i class="fa fa-angle-up"
         *ngIf="showContactForm"></i>
    </button>

    <div [ngClass]="{'collapse': !showContactForm }">

      <kendo-floatinglabel text="Teléfono">
        <input kendoTextBox name="Telephone"
               name="Telephone"
               class="form-control"
               type="tel"
               maxlength="10"
               aria-label="Teléfono"
               placeholder="Teléfono"
               formControlName="Telephone" />
      </kendo-floatinglabel>

      <kendo-floatinglabel text="Correo Electrónico">
        <input kendoTextBox name="Email"
               class="form-control"
               type="email"
               aria-label="Correo Electrónico"
               placeholder="Correo Electrónico"
               formControlName="Email" />
      </kendo-floatinglabel>

    </div>

    <hr />

    <!-- address info -->

    <button (click)="onAddressToggle()"
            class="d-flex justify-content-between align-items-center">
      <span>Domicilio</span>
      <i class="fa fa-angle-down"
         *ngIf="!showAddressForm"></i>
      <i class="fa fa-angle-up"
         *ngIf="showAddressForm"></i>
    </button>

    <div [ngClass]="{'collapse': !showAddressForm}">

      <kendo-floatinglabel text="Domicilio 1">
        <input kendoTextBox name="Address1"
               class="form-control"
               type="text"
               aria-label="Domicilio 1"
               placeholder="Domicilio 1"
               formControlName="Address1" />
      </kendo-floatinglabel>

      <kendo-floatinglabel text="Domicilio 2">
        <input kendoTextBox name="Address2"
               class="form-control"
               type="text"
               aria-label="Domicilio 2"
               placeholder="Domicilio 2"
               formControlName="Address2" />
      </kendo-floatinglabel>

      <div class="row">
        <div class="col-6">
          <kendo-floatinglabel text="Colonia">
            <input kendoTextBox name="Block"
                   class="form-control"
                   type="text"
                   aria-label="Colonia"
                   placeholder="Colonia"
                   formControlName="Block" />
          </kendo-floatinglabel>
        </div>

        <div class="col-6">
          <kendo-floatinglabel text="Código Postal">
            <input kendoTextBox name="ZipCode"
                   class="form-control"
                   maxlength="5"
                   type="text"
                   aria-label="Código Postal"
                   placeholder="Código Postal"
                   formControlName="ZipCode" />
          </kendo-floatinglabel>
        </div>
      </div>

      <app-state-and-city-selector #stateAndCitySelector></app-state-and-city-selector>
    </div>

    <hr />

    <!-- credit info -->

    <button (click)="onCreditToggle()"
            [disabled]="creditLimitInputDisabled"
            class="d-flex justify-content-between align-items-center">
      <span>Crédito</span>
      <i class="fa fa-angle-down"
         *ngIf="!showCreditForm"></i>
      <i class="fa fa-angle-up"
         *ngIf="showCreditForm"></i>
    </button>

    <div [ngClass]="{'collapse': !showCreditForm }">
      <div class="row">
        <div class="col-md-4 col-sm-12">
          <br />
          <label class="form-check-label"
                 [hidden]="creditLimitInputDisabled">
            Tiene Crédito
            <input type="checkbox" kendoCheckBox
                   (change)="onHasCreditChange($event)"
                   formControlName="HasCredit" />
          </label>
        </div>

        <div class="col-md-8 col-sm-12">
          <div [hidden]="!showCreditLimit">
            <kendo-floatinglabel text="Límite de Crédito">
              <input kendoTextBox name="CreditLimit"
                     [readOnly]="creditLimitInputDisabled"
                     #creditLimit
                     class="form-control"
                     type="number"
                     aria-label="Límite de Crédito"
                     placeholder="Límite de Crédito"
                     formControlName="CreditLimit" />
            </kendo-floatinglabel>
          </div>
        </div>
      </div>
    </div>

    <hr />

    <kendo-floatinglabel text="Notas">
      <textarea kendo-textarea name="Notes"
                class="form-control"
                aria-label="Notas"
                placeholder="Notas"
                formControlName="Notes"></textarea>
    </kendo-floatinglabel>

    <div class="d-flex justify-content-end __formButtonsContainer">
      <button class="btn btn-primary"
              *ngIf="!isEdition"
              type="submit"
              [disabled]="!isValidForm()"
              (click)="save()">
        Guardar
      </button>
      <button class="btn btn-primary"
              *ngIf="isEdition"
              type="submit"
              [disabled]="!isValidForm()"
              (click)="update()">
        Guardar cambios
      </button>

      <button class="btn btn-secondary"
              type="button"
              (click)="cancel()">
        Cancelar
      </button>
    </div>
    n
  </form>

</div>
