import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-exit',
  templateUrl: './exit.component.html',
  styleUrls: ['./exit.component.scss']
})
export class ExitComponent {

  showDialog: boolean = false;

  @Output() exitEmiter: EventEmitter<any> = new EventEmitter<any>();
  @Output() modalClose: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  public openDialog() {
    this.showDialog = true;
  }

  saveTicket() {
    this.exitEmiter.emit();
    this.showDialog = false;
  }

  closeDialog() {
    this.modalClose.emit();
    this.showDialog = false;
  }



}
