import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { LoginResponse } from '../models/view-models';

@Injectable()
export class BearerAuthInterceptor implements HttpInterceptor {

  constructor(private authService: AuthenticationService) { }

  intercept(request: HttpRequest<any>,
    next: HttpHandler): Observable<HttpEvent<any>> {

    const sessionUser: LoginResponse = this.authService.SessionUserValue();
    const aip: boolean = this.authService.getAIP();

    if (sessionUser && sessionUser.Token) {
      // Upload file
      if (request.url.includes('Upload')) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${sessionUser.Token}`,
            'Content-Disposition': 'mulipart/form-data'
          }
        });

      } else {
        // simple request
        if (!aip) {
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${sessionUser.Token}`
            }
          });
        } else { // authorization required request
          const authUser: LoginResponse = this.authService.AuthorizationUserValue();
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${authUser.Token}`
            }
          });
        }
      }
    }
    //alert(JSON.stringify(request.headers));
    //alert(`Bearer ${sessionUser.token}`);
    return next.handle(request);

  }
}
