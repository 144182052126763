
<div [hidden]="showForm">

  <h3>
    Métodos de Pago
  </h3>

  <button class="btn btn-primary"
          (click)="addNew()">
    Agregar <i class="fa fa-plus"></i>
  </button>

  <hr />
  <br />

  <div *ngIf="methods">

    <div class="row">
      <div class="col-md-12 mx-auto">

        <div class="list-group">
          <div class="list-group-item d-flex justify-content-between align-items-center"
               *ngFor="let method of methods">

            <span>
              <!--<label class="form-check-label">
          {{method.Name}}
          <input type="checkbox" kendoCheckBox
                 [checked]="method.Active" />
        </label>-->
              <kendo-switch [checked]="method.Active"
                            (valueChange)="methodSwitch($event, method)"
                            [onLabel]="'Activo'"
                            [offLabel]="'Inactivo'">
              </kendo-switch>
              &nbsp;&nbsp;
              <b>{{method.Name}}</b>
            </span>
            <span class="badge">
              <button class="btn btn-secondary"
                      (click)="edit(method)"
                      aria-label="Editar">
                <i class="fa fa-pencil"></i>
              </button>
              <button class="btn btn-secondary"
                      (click)="delete(method)"
                      aria-label="Eliminar">
                <i class="fa fa-trash"></i>
              </button>
            </span>


          </div>
        </div>

      </div>
    </div>

  </div>

</div>

<div [hidden]="!showForm">

  <form [formGroup]="entityForm"
        class="__entityForm">

    <h4 *ngIf="isEdition">
      Editar Método de Pago
    </h4>

    <h4 *ngIf="!isEdition">
      Agregar Método de Pago
    </h4>


    <kendo-floatinglabel text="Código">
      <input kendoTextBox name="Code"
             #code
             class="form-control"
             [readOnly]="isEdition"
             maxlength="5"
             [ngClass]="{'is-invalid': entityForm.get('Code').errors && entityForm.get('Code').touched}"
             type="text"
             aria-label="Código"
             placeholder="Código"
             formControlName="Code" />
    </kendo-floatinglabel>

    <kendo-floatinglabel text="Nombre">
      <input kendoTextBox name="Name"
             #name
             class="form-control"
             [ngClass]="{'is-invalid': entityForm.get('Name').errors && entityForm.get('Name').touched}"
             type="text"
             aria-label="Nombre"
             placeholder="Nombre"
             formControlName="Name" />
    </kendo-floatinglabel>

    <div [hidden]="isEdition">
      <label class="form-check-label">
        Activo
        <input type="checkbox" kendoCheckBox
               formControlName="Active" />
      </label>
    </div>

    <label class="form-check-label">
      Es moneda internacional
      <input type="checkbox" kendoCheckBox
             formControlName="IsCurrency" />
    </label>

    <div class="d-flex justify-content-end __formButtonsContainer">
      <button class="btn btn-primary"
              *ngIf="!isEdition"
              type="submit"
              [disabled]="!isValidForm()"
              (click)="save()">
        Guardar
      </button>
      <button class="btn btn-primary"
              *ngIf="isEdition"
              type="submit"
              [disabled]="!isValidForm()"
              (click)="update()">
        Guardar cambios
      </button>

      <button class="btn btn-secondary"
              type="button"
              (click)="cancel()">
        Cancelar
      </button>
    </div>

  </form>

</div>
