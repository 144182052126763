
export enum PermissionType{
  ROUTE= 'R',
  ACTION = 'A',
  ENDPOINT= 'E'
}

export enum TipoPermiso {
  R = "Ruta",
  A = "Acción",
  E="API Endpoint"
}
