
<div class="modal"
     tabindex="-1"
     role="dialog"
     [ngClass]="{'show': showModal}"
     [ngStyle]="{'display': showModal ? 'block' : 'none'}"
     (keydown.escape)="close()"
     style="max-height: 400px;">
  <div class="modal-dialog modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Ayuda</h5>
        <button type="button"
                #closeButton
                class="close"
                data-dismiss="modal"
                aria-label="Cerrar"
                (click)="close()">
          <span aria-hidden="true">&times;</span>
          <span class="sr-only">Cerrar</span>
        </button>
      </div>
      <div class="modal-body"
           style="max-height: 400px; overflow-y: auto;">
        <ul class="list-group">
          <li class="list-group-item d-flex justify-content-between align-items-center" *ngFor="let s of shortcuts">
            <b>{{ s.description }}</b>
            <span class="badge">{{ s.key }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
