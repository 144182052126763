
export interface ServicePayVM {
  UUID?: string;
  Id?: number;
  Name: string;
  Description: string;
  LogoLink: string;
  Order: number;
  StringLength: number;
  TransferType: number;
  Emisor: string;
  Commission: number;
  WebServiceCommission?: number;
  Tax: number;
  Type: string;
  RequestConfirm: boolean;
  Operator: string;
  EntryMode: string;
  SKU: string;
  TicketId: string;
  USUAPP: string;
  HOST_NAME: string;
  IsActive: boolean;
}

export interface ServiceType {
  Text: string;
  Value: string;
}

export const ServiceTypes: ServiceType[] = [
  {
    Text:'Todos',
    Value: ''
  },
  {
    Text:'Servicios',
    Value: 'Service'
  },
  {
    Text:'Recargas',
    Value: 'Prepaid'
  }
];

export interface EnableOrDisableServiceDto {
  ServiceId: string;
  IsActive: boolean;
}
