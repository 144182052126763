import { Component, ElementRef, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from '../../../../environments/environment';
import { AuthenticationService } from '../../../interceptors/authentication.service';
import { SaleStatus } from '../../../models/SaleStatus';
import { CancelTicketDto, ModifyTicketDto, MovementVM, SaleDetailsVM, SaleVM, TicketVM } from '../../../models/view-models';
import { MovementsService } from '../../../services/movements.service';
import { SalesService } from '../../../services/sales.service';
import { ShiftService } from '../../../services/shift.service';

@Component({
  selector: 'app-authorization-window',
  templateUrl: './authorization-window.component.html',
  styleUrls: ['./authorization-window.component.scss']
})
export class AuthorizationWindowComponent implements OnInit {

  opened: boolean = false;
  dialogForm: FormGroup;

  @ViewChild("user") user: ElementRef;

  loading: boolean = false;
  buttonDisabled: boolean = false;

  // cash in and cash out movements
  authorizeMovement: boolean = false;
  movement: MovementVM;
  @Output() onMovementCreated: EventEmitter<boolean> = new EventEmitter<boolean>();

  // cancel ticket
  authorizeTicketCancelation: boolean = false;
  saleToCancel: SaleVM;
  @Output() onTicketCanceled: EventEmitter<boolean> = new EventEmitter<boolean>();

  // return products
  authorizeReturnProducts: boolean = false;
  productsToReturn: SaleDetailsVM[] = [];
  unSelectedProducts: SaleDetailsVM[] = [];
  selectedSale: SaleVM;
  @Output() onProductsReturned: EventEmitter<boolean> = new EventEmitter<boolean>();

  // Modify ticket
  authorizeModifyTicket: boolean = false;
  modifiedTicket: ModifyTicketDto;
  @Output() onTicketModified: EventEmitter<boolean> = new EventEmitter<boolean>();

  authorizeError: boolean = true;

  public screenWidth: number;
  public screenHeight: number;

  constructor(private authService: AuthenticationService,
    private movementService: MovementsService,
    private saleService: SalesService,
    private shiftService: ShiftService,
    private fb: FormBuilder) { }

  ngOnInit(): void {
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
  }

  public openWindow() {
    this.opened = true;
    this.initForm();
    this.focusOnUserInput();
  }

  initForm() {
    this.dialogForm = this.fb.group({
      user: ['', [Validators.required]],
      password: ['', [Validators.required]]
    });
  }

  openAuthorizationWindowForMovement(value: MovementVM) {
    this.authorizeMovement = true;
    this.movement = value;
    this.openWindow();
  }

  openAuthorizationWindowForTicketCancelation(value: SaleVM) {
    this.authorizeTicketCancelation = true;
    this.saleToCancel = value;
    this.openWindow();
  }

  openAuthorizationWindowForReturnProducts(sale: SaleVM, products: SaleDetailsVM[], nonSelectedProducts: SaleDetailsVM[]) {
    this.authorizeReturnProducts = true;
    this.selectedSale = sale;
    this.productsToReturn = products;
    this.unSelectedProducts = nonSelectedProducts;
    this.openWindow();
  }

  openAuthorizationWindowForModifyTicket(value: ModifyTicketDto) {
    this.authorizeModifyTicket = true;
    this.modifiedTicket = value;
    this.openWindow();
  }

  isValidForm(): boolean {
    return this.dialogForm.valid;
  }

  authorize() {
    if (this.isValidForm()) {
      this.loading = true;
      this.buttonDisabled = true;
      this.authService.authorize(this.dialogForm.value)
        .subscribe((response: boolean) => {
          if (response) {
            this.executeAction();
          } else {
            this.loading = false;
            this.buttonDisabled = false;
          }
        }, (err) => {
          this.authorizeError = true;
          this.loading = false;
          this.buttonDisabled = false;
        });
    }
  }

  focusOnUserInput() {
    setTimeout(() => {
      this.user.nativeElement.focus();
    }, 0);
  }

  public closeWindow() {
    this.opened = false;
    this.authorizeError = false;
    this.loading = false;
    this.buttonDisabled = false;
    this.authorizeMovement = false;
    this.movement = null;
    this.authorizeTicketCancelation = false;
    this.saleToCancel = null;
    this.authorizeReturnProducts = false;
    this.productsToReturn = [];
    this.unSelectedProducts = [];
    this.authorizeModifyTicket = false;
    this.modifiedTicket = null;
    this.selectedSale = null;
    this.authService.setAIP(false);
  }

  executeAction() {
    if (this.authorizeMovement) {
      this.createMovement();
    }
    if (this.authorizeTicketCancelation) {
      this.cancelTicket();
    }
    if (this.authorizeReturnProducts) {
      this.returnProducts();
    }
    if (this.authorizeModifyTicket) {
      this.modifyTicket();
    }
  }

  createMovement() {
    if (this.movement.MovementType === 'I') {
      this.createCashin();
    } else if (this.movement.MovementType === 'O') {
      this.createCashout();
    }
  }

  private createCashin() {
    this.loading = true;
    this.buttonDisabled = true;
    this.movementService.createCashInMovement(this.movement)
      .subscribe((response: MovementVM) => {
        if (response) {
          this.openTicket(response.TicketUrl);
          this.closeWindow();
          this.onMovementCreated.emit(true);
          this.loading = false;
        }
      }, (err) => {
        this.loading = false;
        this.buttonDisabled = false;
      });
  }

  private createCashout() {
    this.loading = true;
    this.buttonDisabled = true;
    this.movementService.createCashOutMovement(this.movement)
      .subscribe((response: MovementVM) => {
        if (response) {
          this.openTicket(response.TicketUrl);
          this.closeWindow();
          this.onMovementCreated.emit(true);
          this.loading = false;
        }
      }, (err) => {
        this.loading = false;
        this.buttonDisabled = false;
      });
  }

  cancelTicket() {
    this.loading = true;
    this.buttonDisabled = true;
    let cancelation: CancelTicketDto = {
      UserId: this.authService.AuthorizationUserValue().UUID,
      Sale: this.saleToCancel,
      ShiftId: this.shiftService.getActiveShiftId()
    };
    this.saleService.cancelSale(cancelation)
      .subscribe((response: TicketVM) => {
        if (response) {
          this.openTicket(response.Url);
          this.closeWindow();
          this.onTicketCanceled.emit(true);
          this.loading = false;
        }
      }, (err) => {
        this.loading = false;
      });
  }

  returnProducts() {
    this.loading = true;
    this.buttonDisabled = true;
    let saleReturns: ModifyTicketDto = {
      UserId: this.authService.AuthorizationUserValue().UUID,
      CurrentSale: this.selectedSale,
      NewSale: this.createNewSale()
    };

    this.saleService.returnProducts(saleReturns)
      .subscribe((response: TicketVM[]) => {
        if (response) {
          response.forEach(item => {
            this.openTicket(item.Url);
          });
          this.closeWindow();
          this.onProductsReturned.emit(true);
          this.loading = false;
        }
      }, (err) => {
        this.loading = false;
      });
  }

  modifyTicket() {
    this.loading = true;
    this.buttonDisabled = true;
    this.modifiedTicket.UserId = this.authService.AuthorizationUserValue().UUID;
    this.modifiedTicket.NewSale.UserId = this.authService.AuthorizationUserValue().UUID;
    this.modifiedTicket.NewSale.Username = this.authService.AuthorizationUserValue().User;
    this.saleService.modifyTicket(this.modifiedTicket)
      .subscribe((response: TicketVM) => {
        if (response) {          
          this.openTicket(response.Url);
          this.closeWindow();
          this.onTicketModified.emit(true);
          this.loading = false;
        }
      }, (err) => {
        this.loading = false;
      });
  }

  openTicket(filename: string): void {
    const url = `${environment.ticketsUrl}/${filename}`;
    window.open(url, '_blank');
  }

  createNewSale(): SaleVM {
    let total: number = 0;
    this.unSelectedProducts.forEach(item => {
      total = total + item.TotalPrice;
    });
    return {
      UserId: this.authService.AuthorizationUserValue().UUID,
      Username: this.authService.AuthorizationUserValue().User,
      PaymentMethodCode: this.selectedSale.PaymentMethodCode,
      PaymentMethodName: this.selectedSale.PaymentMethodName,
      MultiplePayments: this.selectedSale.MultiplePayments,
      Total: total,
      Paid: this.selectedSale.Total,
      Change: this.selectedSale.Total - total,
      BankReference: this.selectedSale.BankReference,
      SaleDetails: this.unSelectedProducts,
      CustomerId: this.selectedSale.CustomerId,
      CustomerName: this.selectedSale.CustomerName,
      IsPaid: this.selectedSale.IsPaid,
      Status: SaleStatus.Active,
      ShiftId: this.selectedSale.ShiftId,
    };
  }

}
