
<div [hidden]="showDetails">

  <h3>
    Existencia de Productos
  </h3>

  <hr />
  <br />

  <div class="text-right">
    <app-product-category-selector #categorySelector
                                   (onSelect)="filterCategory($event)">
    </app-product-category-selector>
  </div>

  <br />

  <app-filtered-grid #grid
                     (onCellClicked)="select($event)">
    </app-filtered-grid>

</div>


<div *ngIf="showDetails"
     class="__entityForm">

  <h4>
    Detalles de Producto
  </h4>

  <hr />

  <div class="row g-0 pull-right">
    <div class="btn-group">
      <button class="btn btn-outline-primary"
              style="float:right;"
              [hidden]="isUniqueProduct"
              (click)="openInputDialog()">
        Entrada de Mercancía
      </button>
      <button class="btn btn-outline-primary"
              style="float:right;"
              (click)="cancel()">
        Cerrar
      </button>
    </div>
  </div>

  <br /><br />

  <div class="row">
    <div class="col-6">
      <label>Código</label>
      <p class="form-control">{{product.Code}}</p>
    </div>
    <div class="col-6">
      <label>Nombre</label>
      <p class="form-control">{{product.Name}}</p>
    </div>
  </div>

  <div class="row">
    <div class="col-6">
      <label>Categoría</label>
      <p class="form-control">{{product.CategoryName}}</p>
    </div>
    <div class="col-6">
      <label>Unidad de Medida</label>
      <p class="form-control">{{product.UnitName}}</p>
    </div>
  </div>

  <div class="row">
    <div class="col-4">
      <label>Costo</label>
      <p class="form-control">{{product.CostPrice | currency}}</p>
    </div>
    <div class="col-4">
      <label>Menudeo</label>
      <p class="form-control">{{product.RetailPrice | currency}}</p>
    </div>
    <div class="col-4">
      <label>Mayoreo</label>
      <p class="form-control">{{product.WholesalePrice | currency}}</p>
    </div>
  </div>

  <div class="row">
    <div class="col-4">
      <label>Existencia</label>
      <p class="form-control">{{product.Quantity }}</p>
    </div>
    <div class="col-4">
      <label>Cantidad Mínima</label>
      <p class="form-control">{{product.MinimumQuantity }}</p>
    </div>
    <div class="col-4">
      <label>Cantidad Máxima</label>
      <p class="form-control">{{product.MaximumQuantity }}</p>
    </div>
  </div>

</div>


<kendo-dialog title="Entrada de Mercancía"
              *ngIf="showInputDialog"
              (close)="closeDialog()"
              [minWidth]="250"
              [width]="450">

  <form [formGroup]="inputForm"
        class="__entityForm">

    <label>Nombre</label>
    <p class="form-control">{{product.Name}}</p>

    <div class="row">
      <div class="col-8">
        <kendo-floatinglabel text="Cantidad">
          <input kendoTextBox name="Quantity"
                 #qty
                 class="form-control"
                 type="number"
                 aria-label="Cantidad"
                 placeholder="Cantidad"
                 formControlName="Quantity" />
        </kendo-floatinglabel>
      </div>
      <div class="col-4">
        <p class="form-control">{{product.UnitName }}</p>
      </div>
    </div>

    <hr />

    <kendo-dialog-actions>
      <div class="d-flex justify-content-between align-items-center">
        <button class="btn btn-primary"
                type="submit"
                [disabled]="!isInputValidForm()"
                (click)="createInputRecord()">
          Guardar
        </button>
        <button class="btn btn-primary"
                type="button"
                (click)="closeInputDialog()">
          Cancelar
        </button>
      </div>
    </kendo-dialog-actions>
  </form>

</kendo-dialog>
