<kendo-dialog title="Seleccionar turnos"
              [minWidth]="350"
              [width]="600"
              [height]="600"
              *ngIf="showDialog"
              (close)="closeDialog()">

  <div class="row" style="margin-bottom:25px;">
    <div class="col-6">
      <b>
        Seleccione uno o más turnos
      </b>
    </div>
    <div class="col-6 text-right">
      <b>{{ selected }} turnos seleccionados</b>
    </div>
  </div>

  <div class="d-flex justify-content-start align-items-center">
    <kendo-floatinglabel text="Filtrar por fecha">
      <kendo-datepicker [format]="'dd/MM/yyyy'"
                        placeholder="Filtrar por fecha"
                        (valueChange)="dateChange($event)"
                        [value]="selectedDate">
        <kendo-datepicker-messages today="Hoy"
                                   toggle="Seleccionar fecha">
        </kendo-datepicker-messages>
      </kendo-datepicker>
    </kendo-floatinglabel>
    <button class="btn btn-outline-secondary" (click)="selectedDate=null"
            [disabled]="selectedDate == null"
            style="margin-left:15px;margin-top:10px;">
      Limpiar filtro
    </button>
  </div>

  <div class="list-group">
    <div class="list-group-item list-group-item-action flex-column align-items-start"
         style="cursor:pointer;"
         *ngFor="let item of shifts | filterShiftsByDate:selectedDate"
         (click)="toggleSelection(item)">
      <div class="d-flex w-100 justify-content-between align-items-center">
        <label class="checkbox-container">
          <input type="checkbox" [checked]="item.IsSelected">
          <span class="checkmark"></span>
        </label>
        <h5 class="mb-1">
          Creado por: {{ item.Username }}
        </h5>
      </div>
      <p class="mb-1">
        {{ item.CreateDate | date: 'dd/MM/yyyy HH:mm:ss' }}
      </p>
    </div>
  </div>

  <kendo-dialog-actions>
    <div class="d-flex justify-content-between align-items-center">
      <button class="btn btn-primary"
              type="submit"
              [disabled]="!isDialogValid()"
              (click)="ok()">
        Crear
      </button>
      <button class="btn btn-primary"
              type="button"
              (click)="closeDialog()">
        Cancelar
      </button>
    </div>
  </kendo-dialog-actions>

</kendo-dialog>
