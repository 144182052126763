import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FilteredGridComponent } from '../../../common/components/filtered-grid/filtered-grid.component';
import { LoadingService } from '../../../common/components/loading/loading.service';
import { StateAndCitySelectorComponent } from '../../../common/components/state-and-city-selector/state-and-city-selector.component';
import { AlertifyService } from '../../../common/services/alertify.service';
import { LocalData } from '../../../models/LocalData';
import { CustomerVM, CustomerWithBalanceAndLastPaymentDateVM } from '../../../models/view-models';
import { ActionPermissionValidatorService } from '../../../services/action-permission-validator.service';
import { CustomersService } from '../../../services/customers.service';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss']
})
export class CustomersComponent implements OnInit {

  customers: CustomerVM[] = [];
  customer: CustomerWithBalanceAndLastPaymentDateVM;
  entityForm: FormGroup;
  showForm: boolean = false;
  isEdition: boolean = false;
  localCustomerId: string;
  localCustomerIdExists: boolean = false;

  @ViewChild("grid", { read: FilteredGridComponent, static: false })
  public grid: FilteredGridComponent;

  @ViewChild("name") name: ElementRef;
  @ViewChild("creditLimit") creditLimit: ElementRef;

  public showContactForm: boolean = false;
  public showAddressForm: boolean = false;
  public showCreditForm: boolean = false;
  public showCreditLimit: boolean = false;

  @ViewChild("stateAndCitySelector", { read: StateAndCitySelectorComponent, static: false })
  public stateAndCitySelector: StateAndCitySelectorComponent;

  creditLimitInputDisabled: boolean = false;

  constructor(private customerService: CustomersService,
    private actionValidatorService: ActionPermissionValidatorService,
    private fb: FormBuilder,
    private alertify: AlertifyService,
    private loading: LoadingService) {

    if (localStorage.getItem(LocalData.CUSTOMER_ID) !== null) {
      this.localCustomerIdExists = true;
      this.localCustomerId = localStorage.getItem(LocalData.CUSTOMER_ID);
    }

  }

  ngOnInit(): void {
    this.initForm();
    this.creditLimitInputDisabled = this.actionValidatorService.creditLimitEdition();
    this.loadData();
    //this.onFormChanges();
    if (this.localCustomerIdExists) {
      this.getDetailsFromLocalCustomerId();
    }
  }

  initForm() {
    this.entityForm = this.fb.group({
      Names: ['', [Validators.required, Validators.minLength(3)]],
      LastNames: ['', [Validators.required, Validators.minLength(3)]],
      Telephone: [''],
      Email: [''],
      Address1: [''],
      Address2: [''],
      Block: [''],
      ZipCode: [''],
      Notes: [''],
      HasCredit: [false],
      CreditLimit: [0],
    });
  }

  loadData() {
    this.loading.show();
    this.customerService.getAll()
      .subscribe((response: CustomerWithBalanceAndLastPaymentDateVM[]) => {
        if (response) {
          response.forEach(item => {
            item.Fullname = `${item.Names} ${item.LastNames}`;
          });
          this.customers = response;
        }
        this.setGridOptions();
        this.loading.hide();
      }, (err) => {
        this.loading.hide();
      });
  }

  setGridOptions() {
    this.grid.setData(this.customers);
    this.grid.options.toolbarTitle = "Clientes";
    this.grid.options.showDetailsButton = false;
    this.grid.columns = [
      { field: "Id", title: "# Cliente", cssClass: "id-header-column" },
      { field: "Alias", title: "Alias" },
      { field: "Fullname", title: "Nombre" },
      { field: "HasCredit", title: "Tiene Crédito", booleanType: true },
      { field: "Balance", title: "Saldo", currencyType: true },
      { field: "LastPaymentDate", title: "Último Pago", dateType: true },
    ];
    this.grid.filterableColumns = [
      { field: "Id", operator:'eq' },
      { field: "Alias", operator: 'eq' },
      { field: "Fullname", operator: 'contains' },
    ];
    this.grid.setGridOptions();
  }

  getDetailsFromLocalCustomerId() {
    this.loading.show();
    this.customerService.getCustomerWithBalanceAndLastPaymentDate(this.localCustomerId)
      .subscribe((response: CustomerWithBalanceAndLastPaymentDateVM) => {
        if (response) {
          this.customer = response;
          this.entityForm.setValue({
            Names: this.customer.Names,
            LastNames: this.customer.LastNames,
            Telephone: this.customer.Telephone,
            Email: this.customer.Email,
            Address1: this.customer.Address1,
            Address2: this.customer.Address2,
            Block: this.customer.Block,
            ZipCode: this.customer.ZipCode,
            Notes: this.customer.Notes,
            HasCredit: this.customer.HasCredit,
            CreditLimit: this.customer.CreditLimit,
          });
          this.isEdition = true;
          this.setFormVisibility();
          setTimeout(() => {
            this.creditLimit.nativeElement.focus();
            this.creditLimit.nativeElement.select();
          }, 0);
        }
        this.loading.hide();
        localStorage.removeItem(LocalData.CUSTOMER_ID);
        this.showCreditForm = true;
      }, (err) => {
        this.loading.hide();
      });
  }

  setFormVisibility = () => this.showForm = !this.showForm;

  addNew() {
    this.isEdition = false;
    this.setFormVisibility();
    setTimeout(() => {
      this.name.nativeElement.focus();
      //this.code.nativeElement.value = "";
    }, 0);
  }

  isValidForm(): boolean {
    return this.entityForm.valid;
  }

  public onContactToggle(): void {
    this.showContactForm = !this.showContactForm;
  }

  public onAddressToggle(): void {
    this.showAddressForm = !this.showAddressForm;
  }

  public onCreditToggle(): void {
    if (!this.creditLimitInputDisabled) {
      this.showCreditForm = !this.showCreditForm;
    }
  }

  onFormChanges(): void {
    this.entityForm.valueChanges
      .subscribe(val => {
        this.showCreditLimit = val.HasCredit;
      });
  }

  save() {
    if (this.isValidForm()) {
      this.loading.show();
      const entity: CustomerVM = Object.assign({
        StateId: this.stateAndCitySelector.getStateId(),
        StateName: this.stateAndCitySelector.getStateName(),
        CityId: this.stateAndCitySelector.getCityId(),
        CityName: this.stateAndCitySelector.getCityName(),
        PositiveBalance: false,
      }, this.entityForm.value);
      this.customerService.insert(entity)
        .subscribe((response: CustomerVM) => {
          if (response) {
            response.Fullname = `${response.Names} ${response.LastNames}`;
            this.grid.addRow(response);
            this.cancel();
            this.loading.hide();
          }
        }, (err) => {
          this.loading.hide();
        });
    }
  }

  cancel() {
    this.initForm();
    this.stateAndCitySelector.reset();
    this.showCreditForm = false;
    this.showAddressForm = false;
    this.showContactForm = false;
    this.setFormVisibility();
  }

  edit(value: CustomerWithBalanceAndLastPaymentDateVM) {
    this.customer = value;
    this.entityForm.setValue({
      Names: this.customer.Names,
      LastNames: this.customer.LastNames,
      Telephone: this.customer.Telephone,
      Email: this.customer.Email,
      Address1: this.customer.Address1,
      Address2: this.customer.Address2,
      Block: this.customer.Block,
      ZipCode: this.customer.ZipCode,
      Notes: this.customer.Notes,
      HasCredit: this.customer.HasCredit,
      CreditLimit: this.customer.CreditLimit,
    });
    this.isEdition = true;
    this.setFormVisibility();
    setTimeout(() => {
      this.name.nativeElement.focus();
    }, 0);
  }

  update() {
    if (this.isValidForm()) {
      this.loading.show();
      const entity: CustomerVM = Object.assign({
        UUID: this.customer.UUID,
        Id: this.customer.Id,
        Alias: this.customer.Alias,
        StateId: this.stateAndCitySelector.getStateId(),
        StateName: this.stateAndCitySelector.getStateName(),
        CityId: this.stateAndCitySelector.getCityId(),
        CityName: this.stateAndCitySelector.getCityName(),
        PositiveBalance: false,
      }, this.entityForm.value);
      this.customerService.update(entity)
        .subscribe((response: CustomerVM) => {
          if (response) {
            this.grid.updateRowByUUID(this.customerConverter(response));
            this.cancel();
            this.loading.hide();
          }
        }, (err) => {
          this.loading.hide();
        });
    }
  }

  delete(value: CustomerVM) {
    this.alertify.confirm(
      `Eliminar cliente ${value.Fullname}`,
      () => {
        this.customerService.delete(value.UUID)
          .subscribe((response: boolean) => {
            if (response) {
              this.grid.deleteRow(value.UUID);
              this.loading.hide();
            }
          }, (err) => {
            this.loading.hide();
          });
      });
  }

  

  onHasCreditChange(e:any) {
    this.showCreditLimit = e.target.checked;
    //this.showCreditForm = e.target.checked;
  }

  private customerConverter(c: CustomerVM): CustomerWithBalanceAndLastPaymentDateVM {
    return {
      Id: c.Id,
      UUID: c.UUID,
      Alias: c.Alias,
      Names: c.Names,
      LastNames: c.LastNames,
      Telephone: c.Telephone,
      Email: c.Email,
      Address1: c.Address1,
      Address2: c.Address2,
      Block: c.Block,
      ZipCode: c.ZipCode,
      Notes: c.Notes,
      HasCredit: c.HasCredit,
      CreditLimit: c.CreditLimit,
      Balance: (this.customer)? this.customer.Balance : 0,
      LastPaymentDate: (this.customer) ? this.customer.LastPaymentDate : null,
      Fullname: `${c.Names} ${c.LastNames}`,
      CityId: c.CityId,
      StateId: c.StateId,
      CityName: c.CityName,
      StateName: c.StateName,
      PositiveBalance: false,
      TotalPaid: (this.customer) ? this.customer.TotalPaid : 0,
      TotalPurchased: (this.customer) ? this.customer.TotalPurchased : 0,
    };
  }

  

}
