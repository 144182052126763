import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AlertifyService } from '../common/services/alertify.service';
import { ExternalCredentialVM, OperationResult } from '../models/view-models';
import { map } from 'rxjs/operators';
import { ErrorService } from '../interceptors/error.service';
import { LocalData } from '../models/LocalData';

@Injectable({
  providedIn: 'root'
})
export class ExternalCredentialsService {

  apiUrl: string = `${environment.apiUrl}/ExternalCredential`;

  constructor(private http: HttpClient,
    private logError: ErrorService,
    private alertify: AlertifyService) { }

  insert(entity: ExternalCredentialVM) {
    return this.http.post(this.apiUrl, entity)
      .pipe(map((_res: any) => {
        const response = _res as OperationResult<ExternalCredentialVM>;
        if (response.Success) {
          this.alertify.success("Registro creado exitosamente");
          return response.Data;
        } else {
          this.logError.logError(response);
          return null;
        }
      }));
  }

  update(entity: ExternalCredentialVM) {
    return this.http.put(this.apiUrl, entity)
      .pipe(map((_res: any) => {
        const response = _res as OperationResult<ExternalCredentialVM>;
        if (response.Success) {
          this.alertify.success("Registro editado exitosamente");
          return response.Data;
        } else {
          this.logError.logError(response);
          return null;
        }
      }));
  }

  delete(uuid: string) {
    const uri = `${this.apiUrl}?uuid=${uuid}`;
    return this.http.delete(uri)
      .pipe(map((_res: any) => {
        const response = _res as OperationResult<boolean>;
        if (response.Success) {
          this.alertify.success("Registro eliminado exitosamente");
          return response.Data;
        } else {
          this.logError.logError(response);
          return false;
        }
      }));
  }

  getAll() {
    return this.http.get(this.apiUrl)
      .pipe(map((_res: any) => {
        const response = _res as OperationResult<ExternalCredentialVM[]>;
        if (response.Success) {
          return response.Data;
        } else {
          this.logError.logError(response);
          return null;
        }
      }));
  }

  getById(uuid: string) {
    const uri = `${this.apiUrl}/GetByIdentifier?uuid=${uuid}`;
    return this.http.get(uri)
      .pipe(map((_res: any) => {
        const response = _res as OperationResult<ExternalCredentialVM>;
        if (response.Success) {
          return response.Data;
        } else {
          this.logError.logError(response);
          return null;
        }
      }));
  }

  getByName(name: string) {
    const uri = `${this.apiUrl}/GetByName?name=${name}`;
    return this.http.get(uri)
      .pipe(map((_res: any) => {
        const response = _res as OperationResult<ExternalCredentialVM>;
        if (response.Success) {
          return response.Data;
        } else {
          this.logError.logError(response);
          return null;
        }
      }));
  }
}
