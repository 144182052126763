import { Component, OnInit, ViewChild } from '@angular/core';
import { GenericGridComponent } from '../../../../common/components/generic-grid/generic-grid.component';
import { LoadingService } from '../../../../common/components/loading/loading.service';
import { CARDEXVM } from '../../../../models/view-models';
import { CARDEXService } from '../../../../services/cardex.service';

@Component({
  selector: 'cardex',
  templateUrl: './cardex.component.html',
  styleUrls: ['./cardex.component.scss']
})
export class CardexComponent implements OnInit {

  data: CARDEXVM[] = [];

  @ViewChild("grid", { read: GenericGridComponent, static: false })
  public grid: GenericGridComponent;

  constructor(private cardexXervice: CARDEXService,
    private loading: LoadingService,) { }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.loading.show();
    this.cardexXervice.getAll()
      .subscribe((response: CARDEXVM[]) => {
        if (response) {
          this.data = response;
        }
        this.setGridOptions();
        this.loading.hide();
      }, (err) => {
        this.loading.hide();
      });
  }

  setGridOptions() {
    this.grid.setData(this.data);
    this.grid.options.toolbarTitle = "CARDEX";
    this.grid.options.showDetailsButton = false;
    this.grid.options.showDeleteButton = false;
    this.grid.options.showEditButton = false;
    this.grid.options.hideActionButtons = true;
    this.grid.columns = [
      { field: "CreateDate", title: "Fecha", dateType: true },
      { field: "RecordTypeName", title: "Tipo" },
      { field: "OperationTypeName", title: "Operación" },
      { field: "ProductCode", title: "Código de Producto" },
      { field: "ProductName", title: "Producto" },
      { field: "Quantity", title: "Cantidad", numberType: true },
      { field: "UnitName", title: "Unidad de Medida" },
      { field: "CategoryName", title: "Categoría" },
    ];
    this.grid.setGridOptions();
  }
}
