import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { RouteItem } from '../../../models/ComponentModels';
import { RolPermissionVM } from '../../../models/view-models';
import { RolPermissionService } from '../../../services/rol-permission.service';

@Component({
  selector: 'app-route-selector',
  templateUrl: './route-selector.component.html',
  styleUrls: ['./route-selector.component.scss']
})
export class RouteSelectorComponent implements OnInit {

  label: string = "Ruta";

  public data: RouteItem[] = [];
  private _all_path_routes: RouteItem[] = [];
  public selectedItem: RouteItem;
  disabled: boolean = true;

  public comboForm: FormGroup = new FormGroup({
    comboValue: new FormControl()
  });

  @Output() selectItem: EventEmitter<RouteItem> = new EventEmitter<RouteItem>();

  constructor(private dataService: RolPermissionService) { }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.data = this.dataService.getAllRoutePaths();
    this.disabled = this.data.length <= 0;
  }

  isValidForm(): boolean {
    return this.comboForm.valid;
  }

  getItem(): RouteItem{
    if (this.isValidForm()) {
      return this.selectedItem;
    }
    return null;
  }

  getItemRoute(): string {
    if (this.isValidForm()) {
      return this.selectedItem.Route;
    }
    return "";
  }

  getItemName(): string {
    if (this.isValidForm()) {
      return this.selectedItem.Name;
    }
    return "";
  }

  selectionChange(value: RouteItem) {
    this.selectedItem = value;
    this.selectItem.emit(this.selectedItem);
  }

  setItemByRoute(_route: string) {
    if (this.data && this.data.length > 0 && _route != null && _route != "") {
      this.selectedItem = this.data.find(item => item.Route === _route);
      this.comboForm.setValue({
        comboValue: this.selectedItem
      });
    }
  }

  setItemByName(name: string) {
    if (this.data && this.data.length > 0 && name != null && name != "") {
      this.selectedItem = this.data.find(item => item.Name === name);
      this.comboForm.setValue({
        comboValue: this.selectedItem
      });
    }
  }

  reset() {
    this.comboForm.reset();
    this.selectedItem = null;
    this.disabled = false;
  }

  removeItems(permissions: RolPermissionVM[]) {
    permissions.forEach(permission => {
      let item = this.data.find(x => x.Route === permission.Route);
      let idx = this.data.indexOf(item);
      this.data.splice(idx, 1);
    });
  }

  removeItem(_path: string) {
    let item = this.data.find(x => x.Route === _path);
    let idx = this.data.indexOf(item);
    this.data.splice(idx, 1);
  }

  hasItems(): boolean {
    return this.data.length > 0;
  }

  getRouteName(_route: string): string {
    let paths = this.dataService.getAllRoutePaths();
    let result = "";

    result = paths.find(item => item.Route === _route).Name;

    return result;
  }


}
