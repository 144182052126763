import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AlertifyService } from '../common/services/alertify.service';
import { CurrencyVM, OperationResult, PaymentMethodVM } from '../models/view-models';
import { map } from 'rxjs/operators';
import { ErrorService } from '../interceptors/error.service';
import { LocalData } from '../models/LocalData';

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {

  apiUrl: string = `${environment.apiUrl}/Currency`;

  constructor(private http: HttpClient,
    private logError: ErrorService,
    private alertify: AlertifyService) { }

  insert(entity: CurrencyVM) {
    return this.http.post(this.apiUrl, entity)
      .pipe(map((_res: any) => {
        const response = _res as OperationResult<CurrencyVM>;
        if (response.Success) {
          this.alertify.success("Registro creado exitosamente");
          return response.Data;
        } else {
          this.logError.logError(response);
          return null;
        }
      }));
  }

  update(entity: CurrencyVM) {
    return this.http.put(this.apiUrl, entity)
      .pipe(map((_res: any) => {
        const response = _res as OperationResult<CurrencyVM>;
        if (response.Success) {
          this.alertify.success("Registro editado exitosamente");
          return response.Data;
        } else {
          this.logError.logError(response);
          return null;
        }
      }));
  }

  delete(uuid: string) {
    const uri = `${this.apiUrl}?uuid=${uuid}`;
    return this.http.delete(uri)
      .pipe(map((_res: any) => {
        const response = _res as OperationResult<boolean>;
        if (response.Success) {
          this.alertify.success("Registro eliminado exitosamente");
          return response.Data;
        } else {
          this.logError.logError(response);
          return false;
        }
      }));
  }

  getAll() {
    return this.http.get(this.apiUrl)
      .pipe(map((_res: any) => {
        const response = _res as OperationResult<CurrencyVM[]>;
        if (response.Success) {
          return response.Data;
        } else {
          this.logError.logError(response);
          return null;
        }
      }));
  }

  getById(uuid: string) {
    const uri = `${this.apiUrl}/GetByIdentifier?uuid=${uuid}`;
    return this.http.get(uri)
      .pipe(map((_res: any) => {
        const response = _res as OperationResult<CurrencyVM>;
        if (response.Success) {
          return response.Data;
        } else {
          this.logError.logError(response);
          return null;
        }
      }));
  }

  getbyCode(code: string) {
    const uri = `${this.apiUrl}/GetByCode?code=${code}`;
    return this.http.get(uri)
      .pipe(map((_res: any) => {
        const response = _res as OperationResult<CurrencyVM>;
        if (response.Success) {
          return response.Data;
        } else {
          this.logError.logError(response);
          return null;
        }
      }));
  }

  // load data and save it to local
  loadAndSaveData([]) {
    this.getAll()
      .subscribe((response: CurrencyVM[]) => {
        if (response) {
          localStorage.setItem(LocalData.CURRENCY_EXCHANGE, JSON.stringify(response));
        }
      });
  }

  // get local data by code
  getLocalCurrency(code: string): CurrencyVM {
    if (localStorage.getItem(LocalData.CURRENCY_EXCHANGE) !== null) {
      let currencies: CurrencyVM[] = JSON.parse(localStorage.getItem(LocalData.CURRENCY_EXCHANGE));
      return currencies.find(c => c.Code === code) || null as any;
    }
    let currencies: CurrencyVM[] = [];
    return null as any;
  }
}
