import { Component, EventEmitter, Output } from '@angular/core';
import { ShiftVM } from '../../../models/view-models';
import { ShiftService } from '../../../services/shift.service';
import { LoadingService } from '../loading/loading.service';

@Component({
  selector: 'app-closed-shifts-selector-dialog',
  templateUrl: './closed-shifts-selector-dialog.component.html',
  styleUrls: ['./closed-shifts-selector-dialog.component.scss']
})
export class ClosedShiftsSelectorDialogComponent {

  shifts: ShiftVM[] = [];
  hasResults: boolean = false;
  showDialog: boolean = false;
  selected: number = 0;
  @Output() onShiftsSelected: EventEmitter<ShiftVM[]> = new EventEmitter<ShiftVM[]>();

  selectedDate: Date | null;

  constructor(private shiftService: ShiftService,
    private loading: LoadingService) { }

  public open() {
    this.selectedDate = null;
    this.loading.show();
    this.shiftService.getClosedShifts()
      .subscribe((response: ShiftVM[]) => {
        if (response) {
          response.forEach(s => {
            s.IsSelected = false;
          });
          this.selected = 0;
          this.shifts = response;
          this.showDialog = true;
        }
        this.loading.hide();
      }, (err) => {
        this.loading.hide();
      });
  }

  select(item: ShiftVM) {
    let idx = this.shifts.findIndex(s => s.UUID == item.UUID);
    this.shifts[idx].IsSelected = !this.shifts[idx].IsSelected;
    this.selected = this.shifts.filter(s => s.IsSelected).length;
  }

  toggleSelection(item: ShiftVM) {
    let idx = this.shifts.findIndex(s => s.UUID == item.UUID);
    this.shifts[idx].IsSelected = !this.shifts[idx].IsSelected;
    this.selected = this.shifts.filter(s => s.IsSelected).length;
  }

  isDialogValid(): boolean {
    return this.selected > 0;
  }

  ok() {
    let ss: ShiftVM[] = this.shifts.filter(s => s.IsSelected == true);
    this.closeDialog();
    this.onShiftsSelected.emit(ss);
  }

  closeDialog() {
    this.shifts = [];
    this.showDialog = false;
  }

  dateChange(dt: Date) {
    this.selectedDate = new Date(dt);
  }

}
