
<kendo-dialog title="Seleccionar turno"
              [minWidth]="350"
              [width]="600"
              height="400"
              *ngIf="showDialog">

  <h2 class="text-center">
    Bienvenido {{ username || '' }}
  </h2>

  <div class="list-group">
    <button class="list-group-item list-group-item-action flex-column align-items-start active"
         *ngFor="let item of shifts">
      <div class="d-flex w-100 justify-content-between">
        <h5 class="mb-1">
          Creado por: {{ item.Username }}
        </h5>
      </div>
      <p class="mb-1">
        {{ item.CreateDate | date: 'dd/MM/yyyy HH:mm:ss' }}
      </p>
      <div class="text-right">
        <button class="btn btn-outline-dark"
                (click)="select(item)">
          Seleccionar
        </button>
      </div>
    </button>
  </div>

</kendo-dialog>
