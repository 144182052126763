import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GenericGridComponent } from '../../../common/components/generic-grid/generic-grid.component';
import { LoadingService } from '../../../common/components/loading/loading.service';
import { RolSelectorComponent } from '../../../common/components/rol-selector/rol-selector.component';
import { AlertifyService } from '../../../common/services/alertify.service';
import { UserVM, RegisterRequestVM, RolVM } from '../../../models/view-models';
import { RolService } from '../../../services/rol.service';
import { UsersService } from '../../../services/users.service';
import { ChangePasswordFormComponent } from './change-password-form/change-password-form.component';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  users: UserVM[] = [];
  user: UserVM;
  entityForm: FormGroup;
  showForm: boolean = false;
  showChangePassword: boolean = false;
  isEdition: boolean = false;

  @ViewChild("grid", { read: GenericGridComponent, static: false })
  public grid: GenericGridComponent;

  @ViewChild("username") username: ElementRef;

  showPassword: boolean = false;

  @ViewChild("rolSelector", { read: RolSelectorComponent })
  public rolSelector: RolSelectorComponent;

  @ViewChild("changePassword", { read: ChangePasswordFormComponent })
  public changePassword: ChangePasswordFormComponent;

  constructor(private usersService: UsersService,
    private rolService: RolService,
    private fb: FormBuilder,
    private alertify: AlertifyService,
    private loading: LoadingService) { }

  ngOnInit(): void {
    this.initForm();
    this.loadData();
  }

  initForm() {
    this.entityForm = this.fb.group({
      Username: ['', [Validators.required, Validators.minLength(4)]],
      Password: ['', [Validators.required, Validators.minLength(4)]],
    });
  }

  loadData() {
    this.loading.show();
    this.usersService.getAll()
      .subscribe((response: UserVM[]) => {
        if (response) {
          this.users = response;
        }
        this.setGridOptions();
        this.loading.hide();
      }, (err) => {
        this.loading.hide();
      });
  }

  setGridOptions() {
    this.grid.setData(this.users);
    this.grid.options.toolbarTitle = "Usuarios";
    this.grid.options.showDetailsButton = false;
    this.grid.columns = [
      { field: "Username", title: "Nombre" },
      { field: "Rol", title: "Rol" },
    ];
    this.grid.setGridOptions();
  }

  setFormVisibility = () => this.showForm = !this.showForm;

  addNew() {
    this.isEdition = false;
    this.setFormVisibility();
  }

  setFocusOnUsernameInput() {
    setTimeout(() => {
      this.username.nativeElement.focus();
      this.username.nativeElement.select();
    }, 0);
  }

  isValidForm(): boolean {
    return this.entityForm.valid
      && this.rolSelector.isValidForm();
  }

  save() {
    if (this.isValidForm()) {
      this.loading.show();
      let newUser: RegisterRequestVM = {
        Username: this.entityForm.controls["Username"].value,
        Password: this.entityForm.controls["Password"].value,
        ConfirmPassword: this.entityForm.controls["Username"].value,
        RolId: this.rolSelector.getItemId()
      };
      this.usersService.insert(newUser)
        .subscribe((response: boolean) => {
          if (response) {
            this.loadData();
          }
          this.cancel();
          this.loading.hide();
        }, (err) => {
            this.loading.hide();
        });
    }
  }

  cancel() {
    this.initForm();
    this.setFormVisibility();
  }

  edit(value: UserVM) {
    this.changePassword.open(value);
    this.showChangePassword = true;
  }

  update() {
    if (this.isValidForm()) {
      this.loading.show();
      let updatedUser: UserVM = {
        UUID: this.user.UUID,
        Username: this.entityForm.controls["Username"].value,
        Rol: this.rolSelector.getItemId()
      };
      this.usersService.update(updatedUser)
        .subscribe((response: boolean) => {
          if (response) {
            this.loadData();
          }
          this.cancel();
          this.loading.hide();
        }, (err) => {
          this.loading.hide();
        }); 
    }
  }

  delete(value: UserVM) {
    this.alertify.confirm(
      `Eliminar usuario ${value.Username}`,
      () => {
        this.loading.show();
        this.usersService.delete(value.UUID)
          .subscribe((response: boolean) => {
            if (response) {
              this.loadData();
            }
            this.loading.hide();
          }, (err) => {
              this.loading.hide();
          });
      });
  }

  onRolSelect(rol: RolVM) {
    this.setFocusOnUsernameInput();
  }

  generatePassword() {
    let pass: string = "";
    let str:string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ' +
      'abcdefghijklmnopqrstuvwxyz0123456789';
    for (var i = 0; i < 8; i++) {
      var char = Math.floor(Math.random()
        * str.length + 1);
      pass += str.charAt(char)
    }

    this.entityForm.controls["Password"].setValue(pass);
  }

  onPasswordChanged(res: boolean) {
    if (res) 
      this.showChangePassword = false;
  }

  changePasswordCancel() {
    this.showChangePassword = false;
  }


}
