import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoadingService } from './loading.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit, OnDestroy {

    loading = false;
    loadingSuscription: Subscription;

    constructor(private loadingService: LoadingService) { }

    ngOnInit() {
        this.loadingSuscription = this.loadingService.loadingStatus
            .subscribe((value: boolean) => {
                this.loading = value;
            });
    }

    ngOnDestroy() {
        this.loadingSuscription.unsubscribe();
    }

}
