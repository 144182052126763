import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DeleteCustomerMovement } from '../../../models/view-models';

@Component({
  selector: 'app-delete-confirm-dialog',
  templateUrl: './delete-confirm-dialog.component.html',
  styleUrls: ['./delete-confirm-dialog.component.scss']
})
export class DeleteConfirmDialogComponent {

  showDialog: boolean = false;
  dialogForm: FormGroup;
  @ViewChild("reason") reason: ElementRef;

  @Output() onConfirm: EventEmitter<DeleteCustomerMovement> = new EventEmitter<DeleteCustomerMovement>();
  @Output() modalClose: EventEmitter<any> = new EventEmitter<any>();

  userId: String = "";
  movementId: string = "";
  msg: string = "";

  constructor(private fb: FormBuilder) { }

  initForm() {
    this.dialogForm = this.fb.group({
      Reason: ['', [Validators.required]],
    });
  }

  focusOnReasonTextbox() {
    setTimeout(() => {
      this.reason.nativeElement.focus();
    }, 0);
  }

  isValidForm(): boolean { return this.dialogForm.valid; }

  public openDialog(user_id: string, movement_id: string, _msg: string) {
    this.userId = user_id;
    this.movementId = movement_id;
    this.msg = _msg;
    this.showDialog = true;
    this.initForm();
    this.focusOnReasonTextbox();
  }

  confirmDeletion() {
    if (this.isValidForm()) {
      const deleteCM: DeleteCustomerMovement = Object.assign({
        UserId: this.userId,
        MovementId: this.movementId,
      }, this.dialogForm.value);
      this.onConfirm.emit(deleteCM);
    }
  }

  closeDialog() {
    this.showDialog = false;
    this.modalClose.emit();
  }



}
