import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AlertifyService } from '../common/services/alertify.service';
import { MovementVM, OperationResult } from '../models/view-models';
import { map } from 'rxjs/operators';
import { ErrorService } from '../interceptors/error.service';
import { LocalData } from '../models/LocalData';

@Injectable({
  providedIn: 'root'
})
export class MovementsService {

  apiUrl: string = `${environment.apiUrl}/Movement`;

  constructor(private http: HttpClient,
    private error: ErrorService,
    private alertify: AlertifyService) { }

  insert(movement: MovementVM) {
    return this.http.post(this.apiUrl, movement)
      .pipe(map((response: OperationResult<MovementVM>) => {
        if (response.Success) {
          this.alertify.success("Se registró el movimiento");
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }

  update(movement: MovementVM) {
    return this.http.put(this.apiUrl, movement)
      .pipe(map((response: OperationResult<MovementVM>) => {
        if (response.Success) {
          this.alertify.success("Se editó el método de pago");
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }

  delete(uuid: string) {
    const uri = `${this.apiUrl}?uuid=${uuid}`;
    return this.http.delete(uri)
      .pipe(map((response: OperationResult<boolean>) => {
        if (response.Success) {
          this.alertify.success("Se eliminó el método de pago");
          return response.Data;
        } else {
          this.error.logError(response);
          return false;
        }
      }));
  }

  createCashInMovement(movement: MovementVM) {
    const uri = `${this.apiUrl}/CreateCashInMovement`;
    return this.http.post(uri, movement)
      .pipe(map((response: OperationResult<MovementVM>) => {
        if (response.Success) {
          this.alertify.success("Se registró el movimiento");
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }

  createCashOutMovement(movement: MovementVM) {
    const uri = `${this.apiUrl}/CreateCashOutMovement`;
    return this.http.post(uri, movement)
      .pipe(map((response: OperationResult<MovementVM>) => {
        if (response.Success) {
          this.alertify.success("Se registró el movimiento");
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }

  getAll() {
    return this.http.get(this.apiUrl)
      .pipe(map((response: OperationResult<MovementVM[]>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }

  getActives() {
    const uri = `${this.apiUrl}/GetActives`;
    return this.http.get(uri)
      .pipe(map((response: OperationResult<MovementVM[]>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }

  getMovementsFromDate(date: Date) {
    let dt: string = this.formatDateToString(date);
    //const uri = `${this.apiUrl}/GetMovementsFromDate?date=${dt}`;
    const uri = `${this.apiUrl}/GetMovementsFromDateAndUser?date=${dt}`;
    return this.http.get(uri)
      .pipe(map((response: OperationResult<MovementVM[]>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }

  getById(uuid: string) {
    const uri = `${this.apiUrl}/GetByIdentifier?uuid=${uuid}`;
    return this.http.get(uri)
      .pipe(map((response: OperationResult<MovementVM>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }

  private formatDateToString(date: Date): string {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} 12:00:00`;
  }
}
