
<div class="row">
  <div class="col-md-8 col-sm-12 mx-auto">
    <h5>
      {{ header }}
    </h5>

    <ul class="list-group">
      <li class="list-group-item d-flex justify-content-between align-items-center"
          *ngFor="let item of items">
        <b>{{ item.Title }}</b>
        <span class="badge">{{ item.Value | currency }}</span>
      </li>
    </ul>
  </div>
</div>
