
<div class="d-none d-md-block">
  <div class="__no-tabs d-flex justify-content-center align-items-center"
       *ngIf="tickets.length === 0">
    <b class="text-center">No hay tickets guardados</b>
  </div>

  <div class="__tabs" *ngIf="tickets">
    <div class="__tab"
         *ngFor="let item of tickets"
         (click)="selectTicket(item)">
      {{ item.TicketId }}
      <br />
      {{ item.Date | date: 'dd/MM/yyyy HH:mm:ss' }}
      <br />
      {{ item.Total | currency }}
    </div>
  </div>
</div>

<div class="d-sm-none">
  <div class="collapse-container"
       [ngClass]="{'collapse': collapse }">
    <div class="__no-tabs d-flex justify-content-start align-items-center"
         *ngIf="tickets.length === 0">
      <b>No hay tickets guardados</b>
    </div>

    <div class="__tabs" *ngIf="tickets">
      <div class="__tab"
           *ngFor="let item of tickets"
           (click)="selectTicket(item)">
       {{ item.TicketId }}
        <br />
        {{ item.Date | date: 'dd/MM/yyyy' }}
        <br />
        {{ item.Date | date: 'HH:mm:ss' }}
        <br />
        {{ item.Total | currency }}
      </div>
    </div>
  </div>
</div>
