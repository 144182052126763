import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { UnitVM } from '../../../models/view-models';
import { UnitsService } from '../../../services/units.service';

@Component({
  selector: 'app-unit-selector',
  templateUrl: './unit-selector.component.html',
  styleUrls: ['./unit-selector.component.scss']
})
export class UnitSelectorComponent implements OnInit {

  public data: UnitVM[] = [];
  public selectedItem: UnitVM;
  disabled: boolean = true;

  public comboForm: FormGroup = new FormGroup({
    comboValue: new FormControl()
  });

  constructor(private dataService: UnitsService) { }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.dataService.getAll()
      .subscribe((response: UnitVM[]) => {
        if (response) {
          this.data = response;
        }
        this.disabled = this.data.length <= 0;
      });
  }

  isValidForm(): boolean {
    return this.comboForm.valid;
  }

  getItem(): UnitVM {
    if (this.isValidForm()) {
      return this.selectedItem;
    }
    return null;
  }

  getItemId(): string {
    if (this.isValidForm()) {
      return this.selectedItem.UUID;
    }
    return "";
  }

  getItemName(): string {
    if (this.isValidForm()) {
      return this.selectedItem.Name;
    }
    return "";
  }

  selectionChange(value: UnitVM) {
    this.selectedItem = value;
  }

  setItemById(uuid: string) {
    if (this.data && this.data.length > 0 && uuid != null && uuid != "") {
      this.selectedItem = this.data.find(item => item.UUID === uuid);
      this.comboForm.setValue({
        comboValue: this.selectedItem
      });
    }
  }

  reset() {
    this.comboForm.reset();
    this.selectedItem = null;
  }


}
