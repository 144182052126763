import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingService } from '../common/components/loading/loading.service';
import { OperationResult } from '../models/Common';
import { LoginResponse } from '../models/Login';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor(private loadingService: LoadingService,
    private router: Router) { }

  logError(err: OperationResult<any>) {
    this.loadingService.hide();
    console.log('Error status code: ', err.StatusCode);
    console.log('Error message: ', err.DetailMessages);

    alert(JSON.stringify(err));

    if (err.StatusCode == 401) {
      this.router.navigate(['/home/unauthorized']);
    }
  }

  
}
