
export enum TicketType {
  CASH_IN = 'I',
  CASH_OUT = 'O',
  SALE = 'S',
  PAY = 'P',
  DEPOSIT = 'D',
  CANCELATION = 'C',
  RETURN = 'R'
}

export enum TipoTicket {
  I="Entrada de Efectivo",
  O = "Salida de Efectivo",
  S = "Venta",
  p = "Pago",
  D = "Abono",
  C = "Cancelación",
  R = "Devolución",
}
