
<kendo-tabstrip>

  <kendo-tabstrip-tab [title]="'Rutas'" [selected]="true">
    <ng-template kendoTabContent>
      <br />
      <app-path-permissions></app-path-permissions>
    </ng-template>
  </kendo-tabstrip-tab>

  <kendo-tabstrip-tab [title]="'Acciones'" [selected]="false">
    <ng-template kendoTabContent>
      <br />
      <app-action-permissions></app-action-permissions>
    </ng-template>
  </kendo-tabstrip-tab>

  <kendo-tabstrip-tab [title]="'API'" [selected]="false">
    <ng-template kendoTabContent>
      <br />
      <app-api-endpoints></app-api-endpoints>
    </ng-template>
  </kendo-tabstrip-tab>

</kendo-tabstrip>
