
<div class="list-group">
  <div class="list-group-item d-flex justify-content-between align-items-center"
       *ngFor="let item of items">
    <b>{{ item.Title }}</b>
    <span class="badge"
          *ngIf="!item.IsCurrency && !item.IsNumber && !item.IsDate && !item.IsBoolean">
      {{ item.Value }}
    </span>
    <span class="badge"
          *ngIf="item.IsCurrency">
      {{ item.Value | currency }}
    </span>
    <span class="badge"
          *ngIf="item.IsNumber">
      {{ item.Value | number }}
    </span>
    <span class="badge"
          *ngIf="item.IsDate">
      {{ item.Value | date: 'dd/MM/yyyy' }}
    </span>
    <span class="badge"
          *ngIf="item.IsBoolean">
      <b *ngIf="item.Value">SI</b>
      <b *ngIf="!item.Value">NO</b>
    </span>
  </div>
</div>
