
<div class="row">
  <div class="col-md-6 col-sm-12">
    <h4>Seleccione roles</h4>
  </div>
  <div class="col-md-6 col-sm-12">
    <div class="btn-group">
      <button class="btn btn-outline-secondary"
              (click)="selectAll()">
        Seleccionar todos
      </button>
      <button class="btn btn-outline-secondary"
              (click)="reset()">
        Eliminar Selección
      </button>
    </div>
  </div>
</div>

<div class="list-group">
  <button class="list-group-item d-flex justify-content-between align-items-center"
          *ngFor="let rol of roles"
          (click)="select(rol)">
    <div class="row">
      <div class="col-4">
        <i class="fa fa-check-square-o fa-2x"
           *ngIf="rol.Selected"></i>
        <i class="fa fa-square-o fa-2x"
           *ngIf="!rol.Selected"></i>
      </div>
      <div class="col-8">
        {{ rol.Name }}
      </div>
    </div>
  </button>
</div>
