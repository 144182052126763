import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cancel-ticket',
  templateUrl: './cancel-ticket.component.html',
  styleUrls: ['./cancel-ticket.component.scss']
})
export class CancelTicketComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
