
export enum SaleStatus {
  Active = 'A',
  Cancelled = 'C',
  Returned = 'R'
}

export enum StatusVenta {
  A="Activo",
  C = "Cancelado",
  R = "Devuelto",
}
