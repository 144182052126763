
<div [hidden]="showDetails">

  <h3>
    Turnos
  </h3>

  <hr />
  <br />

  <app-generic-grid #grid
                    (onCellClicked)="select($event)">
  </app-generic-grid>

</div>

<div [hidden]="!showDetails">

  <h4>
    Detalles del Turno
  </h4>

  <div class="row">
    <div class="col-md-8 col-sm-12 mx-auto">
      <app-list-details #details></app-list-details>

      <div class="d-flex justify-content-end __formButtonsContainer"
           *ngIf="shift">
        <button class="btn btn-primary"
                *ngIf="shift.Active"
                (click)="closeShift()">
          Cerrar Turno
        </button>
        <button class="btn btn-primary"
                *ngIf="!shift.Active"
                (click)="openShift()">
          Abrir Turno
        </button>
        <button class="btn btn-secondary"
                type="button"
                (click)="cancel()">
          Cancelar
        </button>
      </div>
    </div>
  </div>

</div>
