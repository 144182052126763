import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { LocalData } from '../../../models/LocalData';
import { RolVM } from '../../../models/view-models';
import { RolService } from '../../../services/rol.service';

@Component({
  selector: 'app-rol-selector',
  templateUrl: './rol-selector.component.html',
  styleUrls: ['./rol-selector.component.scss']
})
export class RolSelectorComponent implements OnInit {

  label: string = "Seleccione un rol...";

  public data: RolVM[] = [];
  public selectedItem: RolVM;
  disabled: boolean = true;

  public comboForm: FormGroup = new FormGroup({
    comboValue: new FormControl()
  });

  @Output() onItemSelect: EventEmitter<RolVM> = new EventEmitter<RolVM>();

  constructor(private dataService: RolService) {
    
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.dataService.getAll()
      .subscribe((response: RolVM[]) => {
        if (response) {
          response.sort((r1, r2) => {
            if (r1.Name > r2.Name) { return 1; }
            else if (r1.Name < r2.Name) { return -1; }
            else { return 0; }
          });
          this.data = response;
        }
        this.disabled = this.data.length <= 0;
      });
  }

  

  isValidForm(): boolean { return this.comboForm.valid; }

  getItem(): RolVM {
    if (this.isValidForm()) {
      return this.selectedItem;
    }
    return null;
  }

  getItemId(): string {
    if (this.isValidForm()) {
      return this.selectedItem.UUID;
    }
    return "";
  }

  getItemName(): string {
    if (this.isValidForm()) {
      return this.selectedItem.Name;
    }
    return "";
  }

  getItemCode(): string {
    if (this.isValidForm()) {
      return this.selectedItem.Code;
    }
    return "";
  }

  selectionChange(value: RolVM) {
    this.selectedItem = value;
    this.onItemSelect.emit(this.selectedItem);
  }

  setItemById(uuid: string) {
    if (this.data && this.data.length > 0 && uuid != null && uuid != "") {
      this.selectedItem = this.data.find(item => item.UUID === uuid);
      this.comboForm.setValue({
        comboValue: this.selectedItem
      });
    }
  }

  setItemByCode(code: string) {
    if (this.data && this.data.length > 0 && code != null && code != "") {
      this.selectedItem = this.data.find(item => item.Code === code);
      this.comboForm.setValue({
        comboValue: this.selectedItem
      });
    }
  }

  reset() {
    this.comboForm.reset();
    this.selectedItem = null;
  }


}
