import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  title = "frontend";
  appName: string = environment.appName;

  constructor(private headerTitle: Title) {
    this.headerTitle.setTitle(this.appName);
  }
}
