<div class="row"
     style="margin-bottom: 150px;">
  <div class="col-12">

    <div class="tools">
      <div class="row">
        <div class="col-md-4 col-sm-12">
          <app-search-input placeholder="Buscar ticket..."
                            (searchChange)="onSearch($event)">
          </app-search-input>
        </div>
        <div class="col-md-4 col-sm-12">
          <button class="btn btn-outline-primary btn-block"
                  (click)="toggleAll(true)">
            Seleccionar todos
          </button>
        </div>
        <div class="col-md-4 col-sm-12">
          <button class="btn btn-outline-primary btn-block"
                  (click)="toggleAll(false)">
            Deseleccionar todos
          </button>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="sales.length > 0, else noResults">
      <div class="col-md-3 col-sm-6"
           *ngFor="let sale of sales | search: searchField">
        <div class="card" [ngClass]="{'active': sale.IsSelected }" style="cursor:pointer;" (click)="toggleSelection(sale)">
          <div class="card-body" [ngClass]="{'active': sale.IsSelected }" style="max-height: 100px;">
            <h5 class="card-title text-center">
              {{ sale.Sale.TicketId }}
            </h5>
            <ul class="list-group" [ngClass]="{'active': sale.IsSelected }">
              <li class="list-group-item" [ngClass]="{'active': sale.IsSelected }">
                {{ sale.Sale.CreateDate | date: 'dd/MM/yyyy H:mm:ss' }}
              </li>
              <li class="list-group-item" [ngClass]="{'active': sale.IsSelected }">
                <div class="d-flex justify-content-between">
                  <span class="small text-muted">TOTAL</span>
                  <span class="font-weight-bold text-right">{{ sale.TotalSale | currency }}</span>
                </div>
              </li>
              <li class="list-group-item d-flex justify-content-between align-items-center" [ngClass]="{'active': sale.IsSelected }">
                <div class="d-flex justify-content-between">
                  <span class="small text-muted">ADEUDO</span>
                  <span class="font-weight-bold text-right">{{ sale.TotalPending | currency }}</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <ng-template #noResults>
      <div class=" text-center"
           style="padding:30px; font-size:1.5rem;">
        No hay tickets para mostrar
      </div>
    </ng-template>
  </div>
</div>
