import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './interceptors/auth.guard';
import { RoleGuard } from './interceptors/role.guard';
import { LoginComponent } from './login/login.component';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  {
    path: 'sp',
    canActivate: [AuthGuard],
    canLoad: [AuthGuard],
    loadChildren: () => import('./home/home.module').then(x => x.HomeModule)
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ServicePayRoutingModule { }
