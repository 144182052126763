
<div>
  <b>Método de pago</b>

  <kendo-buttongroup class="multi-line-button-group" width="100%" selection="single">
    <button *ngFor="let pm of data"
            [hidden]="isHidden(pm.Code)"
            kendoButton
            [toggleable]="true"
            [selected]="pm.Selected"
            [ngClass]="{'item-selected': pm.Selected}"
            (selectedChange)="selectedChange($event, pm)">
      {{ pm.Name }}
    </button>
  </kendo-buttongroup>

</div>
