
<form [formGroup]="ProductForm">
  <div class="row">
    <div class="col-md-9 col-sm-12">
      <kendo-floatinglabel text="Buscar Producto...">
        <kendo-autocomplete #prod
                            [data]="data"
                            formControlName="selectedProduct"
                            required
                            [valueField]="'Label'"
                            (valueChange)="onValueChange($event)"
                            [filterable]="true">
        </kendo-autocomplete>
      </kendo-floatinglabel>
    </div>
    <div class="col-md-3 col-sm-12">
      <br />
      <button class="btn btn-primary"
              type="submit"
              [disabled]="!isValidForm()"
              (click)="searchProduct()">
        Buscar
      </button>
    </div>
  </div>
</form>
