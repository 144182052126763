
<div style="max-width:600px; margin: 0 auto;">
  <h3>
    Eliminar registros operativos
  </h3>

  <hr />
  <br />

  <div class="bg-warning text-center"
       style="font-size:2rem;font-weight:bolder;margin:25px;padding: 25px;">
    <i class="fa fa-warning"></i>
    <br />
    ADVERTENCIA
  </div>

  <p>
    Se eliminarán todos los registros operativos de la base de datos.
  </p>
  <p>
    Esta acción no se puede deshacer.
  </p>
  <p>
    ¿Desea continuar?
  </p>
  <br />

  <div class="form-check">
    <input type="checkbox" class="form-check-input" id="exampleCheck1"
           [(ngModel)]="continue">
    <label class="form-check-label" for="exampleCheck1">
      Si, continuar
    </label>
  </div>

  <br />
  <ng-container *ngIf="allowed">
    <button class="btn btn-danger btn-block btn-lg"
            [disabled]="!continue"
            (click)="removeRecords()">
      Eliminar registros
    </button>
  </ng-container>
</div>


