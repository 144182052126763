
<div [hidden]="showForm">

  <h3>
    Permisos de Rutas
  </h3>

  <button class="btn btn-primary"
          (click)="addNew()">
    Agregar <i class="fa fa-plus"></i>
  </button>

  <hr />
  <br />

  <app-generic-grid #grid
                    (onCellClicked)="select($event)">
  </app-generic-grid>

</div>

<div [hidden]="!showForm">

  <form [formGroup]="entityForm"
        class="__entityForm">

    <h4 *ngIf="isEdition">
      Editar Permiso
    </h4>

    <h4 *ngIf="!isEdition">
      Agregar Permiso
    </h4>

    <!--<div [hidden]="isEdition">
    <app-route-selector #routeSelector
                        (selectItem)="onRouteSelect($event)">
    </app-route-selector>
  </div>-->

    <div *ngIf="isEdition">
      <p class="form-control">
        {{ routeName }}
      </p>
    </div>

    <kendo-floatinglabel text="Código">
      <input kendoTextBox name="Code"
             #code
             class="form-control"
             [ngClass]="{'is-invalid': entityForm.get('Code').errors && entityForm.get('Code').touched}"
             type="text"
             [readOnly]="isEdition"
             aria-label="Código"
             placeholder="Código"
             formControlName="Code" />
    </kendo-floatinglabel>

    <kendo-floatinglabel text="Ruta">
      <input kendoTextBox name="Route"
             class="form-control"
             [ngClass]="{'is-invalid': entityForm.get('Route').errors && entityForm.get('Route').touched}"
             type="text"
             aria-label="Ruta"
             placeholder="Ruta"
             formControlName="Route" />
    </kendo-floatinglabel>

    <kendo-floatinglabel text="Nombre">
      <input kendoTextBox name="Name"
             #name
             class="form-control"
             [ngClass]="{'is-invalid': entityForm.get('Name').errors && entityForm.get('Name').touched}"
             type="text"
             aria-label="Nombre"
             placeholder="Nombre"
             formControlName="Name" />
    </kendo-floatinglabel>

    <br /><br />
    <app-multiple-rol-selector #rolSelector></app-multiple-rol-selector>


    <div class="d-flex justify-content-end __formButtonsContainer">
      <button class="btn btn-primary"
              *ngIf="!isEdition"
              type="submit"
              [disabled]="!isValidForm()"
              (click)="save()">
        Guardar
      </button>
      <button class="btn btn-primary"
              *ngIf="isEdition"
              type="submit"
              [disabled]="!isEditionValidForm()"
              (click)="update()">
        Guardar cambios
      </button>
      <button class="btn btn-secondary"
              type="button"
              (click)="cancel()">
        Cancelar
      </button>
    </div>
  </form>
</div>
