
<div class="text-center">

  <!--<kendo-floatinglabel text="Seleccionar fecha">-->

  <kendo-datepicker (valueChange)="onChange($event)"
                    [readOnlyInput]="true"
                    [min]="min" [max]="max"
                    [format]="'dd/MM/yyyy'"
                    [value]="value">

    <kendo-datepicker-messages today="Hoy"
                               toggle="Cambiar calendario">
    </kendo-datepicker-messages>
  </kendo-datepicker>

  <!--</kendo-floatinglabel>-->

</div>


<div class="text-center"
     *ngIf="showPreviousNextButtons">
  <button class="btn btn-outline-secondary"
          (click)="prev()">
    <i class="fa fa-angle-double-left"></i>
    &nbsp;&nbsp;
    Anterior
  </button>

  &nbsp;&nbsp;&nbsp;

  <button class="btn btn-outline-secondary"
          (click)="next()">
    Siguiente
    &nbsp;&nbsp;
    <i class="fa fa-angle-double-right"></i>
  </button>
</div>
