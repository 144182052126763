
<div [hidden]="showForm">

  <h3>
    Monedas
  </h3>

  <button class="btn btn-primary"
          (click)="addNew()">
    Agregar <i class="fa fa-plus"></i>
  </button>

  <hr />
  <br />

  <app-generic-grid #grid
                    (rowToEdit)="edit($event)"
                    (rowToDelete)="delete($event)">
  </app-generic-grid>

</div>

<div [hidden]="!showForm">

  <form [formGroup]="entityForm" class="__entityForm">
    <h3 *ngIf="isEdition">Editar Moneda</h3>
    <h3 *ngIf="!isEdition">Agregar Moneda</h3>

    <kendo-floatinglabel text="Código">
      <input kendoTextBox name="Code"
             #code maxlength="10"
             class="form-control" type="text"
             aria-label="Código" placeholder="Código"
             formControlName="Code" />
    </kendo-floatinglabel>

    <kendo-floatinglabel text="Nombre">
      <input kendoTextBox name="Name"
             #name
             class="form-control" type="text"
             aria-label="Nombre" placeholder="Nombre"
             formControlName="Name" />
    </kendo-floatinglabel>

    <kendo-floatinglabel text="Símbolo">
      <input kendoTextBox name="Symbol"
             class="form-control" type="text"
             maxlength="1"
             aria-label="Símbolo" placeholder="Símbolo"
             formControlName="Symbol" />
    </kendo-floatinglabel>
    
    <kendo-floatinglabel text="Tipo de Cambio">
      <input kendoTextBox name="Exchange"
             class="form-control" type="number"
             aria-label="Tipo de Cambio" placeholder="Tipo de Cambio"
             formControlName="Exchange" />
    </kendo-floatinglabel>

    <kendo-floatinglabel text="Descripción">
      <input kendoTextBox name="Description"
             class="form-control" type="text"
             aria-label="Descripción" placeholder="Descripción"
             formControlName="Description" />
    </kendo-floatinglabel>

    <br /><hr />

    <div class="d-flex justify-content-end __formButtonsContainer">
      <button class="btn btn-primary"
              *ngIf="!isEdition" type="submit"
              [disabled]="!isValidForm()" (click)="save()">
        Guardar
      </button>
      <button class="btn btn-primary"
              *ngIf="isEdition" type="submit"
              [disabled]="!isValidForm()" (click)="update()">
        Guardar cambios
      </button>
      <button class="btn btn-secondary"
              type="button" (click)="cancel()">
        Cancelar
      </button>
    </div>
  </form>


</div>
