import { Component, OnInit } from '@angular/core';
import { CategoryVM } from '../models/view-models';
import { TestService } from '../services/test.service';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss']
})
export class TestComponent implements OnInit {

  categories: CategoryVM[] = [];

  constructor(private testService: TestService) {
    this.testService.getAll().subscribe((response: CategoryVM[]) => {
      alert(JSON.stringify(response));
      if (response) {
        this.categories = response;
      }
    }, (err) => {
        
      
    });
  }

  ngOnInit(): void {
  }

}
