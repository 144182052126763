
<div class="jumbotron text-center">
  <h2>404</h2>
  <p>No se encuentra el recurso</p>
  <br />
  <br />
  <a style="cursor:pointer;"
     (click)="back()">
    <i class="fa fa-angle-double-left"></i> Ir atrás
  </a>
</div>
