import { Component } from '@angular/core';
import { SaleDetailsVM } from '../../../models/view-models';

@Component({
  selector: 'app-products-list-selector',
  templateUrl: './products-list-selector.component.html',
  styleUrls: ['./products-list-selector.component.scss']
})
export class ProductsListSelectorComponent {

  details: SaleDetailsVM[] = [];
  showSelector: boolean = false;
  selected: number = 0;

  setDetails(saleDetails: SaleDetailsVM[]) {
    saleDetails.forEach(item => {
      item.IsSelected = false;
    });
    this.details = saleDetails.filter(i => i.IsUnique == false);
    this.setSelectedItemsNumber();
    this.showSelector = true;
  }

  setSelectedItemsNumber() {
    this.selected = this.details.filter(item => item.IsSelected === true).length;
  }

  itemClick(item: SaleDetailsVM) {
    item.IsSelected = !item.IsSelected;
    this.setSelectedItemsNumber();
  }

  selectAll() {
    this.details.forEach(item => {
      item.IsSelected = true;
    });
    this.setSelectedItemsNumber();
  }

}
