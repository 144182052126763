<!-- grid -->
<div [hidden]="showForm">

  <h4>
    Turno Simple
  </h4>

  <button class="btn btn-primary"
          [disabled]="!activeShiftExists"
          (click)="loadCashClosingFromActiveShift()">
    Crear Corte
  </button>

  <!--<button #anchor
          (click)="onToggle()"
          class="btn btn-primary">
    Crear Corte <i class="fa fa-plus"></i>
  </button>

  <kendo-popup [anchor]="anchor"
               (anchorViewportLeave)="showPopup= false"
               *ngIf="showPopup">
    <div class="__popup">
      <button class="btn btn-outline-primary btn-block"
              (click)="loadCashClosingFromActiveShift()">
        Turno Activo
      </button>
      <button class="btn btn-outline-primary btn-block"
              (click)="openDateDialog()">
        Por Fecha
      </button>
    </div>
  </kendo-popup>-->

  <hr />
  <br />

  <app-generic-grid #grid
                    (onCellClicked)="select($event)">
  </app-generic-grid>

</div>

<div [hidden]="!showForm">

  <div class="row">
    <div class="col-md-6 col-sm-12">
      <h4>
        {{ formTitle }}
      </h4>
    </div>
    <div class="col-md-6 col-sm-12">
    </div>
  </div>
  <br />
  <hr />
  <div class="row">
    <div class="col-md-8 col-sm-12 mx-auto">

      <div class="d-flex justify-content-end __formButtonsContainer"
           style="margin-bottom:25px;">
        <button class="btn btn-outline-primary"
                *ngIf="showPrintButton"
                (click)="print()">
          Imprimir
        </button>
        <button class="btn btn-primary"
                *ngIf="!showDetails"
                [disabled]="!canCloseShift"
                type="submit"
                (click)="save()">
          Guardar
        </button>
        <button class="btn btn-secondary"
                *ngIf="!showDetails"
                type="button"
                (click)="cancel()">
          Cancelar
        </button>
        <button class="btn btn-secondary"
                *ngIf="showDetails"
                type="button"
                (click)="closeDetails()">
          Cerrar
        </button>
      </div>

      <app-cash-closing-details #dateCmp>
      </app-cash-closing-details>
      <app-cash-closing-details #salesCmp>
      </app-cash-closing-details>
      <app-cash-closing-details #detailSalesCmp>
      </app-cash-closing-details>
      <app-cash-closing-details #cashInCmp>
      </app-cash-closing-details>
      <app-cash-closing-details #cashOutCmp>
      </app-cash-closing-details>
      <app-cash-closing-details #returnsAndCancelationsCmp>
      </app-cash-closing-details>
      <app-cash-closing-details #paymentsCmp>
      </app-cash-closing-details>
      <app-cash-closing-details #depositsCmp>
      </app-cash-closing-details>
      <app-cash-closing-details #cashCmp>
      </app-cash-closing-details>
      <app-cash-closing-details #startingAndEndingCashCmp>
      </app-cash-closing-details>

      <div class="d-flex justify-content-end __formButtonsContainer">
        <button class="btn btn-primary"
                *ngIf="!showDetails"
                [disabled]="!canCloseShift"
                type="submit"
                (click)="save()">
          Guardar
        </button>
        <button class="btn btn-secondary"
                *ngIf="!showDetails"
                type="button"
                (click)="cancel()">
          Cancelar
        </button>
        <button class="btn btn-secondary"
                *ngIf="showDetails"
                type="button"
                (click)="closeDetails()">
          Cerrar
        </button>
      </div>

    </div>
  </div>
</div>

<!-- date picker selector modal -->
<kendo-dialog title="Seleccionar fecha"
              *ngIf="showDateDialog"
              (close)="closeDateDialog()"
              [minWidth]="250"
              [width]="450">
  <kendo-calendar [type]="'classic'"
                  (valueChange)="onCalendarDateChange($event)"
                  [focusedDate]="focusedDate"
                  [value]="calendarDate">
  </kendo-calendar>
  <hr />
  <div class="list-group">
    <div class="list-group-item d-flex justify-content-between align-items-center">
      <b>Fecha</b>
      <span class="badge">
        {{
 calendarDate | date: 'dd/MM/yyyy'
        }}
      </span>
    </div>
  </div>

  <hr />

  <kendo-dialog-actions>
    <div class="d-flex justify-content-between align-items-center">
      <button class="btn btn-primary"
              type="submit"
              (click)="getData()">
        Aceptar
      </button>
      <button class="btn btn-primary"
              type="button"
              (click)="closeDateDialog()">
        Cancelar
      </button>
    </div>
  </kendo-dialog-actions>

</kendo-dialog>
