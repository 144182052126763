import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AlertifyService } from '../common/services/alertify.service';
import { TicketSettingsVM, OperationResult, TicketSizeVM } from '../models/view-models';
import { map } from 'rxjs/operators';
import { ErrorService } from '../interceptors/error.service';

@Injectable({
  providedIn: 'root'
})
export class TicketSettingsService {

  apiUrl: string = `${environment.apiUrl}/TicketSettings`;

  constructor(private http: HttpClient,
    private error: ErrorService,
    private alertify: AlertifyService) { }

  update(ticketSettings: TicketSettingsVM) {
    return this.http.put(this.apiUrl, ticketSettings)
      .pipe(map((response: OperationResult<TicketSettingsVM>) => {
        if (response.Success) {
          this.alertify.success("Se guardó la configuración del ticket");
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }

  getSettings() {
    return this.http.get(this.apiUrl)
      .pipe(map((response: OperationResult<TicketSettingsVM>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }

  getTicketSizes(): TicketSizeVM[] {
    return [
      { Value: 'S', Name: 'Pequeño (58 mm)' },
      { Value: 'M', Name: 'Mediano (80 mm)' },
      { Value: 'B', Name: 'Grande (100 mm)' },
      { Value: 'L', Name: 'Tamaño carta' },
    ];
  }
}
