
<form [formGroup]="multiForm"
      class="__multiForm">

  <kendo-floatinglabel text="Efectivo">
    <input kendoTextBox name="Cash"
           class="form-control"
           #cash
           [ngClass]="{'is-invalid': multiForm.get('Cash').errors && multiForm.get('Cash').touched}"
           aria-label="Efectivo"
           placeholder="Efectivo"
           formControlName="Cash" />
  </kendo-floatinglabel>

  <kendo-floatinglabel text="Tarjeta de Crédito / Débito">
    <input kendoTextBox name="Card"
           class="form-control"
           [ngClass]="{'is-invalid': multiForm.get('Card').errors && multiForm.get('Card').touched}"
           aria-label="Tarjeta de Crédito / Débito"
           placeholder="Tarjeta de Crédito / Débito"
           formControlName="Card" />
  </kendo-floatinglabel>

</form>
