import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, Output, EventEmitter } from "@angular/core";
import { Router } from '@angular/router';
import { ShortcutInput, ShortcutEventOutput, KeyboardShortcutsComponent, AllowIn, KeyboardShortcutsHelpService } from "ng-keyboard-shortcuts";
import { ShortcutsHelpDialogComponent } from "../shortcuts-help-dialog/shortcuts-help-dialog.component";

@Component({
  selector: 'sale-shortcuts',
  template: `
  <app-shortcuts-help-dialog [shortcuts]="shortcutsList"></app-shortcuts-help-dialog>
      <ng-keyboard-shortcuts [shortcuts]="saleShortcuts"></ng-keyboard-shortcuts>
      `
})
export class SalesShortcutsComponent implements OnInit {

  @ViewChild(ShortcutsHelpDialogComponent) modal!: ShortcutsHelpDialogComponent;
  shortcutsList: any = [];

  // outputs
  //#region outputs
  @Output() charge: EventEmitter<any> = new EventEmitter<any>();
  @Output() searchField: EventEmitter<any> = new EventEmitter<any>();
  @Output() commonProduct: EventEmitter<any> = new EventEmitter<any>();
  @Output() multipleInsertion: EventEmitter<any> = new EventEmitter<any>();
  @Output() wholesalePrice: EventEmitter<any> = new EventEmitter<any>();
  @Output() cashIn: EventEmitter<any> = new EventEmitter<any>();
  @Output() cashOut: EventEmitter<any> = new EventEmitter<any>();
  @Output() priceConsultation: EventEmitter<any> = new EventEmitter<any>();
  @Output() saveTicket: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteCurrentTicket: EventEmitter<any> = new EventEmitter<any>();
  @Output() changeTicket: EventEmitter<any> = new EventEmitter<any>();
  @Output() reprintLastTicket: EventEmitter<any> = new EventEmitter<any>();
  @Output() salesOfTheDayAndReturns: EventEmitter<any> = new EventEmitter<any>();
  @Output() removeLastProduct: EventEmitter<any> = new EventEmitter<any>();
  @Output() openTicketsListDialog: EventEmitter<any> = new EventEmitter<any>();
  //#endregion

  saleShortcuts: ShortcutInput[] = [
    // help
    {
      key: ["ctrl + h", "ctrl + H"],
      preventDefault: true,
      allowIn: [AllowIn.Textarea, AllowIn.Input],
      label: "Ayuda",
      description: "Mostrar Ayuda",
      command: (e) => {
        this.helpService.shortcuts$.subscribe(sc => {
          this.modal.open();
        });
      }
    },
    // search field
    {
      key: ["ctrl + b", "ctrl + B"],
      preventDefault: true,
      allowIn: [AllowIn.Textarea, AllowIn.Input],
      label: "Ventas",
      description: "Buscar producto",
      command: (e) => {
        this.searchField.emit();
      }
    },
    // common product
    {
      key: ["ctrl + 4"],
      preventDefault: true,
      allowIn: [AllowIn.Textarea, AllowIn.Input],
      label: "Ventas",
      description: "Producto común",
      command: (e) => {
        this.commonProduct.emit();
      }
    },
    // wholesale price
    {
      key: ["F11"],
      preventDefault: true,
      allowIn: [AllowIn.Textarea, AllowIn.Input],
      label: "Ventas",
      description: "Precio de mayoreo",
      command: (e) => {
        this.wholesalePrice.emit();
      }
    },
    // cash in
    {
      key: ["F7"],
      preventDefault: true,
      allowIn: [AllowIn.Textarea, AllowIn.Input],
      label: "Ventas",
      description: "Entrada de efectivo",
      command: (e) => {
        this.cashIn.emit();
      }
    },
    // cash out
    {
      key: ["F8"],
      preventDefault: true,
      allowIn: [AllowIn.Textarea, AllowIn.Input],
      label: "Ventas",
      description: "Salida de efectivo",
      command: (e) => {
        this.cashOut.emit();
      }
    },
    // price consultation
    {
      key: ["F10"],
      preventDefault: true,
      allowIn: [AllowIn.Textarea, AllowIn.Input],
      label: "Ventas",
      description: "Consulta de precio",
      command: (e) => {
        this.priceConsultation.emit();
      }
    },
    // charge
    {
      key: ["F12"],
      preventDefault: true,
      allowIn: [AllowIn.Textarea, AllowIn.Input],
      label: "Ventas",
      description: "Cobrar",
      command: (e) => {
        this.charge.emit();
      }
    },
    // save ticket
    {
      key: ["F6"],
      preventDefault: true,
      allowIn: [AllowIn.Textarea, AllowIn.Input],
      label: "Ventas",
      description: "Guardar ticket",
      command: (e) => {
        this.saveTicket.emit();
      }
    },
    // delete current ticket
    {
      key: ["ctrl + 8"],
      preventDefault: true,
      allowIn: [AllowIn.Textarea, AllowIn.Input],
      label: "Ventas",
      description: "Eliminar ticket actual",
      command: (e) => {
        this.deleteCurrentTicket.emit();
      }
    },
    // change ticket
    {
      key: ["ctrl + 1"],
      preventDefault: true,
      allowIn: [AllowIn.Textarea, AllowIn.Input],
      label: "Ventas",
      description: "Cambiar de ticket",
      command: (e) => {
        this.changeTicket.emit();
      }
    },
    // reprint last ticket
    {
      key: ["ctrl + 0"],
      preventDefault: true,
      allowIn: [AllowIn.Textarea, AllowIn.Input],
      label: "Ventas",
      description: "Reimprimir último ticket",
      command: (e) => {
        this.reprintLastTicket.emit();
      }
    },
    // sales of the day and returns
    {
      key: ["ctrl + 5"],
      preventDefault: true,
      allowIn: [AllowIn.Textarea, AllowIn.Input],
      label: "Ventas",
      description: "Ventas del día y devoluciones",
      command: (e) => {
        this.salesOfTheDayAndReturns.emit();
      }
    },
    // multiple insertion
    {
      key: ["ctrl + 2"],
      preventDefault: true,
      allowIn: [AllowIn.Textarea, AllowIn.Input],
      label: "Ventas",
      description: "Insersión múltiple",
      command: (e) => {
        this.multipleInsertion.emit();
      }
    },
    // remove last product added
    {
      key: ["F9"],
      preventDefault: true,
      allowIn: [AllowIn.Textarea, AllowIn.Input],
      label: "Ventas",
      description: "Eliminar último producto agregado",
      command: (e) => {
        this.removeLastProduct.emit();
      }
    },
  ];

  @ViewChild(KeyboardShortcutsComponent) private keyboard: KeyboardShortcutsComponent;

  constructor(private router: Router,
    private helpService: KeyboardShortcutsHelpService) { }

  ngOnInit(): void {
  }

}
