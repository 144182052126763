import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { environment } from '../../../../environments/environment';
import { LoadingService } from '../../../common/components/loading/loading.service';
import { TicketSizeSelectorComponent } from '../../../common/components/ticket-size-selector/ticket-size-selector.component';
import { AlertifyService } from '../../../common/services/alertify.service';
import { TicketSettingsVM } from '../../../models/view-models';
import { TicketSettingsService } from '../../../services/ticket-settings.service';

@Component({
  selector: 'app-ticket-settings',
  templateUrl: './ticket-settings.component.html',
  styleUrls: ['./ticket-settings.component.scss']
})
export class TicketSettingsComponent implements OnInit {

  ticketSettings: TicketSettingsVM;
  _ticketSettings: TicketSettingsVM;
  showForm: boolean = false;
  entityForm: FormGroup;

  datetime: Date = new Date();

  @ViewChild("sizeSelector", { read: TicketSizeSelectorComponent, static: false })
  public sizeSelector: TicketSizeSelectorComponent;

  constructor(private ticketService: TicketSettingsService,
    private fb: FormBuilder,
    private loading: LoadingService,
    private alertify: AlertifyService) { }

  ngOnInit(): void {
    this.getSettings();
  }

  initForm() {
    this.entityForm = this.fb.group({
      //TicketSize: string;
      ShowHeader: [this.ticketSettings.ShowHeader],
      Header: [this.ticketSettings.Header],
      ShowLogo: [this.ticketSettings.ShowLogo],
      //Logo: [this.ticketSettings.Logo],
      ShowDate: [this.ticketSettings.ShowDate],
      ShowTime: [this.ticketSettings.ShowTime],
      ShowAddress: [this.ticketSettings.ShowAddress],
      Address: [this.ticketSettings.Address],
      ShowTelephone: [this.ticketSettings.ShowTelephone],
      Telephone: [this.ticketSettings.Telephone],
      ShowEmail: [this.ticketSettings.ShowEmail],
      Email: [this.ticketSettings.Email],
      ShowContact: [this.ticketSettings.ShowContact],
      Contact: [this.ticketSettings.Contact],
      ShowExtraLine: [this.ticketSettings.ShowExtraLine],
      ExtraLine: [this.ticketSettings.ExtraLine],
      ShowFooter: [this.ticketSettings.ShowFooter],
      Footer: [this.ticketSettings.Footer],
      ShowAfterFooterLine: [this.ticketSettings.ShowAfterFooterLine],
      AfterFooterLine: [this.ticketSettings.AfterFooterLine],
      LeftMargin: [this.ticketSettings.LeftMargin],
      RightMargin: [this.ticketSettings.RightMargin],
    });
  }

  onFormChanges() {
    this.entityForm.valueChanges
      .subscribe(val => {
        this.ticketSettings.ShowHeader = val.ShowHeader;
        this.ticketSettings.Header = val.Header;
        this.ticketSettings.ShowLogo = val.ShowLogo;
        this.ticketSettings.ShowDate = val.ShowDate;
        this.ticketSettings.ShowTime = val.ShowTime;
        this.ticketSettings.ShowAddress = val.ShowAddress;
        this.ticketSettings.Address = val.Address;
        this.ticketSettings.ShowTelephone = val.ShowTelephone;
        this.ticketSettings.Telephone = val.Telephone;
        this.ticketSettings.ShowEmail = val.ShowEmail;
        this.ticketSettings.Email = val.Email;
        this.ticketSettings.ShowContact = val.ShowContact;
        this.ticketSettings.Contact = val.Contact;
        this.ticketSettings.ShowExtraLine = val.ShowExtraLine;
        this.ticketSettings.ExtraLine = val.ExtraLine;
        this.ticketSettings.ShowFooter = val.ShowFooter;
        this.ticketSettings.Footer = val.Footer;
        this.ticketSettings.ShowAfterFooterLine = val.ShowAfterFooterLine;
        this.ticketSettings.AfterFooterLine = val.AfterFooterLine;
        this.ticketSettings.LeftMargin = val.LeftMargin;
        this.ticketSettings.RightMargin = val.RightMargin;
      });
  }

  getSettings() {
    this.loading.show();
    this.ticketService.getSettings()
      .subscribe((response: TicketSettingsVM) => {
        if (response) {
          this.ticketSettings = response;
          this.setLogoUrl();
        }
        this.showForm = true;
        this.initForm();
        this.onFormChanges();
        this.sizeSelector.setItemByValue(response.TicketSize);
        this.loading.hide();
      }, (err) => {
        this.loading.hide();
        console.log(err);
      });
  }

  update() {
    this.loading.show();
    this.ticketSettings.TicketSize = this.sizeSelector.getItemValue();
    this.ticketService.update(this.ticketSettings)
      .subscribe((response: TicketSettingsVM) => {
        if (response) {
          this.loading.hide();
        }
      }, (err) => {
          console.log(err);
          this.loading.hide();
      });
  }

  undo() {
    this.getSettings();
  }

  setLogoUrl() {
    this.ticketSettings.LogoUrl = `${environment.ticketsUrl}/${this.ticketSettings.Logo}`;
  }

  onUploadSuccess(result: boolean) {
    if (result) {
      this.getSettings();
    }
  }

}
