import { Component, OnInit, ViewChild, Output, EventEmitter, ElementRef, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LocalData } from '../../../models/LocalData';
import { CustomerMovementMultiplePaymentVM, MultiplePaymentVM, PaymentMethodVM } from '../../../models/view-models';
import { PaymentMethodService } from '../../../services/payment-method.service';
import { CustomValidators } from '../../validators/CustomValidators';
import { CustomerSelectorComponent } from '../customer-selector/customer-selector.component';
import { PaymentMethodSelectorComponent } from '../payment-method-selector/payment-method-selector.component';

export interface MultiplePayments {
  Cash: number;
  Card: number;
  MPCardReference: string;
  Transfer: number;
  MPTransferReference: string;
  Voucher: number;
  Credit: number;
  MPCustomerId: string;
}

@Component({
  selector: 'app-multiple-payment-method',
  templateUrl: './multiple-payment-method.component.html',
  styleUrls: ['./multiple-payment-method.component.scss']
})
export class MultiplePaymentMethodComponent implements OnInit {

  @ViewChild("methodSelector", { read: PaymentMethodSelectorComponent, static: true })
  public methodSelector: PaymentMethodSelectorComponent;
  selectedMethod: PaymentMethodVM;
  @ViewChild("customerSelector", { read: CustomerSelectorComponent })
  public customerSelector: CustomerSelectorComponent;
  multiForm: FormGroup;
  @ViewChild("amount") amount: ElementRef;
  @ViewChild("reference") reference: ElementRef;
  payments: MultiplePaymentVM[] = [];
  hideBankReference: boolean = true;
  hideCustomerSelector: boolean = true;
  creditPaymentExists: boolean = false;
  total: number = 0;
  @Output() totalChange: EventEmitter<number> = new EventEmitter<number>();


  // new form
  @Input("NewForm") NewForm: boolean = false;
  @Input("AllowCredits") AllowCredits: boolean = false;
  multiplePaymentMethodForm: FormGroup;
  newFormTotal: number = 0;
  @ViewChild("cash") cash: ElementRef;
  @Output() NewFormEmiter: EventEmitter<number> = new EventEmitter<number>();

  constructor(private paymentMethodService: PaymentMethodService,
    private fb: FormBuilder) { }

  ngOnInit(): void {
    if (this.NewForm) {
      this.initMultiplePaymentMethodForm();
      this.onMultiplePaymentMethodFormChanges();
      this.focusOnCash();
    } else {
      this.initForm();
      this.methodSelector.label = "Agregar método de pago...";
      this.methodSelector.hidePaymentMethod(LocalData.PAYMENT_METHOD_MULTIPLE);
      this.methodSelector.hidePaymentMethod(LocalData.PAYMENT_METHOD_RETURN);
      this.methodSelector.sortByName();
    }
    //this.methodSelector.hideCurrencyPaymentMethod();
  }

  initForm() {
    this.multiForm = this.fb.group({
      Amount: ['', [Validators.required]],
      MultiPaymentBankReference: [''],
    });
  }

  isValidForm(): boolean {
    return this.multiForm.valid
      && this.methodSelector.isValidForm();
  }

  canAddPayment(): boolean {
    if (this.methodSelector.getItemCode() === LocalData.PAYMENT_METHOD_CASH
      || this.methodSelector.getItemCode() === LocalData.PAYMENT_METHOD_CARD
      || this.methodSelector.getItemCode() === LocalData.PAYMENT_METHOD_EBANK) {
      return this.multiForm.valid;
    } else if (this.methodSelector.getItemCode() === LocalData.PAYMENT_METHOD_CREDIT) {
      return this.multiForm.valid
        && this.customerSelector.isValidForm();
    }
  }

  focusOnAmountInput() {
    setTimeout(() => {
      this.amount.nativeElement.focus();
    }, 0);
  }

  focusOnReferenceInput() {
    setTimeout(() => {
      this.reference.nativeElement.focus();
    }, 0);
  }

  paymentMethodSelect(method: PaymentMethodVM) {
    this.selectedMethod = method;
    switch (this.selectedMethod.Code) {
      case LocalData.PAYMENT_METHOD_CASH: this.focusOnAmountInput();
        break;
      case LocalData.PAYMENT_METHOD_VOUCHER: this.focusOnAmountInput();
        break;
      case LocalData.PAYMENT_METHOD_CARD: this.focusOnAmountInput();
        this.hideBankReference = false;
        break;
      case LocalData.PAYMENT_METHOD_EBANK: this.focusOnAmountInput();
        this.hideBankReference = false;
        break;
      case LocalData.PAYMENT_METHOD_CREDIT: this.focusOnAmountInput();
        this.hideCustomerSelector = false;
        break;
      default: this.focusOnAmountInput();
        break;
    }
  }

  add() {
    if (this.canAddPayment()) {
      const pay: MultiplePaymentVM = Object.assign({
        PaymentMethodCode: this.selectedMethod.Code,
        PaymentMethodName: this.selectedMethod.Name,
        CustomerId: this.customerSelector.getItemId(),
        CustomerName: this.customerSelector.getItemFullName()
      }, this.multiForm.value);
      this.addPayment(pay);
    }
  }

  addPayment(pay: MultiplePaymentVM) {
    if (pay.PaymentMethodCode === LocalData.PAYMENT_METHOD_CREDIT) {
      let exist: MultiplePaymentVM = this.payments.find(item => item.PaymentMethodCode === LocalData.PAYMENT_METHOD_CREDIT);
      if (!exist) {
        this.payments.push(pay);
        this.creditPaymentExists = true;
      } else {
        let idx = this.payments.indexOf(exist);
        this.payments[idx].Amount += pay.Amount;
      }
    } else {
      pay.CustomerId = "";
      pay.CustomerName = "";
      this.payments.push(pay);
    }
    this.total += pay.Amount;
    this.totalChange.emit(this.total);
    this.clear();
  }

  delete(idx: number) {
    this.total -= this.payments[idx].Amount;
    this.payments.splice(idx, 1);
    this.totalChange.emit(this.total);
  }

  clear() {
    if (this.NewForm) {
      this.multiplePaymentMethodForm.reset();
      if (this.AllowCredits) {
        this.customerSelector.reset();
      }
    } else {
      this.multiForm.reset();
      this.hideBankReference = true;
      if (!this.creditPaymentExists) {
        this.customerSelector.reset();
      }
      this.hideCustomerSelector = true;
      this.methodSelector.reset();
    }
  }

  reset() {
    this.total = 0;
    this.clear();
  }

  getPayments(saleId: string): MultiplePaymentVM[] {
    if (this.NewForm) {
      return this.newForm_GetMultiplePayments(saleId);
    } else {
      this.payments.forEach(item => {
        item.SaleId = saleId;
      });
      return this.payments;
    }
  }

  getCustomerPayments(): CustomerMovementMultiplePaymentVM[] {
    if (this.NewForm) {
      this.NewForm_SetMultiplePaymentsForCustomerPay();
    }
    let result: CustomerMovementMultiplePaymentVM[] = [];
    this.payments.forEach(item => {
      result.push({
        PaymentMethodCode: item.PaymentMethodCode,
        PaymentMethodName: item.PaymentMethodName,
        Amount: item.Amount,
        MultiPaymentBankReference: item.MultiPaymentBankReference,
      });
    });
    return result;
  }

  hasCreditPayment(): boolean {
    let credit: MultiplePaymentVM = this.payments.find(item => item.PaymentMethodCode === LocalData.PAYMENT_METHOD_CREDIT);
    if (credit)
      return true;
    return false;
  }

  getCustomerId(): string {
    if (this.hasCreditPayment()) {
      let credit: MultiplePaymentVM = this.payments.find(item => item.PaymentMethodCode === LocalData.PAYMENT_METHOD_CREDIT);
      return credit.CustomerId;
    }
    return "";
  }

  getCustomerName(): string {
    if (this.hasCreditPayment()) {
      let credit: MultiplePaymentVM = this.payments.find(item => item.PaymentMethodCode === LocalData.PAYMENT_METHOD_CREDIT);
      return credit.CustomerName;
    }
    return "";
  }

  getPaidWithNoCredit(): number {
    if (this.hasCreditPayment()) {
      let credit: MultiplePaymentVM = this.payments.find(item => item.PaymentMethodCode === LocalData.PAYMENT_METHOD_CREDIT);
      return this.total - credit.Amount;
    }
    return this.total;
  }

  hidePaymentMethod(code: string) {
    this.methodSelector.hidePaymentMethod(code);
  }

  initMultiplePaymentMethodForm() {
    this.multiplePaymentMethodForm = this.fb.group({
      Cash: [0],
      Card: [0],
      MPCardReference: ['', [CustomValidators.removeSpecialCharacters]],
      Transfer: [0],
      MPTransferReference: ['', [CustomValidators.removeSpecialCharacters]],
      Voucher: [0],
      Credit: [0],
      MPCustomerId: [''],
    });
  }

  isMultiplePaymentMethodFormValid(): boolean {
    if (this.AllowCredits) {
      if (this.multiplePaymentMethodForm.controls["Credit"].value > 0) {
        return this.customerSelector.isValidForm();
      }
      return this.multiplePaymentMethodForm.valid;
    }
    return this.multiplePaymentMethodForm.valid;
  }

  focusOnCash() {
    setTimeout(() => {
      this.cash.nativeElement.focus();
      this.cash.nativeElement.select();
    }, 0);
  }

  onMultiplePaymentMethodFormChanges(): void {
    this.multiplePaymentMethodForm.valueChanges
      .subscribe(val => {
        let _total: number = 0;
        let _cash: number = 0;
        let _card: number = 0;
        let _transfer: number = 0;
        let _voucher: number = 0;
        let _credit: number = 0;
        if (val.Cash) {
          _cash = parseFloat(val.Cash);
        }
        if (val.Card) {
          _card = parseFloat(val.Card);
        }
        if (val.Transfer) {
          _transfer = parseFloat(val.Transfer);
        }
        if (val.Voucher) {
          _voucher = parseFloat(val.Voucher);
        }
        if (val.Credit) {
          _credit = parseFloat(val.Credit);
        }
        _total = _cash + _card + _transfer + _voucher + _credit;
        this.newFormTotal = _total;
        this.total = this.newFormTotal;
        this.NewFormEmiter.emit(this.newFormTotal);
      });
  }

  newForm_GetMultiplePayments(saleId: string): MultiplePaymentVM[] {
    const mp: MultiplePayments = Object.assign(this.multiplePaymentMethodForm.value);
    if (mp.Cash !== 0 && mp.Cash !== null) {
      this.payments.push({
        SaleId: saleId,
        PaymentMethodCode: LocalData.PAYMENT_METHOD_CASH,
        PaymentMethodName: this.paymentMethodService.getPaymentMethodName(LocalData.PAYMENT_METHOD_CASH),
        Amount: mp.Cash,
      });
    }
    if (mp.Voucher !== 0 && mp.Voucher !== null) {
      this.payments.push({
        SaleId: saleId,
        PaymentMethodCode: LocalData.PAYMENT_METHOD_VOUCHER,
        PaymentMethodName: this.paymentMethodService.getPaymentMethodName(LocalData.PAYMENT_METHOD_VOUCHER),
        Amount: mp.Voucher,
      });
    }
    if (mp.Card !== 0 && mp.Card !== null) {
      this.payments.push({
        SaleId: saleId,
        PaymentMethodCode: LocalData.PAYMENT_METHOD_CARD,
        PaymentMethodName: this.paymentMethodService.getPaymentMethodName(LocalData.PAYMENT_METHOD_CARD),
        Amount: mp.Card,
        MultiPaymentBankReference: mp.MPCardReference,
      });
    }
    if (mp.Transfer !== 0 && mp.Transfer !== null) {
      this.payments.push({
        SaleId: saleId,
        PaymentMethodCode: LocalData.PAYMENT_METHOD_EBANK,
        PaymentMethodName: this.paymentMethodService.getPaymentMethodName(LocalData.PAYMENT_METHOD_EBANK),
        Amount: mp.Transfer,
        MultiPaymentBankReference: mp.MPTransferReference,
      });
    }
    if (mp.Credit !== 0 && mp.Credit !== null) {
      this.payments.push({
        SaleId: saleId,
        PaymentMethodCode: LocalData.PAYMENT_METHOD_CREDIT,
        PaymentMethodName: this.paymentMethodService.getPaymentMethodName(LocalData.PAYMENT_METHOD_CREDIT),
        Amount: mp.Credit,
        CustomerId: this.customerSelector.getItemId(),
        CustomerName: this.customerSelector.getItemFullName(),
      });
    }
    return this.payments;
  }

  NewForm_SetMultiplePaymentsForCustomerPay() {
    const mp: MultiplePayments = Object.assign(this.multiplePaymentMethodForm.value);
    if (mp.Cash !== 0 && mp.Cash !== null) {
      this.payments.push({
        PaymentMethodCode: LocalData.PAYMENT_METHOD_CASH,
        PaymentMethodName: this.paymentMethodService.getPaymentMethodName(LocalData.PAYMENT_METHOD_CASH),
        Amount: mp.Cash,
      });
    }
    if (mp.Voucher !== 0 && mp.Voucher !== null) {
      this.payments.push({
        PaymentMethodCode: LocalData.PAYMENT_METHOD_VOUCHER,
        PaymentMethodName: this.paymentMethodService.getPaymentMethodName(LocalData.PAYMENT_METHOD_VOUCHER),
        Amount: mp.Voucher,
      });
    }
    if (mp.Card !== 0 && mp.Card !== null) {
      this.payments.push({
        PaymentMethodCode: LocalData.PAYMENT_METHOD_CARD,
        PaymentMethodName: this.paymentMethodService.getPaymentMethodName(LocalData.PAYMENT_METHOD_CARD),
        Amount: mp.Card,
        MultiPaymentBankReference: mp.MPCardReference,
      });
    }
    if (mp.Transfer !== 0 && mp.Transfer !== null) {
      this.payments.push({
        PaymentMethodCode: LocalData.PAYMENT_METHOD_EBANK,
        PaymentMethodName: this.paymentMethodService.getPaymentMethodName(LocalData.PAYMENT_METHOD_EBANK),
        Amount: mp.Transfer,
        MultiPaymentBankReference: mp.MPTransferReference,
      });
    }
  }




}
