import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CustomerVM } from '../../../models/view-models';
import { CustomersService } from '../../../services/customers.service';

@Component({
  selector: 'app-customer-selector',
  templateUrl: './customer-selector.component.html',
  styleUrls: ['./customer-selector.component.scss']
})
export class CustomerSelectorComponent implements OnInit {

  label: string = "Clientes";

  public data: CustomerVM[] = [];
  public source: CustomerVM[] = [];
  public selectedItem: CustomerVM;
  disabled: boolean = true;

  public comboForm: FormGroup = new FormGroup({
    comboValue: new FormControl()
  });

  @Output() selectCustomer: EventEmitter<CustomerVM> = new EventEmitter<CustomerVM>();
  @Input() creditOnly: boolean = false;

  constructor(private dataService: CustomersService) { }

  ngOnInit(): void {
    this.loadData();
    let source = this.data.slice
  }

  handleFilter(value) {
    this.data = this.source.filter((s) =>
      s.Fullname.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  loadData() {
    this.dataService.getAll()
      .subscribe((response: CustomerVM[]) => {
        if (response) {
          response.forEach(item => {
            item.Fullname = `${item.Names} ${item.LastNames}`;
          });
          if (this.creditOnly) {
            this.source = response.filter(item => item.HasCredit === true);
          } else {
            this.source = response;
          }
          this.data = this.source.slice();
        }
        this.disabled = this.data.length <= 0;
      });
  }

  isValidForm(): boolean {
    return this.comboForm.valid;
  }

  getItem(): CustomerVM{
    if (this.isValidForm()) {
      return this.selectedItem;
    }
    return null;
  }

  getItemId(): string {
    if (this.isValidForm()) {
      return this.selectedItem.UUID;
    }
    return "";
  }

  getItemFullName(): string {
    if (this.isValidForm()) {
      return this.selectedItem.Fullname;
    }
    return "";
  }

  selectionChange(value: CustomerVM) {
    this.selectedItem = value;
    this.selectCustomer.emit(this.selectedItem);
  }

  setItemById(uuid: string) {
    if (this.data && this.data.length > 0 && uuid != null && uuid != "") {
      this.selectedItem = this.data.find(item => item.UUID === uuid);
      this.comboForm.setValue({
        comboValue: this.selectedItem
      });
    }
  }

  reset() {
    this.comboForm.reset();
    this.selectedItem = null;
  }


}
