
<div class="row">
  <div class="col-12">

    <!-- action button -->
    <div class="form-control"
       style="cursor:pointer;"
       (click)="toggleCollapse()">
      <div *ngIf="!selectedSale"><span>Seleccionar ticket</span></div>
      <div class="d-flex justify-content-between align-items-center"
           *ngIf="selectedSale">
        <span>
          {{ ticketId }}
        </span>
        <span>
          {{ saleTotal | currency }}
        </span>
      </div>
    </div>

    <!-- collapse -->
    <div class="__collapse"
         [ngClass]="{'collapse':collapse}">
      <div class="list-group">
        <div class="list-group-item d-flex justify-content-between align-items-center"
             style="cursor:pointer;"
             *ngFor="let sale of sales"
             (click)="selectSale(sale)">
          <b>
            {{ sale.TicketId }}
          </b>
          <span>
            {{ sale.CreateDate | date: 'dd/MM/yyyy' }}
          </span>
          <span class="badge">
            {{ sale.Total | currency }}
          </span>
        </div>
      </div>
    </div>

  </div>

  <!-- sale balance -->
  <div class="col-12"
       *ngIf="saleBalance">
    <div class="list-group">
      <div class="list-group-item d-flex justify-content-between align-items-center">
        <b>Total</b>
        <span class="badge">
          {{ saleBalance.TotalSale | currency }}
        </span>
      </div>
      <div class="list-group-item d-flex justify-content-between align-items-center">
        <b>Pagado</b>
        <span class="badge">
          {{ saleBalance.TotalMovements | currency }}
        </span>
      </div>
      <div class="list-group-item d-flex justify-content-between align-items-center">
        <b>Pendiente de Pago</b>
        <span class="badge">
          {{ saleBalance.TotalPending | currency }}
        </span>
      </div>
    </div>
  </div>

</div>
