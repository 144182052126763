import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccessDeniedComponent } from './access-denied/access-denied.component';
import { HomeModule } from './home/home.module';
import { LandingModule } from './landing/landing.module';
import { NotFoundComponent } from './not-found/not-found.component';
import { SettingsModule } from './settings/settings.module';

const routes: Routes = [
  { path: '', redirectTo: 'landing', pathMatch: 'full' },
  // landing
  {
    path: 'landing',
    loadChildren: () => import('./landing/landing.module').then(x => LandingModule)
  },
  // home
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(x => HomeModule)
  },
  // Settings
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.module').then(x => SettingsModule)
  },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
