import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { first, subscribeOn } from 'rxjs/operators';
import { LocalData } from '../../../../models/LocalData';
import { ExternalCredentialVM } from '../../../../models/view-models';
import { ExternalCredentialsService } from '../../../../services/external-credentials.service';
import { LoadingService } from '../common/components/loading/loading.service';
import { ClientLoginRequestDto, ClientLoginResponseDto } from '../models/Client';
import { ClientLoginService } from '../services/client-login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  errorMessage: string = "";
  showError: boolean = false;

  constructor(private clientLoginService: ClientLoginService,
    private router: Router,
    private credService: ExternalCredentialsService,
    private loading: LoadingService) {
    if (this.clientLoginService.sessionClientValue()) {
      this.router.navigate(["home/service-pay/sp"]);
    } else {
      this.getCredentials();
    }

  }

  getCredentials() {
    this.loading.show();
    this.credService.getByName(LocalData.SERVICE_PAY_CREDENTIALS_NAME)
      .subscribe((response: ExternalCredentialVM) => {
        if (response) {
          const userInfo: ClientLoginRequestDto = {
            ClientId: response.Username,
            Password: response.Password
          };
          this.clientLoginService.clientLogin(userInfo)
            .pipe(first())
            .subscribe(response => {
              alert(JSON.stringify(response));
              if (response) {
                this.router.navigate(["home/service-pay/sp"]);
              }
            }, error => {
              console.log(error);
            });
        } else { // error al obtener credenciales
          this.errorMessage = "Ocurrió un error al conectarse con el proveedor";
        }
      });
  }

}
