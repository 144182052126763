import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ClientLoginService } from '../services/client-login.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanLoad {

  constructor(private loginService: ClientLoginService,
    private router: Router) { }

    canLoad(route: Route, segments: UrlSegment[]): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
      const sessionUser = this.loginService.sessionClientValue();
      if (sessionUser)
        return true;
      this.router.navigate(['login']);
      return false;
    }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const sessionUser = this.loginService.sessionClientValue();
    if (sessionUser)
      return true;
    this.router.navigate(['login']);
    return false;
  }


  //canLoad(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
  //  const sessionUser = this.loginService.sessionUserValue();
  //  if (sessionUser)
  //    return true;
  //  this.router.navigate(['/landing']);
  //  return false;
  //}
}
