import { Component, ElementRef, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CategorySelectorComponent } from '../../../../common/components/category-selector/category-selector.component';
import { UnitSelectorComponent } from '../../../../common/components/unit-selector/unit-selector.component';
import { ProductVM } from '../../../../models/view-models';

@Component({
  selector: 'app-unique-product',
  templateUrl: './unique-product.component.html',
  styleUrls: ['./unique-product.component.scss']
})
export class UniqueProductComponent implements OnInit {

  product: ProductVM;
  entityForm: FormGroup;
  isEdition: boolean = false;

  @ViewChild("code") code: ElementRef;
  @ViewChild("name") name: ElementRef;

  @ViewChild("categorySelector", { read: CategorySelectorComponent, static: false })
  public categorySelector: CategorySelectorComponent;

  @ViewChild("unitSelector", { read: UnitSelectorComponent, static: false })
  public unitSelector: UnitSelectorComponent;

  showInventoryDetails: boolean = true;
  profit: number = 0;

  @Output() onFormSave: EventEmitter<ProductVM> = new EventEmitter<ProductVM>();
  @Output() onFormUpdate: EventEmitter<ProductVM> = new EventEmitter<ProductVM>();
  @Output() onFormCancel: EventEmitter<any> = new EventEmitter<any>();

  minQty: number = 0;
  maxQty: number = 0;

  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {
    this.initForm();
    this.onFormChanges();
  }

  initForm() {
    this.entityForm = this.fb.group({
      Code: ['', [Validators.required, Validators.minLength(2)]],
      Name: ['', [Validators.required, Validators.minLength(2)]],
      Description: [''],
      CostPrice: [0, [Validators.required]],
      RetailPrice: [0, [Validators.required]],
      WholesalePrice: [0, [Validators.required]],
      Quantity: [0],
    });
  }

  onFormChanges(): void {
    this.entityForm.valueChanges
      .subscribe((value) => {
        this.showInventoryDetails = value.UsesInventory;

        let p: number = ((value.RetailPrice - value.CostPrice) / value.RetailPrice) * 100;
        let _pft: number = Math.round((p + Number.EPSILON) * 100) / 100;
        this.profit = _pft;

        this.minQty = value.Quantity;
        this.maxQty = value.Quantity;
      }
      );
  }

  retailPriceFocusout() {
    if (!this.isEdition) {
      let rp = this.entityForm.controls['RetailPrice'].value;
      this.entityForm.controls['WholesalePrice'].setValue(rp);
    }
  }

  isValidForm(): boolean {
    return this.entityForm.valid
      && this.categorySelector.isValidForm()
      && this.unitSelector.isValidForm();
  }

  save(){
    if (this.isValidForm()) {
      const entity: ProductVM = Object.assign({
        CategoryId: this.categorySelector.getItemId(),
        CategoryName: this.categorySelector.getItemName(),
        UnitId: this.unitSelector.getItemId(),
        UnitName: this.unitSelector.getItemName(),
        Profit: this.profit,
        UsesInventory: true,
        Group:true,
        MinimumQuantity: this.minQty,
        MaximumQuantity: this.maxQty,
        IsUnique: true,
      }, this.entityForm.value);
      this.onFormSave.emit(entity);
    }
  }

  cancel() {
    this.initForm();
    this.categorySelector.reset();
    this.unitSelector.reset();
    this.profit = 0;
    this.showInventoryDetails = false;
    this.isEdition = false;
    this.onFormCancel.emit();
  }

  edit(value: ProductVM) {
    this.product = value;
    this.entityForm.setValue({
      Code: this.product.Code,
      Name: this.product.Name,
      Description: this.product.Description,
      CostPrice: this.product.CostPrice,
      RetailPrice: this.product.RetailPrice,
      WholesalePrice: this.product.WholesalePrice,
      Quantity: this.product.Quantity,
    });
    this.profit = this.product.Profit;
    this.minQty = this.product.MinimumQuantity;
    this.maxQty = this.product.MaximumQuantity;
    this.categorySelector.setItemById(this.product.CategoryId);
    this.unitSelector.setItemById(this.product.UnitId);
    this.isEdition = true;
    setTimeout(() => {
      this.name.nativeElement.focus();
    }, 0);
  }

  update() {
    if (this.isValidForm()) {
      const entity: ProductVM = Object.assign({
        UUID: this.product.UUID,
        CategoryId: this.categorySelector.getItemId(),
        CategoryName: this.categorySelector.getItemName(),
        UnitId: this.unitSelector.getItemId(),
        UnitName: this.unitSelector.getItemName(),
        Profit: this.profit,
        UsesInventory: true,
        Group: true,
        MinimumQuantity: this.minQty,
        MaximumQuantity: this.maxQty,
        IsUnique: true,
      }, this.entityForm.value);
      this.onFormUpdate.emit(entity);
    }
  }

  setFocusOnNameInput() {
    setTimeout(() => {
      this.name.nativeElement.focus();
    }, 0);
  }

  setFocusOnCodeInput() {
    setTimeout(() => {
      this.code.nativeElement.focus();
    }, 0);
  }


}
