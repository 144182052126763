
<div [hidden]="showForm">

  <h3>
    Credenciales
  </h3>

  <button class="btn btn-primary"
          (click)="addNew()">
    Agregar <i class="fa fa-plus"></i>
  </button>

  <hr />
  <br />

  <app-generic-grid #grid
                    (rowToEdit)="edit($event)"
                    (rowToDelete)="delete($event)">
  </app-generic-grid>

</div>

<div [hidden]="!showForm">

  <form [formGroup]="entityForm" class="__entityForm">
    <h3 *ngIf="isEdition">Credencial</h3>
    <h3 *ngIf="!isEdition">Agregar Credencial</h3>

    <kendo-floatinglabel text="Nombre">
      <input kendoTextBox name="Name"
             #name [readOnly]="isEdition"
             class="form-control" type="text"
             aria-label="Nombre" placeholder="Nombre"
             formControlName="Name" />
    </kendo-floatinglabel>

    <kendo-floatinglabel text="Nombre de Usuario">
      <input kendoTextBox name="Username"
             #username
             class="form-control" type="text"
             aria-label="Nombre de Usuario" placeholder="Nombre de Usuario"
             formControlName="Username" />
    </kendo-floatinglabel>

    <kendo-floatinglabel text="Contraseña"
                         *ngIf="!showPassword">
      <input kendoTextBox name="Password"
             class="form-control"
             type="password"
             aria-label="Contraseña"
             placeholder="Contraseña"
             formControlName="Password" />
    </kendo-floatinglabel>

    <kendo-floatinglabel text="Contraseña"
                         *ngIf="showPassword">
      <input kendoTextBox name="Password"
             class="form-control"
             [ngClass]="{'is-invalid': entityForm.get('Password').errors && entityForm.get('Password').touched}"
             type="text"
             aria-label="Contraseña"
             placeholder="Contraseña"
             formControlName="Password" />
    </kendo-floatinglabel>

    <br />

    <label class="form-check-label">
      <input type="checkbox" kendoCheckBox
             [(ngModel)]="showPassword"
             [ngModelOptions]="{standalone: true}" />&nbsp;&nbsp;&nbsp;
      Mostrar contraseña
    </label>

    <kendo-floatinglabel text="Clave Secreta">
      <input kendoTextBox name="SecretKey"
             class="form-control" type="text"
             aria-label="Clave Secreta" placeholder="Clave Secreta"
             formControlName="SecretKey" />
    </kendo-floatinglabel>

    <br /><hr />

    <div class="d-flex justify-content-end __formButtonsContainer">
      <button class="btn btn-primary"
              *ngIf="!isEdition" type="submit"
              [disabled]="!isValidForm()" (click)="save()">
        Guardar
      </button>
      <button class="btn btn-primary"
              *ngIf="isEdition" type="submit"
              [disabled]="!isValidForm()" (click)="update()">
        Guardar cambios
      </button>
      <button class="btn btn-secondary"
              type="button" (click)="cancel()">
        Cancelar
      </button>
    </div>
  </form>

</div>
