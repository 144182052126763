import { Component, ElementRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingService } from '../../../../../common/components/loading/loading.service';
import { LocalData } from '../../../../../models/LocalData';
import { CustomerVM } from '../../../../../models/view-models';
import { CustomersService } from '../../../../../services/customers.service';

@Component({
  selector: 'modal-sale-error',
  templateUrl: './sale-error-dialog.component.html',
  styleUrls: ['./sale-error-dialog.component.scss']
})
export class SaleErrorDialogComponent {

  customer: CustomerVM;
  showDialog: boolean = false;
  @ViewChild("btn1") btn1: ElementRef;

  @Output() modalClose: EventEmitter<any> = new EventEmitter<any>();

  constructor(private customerService: CustomersService,
    private loading: LoadingService,
    private router: Router) { }

  openDialog(customerId: string) {
    this.loading.show();
    this.customerService.getById(customerId)
      .subscribe((response: CustomerVM) => {
        if (response) {
          this.customer = response;
          this.customer.Fullname = `${response.Names} ${response.LastNames}`;
          this.showDialog = true;
          this.focusOnButton();
        }
      }, (err) => {
          this.loading.hide();
      });

  }

  closeDialog() {
    this.showDialog = false;
    this.modalClose.emit();
  }

  goToCustomer() {
    localStorage.setItem(LocalData.CUSTOMER_ID, this.customer.UUID);
    this.router.navigate(['/home/customers'])
  }

  focusOnButton() {
    setTimeout(() => {
      this.btn1.nativeElement.focus();
    }, 0);
  }



}
