
<kendo-dialog title="Guardar ticket actual"
              *ngIf="showDialog"
              (close)="closeDialog()"
              [minWidth]="250"
              [width]="450">

  <p>
    ¿Desea guardar el ticket actual?
  </p>

  <hr />

  <kendo-dialog-actions>
    <div class="d-flex justify-content-between align-items-center">
      <button class="btn btn-primary"
              type="submit"
              (click)="saveTicket()">
        Guardar
      </button>
      <button class="btn btn-primary"
              type="button"
              (click)="closeDialog()">
        No guardar
      </button>
    </div>
  </kendo-dialog-actions>

</kendo-dialog>
