
<div class="__saleItem">
  <div class="row">
    <div class="col-md-8 col-sm-12 mx-auto">
      <div class="jumbotron"
           style="padding-bottom: 10px;">
        <h3>
          Ticket: {{ sale.TicketId }}
        </h3>
      </div>
      <!-- sale details -->
      <ul class="list-group">
        <li class="list-group-item d-flex justify-content-between align-items-center">
          <b>Fecha</b>
          <span class="badge">{{ sale.CreateDate | date: 'dd/MM/yyyy' }}</span>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-center">
          <b>Método de Pago</b>
          <span class="badge">{{ sale.PaymentMethodName }}</span>
        </li>
      </ul>

      <br /><br />

      <!-- saled products -->
      <app-saled-products
                          [saledProducts]="sale.SaleDetails">
      </app-saled-products>

      <!-- sale total -->
      <br /><br />
      <div class="text-center">
        <h4>
          TOTAL: {{ sale.Total | currency }}
        </h4>
      </div>
    </div>
  </div>
  <hr />
</div>
