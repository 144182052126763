import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AlertifyService } from '../common/services/alertify.service';
import { OperationResult } from '../models/view-models';
import { map } from 'rxjs/operators';
import { ErrorService } from '../interceptors/error.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  apiUrl: string = `${environment.apiUrl}/Configuration`;

  constructor(private http: HttpClient,
    private error: ErrorService,
    private alertify: AlertifyService) { }

  truncateOperationTables() {
    const uri = `${this.apiUrl}/TruncateOperationTables`;
    return this.http.get(uri)
      .pipe(map((response: OperationResult<boolean[]>) => {
        if (response.Success) {
          return true;
        } else {
          this.error.logError(response);
          return false;
        }
      }));
  }
}
