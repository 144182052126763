
<div class="row">
  <div class="col-6">
    <h3>
      Detalles de la Venta
    </h3>
    <app-reprint-ticket [ticketId]="saleBalance.Sale.TicketId"></app-reprint-ticket>
  </div>
  <div class="col-6 text-right">
    <button class="btn btn-outline-secondary"
            (click)="close()"
            aria-label="Cerrar">
      Cerrar
      &nbsp;
      <i class="fa fa-close"></i>
    </button>
  </div>
</div>

<app-header-details #saleBalanceDetails></app-header-details>

<hr />
<br />

<app-generic-grid #detailsGrid></app-generic-grid>
