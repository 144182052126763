
<kendo-dialog title="Autorización Requerida"
              *ngIf="showDialog"
              [minWidth]="250"
              [width]="300">

  <div class="text-center"
       [hidden]="!loading">
    <i class="fa fa-spinner fa-spin fa-3x"></i>
    <br />
    <b>Espere...</b>
  </div>

  <form [formGroup]="dialogForm"
        [hidden]="loading"
        class="__entityForm">

    <kendo-floatinglabel text="Usuario">
      <input kendoTextBox name="user"
             #user
             class="form-control"
             type="text"
             aria-label="Usuario / Código"
             placeholder="Usuario"
             formControlName="user" />
    </kendo-floatinglabel>

    <kendo-floatinglabel text="Contraseña">
      <input kendoTextBox name="password"
             class="form-control"
             type="password"
             aria-label="Contraseña"
             placeholder="Contraseña"
             formControlName="password" />
    </kendo-floatinglabel>

    <hr />

    <kendo-dialog-actions>
      <div class="d-flex justify-content-between align-items-center">
        <button class="btn btn-primary"
                type="submit"
                [disabled]="!isValidForm() || buttonDisabled"
                (click)="ok()">
          Autorizar
        </button>
        <button class="btn btn-primary"
                type="button"
                (click)="closeDialog()">
          Cancelar
        </button>
      </div>
    </kendo-dialog-actions>
  </form>

</kendo-dialog>
