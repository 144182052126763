import { Component, Output, EventEmitter } from '@angular/core';
import { SaleBalanceDto, SaleVM } from '../../../models/view-models';
import { CustomerMovementService } from '../../../services/customer-movement.service';
import { SalesService } from '../../../services/sales.service';
import { LoadingService } from '../loading/loading.service';

@Component({
  selector: 'app-unpaid-sales-from-customer-selector',
  templateUrl: './unpaid-sales-from-customer-selector.component.html',
  styleUrls: ['./unpaid-sales-from-customer-selector.component.scss']
})
export class UnpaidSalesFromCustomerSelectorComponent {

  sales: SaleVM[] = [];
  selectedSale: SaleVM;
  ticketId: number = 0;
  saleTotal: number = 0;
  selectedItems: number = 0;
  collapse: boolean = true;

  saleBalance: SaleBalanceDto;

  @Output() ticketSelected: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private salesService: SalesService,
    private customerMovementsService: CustomerMovementService,
    private loading: LoadingService) { }

  getSales(customerId: string) {
    this.loading.show();
    this.salesService.getUnpaidSalesFromCustomer(customerId)
      .subscribe((response: SaleVM[]) => {
        if (response) {
          this.sales = response;
          this.selectedItems = 0;
        }
        this.loading.hide();
      }, (err) => {
        this.loading.hide();
      });
  }

  addSale(sale: SaleVM) {
    if (!sale.IsSelected) {
      this.sales.find(item => item.UUID === sale.UUID).IsSelected = true;
    } else {
      this.sales.find(item => item.UUID === sale.UUID).IsSelected = false;
    }
    this.selectedItems = this.sales.filter(item => item.IsSelected === true).length;
  }

  selectSale(sale: SaleVM) {
    this.selectedSale = sale;
    this.loading.show();
    this.customerMovementsService.getSaleBalance(this.selectedSale.UUID)
      .subscribe((response: SaleBalanceDto) => {
        if (response) {
          this.saleBalance = response;
          this.ticketId = sale.TicketId;
          this.saleTotal = sale.Total;
          this.toggleCollapse();
          this.ticketSelected.emit(true);
        }
        this.loading.hide();
      }, (err) => {
        this.loading.hide();
      });
  }

  toggleCollapse = () => this.collapse = !this.collapse;

  getSelected(): SaleVM {
    if (this.selectedSale) {
      return this.selectedSale;
    }
    return null;
  }

  getSelectedId(): string {
    if (this.selectedSale) {
      return this.selectedSale.UUID;
    }
    return null;
  }

  reset() {
    this.selectedSale = null;
    this.saleBalance = null;
    this.ticketId = 0;
    this.saleTotal = 0;
  }

  


}
