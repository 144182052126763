import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { OperationResult, LoginRequestVM, LoginResponse } from '../models/view-models';
import { LocalData } from '../models/LocalData';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  // Logged in user
  private sessionUserSubject: BehaviorSubject<LoginResponse>;
  public sessionUser: Observable<LoginResponse>;

  // Authorization user
  private authorizationUserSubject: BehaviorSubject<LoginResponse>;
  public authorizationUser: Observable<LoginResponse>;

  // Authorization session
  private AIPSubject: BehaviorSubject<boolean>;
  public sessionAIP: Observable<boolean>;

  constructor(private http: HttpClient,
    private router: Router) {
    this.sessionUserSubject = new BehaviorSubject<LoginResponse>(
      JSON.parse(localStorage.getItem('sessionUser')));
    this.sessionUser = this.sessionUserSubject.asObservable();

    this.authorizationUserSubject = new BehaviorSubject<LoginResponse>(
      JSON.parse(localStorage.getItem(LocalData.AUTHORIZE_USER)));
    this.authorizationUser = this.authorizationUserSubject.asObservable();

    this.AIPSubject = new BehaviorSubject<boolean>(
      JSON.parse(localStorage.getItem(LocalData.AUTHORIZATION_IN_PROCESS)));
    this.sessionAIP = this.AIPSubject.asObservable();
  }

  public SessionUserValue(): LoginResponse {
    return this.sessionUserSubject.value;
  }

  public AuthorizationUserValue(): LoginResponse {
    return this.authorizationUserSubject.value;
  }

  login(user: LoginRequestVM) {
    const uri = `${environment.apiUrl}/Account/SignIn`;
    return this.http.post<any>(uri, user)
      .pipe(map((response: OperationResult<LoginResponse>) => {
        localStorage.setItem('sessionUser', JSON.stringify(response.Data));
        this.sessionUserSubject.next(response.Data);
        this.setAIP(false);
        return response.Data;
      }));
  }

  logout() {
    this.setAIP(false);
    localStorage.removeItem('sessionUser');
    localStorage.removeItem(LocalData.ROUTE_PERMISSIONS);
    localStorage.removeItem(LocalData.ACTION_PERMISSIONS);
    localStorage.removeItem(LocalData.ACTIVE_SHIFT);
    this.sessionUserSubject.next(null);
    this.router.navigate(['/landing']);
  }

  authorize(user: LoginRequestVM) {
    try {
      const uri = `${environment.apiUrl}/Account/Authorize`;
      return this.http.post<any>(uri, user)
        .pipe(map((response: OperationResult<LoginResponse>) => {
          if (response.Success) {
            localStorage.setItem(LocalData.AUTHORIZE_USER, JSON.stringify(response.Data));
            this.authorizationUserSubject.next(response.Data);
            this.setAIP(true);
            return true;
          } else {
            return false;
          }
        }));
    } catch (e) {
      alert(e);
    }
  }

  public setAIP(value: boolean) {
    localStorage.setItem(LocalData.AUTHORIZATION_IN_PROCESS, JSON.stringify(value));
    this.AIPSubject = new BehaviorSubject<boolean>(value);
    this.sessionAIP = this.AIPSubject.asObservable();
  }

  public getAIP(): boolean {
    return this.AIPSubject.value;
  }


}
