import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AlertifyService } from '../common/services/alertify.service';
import { RolPermissionVM, OperationResult } from '../models/view-models';
import { map } from 'rxjs/operators';
import { ErrorService } from '../interceptors/error.service';
import { LocalData } from '../models/LocalData';
import { RouteItem } from '../models/ComponentModels';
import { Route, Router } from '@angular/router';
import { PermissionType } from '../models/PermissionType';

@Injectable({
  providedIn: 'root'
})
export class RolPermissionService    {

  apiUrl: string = `${environment.apiUrl}/RolPermission`;
  public allRoutes: RouteItem[] = [];

  constructor(private http: HttpClient,
    private error: ErrorService,
    private router: Router,
    private alertify: AlertifyService) { }

  insert(permission: RolPermissionVM) {
    return this.http.post(this.apiUrl, permission)
      .pipe(map((response: OperationResult<RolPermissionVM>) => {
        if (response.Success) {
          this.alertify.success("Registro creado exitosamente");
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }

  update(permission: RolPermissionVM) {
    return this.http.put(this.apiUrl, permission)
      .pipe(map((response: OperationResult<RolPermissionVM>) => {
        if (response.Success) {
          this.alertify.success("Se editó el registro");
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }

  delete(id: string) {
    const uri = `${this.apiUrl}?uuid=${id}`;
    return this.http.delete(uri)
      .pipe(map((response: OperationResult<boolean>) => {
        if (response.Success) {
          this.alertify.success("Se eliminó el registro");
          return response.Data;
        } else {
          this.error.logError(response);
          return false;
        }
      }));
  }

  getAll() {
    return this.http.get(this.apiUrl)
      .pipe(map((response: OperationResult<RolPermissionVM[]>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }

  getRoutePermissions() {
    const uri = `${this.apiUrl}/GetRoutePermissions`;
    return this.http.get(uri)
      .pipe(map((response: OperationResult<RolPermissionVM[]>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }

  getActionPermissions() {
    const uri = `${this.apiUrl}/GetActionPermissions`;
    return this.http.get(uri)
      .pipe(map((response: OperationResult<RolPermissionVM[]>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }

  getEndpointPermissions() {
    const uri = `${this.apiUrl}/GetEndpointPermissions`;
    return this.http.get(uri)
      .pipe(map((response: OperationResult<RolPermissionVM[]>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }

  getById(uuid: string) {
    const uri = `${this.apiUrl}/GetById?uuid=${uuid}`;
    return this.http.get(uri)
      .pipe(map((response: OperationResult<RolPermissionVM>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }

  // load data and save it to local
  loadAndSavePermissionsData() {
    this.getAll()
      .subscribe((response: RolPermissionVM[]) => {
        if (response) {
          localStorage.setItem(
            LocalData.ROUTE_PERMISSIONS,
            JSON.stringify(response.filter(x => x.PermissionType === PermissionType.ROUTE)));
          localStorage.setItem(
            LocalData.ACTION_PERMISSIONS,
            JSON.stringify(response.filter(x => x.PermissionType === PermissionType.ACTION)));
        }
      });
  }

  // get all route paths
  private loadAllRoutePaths(parent: String, config: Route[]) {
    for (let i = 0; i < config.length; i++) {
      const route = config[i];
      if (route.data) {
        this.allRoutes.push({
          Name: route.data.name,
          Route: parent + '/' + route.path
        });
      }
      if (route.children) {
        const currentPath = route.path ? parent + '/' + route.path : parent;
        this.loadAllRoutePaths(currentPath, route.children);
      }
    }
  }

  getAllRoutePaths(): RouteItem[] {
    this.loadAllRoutePaths('', this.router.config);
    this.allRoutes.sort((a, b) => {
      if (a.Name > b.Name) { return 1; }
      else if (a.Name < b.Name) { return -1; }
      else { return 0; }
    });
    return this.allRoutes;
  }
}
