import { Component, Output, EventEmitter, Input, OnInit } from '@angular/core';
import { SaleBalanceDto, SaleVM } from '../../../models/view-models';
import { CustomerMovementService } from '../../../services/customer-movement.service';
import { SalesService } from '../../../services/sales.service';
import { LoadingService } from '../loading/loading.service';

@Component({
  selector: 'app-multiple-unpaid-sales-from-customer-selector',
  templateUrl: './multiple-unpaid-sales-from-customer-selector.component.html',
  styleUrls: ['./multiple-unpaid-sales-from-customer-selector.component.scss']
})
export class MultipleUnpaidSalesFromCustomerSelectorComponent implements OnInit{

  @Input("customerId") customerId!: string;

  sales: SaleBalanceDto[] = [];
  selectedSales: SaleBalanceDto[] = [];

  @Output() onItemToggle = new EventEmitter<SaleBalanceDto[]>();

  searchField: string = "";


  constructor(private customerMovementsService: CustomerMovementService,
    private loading: LoadingService) { }

    ngOnInit(): void {
      this.getSales();
    }

  getSales() {
    this.loading.show();
    this.customerMovementsService.getCustomerSalesBalances(this.customerId)
      .subscribe((response: SaleBalanceDto[]) => {
        if (response) {
          this.sales = response;
        }
        this.loading.hide();
      }, (err) => {
        this.loading.hide();
      });
  }

  reset() {
  }

  toggleSelection(sale: SaleBalanceDto) {
    sale.IsSelected = !sale.IsSelected;
    this.addOrRemove(sale);
  }

  addOrRemove(value: SaleBalanceDto) {
    let index = this.selectedSales.findIndex((sale) => sale.Sale.UUID === value.Sale.UUID);
    if (index > -1) 
      this.selectedSales.splice(index, 1);
    else 
      this.selectedSales.push(value);
    
    this.onItemToggle.emit(this.selectedSales);
  }

  toggleAll(sel:boolean) {
    this.sales.forEach((sale) => {
      sale.IsSelected = sel;
      this.addOrRemove(sale);
    });
  }

  onSearch(searchText: string) {
    this.searchField = searchText;
  }




}
