import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CategoryVM } from '../../../models/view-models';
import { CategoriesService } from '../../../services/categories.service';

@Component({
  selector: 'app-product-category-selector',
  templateUrl: './product-category-selector.component.html',
  styleUrls: ['./product-category-selector.component.scss']
})
export class ProductCategorySelectorComponent implements OnInit {

  @Input("label") label: string = "Categoría de Producto";

  public data: CategoryVM[] = [{
    UUID: "",
    Name: "Todas las Categorías",
    Code: ""
  }];
  public selectedItem: CategoryVM;
  disabled: boolean = true;

  public comboForm: FormGroup = new FormGroup({
    comboValue: new FormControl()
  });

  @Output() onSelect: EventEmitter<CategoryVM> = new EventEmitter<CategoryVM>();

  constructor(private dataService: CategoriesService) { }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.dataService.getAll()
      .subscribe((response: CategoryVM[]) => {
        if (response) {
          response.forEach(item => {
            this.data.push(item);
          });
        }
        this.disabled = this.data.length <= 0;
      });
  }

  isValidForm(): boolean {
    return this.comboForm.valid;
  }

  getItem(): CategoryVM {
    if (this.isValidForm()) {
      return this.selectedItem;
    }
    return null;
  }

  getItemId(): string {
    if (this.isValidForm()) {
      return this.selectedItem.UUID;
    }
    return "";
  }

  getItemName(): string {
    if (this.isValidForm()) {
      return this.selectedItem.Name;
    }
    return "";
  }

  selectionChange(value: CategoryVM) {
    this.selectedItem = value;
    this.onSelect.emit(this.selectedItem);
  }

  setItemById(uuid: string) {
    if (this.data && this.data.length > 0 && uuid != null && uuid != "") {
      this.selectedItem = this.data.find(item => item.UUID === uuid);
      this.comboForm.setValue({
        comboValue: this.selectedItem
      });
    }
  }

  reset() {
    this.comboForm.reset();
    this.selectedItem = null;
  }


}
