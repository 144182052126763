
<h3>Configuración de Productos</h3>

<hr />
<br />

<kendo-tabstrip>
  <kendo-tabstrip-tab title="Productos Eliminados" [selected]="true">
    <ng-template kendoTabContent>
      <br />
      <app-deleted-products></app-deleted-products>
    </ng-template>
  </kendo-tabstrip-tab>
  <!--<kendo-tabstrip-tab title="Importar Productos">
    <ng-template kendoTabContent>
      <br />
      <app-import-products></app-import-products>
    </ng-template>
  </kendo-tabstrip-tab>-->
</kendo-tabstrip>
