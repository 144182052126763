

<h1>Sand Box</h1>

<hr />

<h1>
  Plane <i class="bi bi-plane"></i>
</h1>

<hr />

<i class="bi bi-keyboard-control"></i>
<i class="bi bi-plus"></i>
<i class="bi bi-keyboard-1"></i>
<i class="bi bi-keyboard-2"></i>
<i class="bi bi-keyboard-3"></i>
<i class="bi bi-keyboard-4"></i>
<i class="bi bi-keyboard-5"></i>
<i class="bi bi-keyboard-6"></i>
<i class="bi bi-keyboard-7"></i>
<i class="bi bi-keyboard-8"></i>
<i class="bi bi-keyboard-9"></i>
<i class="bi bi-keyboard-0"></i>
<i class="bi bi-keyboard-f1"></i>
<i class="bi bi-keyboard-f2"></i>
<i class="bi bi-keyboard-f3"></i>
<i class="bi bi-keyboard-f4"></i>
<i class="bi bi-keyboard-f5"></i>
<i class="bi bi-keyboard-f6"></i>
<i class="bi bi-keyboard-f7"></i>
<i class="bi bi-keyboard-f8"></i>
<i class="bi bi-keyboard-f9"></i>
<i class="bi bi-keyboard-f10"></i>
<i class="bi bi-keyboard-f11"></i>
<i class="bi bi-keyboard-f12"></i>

<i class="bi bi-arrow-clockwise"></i>
