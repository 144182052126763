import { Component, OnInit, ViewChild } from '@angular/core';
import { ShiftSelectorDialogComponent } from '../../common/components/shift-selector-dialog/shift-selector-dialog.component';
import { StartingCashDialogComponent } from '../components/starting-cash-dialog/starting-cash-dialog.component';

@Component({
  selector: 'app-pos',
  templateUrl: './pos.component.html',
  styleUrls: ['./pos.component.scss']
})
export class PosComponent implements OnInit {

  @ViewChild("startingCashDialog", { read: StartingCashDialogComponent, static: true })
  public startingCashDialog: StartingCashDialogComponent;

  @ViewChild("shiftSelectorDialog", { read: ShiftSelectorDialogComponent, static: true })
  public shiftSelectorDialog: ShiftSelectorDialogComponent;

  hideContent: boolean = true;

  constructor() { }

  ngOnInit(): void {
    this.hideContent = this.startingCashDialog.dialogOpen || this.shiftSelectorDialog.showDialog;
  }

  dialogChange(dialogStatus: boolean) {
    this.hideContent = dialogStatus;
  }
  
  onShiftSelect(status: boolean) {
    this.hideContent = !status;
  }

  userIsAdmin(isAdmin: boolean) {
    if (isAdmin) {
      this.shiftSelectorDialog.open();
    }
  }

}
