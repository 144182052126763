import { Component, Input, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-shortcuts-help-dialog',
  templateUrl: './shortcuts-help-dialog.component.html',
  styleUrls: ['./shortcuts-help-dialog.component.scss']
})
export class ShortcutsHelpDialogComponent {

  @ViewChild("closeButton") closeButton!: ElementRef;

  @Input() shortcuts: any = [];
  @ViewChild('modal') modal!: ElementRef;

  showModal: boolean = false;

  open() {
    this.showModal = true;
    setTimeout(() => {
      this.closeButton.nativeElement.focus();
    }, 0);
  }

  close() {
    this.showModal = false;
  }
}
