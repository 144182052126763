import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModulesModule } from './shared-modules.module';
import { CommonComponentsModule } from './common/common-components.module';

import { ServicePayRoutingModule } from './service-pay-routing.module';
import { ServicePayComponent } from './service-pay.component';
import { LoginComponent } from './login/login.component';
import { GridModule } from '@progress/kendo-angular-grid';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { UploadsModule } from '@progress/kendo-angular-upload';
import { HttpClientModule } from '@angular/common/http';







@NgModule({
  declarations: [
    ServicePayComponent,
    LoginComponent
  ],
  imports: [
    CommonModule,
    ServicePayRoutingModule,
    SharedModulesModule,
    CommonComponentsModule,
    
  ]
})
export class ServicePayModule { }
