import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ErrorService } from '../../interceptors/error.service';
import { CityVM, OperationResult, StateVM } from '../../models/view-models';

@Injectable({
  providedIn: 'root'
})
export class StatesAndCitiesService {

  apiUrl: string = `${environment.apiUrl}/StatesAndCities`;

  constructor(private http: HttpClient,
    private error: ErrorService) { }

  getStates() {
    const uri = `${this.apiUrl}/GetStates`;
    return this.http.get(uri)
      .pipe(map((response: OperationResult<StateVM[]>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }

  getCitiesFromState(stateId: string) {
    const uri = `${this.apiUrl}/GetCitiesFromState?stateId=${stateId}`;
    return this.http.get(uri)
      .pipe(map((response: OperationResult<CityVM[]>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }
}
