import { Component, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../interceptors/authentication.service';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss']
})
export class UserInfoComponent implements OnInit{

  @Input("username") username: string = "user name";

  showOptions: boolean = false;

  @HostListener('document:click', ['$event.target'])
  onClickOutside(target: any) {
    if (!this.elementRef.nativeElement.contains(target)) {
      this.closeDropdown();
    }
  }

  private elementRef: ElementRef;

  constructor(private authService:AuthenticationService,
    private el: ElementRef) {
    this.elementRef = el;
  }

  ngOnInit(): void { }

  toggleDropdown = () => this.showOptions = !this.showOptions;

  closeDropdown() {
    this.showOptions = false;
  }

  logout = () => this.authService.logout();
}
