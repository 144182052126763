
<div *ngIf="showGrid">

  <div class="row">
    <div class="col-6">
      <h4>
        Modificar Ticket {{ ticketId || '' }}
      </h4>
    </div>
    <div class="col-6 text-center">
      <h4>
        {{ total | currency }}
      </h4>
    </div>
  </div>

  <br />
  <hr />

  <kendo-grid id="productsGrid"
              (cellClick)="cellClickHandler($event)"
              [data]="view">
    <ng-template kendoGridToolbarTemplate>
      <button *ngIf="isEditing"
              style="float:left;"
              (click)="saveRow()">
        Guardar
      </button>
      <button *ngIf="isEditing"
              style="float:left;"
              (click)="cancelHandler()">
        Cancelar
      </button>
    </ng-template>
    <kendo-grid-column field="Code" title="Código"></kendo-grid-column>
    <kendo-grid-column field="Name" title="Nombre"></kendo-grid-column>
    <kendo-grid-column field="Quantity" editor="numeric" title="Cantidad"></kendo-grid-column>
    <kendo-grid-column field="UnitName" title="Unidad"></kendo-grid-column>
    <kendo-grid-column field="Existence" title="Existencia"></kendo-grid-column>
    <kendo-grid-column field="RetailPrice" editor="numeric" title="Precio"></kendo-grid-column>
    <kendo-grid-column field="Total" title="Total"></kendo-grid-column>


  </kendo-grid>

  <br />
  <hr />

  <div class="d-flex justify-content-end __formButtonsContainer">
    <button class="btn btn-primary"
            type="submit"
            (click)="modifyTicket()">
      Modificar Ticket
    </button>
    <button class="btn btn-secondary"
            type="button"
            (click)="close()">
      Cancelar
    </button>
  </div>

</div>

