import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { GenericGridComponent } from '../../../common/components/generic-grid/generic-grid.component';
import { LoadingService } from '../../../common/components/loading/loading.service';
import { AlertifyService } from '../../../common/services/alertify.service';
import { TicketVM } from '../../../models/view-models';
import { TicketsService } from '../../../services/tickets.service';

@Component({
  selector: 'app-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.scss']
})
export class TicketsComponent implements OnInit {

  tickets: TicketVM[] = [];
  selectedTicket: TicketVM;
  showForm: boolean = false;

  @ViewChild("grid", { read: GenericGridComponent, static: false })
  public grid: GenericGridComponent;

  constructor(private ticketsService: TicketsService,
    private alertify: AlertifyService,
    private loading: LoadingService) { }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.loading.show();
    this.ticketsService.getClosedTickets()
      .subscribe((response: TicketVM[]) => {
        if (response) {
          this.tickets = response;
        }
        this.setGridOptions();
        this.loading.hide();
      }, (err) => {
        this.loading.hide();
      });
  }

  setGridOptions() {
    this.grid.setData(this.tickets);
    this.grid.options.toolbarTitle = "Tickets";
    this.grid.options.showPrintButton = true;
    this.grid.options.showDetailsButton = false;
    this.grid.options.showEditButton = false;
    this.grid.options.showDeleteButton = false;
    this.grid.columns = [
      { field: "Id", title: "ID" },
      { field: "CreateDate", title: "Fecha", dateType: true },
      { field: "CreateDate", title: "Hora", timeType: true },
      { field: "TypeName", title: "Tipo" },
    ];
    this.grid.setGridOptions();
  }

  printTicket(t: TicketVM) {
    this.selectedTicket = t;
    this.loading.show();
    this.ticketsService.getTicketFilename(this.selectedTicket.Id)
      .subscribe((response: string) => {
        if (response) {
          this.openTicket(response);
        }
        this.loading.hide();
      }, (err) => {
        this.loading.hide();
      });
  }

  openTicket(filename: string): void {
    alert(filename);
    const url = `${environment.ticketsUrl}/${filename}`;
    window.open(url, '_blank');
  }

}
