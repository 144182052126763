import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-credits',
  templateUrl: './credits.component.html',
  styleUrls: ['./credits.component.scss']
})
export class CreditsComponent implements OnInit {

  constructor() { }


    ngOnInit(): void {
        
    }



}
