import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccessDeniedComponent } from '../access-denied/access-denied.component';
import { AuthGuard } from '../interceptors/auth.guard';
import { RolGuard } from '../interceptors/rol.guard';
import { CashClosingComponent } from './components/cash-closing/cash-closing.component';
import { CreditsComponent } from './components/credits/credits.component';
import { PartialPaymentsComponent } from './components/credits/partial-payments/partial-payments.component';
import { CustomersComponent } from './components/customers/customers.component';
import { InventoryComponent } from './components/inventory/inventory.component';
import { ProductsComponent } from './components/products/products.component';
import { ReportsComponent } from './components/reports/reports.component';
import { SaleComponent } from './components/sale/sale.component';
import { SalesOfTheDayComponent } from './components/sales-of-the-day/sales-of-the-day.component';
import { ServicePayModule } from './components/service-pay/service-pay.module';
import { HomeComponent } from './home.component';
import { PosComponent } from './pos/pos.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'pos', pathMatch: 'full' },
      {
        path: 'pos',
        component: PosComponent,
        canActivate: [RolGuard],
        children: [
          { path: '', redirectTo: 'sales', pathMatch: 'full' },
          {
            path: 'sales',
            component: SaleComponent,
            canActivate: [RolGuard],
            data: {
              name: 'Ventas',
            }
          },
          {
            path: 'sales-of-the-day',
            component: SalesOfTheDayComponent,
            canActivate: [RolGuard],
            data: {
              name: 'Ventas del Día y Devoluciones',
            }
          },
          {
            path: 'credits',
            component: CreditsComponent,
            canActivate: [RolGuard],
            data: {
              name: 'Créditos',
            },
            children: [
              { path: 'partial-payments', component: PartialPaymentsComponent }
            ]
          },
        ],
        data: {
          name: 'POS',
        }
      },
      {
        path: 'products',
        component: ProductsComponent,
        canActivate: [RolGuard],
        data: {
          name: 'Productos',
        }
      },
      {
        path: 'customers',
        component: CustomersComponent,
        canActivate: [RolGuard],
        data: {
          name: 'Clientes',
        }
      },
      {
        path: 'inventory',
        component: InventoryComponent,
        canActivate: [RolGuard],
        data: {
          name: 'Inventario',
        }
      },
      {
        path: 'cash-closing',
        component: CashClosingComponent,
        canActivate: [RolGuard],
        data: {
          name: 'Cortes',
        }
      },
      {
        path: 'reports',
        component: ReportsComponent,
        canActivate: [RolGuard],
        data: {
          name: 'Reportes',
        }
      },
      {
        path: 'service-pay',        
        //canActivate: [RolGuard],
        loadChildren: () => import('./components/service-pay/service-pay.module').then(x => ServicePayModule)
      },
      { path: 'access-denied', component: AccessDeniedComponent },
      //{ path: 'purchases', component: PurchasesComponent },
      //{ path: 'bills', component: BillsComponent },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HomeRoutingModule { }
