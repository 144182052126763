
<div>
  <h3>{{ formTitle }}</h3>
  <br />
  <hr />
  <div class="row">
    <div class="col-md-8 col-sm-12 mx-auto">
      <app-cash-closing-details #dateCmp></app-cash-closing-details>
      <app-cash-closing-details #salesCmp></app-cash-closing-details>
      <app-cash-closing-details #detailSalesCmp></app-cash-closing-details>
      <app-cash-closing-details #cashInCmp></app-cash-closing-details>
      <app-cash-closing-details #cashOutCmp></app-cash-closing-details>
      <app-cash-closing-details #returnsAndCancelationsCmp></app-cash-closing-details>
      <app-cash-closing-details #paymentsCmp></app-cash-closing-details>
      <app-cash-closing-details #depositsCmp></app-cash-closing-details>
      <app-cash-closing-details #cashCmp></app-cash-closing-details>
      <app-cash-closing-details #startingAndEndingCashCmp></app-cash-closing-details>      

    </div>
  </div>
</div>
