
<div *ngIf="showSelector">

  <div class="row">
    <div class="col-6">
      <p>
        {{selected}} productos seleccionados
      </p>
    </div>
    <div class="col-6">
      <p>
        <button class="btn btn-outline-secondary btn-block"
                (click)="selectAll()">
          Seleccionar todo
        </button>
      </p>
    </div>
  </div>

  <div class="list-group">
    <div class="list-group-item d-flex justify-content-between align-items-center __cursorPointer"
         *ngFor="let item of details"
         (click)="itemClick(item)">
      <span>
        <i class="fa fa-check-square-o"
           *ngIf="item.IsSelected"></i>
        <i class="fa fa-square-o"
           *ngIf="!item.IsSelected"></i>
        &nbsp;&nbsp;
        {{ item.ProductName }}
      </span>
      <span>
        {{ item.Quantity }} {{ item.UnitName }}
      </span>
    </div>
  </div>

</div>
