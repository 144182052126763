
<div class="d-flex justify-content-between align-items-center">
  <h3>Logs</h3>
  <app-date-selector (selectDate)="onDateChange($event)"></app-date-selector>
</div>

<hr />
<br />

<div class="list-group" [hidden]="showDetails">
  <button class="list-group-item list-group-item-action flex-column align-items-start"
          *ngFor="let log of logs"
          (click)="view(log)">
    <div class="d-flex w-100 justify-content-between">
      <h5 class="mb-1">{{ log.Message }}</h5>
      <small>{{ log.Date | date:"dd/MM/yyyy HH:mm:ss"  }}</small>
    </div>
    <p class="mb-1">{{ log.Exception }}</p>
  </button>
</div>

<div *ngIf="showDetails">
  <div class="d-flex justify-content-between align-items-center">
    <b>Detalles: {{log.Message}}</b>
    <button class="btn btn-secondary"
            (click)="close()">
      Cerrar
    </button>
  </div>
  <hr />
  <div class="list-group">
    <div class="list-group-item d-flex justify-content-between align-items-center">
      <b>Date</b>
      <span class="badge">{{ log.Date | date: 'dd/MM/yyyy HH:mm:ss' }}</span>
    </div>
    <div class="list-group-item d-flex justify-content-between align-items-center">
      <b>Thread</b>
      <span class="badge">{{ log.Thread }}</span>
    </div>
    <div class="list-group-item d-flex justify-content-between align-items-center">
      <b>Level</b>
      <span class="badge">{{ log.Level }}</span>
    </div>
    <div class="list-group-item">
      <b>Logger</b>
      <br />
      <pre>{{ log.Logger }}</pre>
    </div>
    <div class="list-group-item">
      <b>Stacktrace</b>
      <br />
      <pre>{{ log.Stacktrace }}</pre>
    </div>
    <div class="list-group-item">
      <b>Logger</b>
      <br />
      <pre>{{ log.Exception }}</pre>
    </div>
  </div>
</div>
