
<table class="table table-sm">
  <thead>
    <tr>
      <th scope="col">
        Código
      </th>
      <th scope="col">
        Producto
      </th>
      <th scope="col">
        Cantidad
      </th>
      <th scope="col">
        Precio
      </th>
      <th scope="col">
        Total
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let product of saledProducts">
      <td>
        {{ product.ProductCode }}
      </td>
      <td>
        {{ product.ProductName }}
      </td>
      <td>
        {{ product.Quantity | number }} {{ product.UnitName }}
      </td>
      <td>
        {{ product.UnitPrice | currency }}
      </td>
      <td>
        {{ product.TotalPrice | currency }}
      </td>
    </tr>
  </tbody>
</table>
