import { Component, OnInit, OnDestroy, ViewChild, Renderer2, Output, EventEmitter, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { AddEvent, EditEvent, GridComponent } from '@progress/kendo-angular-grid';
import { groupBy, GroupDescriptor } from '@progress/kendo-data-query';
import { LoadingService } from '../../../../common/components/loading/loading.service';
import { AlertifyService } from '../../../../common/services/alertify.service';
import { ProductToSale, ProductVM, SaleDetailsVM, SaleVM } from '../../../../models/view-models';
import { ProductsService } from '../../../../services/products.service';

const createFormGroup = dataItem => new FormGroup({
  'Code': new FormControl(dataItem.Code),
  'Name': new FormControl(dataItem.Name),
  'Quantity': new FormControl(dataItem.Quantity, Validators.required),
  'UnitName': new FormControl(dataItem.UnitName),
  'Existence': new FormControl(dataItem.Existence),
  'RetailPrice': new FormControl(dataItem.RetailPrice, Validators.required),
  'Total': new FormControl(dataItem.Total),
});

const matches = (el, selector) => (el.matches || el.msMatchesSelector).call(el, selector);

@Component({
  selector: 'app-modify-ticket',
  templateUrl: './modify-ticket.component.html',
  styleUrls: ['./modify-ticket.component.scss']
})
export class ModifyTicketComponent implements OnInit {

  @ViewChild(GridComponent)
  private grid: GridComponent;

  public groups: GroupDescriptor[] = [];
  public view: any[];

  public formGroup: FormGroup;

  private editedRowIndex: number;
  private docClickSubscription: any;
  private isNew: boolean;
  showGrid: boolean = false;

  selectedSale: SaleVM;
  records: ProductToSale[] = [];
  backup: ProductToSale[] = [];
  productToSale: ProductToSale;
  selectedRowId: string = "";

  @Output() onSave: EventEmitter<any> = new EventEmitter<any>();
  @Output() onCancel: EventEmitter<any> = new EventEmitter<any>();

  isEditing: boolean = false;

  ticketId: string = "";
  total: number = 0;

  

  constructor(private alertify: AlertifyService,
    private renderer: Renderer2,
    private productsService: ProductsService,
    private fb: FormBuilder,
    private loading: LoadingService) { }

  ngOnDestroy(): void {
    this.docClickSubscription();
  }

  ngOnInit(): void {
    this.docClickSubscription = this.renderer.listen('document', 'click', this.onDocumentClick.bind(this));
  }

  onFormChanges(): void {
    this.formGroup.valueChanges
      .subscribe(val => {
        val.Total = val.Quantity * val.RetailPrice;
        this.total = this.calculateTotal();
      });
  }

  getData() {
    this.loading.show();
    this.productsService.getProductsFromSale(this.selectedSale.UUID)
      .subscribe((response: ProductVM[]) => {
        if (response) {
          this.ticketId = this.selectedSale.TicketId.toString();
          this.backup = this.records = this.productsService.createNonUniqueProductsToSale(response, this.selectedSale.SaleDetails);
          this.view = this.records;
          this.total = this.calculateTotal();
          this.showGrid = true;
          this.loading.hide();
        }
      }, (err) => {
          this.loading.hide();
      });
  }

  calculateTotal(): number {
    let t = 0;
    this.view.forEach(item => {
      t = t + item.Total;
    });
    return t;
  }

  cancel() {
    this.records = [];
    this.view = null;
    this.showGrid = false;
    this.onCancel.emit();
  }

  save() {
    this.onSave.emit(true);
  }

  public cellClickHandler({ isEdited, dataItem, rowIndex }): void {
    if (this.editedRowIndex != undefined) {
      //this.cancelHandler();
    } else {
      this.isEditing = true;
      this.selectedRowId = dataItem.UUID;
      this.editedRowIndex = rowIndex;
      if (isEdited || (this.formGroup && !this.formGroup.valid))
        return;
      this.saveCurrent();
      this.formGroup = createFormGroup(dataItem);
      this.onFormChanges();
      this.grid.editRow(rowIndex, this.formGroup);
    }

  }

  public cancelHandler(): void {
    this.closeEditor();
  }

  private closeEditor(): void {
    this.grid.closeRow(this.editedRowIndex);

    this.productToSale = null;
    this.isEditing = false;
    this.isNew = false;
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  private onDocumentClick(e: any): void {
    if (this.formGroup
      && this.formGroup.valid
      && !matches(e.target, '#productsGrid tbody *, #productsGrid .k-grid-toolbar .k-button')) {
      //this.saveCurrent();
    }
  }

  public saveRow(): void {
    this.saveCurrent();
    this.isEditing = false;
    this.total = this.calculateTotal();
  }

  private saveCurrent(): void {
    if (this.formGroup) {
      Object.assign(
        this.records.find(({ UUID }) => UUID === this.selectedRowId),
        this.formGroup.value
      );

      this.closeEditor();
    }
  }

  restore() {
    this.getData();
  }

  modifyTicket() {
    this.onSave.emit(this.view);
  }

  close = () => this.onCancel.emit();

  

}
