import { Component, Input } from '@angular/core';
import { SaleVM } from '../../../../models/view-models';

@Component({
  selector: 'report-sale-item',
  templateUrl: './sale-item.component.html',
  styleUrls: ['./sale-item.component.scss']
})
export class SaleItemComponent {

  @Input("sale") sale: SaleVM;

  constructor() { }

}
