import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-amount-selector',
  templateUrl: './amount-selector.component.html',
  styleUrls: ['./amount-selector.component.scss']
})
export class AmountSelectorComponent implements OnInit {

  @Input("label") label: string = "Seleccionar monto";
  @Input("data") data: string[] = [];

  public selectedItem: string = "";
  disabled: boolean = false;

  public comboForm: FormGroup = new FormGroup({
    comboValue: new FormControl()
  });

  @Output() selectValue: EventEmitter<number> = new EventEmitter<number>();

  ngOnInit(): void {
  }

  isValidForm(): boolean {
    return this.comboForm.valid;
  }

  getAmount(): string {
    if (this.isValidForm()) 
      return this.selectedItem;
    return "";
  }

  selectionChange(value: string) {
    this.selectedItem = value;
    this.selectValue.emit(Number(this.selectedItem));
  }

  reset() {
    this.comboForm.reset();
    this.selectedItem = "";
  }

  setAmountValue(amount: string) {
    alert(JSON.stringify(this.data));
    if (this.data && this.data.length > 0 && amount != null && amount != "") {
      if (this.data.includes(amount)) {
        alert("data includes " + amount);
        this.selectedItem = this.data.find(item => item === amount) as string;
        this.comboForm.setValue({
          comboValue: this.selectedItem
        });
      }
    }
  }

}
