
<div class="list-group">
  <div class="list-group-item d-flex justify-content-between align-items-center"
       *ngFor="let item of items">
    <b>{{item.Title}}</b>
    <span class="badge"
          *ngIf="!item.IsCurrency && !item.IsDate && !item.IsBoolean && !item.IsTime">
      {{ item.Value }}
    </span>
    <span class="badge"
          *ngIf="item.IsCurrency">
      {{ item.Value | currency }}
    </span>
    <span class="badge"
          *ngIf="item.IsDate">
      {{ item.Value | date:'dd/MM/yyyy' }}
    </span>
    <span class="badge"
          *ngIf="item.IsTime">
      {{ item.Value | date:'HH:mm:ss' }}
    </span>
    <span class="badge"
          *ngIf="item.IsBoolean">
      <i *ngIf="item.Value">SI</i>
      <i *ngIf="!item.Value">NO</i>
    </span>
  </div>
</div>
