import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ServiceType, ServiceTypes } from '../../../models/ServicePay';

@Component({
  selector: 'app-service-type-selector',
  templateUrl: './service-type-selector.component.html',
  styleUrls: ['./service-type-selector.component.scss']
})
export class ServiceTypeSelectorComponent implements OnInit{

  @Input("label") label: string = "Mostrando";
  @Input("hideFirst") hideFirst: boolean = false;

  public data: ServiceType[] = ServiceTypes;
  public selectedItem!: ServiceType;
  disabled: boolean = false;

  public comboForm: FormGroup = new FormGroup({
    comboValue: new FormControl()
  });

  @Output() selectType: EventEmitter<string> = new EventEmitter<string>();

  ngOnInit() {
    if (this.hideFirst) {
      this.data.splice(0, 1);
    }
  }

  isValidForm(): boolean {
    return this.comboForm.valid;
  }

  getSelection(): string {
    if (this.isValidForm()) {
      return this.selectedItem.Value;
    }
    return "";
  }

  selectionChange(value: ServiceType) {
    this.selectedItem = value;
    this.selectType.emit(this.selectedItem.Value);
  }

  reset() {
    this.comboForm.reset();
    this.selectedItem = null as unknown as ServiceType;
  }

  setItem(value: string) {
    if (this.data && this.data.length > 0 && value != null && value != "") {
      this.selectedItem = this.data.find(item => item.Value == value) as ServiceType;
      this.comboForm.setValue({
        comboValue: this.selectedItem
      });
    }
  }


}
