
<kendo-dialog title="Insersión múltiple"
              *ngIf="showDialog"
              (close)="closeDialog()"
              [minWidth]="250"
              [width]="450">

  <form [formGroup]="codeForm"
        [hidden]="showProductDetails">
    <div class="row">
      <div class="col-6">
        <kendo-floatinglabel text="Código">
          <input kendoTextBox name="Code"
                 #code
                 class="form-control"
                 type="text"
                 [readOnly]="disabledInput"
                 aria-label="Código"
                 placeholder="Código"
                 formControlName="Code" />
        </kendo-floatinglabel>
      </div>
      <div class="col-6">
        <br />
        <button class="btn btn-primary btn-block"
                type="submit"
                [disabled]="!isCodeFormValid()"
                (click)="searchProduct()">
          Buscar
        </button>
      </div>
    </div>
  </form>

  <p class="form-control d-flex justify-content-between align-items-center"
     *ngIf="showProductDetails">
    <b>
      Código
    </b>
    <span>
      {{ product.Code }}
    </span>
  </p>
  <p class="form-control d-flex justify-content-between align-items-center"
     *ngIf="showProductDetails">
    <b>
      Producto
    </b>
    <span>
      {{ product.Name }}
    </span>
  </p>
  <p class="form-control d-flex justify-content-between align-items-center"
     *ngIf="showProductDetails">
    <b>
      Precio
    </b>
    <span>
      {{ product.RetailPrice | currency }}
    </span>
  </p>
  <p class="form-control d-flex justify-content-between align-items-center"
     *ngIf="showProductDetails">
    <b>
      Existencia
    </b>
    <span>
      {{ product.Existence }}
    </span>
  </p>
  <p class="form-control d-flex justify-content-between align-items-center"
     *ngIf="showProductDetails">
    <b>
      Unidad de medida
    </b>
    <span>
      {{ product.UnitName }}
    </span>
  </p>

  <form [formGroup]="dialogForm"
        class="__entityForm">

    <kendo-floatinglabel text="Cantidad">
      <input kendoTextBox name="Quantity"
             #quantity
             class="form-control"
             type="number"
             aria-label="Cantidad"
             placeholder="Cantidad"
             formControlName="Quantity" />
    </kendo-floatinglabel>

    <p class="form-control d-flex justify-content-between align-items-center">
      <b>
        Total
      </b>
      <span>
        {{ total | currency }}
      </span>
    </p>

    <hr />

    <kendo-dialog-actions>
      <div class="d-flex justify-content-between align-items-center">
        <button class="btn btn-primary"
                type="submit"
                [disabled]="!isDialogFormValid()"
                (click)="returnProduct()">
          Agregar
        </button>
        <button class="btn btn-primary"
                type="button"
                (click)="closeDialog()">
          Cancelar
        </button>
      </div>
    </kendo-dialog-actions>
  </form>

</kendo-dialog>






