import { Component, OnInit, ViewChild } from '@angular/core';
import { DateRangeSelectorComponent } from '../../../../common/components/date-range-selector/date-range-selector.component';
import { GenericGridComponent } from '../../../../common/components/generic-grid/generic-grid.component';
import { LoadingService } from '../../../../common/components/loading/loading.service';
import { AlertifyService } from '../../../../common/services/alertify.service';
import { AuthenticationService } from '../../../../interceptors/authentication.service';
import { ReportType } from '../../../../models/ReportType';
import { DateRangeReportVM, LoginResponse, ReportRequestVM } from '../../../../models/view-models';
import { ReportsService } from '../../../../services/reports.service';

@Component({
  selector: 'app-date-range-report',
  templateUrl: './date-range-report.component.html',
  styleUrls: ['./date-range-report.component.scss']
})
export class DateRangeReportComponent implements OnInit {

  reportRequest: ReportRequestVM;
  userId: string;
  report: DateRangeReportVM;
  showReport: boolean = false;

  @ViewChild("dateRangeSelector", { read: DateRangeSelectorComponent })
  public dateRangeSelector: DateRangeSelectorComponent;

  @ViewChild("reportGrid", { read: GenericGridComponent, static: true })
  public reportGrid: GenericGridComponent;

  constructor(private reportsService: ReportsService,
    private authService: AuthenticationService,
    private alertify: AlertifyService,
    private loading: LoadingService) {
    let usr: LoginResponse = this.authService.SessionUserValue();
    this.userId = usr.UUID;
  }

  ngOnInit(): void {
  }

  isValidForm(): boolean {
    if (this.dateRangeSelector) {
      return this.dateRangeSelector.areDatesValid();
    }
    return true;
  }

  createReport() {
    if (this.isValidForm()) {
      this.loading.show();
      this.reportRequest = {
        StartDate: this.dateRangeSelector.startDate,
        EndDate: this.dateRangeSelector.endDate,
        userId: this.userId,
        ReportType: ReportType.DATE_RANGE
      };
      this.reportsService.createDateRangeReport(this.reportRequest)
        .subscribe((response: DateRangeReportVM) => {
          if (response) {
            this.report = response;
            this.showReport = true;
            this.setGridOptions();
          }
          this.loading.hide();
        }, (err) => {
          this.loading.hide();
        });
    } else {
      this.alertify.warning("La fecha inicial debe ser menor que la fecha final")
    }
  }

  setGridOptions() {
    this.reportGrid.setData(this.report.SalesReport);
    this.reportGrid.options.toolbarTitle = "Reporte de Ventas";
    this.reportGrid.options.showDetailsButton = false;
    this.reportGrid.options.showEditButton = false;
    this.reportGrid.options.showDeleteButton = false;
    this.reportGrid.options.hideActionButtons = true;
    this.reportGrid.options.paging = false;
    this.reportGrid.columns = [
      { field: "TicketId", title: "Ticket" },
      { field: "CreateDate", title: "Fecha", dateType: true },
      { field: "PaymentMethodName", title: "Método de Pago" },
      { field: "Total", title: "Total", currencyType: true },
      { field: "CategoryName", title: "Categoría" },
      { field: "ProductCode", title: "Código" },
      { field: "ProductName", title: "Producto" },
      { field: "Quantity", title: "Cantidad", numberType: true },
      { field: "UnitPrice", title: "Precio", currencyType: true },
      { field: "SalePrice", title: "Precio Total" },
    ];
    this.reportGrid.setGridOptions();
  }

}
