<!-- Sales on credit list -->
<div [hidden]="showDetails || showForm">

  <div class="row">
    <div class="col-4">
      <h3>
        Créditos
      </h3>
    </div>
    <div class="col-8 text-center">
      <h4 style="cursor:pointer"
          (click)="showCollapse=!showCollapse">
        Total: {{ totalCredits | currency}}
      </h4>
      <div class="__collapse_details"
           [ngClass]="{'collapse': !showCollapse}">
        <div class="row">
          <div class="col-md-6 col-sm-12 mx-auto">
            <app-list-details #creditsDetails></app-list-details>
          </div>
        </div>
      </div>
    </div>
  </div>

  <hr />
  <br />

  <app-filtered-grid #grid
                    (onCellClicked)="select($event)">
  </app-filtered-grid>



</div>

<!-- Sale details grid -->
<div [hidden]="!showDetails || showForm">

  <div class="row">
    <div class="col-6">
      <h3>
        Detalles de la Venta
      </h3>
      <button class="btn btn-outline-primary"
              [disabled]="allowPaying"
              (click)="payTicket()">
        Liquidar Ticket
      </button>
      <app-reprint-ticket [ticketId]="selectedTicketId"></app-reprint-ticket>
    </div>
    <div class="col-6 text-right">
      <button class="btn btn-outline-secondary"
              (click)="showDetails=false; selectedTicketId=null;"
              aria-label="Cerrar">
        Cerrar
        &nbsp;
        <i class="fa fa-close"></i>
      </button>
    </div>
  </div>

  <app-header-details #saleBalanceDetails
                      (onItemClick)="onHeaderItemClick($event)">
  </app-header-details>

  <hr />
  <br />

  <app-generic-grid #detailsGrid></app-generic-grid>

  <kendo-dialog title="Movimientos de la Venta"
                *ngIf="showMovementsDialog"
                (close)="closeMovementsDialog()"
                [minWidth]="250"
                [width]="'100%'">



    <hr />

    <kendo-dialog-actions>
      <div class="d-flex justify-content-between align-items-center">
        <button class="btn btn-primary"
                type="button"
                (click)="closeMovementsDialog()">
          Cerrar
        </button>
      </div>
    </kendo-dialog-actions>

  </kendo-dialog>

</div>

<!-- Pay ticket form -->
<div [hidden]="!showForm">

  <div class="row"
       *ngIf="saleBalance">
    <div class="col-md-6 col-sm-12 mx-auto">
      <h4>
        Pagar Ticket
      </h4>
      <hr /><br />
      <app-list-details #payDetails></app-list-details>

      <br />

      <form [formGroup]="entityForm"
            class="__entityForm">

        <app-payment-method-button-selector #paymentMethodSelector
                                            (onItemSelect)="paymentMethodSelect($event)"
                                            hideCredit="true">
        </app-payment-method-button-selector>

        <app-multiple-payment-method #multiplePay
                                     (NewFormEmiter)="onMultiplePaymentChange($event)"
                                     [NewForm]="true"
                                     [AllowCredits]="false"
                                     [hidden]="hideMultiplePay">
        </app-multiple-payment-method>

        <kendo-floatinglabel text="Monto"
                             [hidden]="false">
          <input kendoTextBox name="Amount"
                 class="form-control"
                 [ngClass]="{'is-invalid': entityForm.get('Amount').errors && entityForm.get('Amount').touched}"
                 aria-label="Monto"
                 placeholder="Monto"
                 [readOnly]="true"
                 formControlName="Amount" />
        </kendo-floatinglabel>

        <kendo-floatinglabel text="Referencia Bancaria"
                             [hidden]="hideReferenceInput">
          <input kendoTextBox name="BankReference"
                 #reference
                 class="form-control"
                 type="text"
                 aria-label="Referencia Bancaria"
                 placeholder="Referencia Bancaria"
                 formControlName="BankReference" />
        </kendo-floatinglabel>

        <ng-container *ngIf="isCurrencyPaymentMethod">
          <p class="form-control d-flex justify-content-between align-items-center">
            <b>Tipo de Cambio:</b>
            <span>{{ currencyValue | currency }}</span>
          </p>
          <p class="form-control d-flex justify-content-between align-items-center">
            <b>Total {{ currencyCode }}:</b>
            <span>{{ currencyTotal | number: '1.2-2' }}</span>
          </p>
          <!--<p class="form-control d-flex justify-content-between align-items-center">
            <b>Cambio {{ currencyCode }}:</b>
            <span>{{ currencyChange | number: '1.2-2' }}</span>
          </p>-->
        </ng-container>

        <div class="d-flex justify-content-end __formButtonsContainer">
          <button class="btn btn-primary"
                  #payButton
                  type="submit"
                  [disabled]="!isValidForm()"
                  (click)="save()">
            Pagar
          </button>
          <button class="btn btn-secondary"
                  type="button"
                  (click)="reset()">
            Cancelar
          </button>
        </div>

      </form>
    </div>

  </div>
