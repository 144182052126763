import { Pipe, PipeTransform } from '@angular/core';
import { ShiftVM } from '../../models/view-models';

@Pipe({
  name: 'filterShiftsByDate'
})
export class FilterShiftsByDatePipe implements PipeTransform {

  transform(items: ShiftVM[], selectedDate: Date | null): ShiftVM[] {
    if (!items)
      return [];

    if (selectedDate)
      return items.filter(item => this.isSameDay(new Date(item.CreateDate), selectedDate));

    return items;
  }

  private isSameDay(date1: Date, date2: Date): boolean {
    return date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate();
  }

}
