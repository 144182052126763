import { DecimalPipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoadingService } from '../../../common/components/loading/loading.service';
import { AuthenticationService } from '../../../interceptors/authentication.service';
import { LocalData } from '../../../models/LocalData';
import { LoginResponse, ShiftVM } from '../../../models/view-models';
import { ActionPermissionValidatorService } from '../../../services/action-permission-validator.service';
import { ShiftService } from '../../../services/shift.service';

@Component({
  selector: 'app-starting-cash-dialog',
  templateUrl: './starting-cash-dialog.component.html',
  styleUrls: ['./starting-cash-dialog.component.scss']
})
export class StartingCashDialogComponent implements OnInit {

  cashForm: FormGroup;
  @ViewChild("cash") cash: ElementRef;
  public dialogOpen: boolean = true;

  @Output() onDialogChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onUserIsAdmin: EventEmitter<boolean> = new EventEmitter<boolean>();

  userInfo: LoginResponse;
  username: string = "";
  createButtonDisabled: boolean = false;

  constructor(private shiftService: ShiftService,
    private decimalPipe: DecimalPipe,
    private authService: AuthenticationService,
    private loading: LoadingService,
    private actionValidatorService: ActionPermissionValidatorService,
    private fb: FormBuilder) { }

  ngOnInit(): void {
    this.userInfo = this.authService.SessionUserValue();
    this.username = this.userInfo.User;
    this.initForm();
    this.createButtonDisabled = this.actionValidatorService.createShift();
    this.loadShiftInfo();
  }

  initForm() {
    this.cashForm = this.fb.group({
      Cash: [this.setFormatNumber(0), [Validators.required, Validators.min(0)]]
    });
  }

  loadShiftInfo() {
    if (this.userInfo.Rol !== LocalData.ADMIN_ROL) {
      this.loading.show();
      this.shiftService.getByUserId(this.userInfo.UUID)
        .subscribe((response: ShiftVM) => {
          if (response) {
            localStorage.setItem(LocalData.ACTIVE_SHIFT, JSON.stringify(response));
            this.dialogOpen = false;
          } else {
            this.dialogOpen = true;
            setTimeout(() => {
              this.cash.nativeElement.focus();
              this.cash.nativeElement.select();
            }, 0);
          }
          this.loading.hide();
          this.onDialogChange.emit(this.dialogOpen);
        }, (err) => {
          this.loading.hide();
        });
    } else {
      this.dialogOpen = false;
      this.onUserIsAdmin.emit(true);
    }
  }

  public open() {
    this.dialogOpen = true;
    this.onDialogChange.emit(this.dialogOpen);
  }

  isValidForm(): boolean { return this.cashForm.valid; }

  public onAccept() {
    if (this.isValidForm()) {
      this.loading.show();
      const newShift: ShiftVM = Object.assign({
        UserId: this.userInfo.UUID,
        Username: this.userInfo.User,
        Active: true
      }, this.cashForm.value);
      this.shiftService.insert(newShift)
        .subscribe((response: ShiftVM) => {
          if (response) {
            localStorage.setItem(LocalData.ACTIVE_SHIFT, JSON.stringify(response));
            this.dialogOpen = false;
            this.onDialogChange.emit(this.dialogOpen);
          }
          this.loading.hide();
        }, (err) => {
            this.loading.hide();
        });;
    }
  }

  setFormatNumber(value: any) {
    let tmp = this.decimalPipe.transform(value, '1.2-2', 'mx');
    tmp = tmp.replace(',', '');
    return tmp;
  }

  logout() {
    this.authService.logout();
  }

}
