import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared.module';
import { CommonComponentsModule } from './common/common-components.module';
import { AppRoutingModule } from './app-routing.module';

import { registerLocaleData, CurrencyPipe, DatePipe, DecimalPipe } from '@angular/common';
import localeMX from '@angular/common/locales/es-MX';
registerLocaleData(localeMX, 'mx');


import { AppComponent } from './app.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { AccessDeniedComponent } from './access-denied/access-denied.component';
import { TestComponent } from './test/test.component';



@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    AccessDeniedComponent,
    TestComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    CommonComponentsModule
  ],
  providers: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
