import { Component, Input } from '@angular/core';
import { GenericListItem } from '../../../models/ListItem';

@Component({
  selector: 'app-generic-list',
  templateUrl: './generic-list.component.html',
  styleUrls: ['./generic-list.component.scss']
})
export class GenericListComponent {

  @Input("items") items: GenericListItem[] = [];

  

}
