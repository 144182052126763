
<div class="__cashClosing"
     *ngIf="detail">

  <h4 class="__header d-flex justify-content-between align-items-center"
      *ngIf="detail.ShowHeader"
      (click)="collapse=!collapse">
    <span>{{ detail.Header }}</span>
    <span>
      <i class="fa fa-2x"
         [ngClass]="{'fa-angle-down': collapse, 'fa-angle-up': !collapse}"></i>
    </span>
  </h4>


  <div [ngClass]="{'collapse':collapse}">
    <div class="list-group">
      <div class="list-group-item d-flex justify-content-between align-items-center"
           *ngFor="let item of detail.DetailItems">
        <b>
          {{ item.Title }}
        </b>
        <span class="badge"
              *ngIf="!item.IsCurrency && !item.IsDate && !item.IsTime && !item.IsNumber && !item.IsBoolean && !item.IsDateTime">
          {{ item.Value }}
        </span>
        <span class="badge"
              *ngIf="item.IsCurrency">
          {{ item.Value | currency }}
        </span>
        <span class="badge"
              *ngIf="item.IsDate">
          {{ item.Value | date: 'dd/MM/yyyy' }}
        </span>
        <span class="badge"
              *ngIf="item.IsTime">
          {{ item.Value | date: 'HH:mm:ss' }}
        </span>
        <span class="badge"
              *ngIf="item.IsDateTime">
          {{ item.Value | date: 'dd/MM/yyyy HH:mm:ss' }}
        </span>
        <span class="badge"
              *ngIf="item.IsNumber">
          {{ item.Value | number }}
        </span>
        <span class="badge"
              *ngIf="item.IsBoolean">
          <b *ngIf="item.Value">
            Si
          </b>
          <b *ngIf="!item.Value">
            No
          </b>
        </span>
      </div>

      <div class="list-group-item d-flex justify-content-between align-items-center"
           *ngFor="let item of detail.currencySales">
        <b>
          {{ item.Name }}
        </b>
        <span class="badge">
          {{ item.Value | currency }}
        </span>
      </div>

      <div class="list-group-item d-flex justify-content-between align-items-center"
           *ngIf="detail.ShowTotal">
        <b>
          {{ detail.TotalTitle || 'Total' }}
        </b>
        <span class="badge">
          {{ detail.Total | currency }}
        </span>
      </div>

      <ng-container *ngIf="detail.showCurrencyMovements">
        <div class="list-group-item d-flex justify-content-between align-items-center"
             *ngFor="let mov of detail.currencyMovements">
          <b>
            Total {{ mov.Name
          }}
          </b>
          <span class="badge">
            {{ mov.Symbol }} {{ (mov.Value/mov.ExchangeValue) | number: '1.2-2' }} {{ mov.Code }}
          </span>
</div>
      </ng-container>

    </div>
  </div>
</div>
