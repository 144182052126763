import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GenericGridComponent } from '../../../common/components/generic-grid/generic-grid.component';
import { LoadingService } from '../../../common/components/loading/loading.service';
import { AlertifyService } from '../../../common/services/alertify.service';
import { CategoryVM } from '../../../models/view-models';
import { CategoriesService } from '../../../services/categories.service';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {

  categories: CategoryVM[] = [];
  category: CategoryVM;
  entityForm: FormGroup;
  showForm: boolean = false;
  isEdition: boolean = false;

  @ViewChild("grid", { read: GenericGridComponent, static: false })
  public grid: GenericGridComponent;

  @ViewChild("code") code: ElementRef;
  @ViewChild("name") name: ElementRef;

  constructor(private categoriesService: CategoriesService,
    private fb: FormBuilder,
    private alertify: AlertifyService,
    private loading: LoadingService) { }

  ngOnInit(): void {
    this.initForm();
    this.loadData();
  }

  initForm() {
    this.entityForm = this.fb.group({
      Code: ['', [Validators.required, Validators.minLength(2)]],
      Name: ['', [Validators.required, Validators.minLength(2)]],
      Description: [''],
    });
  }

  loadData() {
    this.loading.show();
    this.categoriesService.getAll()
      .subscribe((response: CategoryVM[]) => {
        if (response) {
          this.categories = response;
        }
        this.setGridOptions();
        this.loading.hide();
      }, (err) => {
          this.loading.hide();
      });
  }

  setGridOptions() {
    this.grid.setData(this.categories);
    this.grid.options.toolbarTitle = "Categorías";
    this.grid.options.showDetailsButton = false;
    this.grid.columns = [
      { field: "Code", title: "Código" },
      { field: "Name", title: "Nombre" },
      { field: "Description", title: "Descripción" },
    ];
    this.grid.setGridOptions();
  }

  setFormVisibility = () => this.showForm = !this.showForm;

  addNew() {
    this.isEdition = false;
    this.setFormVisibility();
    setTimeout(() => {
      this.code.nativeElement.focus();
      this.code.nativeElement.value = "";
    }, 0);
  }

  isValidForm(): boolean {
    return this.entityForm.valid;
  }

  save() {
    if (this.isValidForm()) {
      this.loading.show();
      const entity: CategoryVM = Object.assign(this.entityForm.value);
      this.categoriesService.insert(entity)
        .subscribe((response: CategoryVM) => {
          if (response) {
            this.grid.addRow(response);
            this.cancel();
            this.loading.hide();
          }
        }, (err) => {
            this.loading.hide();
        });
    }
  }

  cancel() {
    this.initForm();
    this.setFormVisibility();
  }

  edit(value: CategoryVM) {
    this.category = value;
    this.entityForm.setValue({
      Code: this.category.Code,
      Name: this.category.Name,
      Description: this.category.Description,
    });
    this.isEdition = true;
    this.setFormVisibility();
    setTimeout(() => {
      this.name.nativeElement.focus();
    }, 0);
  }

  update() {
    if (this.isValidForm()) {
      this.loading.show();
      const entity: CategoryVM = Object.assign({
        UUID: this.category.UUID
      }, this.entityForm.value);
      this.categoriesService.update(entity)
        .subscribe((response: CategoryVM) => {
          if (response) {
            this.grid.updateRowByUUID(response);
            this.cancel();
            this.loading.hide();
          }
        }, (err) => {
          this.loading.hide();
        });
    }
  }

  delete(value: CategoryVM) {
    this.alertify.confirm(
      `Eliminar categoría ${value.Name}`,
      () => {
        this.categoriesService.delete(value.UUID)
          .subscribe((response: boolean) => {
            if (response) {
              this.grid.deleteRow(value.UUID);
              this.loading.hide();
            }
          }, (err) => {
              this.loading.hide();
          });
      });
  }

}
