import { Component, Output, EventEmitter } from '@angular/core';
import { HeaderDetailsItem } from '../../../models/ComponentModels';

@Component({
  selector: 'app-header-details',
  templateUrl: './header-details.component.html',
  styleUrls: ['./header-details.component.scss']
})
export class HeaderDetailsComponent {

  items: HeaderDetailsItem[] = [];
  showHeader: boolean = false;
  columnsClass: string = "row-cols-12";
  @Output() onItemClick: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  setHeaders(headerItems: HeaderDetailsItem[]) {
    this.items = headerItems;
    this.calculateColumnsClass();
    this.showHeader = true;
  }

  setHeadersAndColumnsCount(headerItems: HeaderDetailsItem[], columnsCount: number) {
    this.items = headerItems;
    this.setColumnsClass(columnsCount);
    this.showHeader = true;
  }

  private calculateColumnsClass() {
    if (this.items.length <= 12) 
      this.columnsClass = `row-cols-${this.items.length}`;
    else 
      this.columnsClass = `row-cols-12`;
  }

  private setColumnsClass(columnsCount: number) {
    if (columnsCount <= 12)
      this.columnsClass = `row-cols-${columnsCount}`;
    else
      this.columnsClass = `row-cols-12`;
  }

  itemClick(item: HeaderDetailsItem) {
    this.onItemClick.emit(item);
  }
}
