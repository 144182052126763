import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { ProductsListSelectorComponent } from '../../../../common/components/products-list-selector/products-list-selector.component';
import { SaleDetailsVM } from '../../../../models/view-models';

@Component({
  selector: 'app-return-products',
  templateUrl: './return-products.component.html',
  styleUrls: ['./return-products.component.scss']
})
export class ReturnProductsComponent implements OnInit {

  products: SaleDetailsVM[] = [];
  @ViewChild("productsSelector", { read: ProductsListSelectorComponent })
  public productsSelector: ProductsListSelectorComponent;

  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();
  @Output() ok: EventEmitter<SaleDetailsVM[]> = new EventEmitter<SaleDetailsVM[]>();

  constructor() { }

  ngOnInit(): void {
  }

  setProductsList() {
    this.productsSelector.setDetails(this.products);
  }

  isValid(): boolean {
    if (this.productsSelector) {
      return this.productsSelector.details.filter(item => item.IsSelected === true).length > 0;
    }
    return false;
  }

  returnProducts() {
    this.ok.emit(this.productsSelector.details.filter(item => item.IsSelected === true));
  }

  getSelectedProducts(): SaleDetailsVM[] {
    return this.productsSelector.details.filter(item => item.IsSelected === true);
  }

  getUnselectedProducts(): SaleDetailsVM[] {
    return this.productsSelector.details.filter(item => item.IsSelected === false);
  }

  close() {
    this.cancel.emit();
  }

}
