
<app-starting-cash-dialog #startingCashDialog
                          (onUserIsAdmin)="userIsAdmin($event)"
                          (onDialogChange)="dialogChange($event)">
</app-starting-cash-dialog>

<app-shift-selector-dialog #shiftSelectorDialog
                           (onShiftSelect)="onShiftSelect($event)">
</app-shift-selector-dialog>

<div *ngIf="!hideContent">
  <router-outlet></router-outlet>
</div>
