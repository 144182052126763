import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AlertifyService } from '../common/services/alertify.service';
import { AppLogVM, OperationResult } from '../models/view-models';
import { map } from 'rxjs/operators';
import { ErrorService } from '../interceptors/error.service';

@Injectable({
  providedIn: 'root'
})
export class AppLogsService {

  apiUrl: string = `${environment.apiUrl}/AppLogs`;

  constructor(private http: HttpClient,
    private error: ErrorService,
    private alertify: AlertifyService) { }

  getLogs() {
    return this.http.get(this.apiUrl)
      .pipe(map((response: OperationResult<AppLogVM[]>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }

  getLogsFromDate(date: Date) {
    let dt = this.formatDateToString(date);
    const uri = `${this.apiUrl}/GetLogsFromDate?date=${dt}`;
    return this.http.get(uri)
      .pipe(map((response: OperationResult<AppLogVM[]>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }

  private formatDateToString(date: Date): string {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} 12:00:00`;
  }

}
