import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Ticket } from '../../../models/LocalModels';
import { SavedTicketVM } from '../../../models/view-models';
import { SavedTicketService } from '../../../services/saved-ticket.service';


@Component({
  selector: 'app-saved-tickets',
  templateUrl: './saved-tickets.component.html',
  styleUrls: ['./saved-tickets.component.scss']
})
export class SavedTicketsComponent implements OnInit {

  tickets: Ticket[] = [];
  @Output() onTicketSelected: EventEmitter<Ticket> = new EventEmitter<Ticket>();

  @Input("collapse") collapse: boolean = true;

  constructor(private dataService: SavedTicketService) { }

  ngOnInit(): void {
    setInterval(() => {
      this.loadSavedTickets();
    }, 1000);
  }

  loadSavedTickets() {
    this.dataService.getSavedTickets()
      .subscribe((response: SavedTicketVM[]) => {
        let res: Ticket[] = [];
        if (response && response.length > 0) {
          response.forEach(item => {
            res.push(JSON.parse(item.JsonInfo));
          });
        }
          this.tickets = res;
      });
  }

  selectTicket(ticket: Ticket) {
    this.onTicketSelected.emit(ticket);
  }

}
