
export enum CARDEXRecordType {
  INPUT= 'I',
  OUTPUT= 'O',
}

export enum TipoDeRegistroCARDEX {
  I = "Entrada",
  O = "Salida",
}



export enum CustomerMovementType {
  BUY = 'B',
  PAY = 'P',
  DEPOSIT = 'D'
}

export enum MovimientoCliente {
  B = 'Compra',
  P = 'Pago',
  D = 'Abono'
}
