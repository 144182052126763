
<div *ngIf="!showGrid"
     class="jumbotron text-center">
  <h5>No hay registros</h5>
</div>

<div *ngIf="showGrid">

  <kendo-grid [data]="gridData"
              (cellClick)="cellClickHandler($event)"
              [pageSize]="options.perPage"
              [navigable]="options.navigable"
              [pageable]="options.paging"
              [sortable]="true"
              [groupable]="false"
              [reorderable]="true"
              [resizable]="true"
              (dataStateChange)="dataStateChange($event)">

    <!-- toolbar template -->
    <ng-template kendoGridToolbarTemplate
                 *ngIf="options.showToolbar">
      <div style="width:100%;"
           class="row">
        <div class="col-6">
          <!--<kendo-floatinglabel text="Buscar">-->
            <input *ngIf="options.showSearchField"
                   class="form-control"
                   style="width:100%;border:2px solid #666;box-shadow:inset;"
                   placeholder="Buscar..."
                   kendoTextBox
                   [(ngModel)]="searchValue"
                   (input)="onFilter($event)"
                   />
          <!--</kendo-floatinglabel>-->
        </div>
        <div class="col-6 align-items-baseline text-end">
          <button *ngIf="options.showExportExcelButton"
                  kendoGridExcelCommand type="button"
                  icon="file-excel"
                  class="btn btn-outline-secondary">
            Exportar a Excel
          </button>
          <button *ngIf="options.showExportPDFButton"
                  kendoGridPDFCommand
                  icon="file-pdf"
                  class="btn btn-outline-secondary">
            Exportar a PDF
          </button>
        </div>
      </div>
    </ng-template>

    <!-- checkbox column -->
    <kendo-grid-column title=""
                       [width]="staticColumnWidth"
                       *ngIf="options.selectableCheckbox">
      <ng-template kendoGridCellTemplate let-dataItem let isSelected="false">
        <div class="text-center">
          <input type="checkbox" kendoCheckBox
                 class="form-check-input text-center"
                 (click)="isSelected=!isSelected; SelectCheckboxItem(dataItem)"
                 aria-label="Seleccionar producto" />
        </div>
      </ng-template>
      </kendo-grid-column>

      <!-- kendo grid column -->
      <kendo-grid-column *ngFor="let col of columns"
                         field="{{col.field}}"
                         title="{{col.title}}"
                         [headerStyle]="{'text-align':'center','line-height': '2em'}"
                         [headerClass]="{'center': true}"
                         class="{{col.cssClass}}">
        <ng-template kendoGridCellTemplate let-dataItem let-column="column">
          <div *ngIf="!col.dateType && !col.currencyType && !col.booleanType && !col.timeType&& !col.numberType"
               [ngClass]="{'__pointer':options.rowClick}">
            {{ dataItem[column.field] }}
          </div>
          <div *ngIf="col.dateType"
               [ngClass]="{'__pointer':options.rowClick}">
            <div *ngIf="dataItem[column.field]=='2000-01-01T00:00:00'">
              N/A
            </div>
            <div *ngIf="dataItem[column.field]!='2000-01-01T00:00:00'">
              {{ dataItem[column.field] | date:'dd/MM/yyyy' }}
            </div>
          </div>
          <div *ngIf="col.currencyType"
               [ngClass]="{'__pointer':options.rowClick}">
            {{ dataItem[column.field] | currency }}
          </div>
          <div *ngIf="col.numberType"
               [ngClass]="{'__pointer':options.rowClick}">
            {{ dataItem[column.field] | number }}
          </div>
          <div *ngIf="col.booleanType"
               [ngClass]="{'__pointer':options.rowClick}">
            <b *ngIf="dataItem[column.field]">
              <span class="sr-only">si</span>
              <i class="fa fa-check"></i>
            </b>
            <b *ngIf="!dataItem[column.field]">
              <span class="sr-only">no</span>
              <i class="fa fa-close"></i>
            </b>
          </div>
          <div *ngIf="col.timeType"
               [ngClass]="{'__pointer':options.rowClick}">
            {{ dataItem[column.field] | date:'HH:mm:ss' }}
          </div>
        </ng-template>
      </kendo-grid-column>

      <!-- kendo grid messages -->
      <kendo-grid-messages noRecords="No hay registros"
                           pagerPage="Página"
                           pagerOf="de"
                           pagerItems="ítems"
                           pagerItemsPerPage="ítems por página">
      </kendo-grid-messages>

      <!-- Action buttons -->
      <kendo-grid-column title=""
                         [width]="staticColumnWidth"
                         *ngIf="!options.hideActionButtons">
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="d-flex justify-content-around">
            <button class="btn btn-outline-secondary btn-sm"
                    *ngIf="options.showDetailsButton"
                    (click)="select(dataItem)"
                    aria-label="Ver detalles">
              <i class="fa fa-list"></i>
              <span class="sr-only">Ver detalles</span>
            </button>
            <button class="btn btn-outline-secondary btn-sm"
                    *ngIf="options.showEditButton"
                    (click)="edit(dataItem)"
                    aria-label="Editar">
              <i class="fa fa-pencil-square"></i>
              <span class="sr-only">Editar</span>
            </button>
            <button class="btn btn-outline-secondary btn-sm"
                    *ngIf="options.showDeleteButton"
                    (click)="delete(dataItem)"
                    aria-label="Eliminar">
              <i class="fa fa-trash-o"></i>
              <span class="sr-only">Eliminar</span>
            </button>
            <button class="btn btn-outline-secondary btn-sm"
                    *ngIf="options.showPrintButton"
                    (click)="print(dataItem)"
                    aria-label="Imprimir">
              <i class="fa fa-print"></i>
              <span class="sr-only">Imprimir</span>
            </button>
            <button class="btn btn-outline-secondary btn-sm"
                    *ngIf="options.showRestoreButton"
                    (click)="restore(dataItem)"
                    aria-label="Restaurar">
              <span class="sr-only">Restaurar</span>
              <i class="fa fa-check"></i>
            </button>
            <button class="btn btn-outline-secondary btn-sm"
                    *ngIf="options.showToggleButton"
                    (click)="toggle(dataItem)"
                    aria-label="Habilitar / Deshabilitar">
              <span class="sr-only">Habilitar / Deshabilitar</span>
              <i class="fa fa-toggle-on"></i>
            </button>
          </div>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-pdf fileName="{{options.toolbarTitle}}.pdf" [repeatHeaders]="true"></kendo-grid-pdf>
      <kendo-grid-excel fileName="{{options.toolbarTitle}}.xlsx"></kendo-grid-excel>

</kendo-grid>
</div>
