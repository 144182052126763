import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-labeled-input',
  templateUrl: './labeled-input.component.html',
  styleUrls: ['./labeled-input.component.scss']
})
export class LabeledInputComponent {

  @Input("label") label: String = "";
  @Input("value") value: String = "";

}
