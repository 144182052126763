import { Component, Output, EventEmitter, ViewChild } from '@angular/core';
import { WindowState } from '@progress/kendo-angular-dialog';
import { SelectEvent } from '@progress/kendo-angular-layout';
import { GenericGridComponent } from '../../../../../common/components/generic-grid/generic-grid.component';
import { LoadingService } from '../../../../../common/components/loading/loading.service';
import { SaleVM } from '../../../../../models/view-models';
import { SalesService } from '../../../../../services/sales.service';

@Component({
  selector: 'window-sales-of-the-day-and-returns',
  templateUrl: './sales-of-the-day-and-returns.component.html',
  styleUrls: ['./sales-of-the-day-and-returns.component.scss']
})
export class SalesOfTheDayAndReturnsComponent {

  showWindow: boolean = false;
  public windowState: WindowState = 'maximized';

  @Output() windowClose: EventEmitter<any> = new EventEmitter<any>();
  sales: SaleVM[] = [];

  @ViewChild("salesGrid", { read: GenericGridComponent, static: false })
  public salesGrid: GenericGridComponent;

  salesDate: Date = new Date();

  constructor(private salesService: SalesService,
    private loading: LoadingService) { }

  openWindow() {
    this.showWindow = true;
    this.loadData();
    
  }

  loadData() {
    this.loading.show();
    this.sales = [];
    this.salesService.getSalesFromDate(this.salesDate)
      .subscribe((response: SaleVM[]) => {
        if (response) {
          this.sales = response;
        }
        this.setGridOptions();
        this.loading.hide();
      }, (err) => {
          this.loading.hide();
          console.log(err);
      });
  }

  closeDialog() {
    this.showWindow = false;
    this.windowClose.emit();
  }

  setGridOptions() {
    this.salesGrid.setData(this.sales);
    this.salesGrid.options.toolbarTitle = "Ventas del Día";
    this.salesGrid.options.paging = false;
    this.salesGrid.options.showDetailsButton = false;
    this.salesGrid.options.hideActionButtons = true;
    this.salesGrid.options.showEditButton = false;
    this.salesGrid.options.showDeleteButton = false;
    this.salesGrid.columns = [
      { field: "TicketId", title: "Ticket" },
      { field: "CreateDate", title: "Hora", timeType: true },
      { field: "Username", title: "Usuario" },
      { field: "PaymentMethodName", title: "Método de Pago" },
      { field: "CustomerName", title: "Cliente" },
      { field: "Total", title: "Total", currencyType: true },
      { field: "Paid", title: "Pagado", currencyType: true },
      { field: "Change", title: "Cambio", currencyType: true },
    ];
    this.salesGrid.setGridOptions();
  }

  onDateChange(selectedDate: Date) {
    this.salesDate = selectedDate;
    this.loadData();
  }

}
