
<button class="btn btn-outline-secondary"
        (click)="showUpload=!showUpload">
  Cambiar Logo
</button>

<div [ngClass]="{'collapse': !showUpload}">
  <div class="row">
    <div class="col-md-6 col-sm-12">
      <br /><br />

      <div [hidden]="fileSelected">
        <kendo-upload #logo
                      [restrictions]="fileRestrictions"
                      [multiple]="false"
                      [autoUpload]="false"
                      (select)="selectEventHandler($event)">
        </kendo-upload>
      </div>

      <div [hidden]="!fileSelected">
        <p>
          {{ filename }}
        </p>
        <div class="btn-group text-right">
          <button class="btn btn-primary"
                  (click)="uploadFile()">
            Guardar
          </button>
          <button class="btn btn-secondary"
                  (click)="cancelUpload()">
            Cancelar
          </button>
        </div>
      </div>

      <br /><br />
    </div>
  </div>
</div>
