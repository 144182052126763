import { Component, ElementRef, OnDestroy, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthorizationDialogService } from './authorization-dialog.service';
import { AuthenticationService } from '../../../interceptors/authentication.service';

@Component({
  selector: 'authorization-dialog',
  templateUrl: './authorization-dialog.component.html',
  styleUrls: ['./authorization-dialog.component.scss']
})
export class AuthorizationDialogComponent implements OnInit, OnDestroy {

  showDialog: boolean = false;
  dialogForm: FormGroup;
  statusSuscription: Subscription;

  @ViewChild("user") user: ElementRef;

  loading: boolean = false;
  buttonDisabled: boolean = false;

  @Output() onAuthorizationSuccess: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private dialogService: AuthorizationDialogService,
    private authService: AuthenticationService,
    private fb: FormBuilder) { }


  ngOnInit(): void {
    this.statusSuscription = this.dialogService.dialogStatus
      .subscribe((value: boolean) => {
        this.showDialog = value;
        if (this.showDialog) {
          this.initForm();
          this.focusOnUserInput();
        }
        });
    }

  ngOnDestroy(): void {
    this.statusSuscription.unsubscribe();
  }

  public openDialog() {
    this.showDialog = true;
    this.initForm();
  }

  initForm() {
    this.dialogForm = this.fb.group({
      user: ['', [Validators.required]],
      password: ['', [Validators.required]]
    });
  }

  isValidForm(): boolean {
    return this.dialogForm.valid;
  }

  authorize() {
    if (this.isValidForm()) {
      this.loading = true;
      this.buttonDisabled = true;
      this.authService.authorize(this.dialogForm.value)
        .subscribe((response: boolean) => {
          if (response) {
            this.closeDialog();
            this.onAuthorizationSuccess.emit(true);
          } else {
            this.loading = false;
            this.buttonDisabled = false;
            this.onAuthorizationSuccess.emit(false);
          }
        }, (err) => {
          this.loading = false;
          this.buttonDisabled = false;
          this.onAuthorizationSuccess.emit(false);
        });
    }
  }

  focusOnUserInput() {
    setTimeout(() => {
      this.user.nativeElement.focus();
    }, 0);
  }

  closeDialog(): void {
    this.showDialog = false;
  }

  

}
