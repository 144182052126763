import { Component, Input } from '@angular/core';
import { SaleDetailsVM } from '../../../../models/view-models';

@Component({
  selector: 'app-saled-products',
  templateUrl: './saled-products.component.html',
  styleUrls: ['./saled-products.component.scss']
})
export class SaledProductsComponent {

  @Input("saledProducts") saledProducts: SaleDetailsVM[] = [];

  constructor() { }

  ngOnInit(): void {
  }

}
