import { DatePipe } from '@angular/common';
import { Component, Input, ViewChild } from '@angular/core';
import { CashClosingDetailItem } from '../../../models/ComponentModels';
import { CashClosingVM, ShiftVM } from '../../../models/view-models';
import { CashClosingDetailsComponent } from '../cash-closing-details/cash-closing-details.component';

@Component({
  selector: 'app-cash-close',
  templateUrl: './cash-close.component.html',
  styleUrls: ['./cash-close.component.scss']
})
export class CashCloseComponent {

  @Input("cashClosing") cashClosing: CashClosingVM;
  @Input("formTitle") formTitle: string;
  @Input("isGeneral") isGeneral: boolean = false;
  @ViewChild("dateCmp", { read: CashClosingDetailsComponent, static: true })
  public dateCmp: CashClosingDetailsComponent;
  @ViewChild("salesCmp", { read: CashClosingDetailsComponent, static: true })
  public salesCmp: CashClosingDetailsComponent;
  @ViewChild("detailSalesCmp", { read: CashClosingDetailsComponent, static: true })
  public detailSalesCmp: CashClosingDetailsComponent;
  @ViewChild("cashInCmp", { read: CashClosingDetailsComponent, static: true })
  public cashInCmp: CashClosingDetailsComponent;
  @ViewChild("cashOutCmp", { read: CashClosingDetailsComponent, static: true })
  public cashOutCmp: CashClosingDetailsComponent;
  @ViewChild("returnsAndCancelationsCmp", { read: CashClosingDetailsComponent, static: true })
  public returnsAndCancelationsCmp: CashClosingDetailsComponent;
  @ViewChild("paymentsCmp", { read: CashClosingDetailsComponent, static: true })
  public paymentsCmp: CashClosingDetailsComponent;
  @ViewChild("depositsCmp", { read: CashClosingDetailsComponent, static: true })
  public depositsCmp: CashClosingDetailsComponent;
  @ViewChild("startingAndEndingCashCmp", { read: CashClosingDetailsComponent, static: true })
  public startingAndEndingCashCmp: CashClosingDetailsComponent;
  @ViewChild("cashCmp", { read: CashClosingDetailsComponent, static: true })
  public cashCmp: CashClosingDetailsComponent;
  resumeShifts: ShiftVM[] = [];

  constructor(private datePipe: DatePipe) { }

  setInfo() {
    if (!this.isGeneral) {
      this.setDate();
    } else {
      this.setDates();
    }
    this.setSales();
    this.setDetailSales();
    this.setCashin();
    this.setCashOut();
    this.setReturnsAndCancelations();
    this.setPayments();
    this.setDeposits();
    this.setStartingAndEndingCash();
    this.setCash();
  }

  setDate() {
    this.dateCmp.detail = {
      Header: '',
      ShowHeader: false,
      ShowTotal: false,
      Total: 0,
      DetailItems: [
        {
          Title: 'Fecha de Apertura',
          Value: new Date(this.cashClosing.Shift.CreateDate),
          IsDate: true
        },
        {
          Title: 'Hora de Apertura',
          Value: new Date(this.cashClosing.Shift.CreateDate),
          IsTime: true
        },
        { Title: 'Ventas en el día', Value: this.cashClosing.SalesCount, IsNumber: true },
      ]
    };
  }

  setDates() {
    let items: CashClosingDetailItem[] = [];
    this.resumeShifts.forEach(shift => {
      items.push({
        Title: shift.Username,
        Value: this.datePipe.transform(shift.CreateDate, 'dd/MM/yyyy HH:mm:ss')
      });
    });
    items.push({
      Title: 'Ventas totales',
      Value: this.cashClosing.SalesCount,
      IsNumber: true
    });
    this.dateCmp.detail = {
      Header: 'Fechas de turnos',
      ShowHeader: true,
      ShowTotal: false,
      Total: 0,
      DetailItems:items
    };
  }

  setSales() {
    this.salesCmp.detail = {
      Header: 'Ventas',
      ShowHeader: true,
      ShowTotal: true,
      Total: this.cashClosing.TotalSales,
      TotalTitle: 'Total de Ventas',
      DetailItems: [
        { Title: 'En efectivo', Value: this.cashClosing.TotalSalesOnCash, IsCurrency: true },
        { Title: 'Con tarjeta', Value: this.cashClosing.TotalSalesOnCard, IsCurrency: true },
        { Title: 'A crédito', Value: this.cashClosing.TotalSalesOnCredit, IsCurrency: true },
        { Title: 'Transferencia Bancaria', Value: this.cashClosing.TotalSalesOnEBank, IsCurrency: true },
        { Title: 'Con Vale', Value: this.cashClosing.TotalSalesOnVoucher, IsCurrency: true },
      ]
    };
  }

  setDetailSales() {
    let items: CashClosingDetailItem[] = [];
    this.cashClosing.TotalSalesByProductCategories.forEach(s => {
      items.push({
        Title: s.CategoryName,
        Value: s.Total,
        IsCurrency: true
      });
      this.detailSalesCmp.detail = {
        Header: 'Ventas por Departamento',
        ShowHeader: true,
        ShowTotal: false,
        Total: 0,
        DetailItems: items
      };
    });
  }

  setCashin() {
    let items: CashClosingDetailItem[] = [];
    this.cashClosing.CashInList.forEach(mov => {
      items.push({
        Title: mov.Concept,
        Value: mov.Amount,
        IsCurrency: true
      });
      this.cashInCmp.detail = {
        Header: 'Entradas de Efectivo',
        ShowHeader: true,
        ShowTotal: true,
        Total: this.cashClosing.TotalCashIn,
        TotalTitle: 'Total entradas',
        DetailItems: items
      };
    });
  }

  setCashOut() {
    let items: CashClosingDetailItem[] = [];
    this.cashClosing.CashOutList.forEach(mov => {
      items.push({
        Title: mov.Concept,
        Value: mov.Amount,
        IsCurrency: true
      });
      this.cashOutCmp.detail = {
        Header: 'Salidas de Efectivo',
        ShowHeader: true,
        ShowTotal: true,
        Total: this.cashClosing.TotalCashOut,
        TotalTitle: 'Total salidas',
        DetailItems: items
      };
    });
  }

  setReturnsAndCancelations() {
    this.returnsAndCancelationsCmp.detail = {
      Header: 'Devoluciones y Cancelaciones',
      ShowHeader: true,
      ShowTotal: false,
      Total: 0,
      DetailItems: [
        { Title: 'Total devoluciones', Value: this.cashClosing.TotalReturns, IsCurrency: true },
        { Title: 'Total cancelaciones', Value: this.cashClosing.TotalCancelations, IsCurrency: true },
      ]
    };
  }

  setPayments() {
    
    let items: CashClosingDetailItem[] = [];
    this.cashClosing.CustomersPaymentsList.forEach(pay => {
      items.push({
        Title: pay.CustomerName,
        Value: pay.Amount,
        IsCurrency: true
      });
    });
    this.paymentsCmp.detail = {
      Header: 'Pagos de Clientes (Liquidados)',
      ShowHeader: true,
      Total: this.cashClosing.TotalCustomerPayments,
      ShowTotal: true,
      TotalTitle: 'Total pagos',
      DetailItems: items
    };
  }

  setDeposits() {
    let items: CashClosingDetailItem[] = [];
    this.cashClosing.DepositsList.forEach(deposit => {
      items.push({
        Title: deposit.CustomerName,
        Value: deposit.Amount,
        IsCurrency: true
      });
    });
    this.depositsCmp.detail = {
      Header: 'Abonos de Clientes',
      ShowHeader: true,
      Total: this.cashClosing.TotalDeposits,
      ShowTotal: true,
      TotalTitle: 'Total abonos',
      DetailItems: items
    };
  }

  setStartingAndEndingCash() {
    this.startingAndEndingCashCmp.detail = {
      Header: 'Dinero en Caja',
      ShowHeader: true,
      ShowTotal: false,
      Total: 0,
      DetailItems: [
        { Title: 'Total de Ventas Efectivo', Value: this.cashClosing.EndingCash, IsCurrency: true },
        { Title: 'Fondo inicial', Value: this.cashClosing.StartingCash, IsCurrency: true },
      ]
    };
  }

  setCash() {
    let items: CashClosingDetailItem[] = [];
    this.cashClosing.CashEntries.forEach(item => {
      items.push({
        Title: item.Title,
        Value: item.Value,
        IsCurrency: true
      });
    });
    items.push({
      Title: "Total Entradas",
      Value: this.cashClosing.TotalRevenues,
      IsCurrency: true
    });
    items.push({
      Title: "Total Salidas",
      Value: this.cashClosing.TotalExpenses,
      IsCurrency: true
    });
    this.cashCmp.detail = {
      Header: 'Totales',
      ShowHeader: true,
      ShowTotal: false,
      Total: 0,
      DetailItems: items
    };
  }

  save() { }

  cancel() { }

}
