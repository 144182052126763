import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { LocalData } from '../../../models/LocalData';
import { PaymentMethodVM } from '../../../models/view-models';
import { PaymentMethodService } from '../../../services/payment-method.service';

@Component({
  selector: 'app-payment-method-button-selector',
  templateUrl: './payment-method-button-selector.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./payment-method-button-selector.component.scss']
})
export class PaymentMethodButtonSelectorComponent implements OnInit {

  @Input("includeCurrencyPaymentMethods") includeCurrencyPaymentMethods: boolean = false;

  public paymentMethods: PaymentMethodVM[];
  public data: PaymentMethodVM[];
  public selectedItem: PaymentMethodVM;
  disabled: boolean = true;

  @Output() onItemSelect: EventEmitter<PaymentMethodVM> = new EventEmitter<PaymentMethodVM>();
  @Input() hideCredit: boolean = false;
  @Input() setDefault: boolean = true;

  constructor(private dataService: PaymentMethodService) {
this.data = [];
    this.dataService.getPaymentMethodsList().subscribe((response) => {
      this.data = response;
    });
  }

  ngOnInit(): void {
    this.unselectAll();
    this.setDefaultSelection();
  }

  setDefaultSelection() {
    this.unselectAll();
    this.setItemByCode(LocalData.PAYMENT_METHOD_CASH);
  }

  hideCreditPaymentMethod() { }

  hideCurrencyPaymentMethod() { }

  hidePaymentMethod(code: string) {
    let crdt = this.data.find(item => item.Code === code);
    if (crdt) {
      let idx = this.data.indexOf(crdt);
      this.data.splice(idx, 1);
    }
  }

  sortByName() {
    this.data.sort((a, b) => {
      if (a.Name > b.Name) {
        return 1;
      } else if (a.Name < b.Name) {
        return -1;
      } else {
        return 0;
      }
    });
  }

  getItem(): PaymentMethodVM {
    if (this.selectedItem) {
      return this.selectedItem;
    }
    return null;
  }

  getItemId(): string {
    if (this.selectedItem) {
      return this.selectedItem.UUID;
    }
    return '';
  }

  getItemName(): string {
    if (this.selectedItem) {
    return this.selectedItem.Name;
    }
    return '';
  }

  getItemCode(): string {
    if (this.selectedItem) {
    return this.selectedItem.Code;
    }
    return '';
  }

  selectionChange(value: PaymentMethodVM) {
    const idx = this.data.findIndex(x => x.UUID === value.UUID);
    this.data[idx].Selected = true;
    this.selectedItem = value;
    this.onItemSelect.emit(this.selectedItem);
  }

  setItemById(uuid: string) {
    if (this.data && this.data.length > 0 && uuid != null && uuid != "") {
      this.selectedItem = this.data.find(item => item.UUID === uuid);
    }
  }

  setItemByCode(code: string) {
    if (this.data && this.data.length > 0 && code != null && code != "") {
      this.selectedItem = this.data.find(item => item.Code === code);
      const idx = this.data.findIndex(item => item.UUID === this.selectedItem.UUID);
      this.data[idx].Selected = true;
    }
  }

  reset() {
    this.setDefaultSelection();
  }

  selectedChange(e: boolean, pm: PaymentMethodVM): void {
    this.unselectAll();
    this.selectionChange(pm);
  }

  unselectAll = () => this.data.forEach(pm => pm.Selected = false);

  isHidden(code: string): boolean {
    if (code === LocalData.PAYMENT_METHOD_CREDIT) {
      return this.hideCredit;
    }
    return false;
  }

  


}
