import { Component, Output, EventEmitter, HostListener, ViewChild, ElementRef } from '@angular/core';
import { LoadingService } from '../../../../../common/components/loading/loading.service';
import { Ticket } from '../../../../../models/LocalModels';
import { ProductToSale, SavedTicketVM } from '../../../../../models/view-models';
import { SavedTicketService } from '../../../../../services/saved-ticket.service';
import { TicketsService } from '../../../../../services/tickets.service';

@Component({
  selector: 'modal-change-ticket',
  templateUrl: './change-ticket.component.html',
  styleUrls: ['./change-ticket.component.scss']
})
export class ChangeTicketComponent {

  showDialog: boolean = false;
  product: ProductToSale;
  tickets: Ticket[] = [];
  hasResults: boolean = false;

  @Output() changeTicketEmiter: EventEmitter<Ticket> = new EventEmitter<Ticket>();
  @Output() modalClose: EventEmitter<any> = new EventEmitter<any>();

  lista: string[] = ['Elemento 1', 'Elemento 2', 'Elemento 3', 'Elemento 4']; // Ejemplo de una lista de elementos
  selectedElementIndex = 0;
  @ViewChild('inputElement') inputElement: ElementRef;

  constructor(private ticketsService: TicketsService,
    private loading: LoadingService,
    private savedTicketService: SavedTicketService) { }

  public openDialog() {
    this.loading.show();
    this.tickets = [];
    this.savedTicketService.getAll()
      .subscribe((response: SavedTicketVM[]) => {
        if (response) {
          response.forEach(item => {
            this.tickets.push(JSON.parse(item.JsonInfo));
          });
          //this.tickets = this.ticketsService.getAllTickets();
        }
        this.hasResults = this.tickets.length > 0;
        this.loading.hide();
        this.showDialog = true;
        this.focusOnInput();
      }, (err) => {
        this.loading.hide();
      });
  }

  selectTicket(ticket: Ticket) {
    this.changeTicketEmiter.emit(ticket);
    this.showDialog = false;
  }

  closeDialog() {
    this.showDialog = false;
    this.modalClose.emit();
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    if (this.showDialog) {
      if (event.key === 'ArrowUp') {
        this.selectPreviousElement();
      } else if (event.key === 'ArrowDown') {
        this.selectNextElement();
      } else if (event.key === 'Enter') {
        if (this.hasResults) {
          this.selectTicket(this.tickets[this.selectedElementIndex]);
        }
      } else if (event.key === 'Escape') {
        this.closeDialog();
      }
    }
  }

  selectPreviousElement() {
    if (this.selectedElementIndex > 0) {
      this.selectedElementIndex--;
    }
  }

  selectNextElement() {
    if (this.selectedElementIndex < this.tickets.length - 1) {
      this.selectedElementIndex++;
    }
  }

  onElementSelected(element: string) {
    alert(element);
    this.focusOnInput();
    console.log('Elemento seleccionado:', element);
  }

  focusOnInput() {
    setTimeout(() => {
      this.inputElement.nativeElement.focus();
    }, 0);
  }





}
