import { Component, ElementRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProductToSale } from '../../../../../models/view-models';

@Component({
  selector: 'modal-price-consultation',
  templateUrl: './price-consultation.component.html',
  styleUrls: ['./price-consultation.component.scss']
})
export class PriceConsultationComponent {

  showDialog: boolean = false;
  dialogForm: FormGroup;
  product: ProductToSale;
  showProductDetails: boolean = false;
  @ViewChild("code") code: ElementRef;
  @ViewChild("closeButton") closeButton: ElementRef;

  @Output() searchCode: EventEmitter<string> = new EventEmitter<string>();
  @Output() modalClose: EventEmitter<any> = new EventEmitter<any>();

  disabledInput: boolean = false;

  constructor(private fb: FormBuilder) { }

  initDialogForm() {
    this.dialogForm = this.fb.group({
      Code: ['', [Validators.required]]
    });
  }
  focusOnCodeInput() {
    setTimeout(() => {
      this.code.nativeElement.focus();
    }, 0);
  }

  focusOnCloseButton() {
    setTimeout(() => {
      this.closeButton.nativeElement.focus();
    }, 0);
  }

  isValidForm(): boolean { return this.dialogForm.valid; }

  public openDialog() {
    this.showDialog = true;
    this.showProductDetails = false;
    this.initDialogForm();

    setTimeout(() => {
      this.code.nativeElement.focus();
    }, 0);
  }

  searchProduct() {
    if (this.isValidForm()) {
      let _code: string = this.dialogForm.value["Code"];
      this.searchCode.emit(_code);
    }
  }

  closeDialog() {
    this.showProductDetails = false;
    this.showDialog = false;
    this.modalClose.emit();
  }



}
