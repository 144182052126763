import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FilteredGridComponent } from '../../../../common/components/filtered-grid/filtered-grid.component';
import { LoadingService } from '../../../../common/components/loading/loading.service';
import { ProductCategorySelectorComponent } from '../../../../common/components/product-category-selector/product-category-selector.component';
import { AlertifyService } from '../../../../common/services/alertify.service';
import { CustomValidators } from '../../../../common/validators/CustomValidators';
import { CARDEXOperationType, TipoDeOperacionCARDEX } from '../../../../models/CARDEXOperationType';
import { CARDEXRecordType, TipoDeRegistroCARDEX } from '../../../../models/CARDEXRecordType';
import { CARDEXVM, CategoryVM, ProductVM } from '../../../../models/view-models';
import { CARDEXService } from '../../../../services/cardex.service';
import { ProductsService } from '../../../../services/products.service';

@Component({
  selector: 'inventory-products',
  templateUrl: './inventory-products.component.html',
  styleUrls: ['./inventory-products.component.scss']
})
export class InventoryProductsComponent implements OnInit {

  products: ProductVM[] = [];
  product: ProductVM;

  @ViewChild("grid", { read: FilteredGridComponent, static: false })
  public grid: FilteredGridComponent;

  @ViewChild("categorySelector", { read: ProductCategorySelectorComponent, static: true })
  public categorySelector: ProductCategorySelectorComponent;

  showDetails: boolean = false;

  @ViewChild("qty") qty: ElementRef;

  showInputDialog: boolean = false;
  inputForm: FormGroup;

  isUniqueProduct: boolean = false;

  constructor(private productsService: ProductsService,
    private cardexService: CARDEXService,
    private fb: FormBuilder,
    private alertify: AlertifyService,
    private loading: LoadingService) { }

  ngOnInit(): void {
    this.categorySelector.label = "Filtrar por Categoría";
    this.loadData();
  }

  loadData() {
    this.loading.show();
    this.productsService.getInventoryProducts()
      .subscribe((response: ProductVM[]) => {
        if (response) {
          this.products = response;
        }
        this.setGridOptions();
        this.loading.hide();
      }, (err) => {
        this.loading.hide();
      });
  }

  setGridOptions() {
    this.grid.setData(this.products);
    this.grid.options.toolbarTitle = "Inventario";
    this.grid.options.showDetailsButton = false;
    this.grid.options.showDeleteButton = false;
    this.grid.options.showEditButton = false;
    this.grid.options.hideActionButtons = true;
    this.grid.options.rowClick = true;
    this.grid.options.navigable = true;
    this.grid.columns = [
      { field: "Code", title: "Código" },
      { field: "Name", title: "Nombre", cssClass: 'text-center' },
      { field: "CategoryName", title: "Categoría", cssClass: 'text-center' },
      { field: "Quantity", title: "Existencia", cssClass: 'text-center' },
      { field: "UnitName", title: "Unidad de Medida", cssClass: 'text-center' },
    ];
    this.grid.filterableColumns = [
      { field: "Code", operator: 'eq' },
      { field: "Name", operator: 'contains' },
    ];
    this.grid.setGridOptions();
  }

  filterCategory(category: CategoryVM) {
    this.grid.onSpecialFilter("CategoryName", category.Name);
  }

  select(value: ProductVM) {
    this.product = value;
    this.isUniqueProduct = this.product.IsUnique;
    this.showDetails = true;
    setTimeout(() => {
      
    }, 0);
  }
  
  cancel() {
    this.showDetails = false;
    this.product = null;
  }

  closeInputDialog() {
    this.showInputDialog = false;
  }

  openInputDialog() {
    this.showInputDialog = true;
    this.initInputForm();
    this.focusOnQuantityInput();
  }

  focusOnQuantityInput() {
    setTimeout(() => {
      this.qty.nativeElement.focus();
      this.qty.nativeElement.select();
    }, 0);
  }

  initInputForm() {
    this.inputForm = this.fb.group({
      Quantity: [0, [Validators.required, CustomValidators.greaterThanZero]]
    });
  }

  isInputValidForm(): boolean {
    return this.inputForm.valid;
  }

  createInputRecord() {
    if (this.isInputValidForm()) {
      this.loading.show();
      const newRecord: CARDEXVM = Object.assign({
        ProductId: this.product.UUID,
        ProductCode: this.product.Code,
        ProductName: this.product.Name,
        CategoryId: this.product.CategoryId,
        CategoryName: this.product.CategoryName,
        UnitId: this.product.UnitId,
        UnitName: this.product.UnitName,
        RecordType: CARDEXRecordType.INPUT,
        RecordTypeName: TipoDeRegistroCARDEX.I,
        OperationType: CARDEXOperationType.INPUT,
        OperationTypeName: TipoDeOperacionCARDEX.I,
      }, this.inputForm.value);
      this.cardexService.insert(newRecord)
        .subscribe((response: CARDEXVM) => {
          if (response) {
            this.product.Quantity = this.product.Quantity + newRecord.Quantity;
            this.grid.updateRowByUUID(this.product);
            this.cancel();
            this.closeInputDialog();
          }
          this.loading.hide();
        }, (err) => {
          this.loading.hide();
        });
    }
  }

  

}
