import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ClientLoginService } from '../../../services/client-login.service';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss']
})
export class UnauthorizedComponent implements OnInit {

  constructor(private loginService: ClientLoginService,
    private router: Router) { }

  ngOnInit(): void {
  }

  logout() {
    this.loginService.logout();
    this.router.navigate(['/login']);
  }

}
