import { ValueConverter } from '@angular/compiler/src/render3/view/template';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoadingService } from '../../../common/components/loading/loading.service';
import { AlertifyService } from '../../../common/services/alertify.service';
import { PaymentMethodVM } from '../../../models/view-models';
import { PaymentMethodService } from '../../../services/payment-method.service';

@Component({
  selector: 'app-payment-methods',
  templateUrl: './payment-methods.component.html',
  styleUrls: ['./payment-methods.component.scss']
})
export class PaymentMethodsComponent implements OnInit {

  methods: PaymentMethodVM[] = [];
  method: PaymentMethodVM;
  entityForm: FormGroup;
  showForm: boolean = false;
  isEdition: boolean = false;

  @ViewChild("code") code: ElementRef;
  @ViewChild("name") name: ElementRef;

  public checked = true;

  constructor(private paymentMethodService: PaymentMethodService,
    private fb: FormBuilder,
    private alertify: AlertifyService,
    private loading: LoadingService) { }

  ngOnInit(): void {
    this.initForm();
    this.loadData();
  }

  initForm() {
    this.entityForm = this.fb.group({
      Code: ['', [Validators.required, Validators.minLength(2)]],
      Name: ['', [Validators.required, Validators.minLength(2)]],
      Active: [false],
      IsCurrency: [false],
    });
  }

  loadData() {
    this.loading.show();
    this.paymentMethodService.getAll()
      .subscribe((response: PaymentMethodVM[]) => {
        if (response) {
          this.methods = response;
        }
        this.loading.hide();
      }, (err) => {
        this.loading.hide();
      });
  }

  setFormVisibility = () => this.showForm = !this.showForm;

  addNew() {
    this.isEdition = false;
    this.setFormVisibility();
    setTimeout(() => {
      this.code.nativeElement.focus();
    }, 0);
  }

  isValidForm(): boolean { return this.entityForm.valid; }

  save() {
    if (this.isValidForm()) {
      this.loading.show();
      const entity: PaymentMethodVM = Object.assign(this.entityForm.value);
      this.paymentMethodService.insert(entity)
        .subscribe((response: PaymentMethodVM) => {
          if (response) {
            this.methods.push(response);
            this.cancel();
            this.loading.hide();
          }
        }, (err) => {
          this.loading.hide();
        });
    }
  }

  cancel() {
    this.initForm();
    this.isEdition = false;
    this.setFormVisibility();
  }

  edit(value: PaymentMethodVM) {
    this.method = value;
    this.entityForm.setValue({
      Code: this.method.Code,
      Name: this.method.Name,
      Active: this.method.Active,
      IsCurrency: this.method.IsCurrency,
    });
    this.isEdition = true;
    this.setFormVisibility();
    setTimeout(() => {
      this.name.nativeElement.focus();
    }, 0);
  }

  update() {
    if (this.isValidForm()) {
      this.loading.show();
      const entity: PaymentMethodVM = Object.assign({
        UUID: this.method.UUID
      }, this.entityForm.value);
      this.paymentMethodService.update(entity)
        .subscribe((response: PaymentMethodVM) => {
          if (response) {
            this.updateItem(entity);
            this.cancel();
            this.loading.hide();
          }
        }, (err) => {
          this.loading.hide();
        });
    }
  }

  delete(value: PaymentMethodVM) {
    this.alertify.confirm(
      `Eliminar método de pago ${value.Name}`,
      () => {
        this.paymentMethodService.delete(value.UUID)
          .subscribe((response: boolean) => {
            if (response) {
              this.deleteItem(value);
              this.loading.hide();
            }
          }, (err) => {
            this.loading.hide();
          });
      });
  }

  updateItem(value: PaymentMethodVM) {
    let last = this.methods.find(item => item.UUID === value.UUID);
    let idx = this.methods.indexOf(last);
    this.methods[idx] = value;
  }

  deleteItem(value: PaymentMethodVM) {
    let last = this.methods.find(item => item.UUID === value.UUID);
    let idx = this.methods.indexOf(last);
    this.methods.splice(idx, 1);
  }

  methodSwitch(current: boolean, value: PaymentMethodVM) {
    //alert(`Current: ${current}\n\nswitch:${value.Active}`);
    value.Active = current;
    this.loading.show();
    this.paymentMethodService.update(value)
      .subscribe((response: PaymentMethodVM) => {
        if (response) {
          this.updateItem(value);
          this.loading.hide();
        }
      }, (err) => {
        this.loading.hide();
      }); 
  }

}
