
<kendo-tabstrip>

  <kendo-tabstrip-tab [title]="'Créditos'" [selected]="true">
    <ng-template kendoTabContent>
      <br />
      <app-sales-on-credit></app-sales-on-credit>
    </ng-template>
  </kendo-tabstrip-tab>

  <kendo-tabstrip-tab [title]="'Saldos'" [selected]="false">
    <ng-template kendoTabContent>
      <br />
      <app-customers-info></app-customers-info>
    </ng-template>
  </kendo-tabstrip-tab>

</kendo-tabstrip>
