import { Component, OnInit, ViewChild } from "@angular/core";
import { LocalData } from "../models/LocalData";
import { RolPermissionService } from "../services/rol-permission.service";
import { RolService } from "../services/rol.service";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(private rolService: RolService,) {
    this.rolService.loadAndSaveData();
  }

  ngOnInit(): void { }

  

}
