import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AlertifyService } from '../common/services/alertify.service';
import { OperationResult, CustomerMovementVM, CustomerBalanceVM, SaleBalanceDto, CustomerStatementVM, DeleteCustomerMovement } from '../models/view-models';
import { map } from 'rxjs/operators';
import { ErrorService } from '../interceptors/error.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerMovementService {

  apiUrl: string = `${environment.apiUrl}/CustomerMovement`;

  constructor(private http: HttpClient,
    private error: ErrorService,
    private alertify: AlertifyService) { }

  insert(movement: CustomerMovementVM) {
    return this.http.post(this.apiUrl, movement)
      .pipe(map((response: OperationResult<CustomerMovementVM>) => {
        if (response.Success) {
          this.alertify.success("Registro creado exitosamente");
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }

  createPay(movement: CustomerMovementVM) {
    const uri = `${this.apiUrl}/CreatePay`;
    return this.http.post(uri, movement)
      .pipe(map((response: OperationResult<CustomerMovementVM>) => {
        if (response.Success) {
          this.alertify.success("Registro creado exitosamente");
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }

  createDeposit(movement: CustomerMovementVM) {
    const uri = `${this.apiUrl}/CreateDeposit`;
    return this.http.post(uri, movement)
      .pipe(map((response: OperationResult<CustomerMovementVM>) => {
        if (response.Success) {
          this.alertify.success("Registro creado exitosamente");
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }

  createMultipleDeposit(movement: CustomerMovementVM) {
    const uri = `${this.apiUrl}/CreateMultipleDeposit`;
    return this.http.post(uri, movement)
      .pipe(map((response: OperationResult<CustomerMovementVM>) => {
        if (response.Success) {
          this.alertify.success("Registro creado exitosamente");
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }

  update(movement: CustomerMovementVM) {
    return this.http.put(this.apiUrl, movement)
      .pipe(map((response: OperationResult<CustomerMovementVM>) => {
        if (response.Success) {
          this.alertify.success("Registro editado exitosamente");
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }

  deletePay(dcm: DeleteCustomerMovement) {
    const uri = `${this.apiUrl}/DeletePay`;
    return this.http.post(uri, dcm)
      .pipe(map((response: OperationResult<boolean>) => {
        if (response.Success) {
          this.alertify.success("Registro eliminado exitosamente");
          return response.Data;
        } else {
          this.error.logError(response);
          return false;
        }
      }));
  }

  getAll() {
    return this.http.get(this.apiUrl)
      .pipe(map((response: OperationResult<CustomerMovementVM[]>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }

  getById(uuid: string) {
    const uri = `${this.apiUrl}/GetByIdentifier?uuid=${uuid}`;
    return this.http.get(uri)
      .pipe(map((response: OperationResult<CustomerMovementVM>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }

  getSaleBalance(saleId: string) {
    const uri = `${this.apiUrl}/GetSaleBalance?saleId=${saleId}`;
    return this.http.get(uri)
      .pipe(map((response: OperationResult<SaleBalanceDto>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }

  getFromCustomer(id: string) {
    const uri = `${this.apiUrl}/GetFromCustomer?customerId=${id}`;
    return this.http.get(uri)
      .pipe(map((response: OperationResult<CustomerMovementVM[]>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }

  getCustomersBalance() {
    const uri = `${this.apiUrl}/GetCustomersBalance`;
    return this.http.get(uri)
      .pipe(map((response: OperationResult<CustomerBalanceVM[]>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }

  getCustomerStatement(id: string) {
    const uri = `${this.apiUrl}/GetCustomerStatement?customerId=${id}`;
    return this.http.get(uri)
      .pipe(map((response: OperationResult<CustomerStatementVM>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }

  getCustomerSalesBalances(customerId:string) {
    const uri = `${this.apiUrl}/GetCustomerSalesBalances?customerId=${customerId}`;
    return this.http.get(uri)
      .pipe(map((response: OperationResult<SaleBalanceDto[][]>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.error.logError(response);
          return null as any;
        }
      }));
  }

  setMovementTypeName(movementType: string): string {
    switch (movementType) {
      case 'B': return "Compra";
        break;
      case 'P': return "Pago";
        break;
      case 'D': return "Abono";
        break;
      case 'C': return "Cancelación";
        break;
      case 'M': return "Abono múltiple";
        break;
      default: return "";
        break;
    }
    return "";
  }
}
