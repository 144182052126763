import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../interceptors/auth.guard';
import { RolGuard } from '../interceptors/rol.guard';
import { AppLogsComponent } from './components/app-logs/app-logs.component';
import { CategoriesComponent } from './components/categories/categories.component';
import { CurrenciesComponent } from './components/currencies/currencies.component';
import { CustomizeSettingsComponent } from './components/customize-settings/customize-settings.component';
import { ExternalCredentialsComponent } from './components/external-credentials/external-credentials.component';
import { GeneralSettingsComponent } from './components/general-settings/general-settings.component';
import { PaymentMethodsComponent } from './components/payment-methods/payment-methods.component';
import { ProductsSettingsComponent } from './components/products-settings/products-settings.component';
import { RemoveRecordsComponent } from './components/remove-records/remove-records.component';
import { RolPermissionsComponent } from './components/rol-permissions/rol-permissions.component';
import { SandBoxComponent } from './components/sand-box/sand-box.component';
import { ShiftsComponent } from './components/shifts/shifts.component';
import { TicketSettingsComponent } from './components/ticket-settings/ticket-settings.component';
import { UnitsComponent } from './components/units/units.component';
import { UsersComponent } from './components/users/users.component';
import { SettingsComponent } from './settings.component';

const routes: Routes = [
  {
    path: '',
    component: SettingsComponent,
    canActivate: [AuthGuard, RolGuard],
    data: {
      name: 'Configuración',
    },
    children: [
      { path: 'general-settings', component: GeneralSettingsComponent },
      { path: 'categories', component: CategoriesComponent },
      { path: 'units', component: UnitsComponent },
      { path: 'payment-methods', component: PaymentMethodsComponent },
      { path: 'users', component: UsersComponent },
      { path: 'rol-permissions', component: RolPermissionsComponent },
      { path: 'shifts', component: ShiftsComponent },
      { path: 'products-settings', component: ProductsSettingsComponent },
      { path: 'external-credentials', component: ExternalCredentialsComponent },

      { path: 'customize-settings', component: CustomizeSettingsComponent },
      { path: 'ticket-settings', component: TicketSettingsComponent },
      { path: 'currencies', component: CurrenciesComponent },
      { path: 'app-logs', component: AppLogsComponent },
      { path: 'remove-records', component: RemoveRecordsComponent},
      { path: 'dev-sand-box', component: SandBoxComponent },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SettingsRoutingModule { }
