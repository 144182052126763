import { Component, OnInit } from '@angular/core';
import { LocalData } from '../../../models/LocalData';
import { RolVM } from '../../../models/view-models';
import { RolService } from '../../../services/rol.service';

@Component({
  selector: 'app-multiple-rol-selector',
  templateUrl: './multiple-rol-selector.component.html',
  styleUrls: ['./multiple-rol-selector.component.scss']
})
export class MultipleRolSelectorComponent implements OnInit {

  roles: RolVM[] = [];
  selectedRoles: RolVM[] = [];

  constructor() {

  }

  ngOnInit(): void {
    this.loadData();

  }

  loadData() {
    this.roles = JSON.parse(localStorage.getItem(LocalData.ROLES));
    this.reset();
  }

  reset() {
    this.roles.forEach(item => {
      item.Selected = false;
    });
  }

  selectAll() {
    this.roles.forEach(item => {
      item.Selected = true;
    });
  }

  select(rol: RolVM) {
    rol.Selected = !rol.Selected;
  }

  isValid(): boolean {
    if (this.roles.length > 0) {
      return this.roles.filter(item => item.Selected === true).length > 0;
    }
    return false;
  }

  getSelectedCodes(): string[] {
    let result: string[] = [];

    if (!this.isValid) 
      return null;

    this.roles.forEach(rol => {
      if (rol.Selected) {
        result.push(rol.Code);
      }
    });

    return result;
  }

  setSelectedCodes(_roles: string[]) {
    //if (this.roles) {
    //  _roles.forEach(rol => {
    //    this.roles.find(item => item.Code === rol).Selected = true;
    //  });
    //}
    this.roles.forEach(r => {
      if (_roles.includes(r.Code)) {
        r.Selected = true;
      }
    });
  }

}
