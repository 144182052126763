import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AlertifyService } from '../common/services/alertify.service';
import { CashCloseVM, CashClosingVM, MultipleShiftCashClosing, OperationResult } from '../models/view-models';
import { map } from 'rxjs/operators';
import { ErrorService } from '../interceptors/error.service';

@Injectable({
  providedIn: 'root'
})
export class CashClosingService {

  apiUrl: string = `${environment.apiUrl}/CashClosing`;

  constructor(private http: HttpClient,
    private error: ErrorService,
    private alertify: AlertifyService) { }

  getAll() {
    return this.http.get(this.apiUrl).pipe(
      map((response: OperationResult<CashCloseVM[]>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }

  getSingleShiftCashClose() {
    const uri = `${this.apiUrl}/GetSingleShiftCashClose`;
    return this.http.get(uri).pipe(
      map((response: OperationResult<CashCloseVM[]>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }
  
  getMultipleShiftCashClose() {
    const uri = `${this.apiUrl}/GetMultipleShiftCashClose`;
    return this.http.get(uri).pipe(
      map((response: OperationResult<CashCloseVM[]>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }

  getCashClosing(date: Date) {
    let dt: string = this.formatDateToString(date);
    const uri = `${this.apiUrl}/GetCashClosing?date=${dt}`;
    return this.http.get(uri).pipe(
      map((response: OperationResult<CashClosingVM>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }

  getCashClosingFromShift(shiftId: string) {
    const uri = `${this.apiUrl}/GetCashClosingFromShift?shiftId=${shiftId}`;
    return this.http.get(uri).pipe(
      map((response: OperationResult<CashClosingVM>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }

  create(cashClosing: CashClosingVM) {
    return this.http.post(this.apiUrl, cashClosing).pipe(
      map((response: OperationResult<CashCloseVM>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }
  
  createMultipleShiftCashclose(cashClosing: CashClosingVM) {
    const uri = `${this.apiUrl}/CreateMultipleShiftCashclose`;
    return this.http.post(uri, cashClosing).pipe(
      map((response: OperationResult<CashCloseVM>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }

  private formatDateToString(date: Date): string {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} 12:00:00`;
  }

  getCashClosingFromMultipleShifts(shiftIds: string[]) {
    const uri = `${this.apiUrl}/GetCashClosingFromMultipleShifts`;
    return this.http.post(uri, shiftIds).pipe(
      map((response: OperationResult<MultipleShiftCashClosing>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }

  getFromIdentifier(uuid: string) {
    const uri = `${this.apiUrl}/GetFromIdentifier?uuid=${uuid}`;
    return this.http.get(uri).pipe(
      map((response: OperationResult<MultipleShiftCashClosing>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }
  
  getByIdentifier(uuid: string) {
    const uri = `${this.apiUrl}/GetByIdentifier?uuid=${uuid}`;
    return this.http.get(uri).pipe(
      map((response: OperationResult<CashCloseVM>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }

}
