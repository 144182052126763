
<div class="jumbotron text-center"
     style="padding-top: 25px; padding-bottom: 25px;">

  <i class="fa fa-warning fa-5x"></i>
  <br /><br /><br />

  <h1>
    Acceso Denegado
  </h1>
  <br />
  <p>
    No tiene permisos para realizar esta acción o visitar esta página.
  </p>
</div>

<div class="text-center">
  <div class="btn-group">
    <button class="btn btn-primary"
            (click)="back()">
      Ir atrás <i class="fa fa-angle-double-left"></i>
    </button>
    <button class="btn btn-primary"
            (click)="logout()">
      Cerrar Sesión
    </button>
  </div>
</div>
