import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CurrencyVM } from '../../../models/view-models';
import { CurrencyService } from '../../../services/currency.service';

@Component({
  selector: 'app-currency-selector',
  templateUrl: './currency-selector.component.html',
  styleUrls: ['./currency-selector.component.scss']
})
export class CurrencySelectorComponent implements OnInit {

  label: string = "Moneda";

  public data: CurrencyVM[] = [];
  public selectedItem: CurrencyVM;
  disabled: boolean = true;

  public comboForm: FormGroup = new FormGroup({
    comboValue: new FormControl()
  });

  @Output() selectCurrency: EventEmitter<CurrencyVM> = new EventEmitter<CurrencyVM>();

  constructor(private dataService: CurrencyService) {
    
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.dataService.getAll()
      .subscribe((response: CurrencyVM[]) => {
        if (response) {
          this.data = response;
        }
        this.disabled = this.data.length <= 0;
      });
  }

  isValidForm(): boolean {
    return this.comboForm.valid;
  }

  getItem(): CurrencyVM {
    if (this.isValidForm()) {
      return this.selectedItem;
    }
    return null;
  }

  getItemId(): string {
    if (this.isValidForm()) {
      return this.selectedItem.UUID;
    }
    return "";
  }

  getItemName(): string {
    if (this.isValidForm()) {
      return this.selectedItem.Name;
    }
    return "";
  }

  selectionChange(value: CurrencyVM) {
    this.selectedItem = value;
    this.selectCurrency.emit(this.selectedItem);
  }

  setItemById(uuid: string) {
    if (this.data && this.data.length > 0 && uuid != null && uuid != "") {
      this.selectedItem = this.data.find(item => item.UUID === uuid);
      this.comboForm.setValue({
        comboValue: this.selectedItem
      });
    }
  }

  reset() {
    this.comboForm.reset();
    this.selectedItem = null;
  }


}
