import { Component, OnInit, ViewChild } from '@angular/core';
import { GenericGridComponent } from '../../../common/components/generic-grid/generic-grid.component';
import { ListDetailsComponent } from '../../../common/components/list-details/list-details.component';
import { LoadingService } from '../../../common/components/loading/loading.service';
import { AlertifyService } from '../../../common/services/alertify.service';
import { ShiftVM } from '../../../models/view-models';
import { ShiftService } from '../../../services/shift.service';

@Component({
  selector: 'app-shifts',
  templateUrl: './shifts.component.html',
  styleUrls: ['./shifts.component.scss']
})
export class ShiftsComponent implements OnInit {

  shifts: ShiftVM[] = [];
  shift: ShiftVM;

  @ViewChild("grid", { read: GenericGridComponent })
  public grid: GenericGridComponent;

  showDetails: boolean = false;

  @ViewChild("details", { read: ListDetailsComponent })
  public details: ListDetailsComponent;

  canOpenShif: boolean = true;

  constructor(private shiftService: ShiftService,
    private alertify: AlertifyService,
    private loading: LoadingService) { }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.loading.show();
    this.shiftService.getAll()
      .subscribe((response: ShiftVM[]) => {
        if (response) {
          response.forEach(s => {
            if (s.Active) 
              this.canOpenShif = false;
          });
          this.shifts = response;
        }
        this.setGridOptions();
        this.loading.hide();
      }, (err) => {
        this.loading.hide();
      });
  }

  setGridOptions() {
    this.grid.setData(this.shifts);
    this.grid.options.toolbarTitle = "Turnos";
    this.grid.options.hideActionButtons = true;
    this.grid.options.rowClick = true;
    this.grid.columns = [
      { field: "CreateDate", title: "Fecha de apertura", dateType: true },
      { field: "CreateDate", title: "Hora de apertura", timeType: true },
      { field: "Username", title: "Usuario" },
      { field: "Active", title: "Activo", booleanType: true },
    ];
    this.grid.setGridOptions();
  }

  select(value: ShiftVM) {
    this.shift = value;
    this.details.items = [
      { Title: "Fecha de Apertura", Value: this.shift.CreateDate, IsDate: true },
      { Title: "Hora de Apertura", Value: this.shift.CreateDate, IsTime: true },
      { Title: "Usuario", Value: this.shift.Username },
      { Title: "Activo", Value: this.shift.Active, IsBoolean: true },
    ];
    this.showDetails = true;
  }

  closeShift() {
    this.alertify.confirm("¿Desea cerrar este turno?",
      () => {
        this.loading.show();
        this.shiftService.closeActiveShift(this.shift)
          .subscribe((response: boolean) => {
            if (response) {
              this.shift.Active = false;
              this.grid.updateRowByUUID(this.shift);
            }
            this.cancel();
            this.loading.hide();
          }, (err) => {
            this.loading.hide();
          });
      });
  }

  openShift() {
    this.alertify.confirm("¿Desea abrir este turno?",
      () => {
        this.loading.show();
        this.shiftService.openShift(this.shift)
          .subscribe((response: boolean) => {
            if (response) {
              this.shift.Active = true;
              this.grid.updateRowByUUID(this.shift);
            }
            this.cancel();
            this.loading.hide();
          }, (err) => {
            this.loading.hide();
          });
      });
  }

  cancel() {
    this.showDetails = false;
    this.details.items = [];
    this.shift = null;
  }

}
