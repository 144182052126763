
<kendo-dialog title="Salida de Efectivo"
              *ngIf="showDialog"
              (close)="closeDialog()"
              [minWidth]="250"
              [width]="450">

  <form [formGroup]="dialogForm"
        class="__entityForm">

    <kendo-floatinglabel text="Monto">
      <input kendoTextBox name="Amount"
             #amount
             class="form-control"
             type="number"
             aria-label="Monto"
             placeholder="Monto"
             formControlName="Amount"
             (keydown.enter)="conceptFocus($event)" />
    </kendo-floatinglabel>

    <kendo-floatinglabel text="Concepto">
      <textarea kendo-textarea name="Concept"
                class="form-control"
                #conceptInput
                aria-label="Concepto"
                placeholder="Concepto"
                formControlName="Concept"
                (keydown.enter)="saveFocus($event)"></textarea>
    </kendo-floatinglabel>

    <hr />

    <kendo-dialog-actions>
      <div class="d-flex justify-content-between align-items-center">
        <button class="btn btn-primary"
                #saveButton
                type="submit"
                [disabled]="!isValidForm()"
                (click)="createMovement()">
          Agregar
        </button>
        <button class="btn btn-primary"
                type="button"
                (click)="closeDialog()">
          Cancelar
        </button>
      </div>
    </kendo-dialog-actions>
  </form>

</kendo-dialog>
