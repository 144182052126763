
<div class="__headerContainer"
     *ngIf="showHeader">
  <div class="row {{ columnsClass }}">
    <div class="col __header"
         *ngFor="let item of items"
         (click)="itemClick(item)"
         [ngClass]="{'__pointer': item.Pointer}">
      <div class="__headerTitle">
        {{ item.Title }}
      </div>
      <div class="__headerValue"
           *ngIf="!item.IsCurrency && !item.IsDate && !item.IsTime && !item.IsNumber && !item.IsBoolean && !item.IsDateTime">
        {{ item.Value }}
      </div>
      <div class="__headerValue"
           *ngIf="item.IsCurrency">
        {{ item.Value | currency }}
      </div>
      <div class="__headerValue"
           *ngIf="item.IsDate">
        {{ item.Value | date: 'dd/MM/yyyy' }}
      </div>
      <div class="__headerValue"
           *ngIf="item.IsTime">
        {{ item.Value | date: 'HH:mm:ss' }}
      </div>
      <div class="__headerValue"
           *ngIf="item.IsDateTime">
        {{ item.Value | date: 'dd/MM/yyyy HH:mm:ss' }}
      </div>
      <div class="__headerValue"
           *ngIf="item.IsNumber">
        {{ item.Value | value }}
      </div>
      <div class="__headerValue"
           *ngIf="item.IsBoolean">
        <span *ngIf="item.Value">SI</span>
        <span *ngIf="!item.Value">NO</span>
      </div>
    </div>
  </div>
</div>
