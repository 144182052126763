import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reprint-last-ticket',
  templateUrl: './reprint-last-ticket.component.html',
  styleUrls: ['./reprint-last-ticket.component.scss']
})
export class ReprintLastTicketComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
