import { Component, ViewChild, AfterViewInit, Output, EventEmitter } from "@angular/core";
import { FormGroup, FormControl } from '@angular/forms';
import { tap, delay, map } from "rxjs/operators";
import { AutoCompleteComponent } from "@progress/kendo-angular-dropdowns";
import { ProductToSale, ProductVM, SimpleProduct } from '../../../models/view-models';
import { ProductsService } from '../../../services/products.service';
import { LoadingService } from '../loading/loading.service';
import { ScaleProduct } from "../../../models/ComponentModels";

@Component({
  selector: 'app-product-search',
  templateUrl: './product-search.component.html',
  styleUrls: ['./product-search.component.scss'],
  providers: [ProductsService]
})
export class ProductSearchComponent implements AfterViewInit {

  public source: Array<SimpleProduct> = [];
  public data: Array<SimpleProduct> = [];
  simpleProduct: SimpleProduct;
  product: ProductVM;
  productToSale: ProductToSale;
  scaleProduct: ScaleProduct;

  @ViewChild("prod", { static: false })
  public prod: AutoCompleteComponent;

  public ProductForm: FormGroup = new FormGroup({
    selectedProduct: new FormControl()
  });
  @Output() onSimpleProductSelect: EventEmitter<ProductToSale> = new EventEmitter<ProductToSale>();
  @Output() onScaleProductSelect: EventEmitter<ProductToSale> = new EventEmitter<ProductToSale>();

  constructor(private productService: ProductsService,
    private loading: LoadingService) {

    this.loading.show();
    this.productService.getSimpleProducts()
      .subscribe((response: SimpleProduct[]) => {
        if (response) {
          this.source = response;
          this.loading.hide();
        }
      });
  }

  ngAfterViewInit(): void {
    const contains = (searchTerm: string) => (item: SimpleProduct) =>
      item.Name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
      || item.Code.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1;

    this.prod.filterChange
      .asObservable()
      .pipe(
        tap(() => (this.prod.loading = true)),
        delay(5000),
        map((searchTerm) => this.source.filter(contains(searchTerm)))
      )
      .subscribe((data) => {
        this.data = data;
        this.prod.loading = false;
      });
  }

  onValueChange(value) {
    let productCode: string = this.getProductCode(value);

    if (this.isScaleProduct(productCode)) {
      this.getScaleProductInfo();
    } else {
      this.getSimpleProductInfo(productCode);
    }
  }

  isValidForm(): boolean {
    return this.ProductForm.valid;
  }

  searchProduct() {
    if (this.isValidForm()) {
      let productCode = this.getProductCode(this.ProductForm.value["selectedProduct"]);
      if (this.isScaleProduct(productCode)) {
        this.getScaleProductInfo();
      } else { // if is scale product: return scale product
        this.getSimpleProductInfo(productCode);
      }
    }
  }

  reset() {
    this.ProductForm.reset();
    this.prod.focus();
  }

  focusOnInput() {
    this.prod.focus();
  }

  inputBlur() {
    this.prod.blur();
  }

  getSimpleProductInfo(productCode: string) {
    this.loading.show();
    this.productService.getByCode(productCode)
      .subscribe((response: ProductVM) => {
        if (response) {
          this.onSimpleProductSelect.emit(this.productService.createProductToSale(response, 0));
        }
        this.reset();
        this.loading.hide();
      });
  }

  getScaleProductInfo() {
    this.loading.show();
    this.productService.getByCode(this.scaleProduct.ProductCode)
      .subscribe((response: ProductVM) => {
        if (response) {
          this.onScaleProductSelect.emit(this.productService.createProductToSale(response, this.scaleProduct.Weight));
        }
        this.reset();
        this.loading.hide();
      });
  }

  getProductCode(value: string): string {
    let code: string = "";

    if (value.includes('|')) {
      let tmp = value.split('|');
      code = tmp[1].trim();
    } else {
      code = value.trim();
    }
    return code;
  }

  isScaleProduct(productCode: string): boolean {
    if (productCode.length === 13) {
      this.scaleProduct = this.decomposeCode(productCode);
      if (this.scaleProduct.StartingCharacter === '2'
        //&& (this.scaleProduct.EndingCharacter === '5'
        //  || this.scaleProduct.EndingCharacter === '6'
        //  || this.scaleProduct.EndingCharacter === '9')
      ) {
        return true;
      }
      return false;
    }
    return false;
  }

  private decomposeCode(code: string): ScaleProduct {
    return {
      StartingCharacter: code.substring(0, 1),
      ProductCode: this.removeZeros(code.substring(1, 7)),
      Weight: this.calculateWeight(code.substring(8, 12)),
      EndingCharacter: code.substring(12, 13)
    };
  }

  private removeZeros(cadena: string): string {
    let index = 0;
    while (index < cadena.length && cadena.charAt(index) === "0") {
      index++;
    }
    if (index === cadena.length) {
      return "";
    }
    return cadena.substring(index);
  }

  private calculateWeight(value: string): number {
    let Weight: number = parseFloat(value);
    return Weight / 1000;
  }

  
}
