import { Component, OnInit } from '@angular/core';

export interface ListDetailsItem {
  Title: string;
  Value: any;
  IsCurrency?: boolean;
  IsDate?: boolean;
  IsTime?: boolean;
  IsBoolean?: boolean;
}

@Component({
  selector: 'app-list-details',
  templateUrl: './list-details.component.html',
  styleUrls: ['./list-details.component.scss']
})
export class ListDetailsComponent implements OnInit {

  public items: ListDetailsItem[] = [];

  constructor() { }

  ngOnInit(): void {
  }

}
