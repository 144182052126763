import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TicketSizeVM } from '../../../models/view-models';
import { TicketSettingsService } from '../../../services/ticket-settings.service';
import { TicketsService } from '../../../services/tickets.service';

@Component({
  selector: 'app-ticket-size-selector',
  templateUrl: './ticket-size-selector.component.html',
  styleUrls: ['./ticket-size-selector.component.scss']
})
export class TicketSizeSelectorComponent implements OnInit {

  label: string = "Tamaño de Ticket";

  public data: TicketSizeVM[] = [];
  public selectedItem: TicketSizeVM;
  disabled: boolean = true;

  public comboForm: FormGroup = new FormGroup({
    comboValue: new FormControl()
  });

  @Output() selectSize: EventEmitter<TicketSizeVM> = new EventEmitter<TicketSizeVM>();

  constructor(private dataService: TicketSettingsService) { }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.data = this.dataService.getTicketSizes();
    /*this.setItemByValue('M');*/
    this.disabled = this.data.length <= 0;
  }

  isValidForm(): boolean {
    return this.comboForm.valid;
  }

  getItem(): TicketSizeVM{
    if (this.isValidForm()) {
      return this.selectedItem;
    }
    return null;
  }

  getItemValue(): string {
    if (this.isValidForm()) {
      return this.selectedItem.Value;
    }
    return "";
  }

  getItemName(): string {
    if (this.isValidForm()) {
      return this.selectedItem.Name;
    }
    return "";
  }

  selectionChange(value: TicketSizeVM) {
    this.selectedItem = value;
    this.selectSize.emit(this.selectedItem);
  }

  setItemByValue(value: string) {
    if (this.data && this.data.length > 0) {
      if (value != null && value != "") {
        this.selectedItem = this.data.find(item => item.Value === value);
        this.comboForm.setValue({
          comboValue: this.selectedItem
        });
      } else {
        this.setItemByValue('M');
      }
    }
  }

  reset() {
    this.comboForm.reset();
    this.selectedItem = null;
  }


}
