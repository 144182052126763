import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { AuthenticationService } from '../../../interceptors/authentication.service';
import { LoginResponse } from '../../../models/view-models';

export interface MenuItem {
  title: string;
  url: string;
};

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  appName: string = environment.appName;

  collapse: boolean = true;
  menuItems: MenuItem[] = [];
  username: string = "";

  userCollapse: boolean = true;

  constructor(private router: Router,
    private authService: AuthenticationService) {
    let usr: LoginResponse = this.authService.SessionUserValue();
    this.username = usr.User;
    this.createMenu();
  }

  ngOnInit(): void {
  }

  menuItemClick(item: MenuItem) {
    this.collapse = true;
    //this.router.navigate([item.url]);
  }

  createMenu() {
    this.menuItems = [
      { title: "Ventas", url: "/home/pos/sales" },
      { title: "Créditos", url: "/home/pos/credits" },
      { title: "Productos", url: "/home/products" },
      { title: "Inventario", url: "/home/inventory" },
      { title: "Clientes", url: "/home/customers" },
      //{ title: "Tickets", url: "/home/tickets" },
      //{ title: "Compras", url: "/home/purchases" },
      //{ title: "Facturas", url: "/home/bills" },
      { title: "Corte", url: "/home/cash-closing" },
      { title: "Reportes", url: "/home/reports" },
      //{ title: "Pago de Servicios", url: "/home/service-pay" },
      //{ title: "Configuración", url: "/settings/general-settings" },
    ];
  }

  logout() {
this.authService.logout();
  }

}
