
<h3>
  Inventario
</h3>

<hr /><br />

<kendo-tabstrip>

  <kendo-tabstrip-tab [title]="'Existencias'" [selected]="true">
    <ng-template kendoTabContent>
      <br />
      <inventory-products></inventory-products>
    </ng-template>
  </kendo-tabstrip-tab>

  <kendo-tabstrip-tab [title]="'CARDEX'" [selected]="false">
    <ng-template kendoTabContent>
      <br />
      <cardex></cardex>
    </ng-template>
  </kendo-tabstrip-tab>

</kendo-tabstrip>
