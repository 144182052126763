
<div class="login-container">
  <div class="login">
    <div class="jumbotron text-center"
         *ngIf="!showError">
      <i class="fa fa-spinner fa-spin fa-3x"></i>
      <br />
      <b>Conectando...</b>
    </div>
    <div class="jumbotron text-center"
         *ngIf="showError">
      <i class="fa fa-warning fa-3x"></i>
      <br />
      <b>{{ errorMessage }}</b>
    </div>
  </div>
</div>
