import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AlertifyService } from '../common/services/alertify.service';
import { CARDEXVM, OperationResult, ProductToSale, SaleDetailsVM, SimpleProduct } from '../models/view-models';
import { map } from 'rxjs/operators';
import { ErrorService } from '../interceptors/error.service';

@Injectable({
  providedIn: 'root'
})
export class CARDEXService {

  apiUrl: string = `${environment.apiUrl}/CARDEX`;

  constructor(private http: HttpClient,
    private error: ErrorService,
    private alertify: AlertifyService) { }

  insert(cardex: CARDEXVM) {
    return this.http.post(this.apiUrl, cardex)
      .pipe(map((response: OperationResult<CARDEXVM>) => {
        if (response.Success) {
          this.alertify.success("Registro creado");
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }

  getAll() {
    return this.http.get(this.apiUrl)
      .pipe(map((response: OperationResult<CARDEXVM[]>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }
}
