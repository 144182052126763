import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent implements OnInit{

  @Input("placeholder") placeholder: string;
  searchField: string = '';
  @Output() searchChange: EventEmitter<string> = new EventEmitter<string>();

  ngOnInit(): void {
  }

  onSearchChange(value: string) {
    this.searchField = value;
    this.searchChange.emit(value);
  }


  

}
