import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { GridDataResult, DataStateChangeEvent, SelectableSettings, SelectableMode } from '@progress/kendo-angular-grid';
import { Observable } from 'rxjs';
import { State, process } from '@progress/kendo-data-query';
import { GridOptions, gridHeader } from '../../../models/options';

@Component({
  selector: 'app-generic-grid',
  templateUrl: './generic-grid.component.html',
  styleUrls: ['./generic-grid.component.scss']
})
export class GenericGridComponent implements OnInit {

  public showGrid: boolean = false;
  public hasRows: boolean = false;

  options: GridOptions = {
    paging: true,
    navigable: false,
    perPage: 15,
    showToolbar: true,
    showSearchField: true,
    showExportExcelButton: true,
    showExportPDFButton: true,
    toolbarTitle: "",
    hideActionButtons: false,
    showEditButton: true,
    showDeleteButton: true,
    showDetailsButton: false,
    selectableCheckbox: false,
  };

  public columns: gridHeader[] = [];
  public items: any[] = [];
  public bindingType: String = 'array';
  public gridData: any;

  public gridDataResult: GridDataResult;

  public state: State;

  @Output() rowToEdit: EventEmitter<any> = new EventEmitter<any>();
  @Output() rowToDelete: EventEmitter<any> = new EventEmitter<any>();
  @Output() rowToSelect: EventEmitter<any> = new EventEmitter<any>();
  @Output() onCellClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() rowToPrint: EventEmitter<any> = new EventEmitter<any>();
  @Output() rowToRestore: EventEmitter<any> = new EventEmitter<any>();

  filters: any[] = [];
  specialFilters: any[] = [];

  defaultDate: Date = new Date("2000-01-01T00:00:00");

  public selectableSettings: SelectableSettings = {
    enabled: this.options.selectableCheckbox,
    checkboxOnly: true,
    mode: 'multiple',
    cell: true
  };
  selectedRows: string[] = [];

  constructor() { }

  ngOnInit(): void { }

  setData(data: any) {
    this.items = data;
  }

  loadGridData() {
    this.state = {
      skip: 0,
      take: this.options.perPage,
      filter: {
        logic: "or",
        filters: this.filters
      }
    };
    this.gridData = process(this.items, this.state);
  }

  setGridOptions() {
    if (!this.options.paging) {
      this.options.perPage = this.items.length + 1;
    }
    this.loadGridData();
    this.showGrid = true;
  }

  select(row: any) {
    this.rowToSelect.emit(row);
  }

  edit(row: any) {
    this.rowToEdit.emit(row);
  }

  delete(row: any) {
    this.rowToDelete.emit(row);
  }

    addRow(row: any) {
    this.items.push(row);
    this.options.perPage = this.items.length + 1;
    this.loadGridData();
  }

  addRowAtStart(row: any) {
    this.items.unshift(row);
    this.loadGridData();
  }

  updateRowByUUID(row: any) {
    try {
      let last = this.items.find(item => item.UUID === row.UUID);
      let idx = this.items.indexOf(last);
      this.items[idx] = row;
      this.loadGridData();
    } catch (e) {
      alert(e);
    }
  }

  deleteRow(uuid: string) {
    let last = this.items.find(item => item.UUID === uuid);
    let idx = this.items.indexOf(last);
    this.items.splice(idx, 1);
    this.loadGridData();
  }

  public dataStateChange(state): void {
    this.state = state;
    this.gridData = process(this.items, this.state);
  }

  public onFilter(inputValue: string): void {
    if (inputValue.trim() === '') {
      this.state.filter = undefined; // Eliminar el filtro cuando el valor es vacío
    } else {
      this.state.filter = {
        logic: "or",
        filters: this.createFilters(inputValue)
      };
    }
    this.gridData = process(this.items, this.state);
  }

  public onSpecialFilter(field: string, value: string): void {
    this.specialFilters.push({
      field: field,
      operator: 'eq',
      value: value,
    });
    this.state.filter = {
      logic: "or",
      filters: this.specialFilters
    };
    this.gridData = process(this.items, this.state);
  }

  private createFilters(inputValue: string): any[] {
    this.filters = [];

    this.specialFilters.forEach(item => { this.filters.push(item) });

    this.columns.forEach(item => {
      this.filters.push({
        field: item.field,
        operator: 'contains',
        //operator: 'eq',
        value: inputValue,
      });
    });

    return this.filters;
  }

  public cellClickHandler({ isEdited, dataItem, rowIndex, columnIndex }): void {
    if (this.options.selectableCheckbox) {
      if (columnIndex > 0) {
        if (columnIndex <= this.columns.length) {
          this.onCellClicked.emit(dataItem);
        }
      }
    } else {
      this.onCellClicked.emit(dataItem);
    }
  }

  getSelectedRows(): string[] {
    return this.selectedRows;
  }

  SelectCheckboxItem(item: any) {
    if (this.selectedRows.includes(item.UUID)) {
      let idx = this.selectedRows.indexOf(item.UUID);
      this.selectedRows.splice(idx, 1);
    } else {
      this.selectedRows.push(item.UUID);
    }
  }

  print(row: any) {
    this.rowToPrint.emit(row);
  }

  restore(row: any) {
    this.rowToRestore.emit(row);
  }

}
