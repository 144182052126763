import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { FileInfo, FileRestrictions, SelectEvent, UploadComponent } from '@progress/kendo-angular-upload';
import { LoadingService } from '../loading/loading.service';
import { UploadService } from '../../../services/upload.service';

@Component({
  selector: 'app-logo-upload',
  templateUrl: './logo-upload.component.html',
  styleUrls: ['./logo-upload.component.scss']
})
export class LogoUploadComponent implements OnInit {

  showUpload: boolean = false;
  @ViewChild("logo", { read: UploadComponent, static: false })
  public logo: UploadComponent;
  myFiles: Array<FileInfo> = [];
  public fileRestrictions: FileRestrictions = {
    allowedExtensions: ['.png', '.jpg', '.jpeg'],
    //maxFileSize: 10240,
    //minFileSize: 256,
  };
  fileSelected: boolean = false;
  filename: string = "";

  @Output() onFileUpload: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private uploadService: UploadService,
    private loading: LoadingService) { }

  ngOnInit(): void {
  }

  selectEventHandler(e: SelectEvent) {
    e.files.forEach((file) => {
      if (!file.validationErrors) {
        this.myFiles = [];
        this.myFiles.push(file);
        this.filename = this.myFiles[0].name;
        this.fileSelected = true;
      }
    });
  }

  uploadFile() {
    this.loading.show();
    const formData: FormData = new FormData();
    formData.append('Name', this.myFiles[0].name);
    formData.append('File', this.myFiles[0].rawFile);
    this.uploadService.uploadLogo(formData)
      .subscribe((response: boolean) => {
        if (response) {
          this.cancelUpload();
          this.onFileUpload.emit(true);
        }
        this.loading.hide();
      }, (err) => {
        this.loading.hide();
      });

  }

  cancelUpload() {
    this.showUpload = false;
    this.fileSelected = false;
    this.filename = "";
    this.myFiles = [];
  }

}
