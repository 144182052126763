<div class="row">
  <div class="col-6">
    <kendo-floatinglabel text="Fecha Inicial">
      <kendo-datepicker (valueChange)="onStartDateChange($event)"
                        [readOnlyInput]="true"
                        [format]="'dd/MM/yyyy'"
                        [value]="startDate">
        <kendo-datepicker-messages today="Hoy"
                                   toggle="Cambiar calendario">
        </kendo-datepicker-messages>
      </kendo-datepicker>
    </kendo-floatinglabel>
  </div>
  <div class="col-6">
    <kendo-floatinglabel text="Fecha Final">
      <kendo-datepicker (valueChange)="onEndDateChange($event)"
                        [readOnlyInput]="true"
                        [format]="'dd/MM/yyyy'"
                        [value]="endDate">
        <kendo-datepicker-messages today="Hoy"
                                   toggle="Cambiar calendario">
        </kendo-datepicker-messages>
      </kendo-datepicker>
    </kendo-floatinglabel>
  </div>
</div>
