
<div *ngIf="!NewForm">
  <form [formGroup]="multiForm"
        class="__entityForm">

    <h5 class="text-center">
      Método de Pago Múltiple
    </h5>

    <app-payment-method-selector #methodSelector
                                 [setDefault]="false"
                                 (onItemSelect)="paymentMethodSelect($event)">
    </app-payment-method-selector>

    <kendo-floatinglabel text="Monto">
      <input kendoTextBox name="Amount"
             #amount
             class="form-control"
             type="number"
             aria-label="Monto"
             placeholder="0.00"
             formControlName="Amount" />
    </kendo-floatinglabel>

    <app-customer-selector #customerSelector
                           [hidden]="hideCustomerSelector || creditPaymentExists">
    </app-customer-selector>

    <kendo-floatinglabel text="Referencia Bancaria"
                         [hidden]="hideBankReference">
      <input kendoTextBox name="MultiPaymentBankReference"
             #reference
             class="form-control"
             type="text"
             aria-label="Referencia Bancaria"
             placeholder="Referencia Bancaria"
             formControlName="MultiPaymentBankReference" />
    </kendo-floatinglabel>

    <button class="btn btn-primary btn-block"
            type="submit"
            [disabled]="!isValidForm()"
            (click)="add()">
      Agregar
    </button>

  </form>
  <hr />
  <div *ngIf="payments.length == 0">
    <h3 class="text-center">
      Agregue métodos de pago
    </h3>
  </div>
  <div *ngIf="payments.length > 0">
    <div class="list-group">
      <div class="list-group-item list-group-item-action flex-column align-items-start active"
           *ngFor="let pay of payments; let i = index">
        <div class="d-flex w-100 justify-content-between">
          <h5 class="mb-1">{{ pay.PaymentMethodName }}</h5>
          <b>{{ pay.Amount | currency }}</b>
        </div>
        <p class="mb-1">{{ pay.MultiPaymentBankReference }}</p>
        <p class="mb-1">{{ pay.CustomerName }}</p>
        <div class="text-right">
          <button class="btn btn-sm"
                  (click)="delete(i)">
            <!--<span class="sr-only">eliminar</span>-->
            Eliminar
            &nbsp;
            <i class="fa fa-trash"></i>
          </button>
        </div>
      </div>
    </div>
    <!--<hr />
    <div class="list-group">
      <div class="list-group-item d-flex justify-content-between align-items-center">
        <b>Pagado</b>
        <span class="badge">{{ total | currency }}</span>
      </div>
    </div>-->
  </div>
</div>

<div *ngIf="NewForm">

  <form [formGroup]="multiplePaymentMethodForm"
        class="__entityForm">

    <div class="row">
      <div class="col-6">
        <kendo-floatinglabel text="Efectivo">
          <input kendoTextBox name="Cash"
                 #cash
                 class="form-control"
                 type="number"
                 aria-label="Efectivo"
                 placeholder="0.00"
                 formControlName="Cash" />
        </kendo-floatinglabel>
      </div>
      <div class="col-6">
        <kendo-floatinglabel text="Vale">
          <input kendoTextBox name="Voucher"
                 class="form-control"
                 type="number"
                 aria-label="Vale"
                 placeholder="0.00"
                 formControlName="Voucher" />
        </kendo-floatinglabel>
      </div>
      <div class="col-3">
        <kendo-floatinglabel text="Tarjeta">
          <input kendoTextBox name="Card"
                 class="form-control"
                 type="number"
                 aria-label="Tarjeta"
                 placeholder="0.00"
                 formControlName="Card" />
        </kendo-floatinglabel>
      </div>
      <div class="col-3">
        <kendo-floatinglabel text="Referencia Bancaria">
          <input kendoTextBox name="MPCardReference"
                 class="form-control"
                 type="text"
                 aria-label="Referencia Bancaria"
                 placeholder="0.00"
                 formControlName="MPCardReference" />
        </kendo-floatinglabel>
      </div>
      <div class="col-3">
        <kendo-floatinglabel text="Transferencia Electrónica">
          <input kendoTextBox name="Transfer"
                 class="form-control"
                 type="number"
                 aria-label="Transferencia Electrónica"
                 placeholder="0.00"
                 formControlName="Transfer" />
        </kendo-floatinglabel>
      </div>
      <div class="col-3">
        <kendo-floatinglabel text="Referencia Bancaria">
          <input kendoTextBox name="MPTransferReference"
                 class="form-control"
                 type="text"
                 aria-label="Referencia Bancaria"
                 placeholder="0.00"
                 formControlName="MPTransferReference" />
        </kendo-floatinglabel>
      </div>
      <div class="col-6" [hidden]="!AllowCredits">
        <kendo-floatinglabel text="Crédito">
          <input kendoTextBox name="Credit"
                 class="form-control"
                 type="number"
                 aria-label="Crédito"
                 placeholder="0.00"
                 formControlName="Credit" />
        </kendo-floatinglabel>
      </div>
      <div class="col-6" [hidden]="!AllowCredits">
        <app-customer-selector #customerSelector>
        </app-customer-selector>
      </div>
    </div>

  </form>

</div>
