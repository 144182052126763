
const baseUrl: string = "https://pos-api.ithub-webhost.net";
const name: string = "IT HUB POS [QAs]";

export const environment = {
  production: false,
  appName: `${name}`,
  apiUrl: `${baseUrl}/api`,
  ticketsUrl: `${baseUrl}/Tickets`,
  cashClosingUrl: `${baseUrl}/CashClosing`,
  customerStatementsUrl: `${baseUrl}/CustomerStatements`,
  CSVTemplate: `${baseUrl}/csv-files/ITHUB-POS-PLANTILLA-PRODUCTOS.xlsx`,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
