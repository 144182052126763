
<h3>
  Opciones de Personalización
</h3>

<hr />
<br />

<div class="grid-container">
  <div class="row">
    <div class="col-lg-4 col-md-6 col-sm-12"
         *ngFor="let item of items">
      <div class="card"
           routerLink="{{ item.Url }}">
        <div class="card-header text-center">
          <div class="card-img">
            <i class="fa {{ item.Icon }} fa-5x"></i>
          </div>
        </div>
        <div class="card-body">
          <p>{{ item.Title }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
