import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-sand-box',
  templateUrl: './sand-box.component.html',
  styleUrls: ['./sand-box.component.scss']
})
export class SandBoxComponent implements OnInit {
    ngOnInit(): void {
        
    }
}
