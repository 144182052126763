import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { LocalData } from '../../../models/LocalData';
import { PaymentMethodVM } from '../../../models/view-models';
import { PaymentMethodService } from '../../../services/payment-method.service';

@Component({
  selector: 'app-payment-method-selector',
  templateUrl: './payment-method-selector.component.html',
  styleUrls: ['./payment-method-selector.component.scss']
})
export class PaymentMethodSelectorComponent implements OnInit {

  label: string = "Método de Pago";
  @Input("includeCurrencyPaymentMethods") includeCurrencyPaymentMethods: boolean = true;

  public data: PaymentMethodVM[] = [];
  public selectedItem: PaymentMethodVM;
  disabled: boolean = true;

  public comboForm: FormGroup = new FormGroup({
    comboValue: new FormControl()
  });

  @Output() onItemSelect: EventEmitter<PaymentMethodVM> = new EventEmitter<PaymentMethodVM>();
  @Input() hideCredit: boolean = false;
  @Input() setDefault: boolean = true;

  constructor(private dataService: PaymentMethodService) {
    this.loadLocalData();
  }

  ngOnInit(): void {
    if (!this.setDefault) {
      this.reset();
    }
  }

  loadData() {
    this.dataService.getActives()
      .subscribe((response: PaymentMethodVM[]) => {
        if (response) {
          this.data = response;
        }
        this.disabled = this.data.length <= 0;
      });
  }

  loadLocalData() {
    this.data = JSON.parse(localStorage.getItem(LocalData.PAYMENT_METHODS));
    this.disabled = this.data.length <= 0;
    if (this.data.length > 0) {
      this.hideCreditPaymentMethod();
      this.setComboToDefaultMethod();
    }
  }

  setComboToDefaultMethod() {
    if (this.setDefault)
      this.setItemByCode(LocalData.PAYMENT_METHOD_CASH);
  }

  hideCreditPaymentMethod() {
    if (this.hideCredit) {
      let crdt = this.data.find(item => item.Code === LocalData.PAYMENT_METHOD_CREDIT);
      let idx = this.data.indexOf(crdt);
      this.data.splice(idx, 1);
    }
  }

  hideCurrencyPaymentMethod() {
    if (this.data.length > 0) {
      this.data = this.data.filter(pm => pm.IsCurrency === false);
    }
  }

  hidePaymentMethod(code:string) {
    let crdt = this.data.find(item => item.Code === code);
    if (crdt) {
      let idx = this.data.indexOf(crdt);
      this.data.splice(idx, 1);
    }
  }

  sortByName() {
    this.data.sort((a, b) => {
      if (a.Name > b.Name) {
        return 1;
      } else if (a.Name < b.Name) {
        return -1;
      } else {
        return 0;
      }
    });
  }

  isValidForm(): boolean { return this.comboForm.valid; }

  getItem(): PaymentMethodVM {
    if (this.isValidForm()) {
      return this.selectedItem;
    }
    return null;
  }

  getItemId(): string {
    if (this.isValidForm()) {
      return this.selectedItem.UUID;
    }
    return "";
  }

  getItemName(): string {
    if (this.isValidForm()) {
      return this.selectedItem.Name;
    }
    return "";
  }

  getItemCode(): string {
    if (this.isValidForm()) {
      return this.selectedItem.Code;
    }
    return "";
  }

  selectionChange(value: PaymentMethodVM) {
    this.selectedItem = value;
    this.onItemSelect.emit(this.selectedItem);
  }

  setItemById(uuid: string) {
    if (this.data && this.data.length > 0 && uuid != null && uuid != "") {
      this.selectedItem = this.data.find(item => item.UUID === uuid);
      this.comboForm.setValue({
        comboValue: this.selectedItem
      });
    }
  }

  setItemByCode(code: string) {
    if (this.data && this.data.length > 0 && code != null && code != "") {
      this.selectedItem = this.data.find(item => item.Code === code);
      this.comboForm.setValue({
        comboValue: this.selectedItem
      });
    }
  }

  reset() {
    this.comboForm.reset();
    this.selectedItem = null;
  }


}
