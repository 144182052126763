import { Injectable } from '@angular/core';
import { AuthenticationService } from '../interceptors/authentication.service';
import { ActionPermissionCode } from '../models/ActionPermissionCode';
import { LocalData } from '../models/LocalData';
import { LoginResponse, RolPermissionVM } from '../models/view-models';

@Injectable({
  providedIn: 'root'
})
export class ActionPermissionValidatorService {

  private actionPermissions: RolPermissionVM[] = [];
  private sessionUser: LoginResponse;
  private sessionUserRol: string;

  constructor(private authService: AuthenticationService) { }

  private getInfo() {
    this.actionPermissions = JSON.parse(localStorage.getItem(LocalData.ACTION_PERMISSIONS));
    this.sessionUser = this.authService.SessionUserValue();
    this.sessionUserRol = this.sessionUser.Rol;
  }

  // Cash in
  cashIn(): boolean {
    this.getInfo();
    let permission: RolPermissionVM = this.actionPermissions.find(x => x.Code === ActionPermissionCode.CASH_IN);
    if (permission.RolCodes.includes(this.sessionUserRol)
      || permission.AuthorizationRequired) {
      return false;
    }
    return true;
  }

  // Cash out
  cashOut(): boolean {
    this.getInfo();
    let permission: RolPermissionVM = this.actionPermissions.find(x => x.Code === ActionPermissionCode.CASH_OUT);
    if (permission.RolCodes.includes(this.sessionUserRol)
      || permission.AuthorizationRequired) {
      return false;
    }
    return true;
  }

  // Cancel ticket
  cancelTicket(): boolean {
    this.getInfo();
    let permission: RolPermissionVM = this.actionPermissions.find(x => x.Code === ActionPermissionCode.CANCELATION);
    if (permission.RolCodes.includes(this.sessionUserRol)
      || permission.AuthorizationRequired) {
      return false;
    }
    return true;
  }

  // Return ticket
  returnTicket(): boolean {
    this.getInfo();
    let permission: RolPermissionVM = this.actionPermissions.find(x => x.Code === ActionPermissionCode.RETURNS);
    if (permission.RolCodes.includes(this.sessionUserRol)
      || permission.AuthorizationRequired) {
      return false;
    }
    return true;
  }

  // Modify ticket
  modifyTicket(): boolean {
    this.getInfo();
    let permission: RolPermissionVM = this.actionPermissions.find(x => x.Code === ActionPermissionCode.MODIFY);
    if (permission.RolCodes.includes(this.sessionUserRol)
      || permission.AuthorizationRequired) {
      return false;
    }
    return true;
  }

  // Customer´s credit limit edition
  creditLimitEdition(): boolean {
    this.getInfo();
    let permission: RolPermissionVM = this.actionPermissions.find(x => x.Code === ActionPermissionCode.CREDIT_LIMIT);
    if (permission.RolCodes.includes(this.sessionUserRol)) {
      return false;
    }
    return true;
  }

  // create shift
  createShift(): boolean {
    this.getInfo();
    let permission: RolPermissionVM = this.actionPermissions.find(x => x.Code === ActionPermissionCode.CREATE_SHIFT);
    if (permission.RolCodes.includes(this.sessionUserRol)) {
      return false;
    }
    return true;
  }

  // requires authorization
  requiresAuthorization(actionCode: ActionPermissionCode): boolean {
    this.getInfo();
    let permission: RolPermissionVM = this.actionPermissions.find(x => x.Code === actionCode);
    return permission.AuthorizationRequired;
  }

  // user can execute action
  canExecuteAction(actionCode: ActionPermissionCode): boolean {
    this.getInfo();
    let permission: RolPermissionVM = this.actionPermissions.find(x => x.Code === actionCode);
    if (permission.RolCodes.includes(this.sessionUserRol)) {
      return true;
    }
    return false;
  }
}
