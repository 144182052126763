
<div [hidden]="showForm || showChangePassword">

  <h3>
    Usuarios
  </h3>

  <button class="btn btn-primary"
          (click)="addNew()">
    Agregar <i class="fa fa-plus"></i>
  </button>

  <hr />
  <br />

  <app-generic-grid #grid
                    (rowToEdit)="edit($event)"
                    (rowToDelete)="delete($event)">
  </app-generic-grid>

</div>

<div [hidden]="!showForm">

  <form [formGroup]="entityForm"
        class="__entityForm">

    <h4 *ngIf="isEdition">
      Editar Usuario
    </h4>

    <h4 *ngIf="!isEdition">
      Agregar Usuario
    </h4>

    <app-rol-selector #rolSelector
                      (onItemSelect)="onRolSelect($event)">
    </app-rol-selector>

    <kendo-floatinglabel text="Nombre de Usuario">
      <input kendoTextBox name="Username"
             #username
             class="form-control"
             [ngClass]="{'is-invalid': entityForm.get('Username').errors && entityForm.get('Username').touched}"
             type="text"
             aria-label="Nombre de Usuario"
             placeholder="Nombre de Usuario"
             formControlName="Username" />
    </kendo-floatinglabel>

    <div *ngIf="!isEdition">
      <kendo-floatinglabel text="Contraseña"
                           *ngIf="!showPassword">
        <input kendoTextBox name="Password"
               class="form-control"
               [ngClass]="{'is-invalid': entityForm.get('Password').errors && entityForm.get('Password').touched}"
               type="password"
               aria-label="Contraseña"
               placeholder="Contraseña"
               formControlName="Password" />
      </kendo-floatinglabel>

      <kendo-floatinglabel text="Contraseña"
                           *ngIf="showPassword">
        <input kendoTextBox name="Password"
               class="form-control"
               [ngClass]="{'is-invalid': entityForm.get('Password').errors && entityForm.get('Password').touched}"
               type="text"
               aria-label="Contraseña"
               placeholder="Contraseña"
               formControlName="Password" />
      </kendo-floatinglabel>

      <div class="row">
        <div class="col-6">
          <label class="form-check-label">
            <input type="checkbox" kendoCheckBox
                   [(ngModel)]="showPassword"
                   [ngModelOptions]="{standalone: true}" />&nbsp;&nbsp;&nbsp;
            Mostrar contraseña
          </label>
        </div>
        <div class="col-6">
          <button class="btn btn-outline-secondary btn-block"
                  (click)="generatePassword()">
            Generar Contraseña
          </button>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-end __formButtonsContainer">
      <button class="btn btn-primary"
              *ngIf="!isEdition"
              type="submit"
              [disabled]="!isValidForm()"
              (click)="save()">
        Guardar
      </button>

      <button class="btn btn-secondary"
              type="button"
              (click)="cancel()">
        Cancelar
      </button>
    </div>

  </form>

</div>

<div [hidden]="!showChangePassword">
  <app-change-password-form #changePassword
                            (onPasswordChanged)="onPasswordChanged($event)"
                            (onCancel)="changePasswordCancel()">
  </app-change-password-form>
</div>
