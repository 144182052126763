
export enum CARDEXOperationType {
  SALE = 'S',
  CANCELATION = 'C',
  RETURN = 'R',
  MODIFICATION = 'M',
  INPUT= 'I',
}

export enum TipoDeOperacionCARDEX {
  S = "Venta",
  C = "Cancelación",
  R = "Devolución",
  M = "Modificación",
  I = "Entrada de Mercancía",
}
