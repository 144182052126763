
<kendo-window [hidden]="!showWindow"
              [resizable]="false"
              [draggable]="false"
              [(state)]="windowState"
              (close)="closeDialog()"
              [width]="450">

  <!-- window title bar -->
  <kendo-window-titlebar>
    <div class="k-window-title">Ventas del Día y Devoluciones</div>
    <button kendoWindowMaximizeAction *ngIf="false"></button>
    <button kendoWindowMinimizeAction *ngIf="false"></button>
    <button kendoWindowRestoreAction *ngIf="false"></button>
    <button kendoWindowCloseAction></button>
  </kendo-window-titlebar>

  <app-date-selector (selectDate)="onDateChange($event)">
  </app-date-selector>

  <app-generic-grid #salesGrid>
  </app-generic-grid>

</kendo-window>
