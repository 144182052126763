import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'app-multiple-pay-method-form',
  templateUrl: './multiple-pay-method-form.component.html',
  styleUrls: ['./multiple-pay-method-form.component.scss']
})
export class MultiplePayMethodFormComponent implements OnInit {

  multiForm: FormGroup;
  @ViewChild("cash") cash: ElementRef;

  constructor(private fb: FormBuilder,
    private decimalPipe: DecimalPipe) { }

  ngOnInit(): void {
    
  }

  initForm() {
    this.multiForm = this.fb.group({
      Cash: [this.setFormatNumber(0), [Validators.required]],
      Card: [this.setFormatNumber(0), [Validators.required]],
    });
  }

  isValidForm(): boolean {
    return this.multiForm.valid;
  }

  setFormatNumber(value: any) {
    let tmp = this.decimalPipe.transform(value, '1.2-2', 'mx');
    tmp = tmp.replace(',', '');
    return tmp;
  }

  focusOnCashInput() {
    setTimeout(() => {
      this.cash.nativeElement.focus();
      this.cash.nativeElement.select();
    }, 0);
  }

  getFormValue() {
    if (this.isValidForm()) {
      return this.multiForm.value;
    }
    return null;
  }

}
