import { Component, OnInit } from '@angular/core';
import { LoadingService } from '../../../common/components/loading/loading.service';
import { AppLogVM } from '../../../models/view-models';
import { AppLogsService } from '../../../services/app-logs.service';

@Component({
  selector: 'app-app-logs',
  templateUrl: './app-logs.component.html',
  styleUrls: ['./app-logs.component.scss']
})
export class AppLogsComponent implements OnInit {

  logs: AppLogVM[] = [];
  log!: AppLogVM;
  showDetails: boolean = false;
  logsDate: Date = new Date();

  constructor(private logsService: AppLogsService,
    private loading: LoadingService) { }

  ngOnInit(): void {
    this.loadLogs();
  }

    loadLogs() {
      this.loading.show();
      this.logsService.getLogsFromDate(this.logsDate)
            .subscribe((response: AppLogVM[]) => {
                if (response) {
                    this.logs = response;
                }
                this.loading.hide();
            });
    }

  view(value: AppLogVM) {
    this.log = value;
    this.showDetails = true;
  }

  close = () => this.showDetails = false;

  onDateChange(d: Date) {
    this.logsDate = new Date(d);
    this.loadLogs();
  }

}
