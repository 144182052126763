import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LocalData } from '../models/LocalData';
import { RolPermissionVM } from '../models/view-models';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class RolGuard implements CanActivate {

  constructor(private authService: AuthenticationService,
    private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (localStorage.getItem(LocalData.ROUTE_PERMISSIONS) === null
      || localStorage.getItem(LocalData.ACTION_PERMISSIONS) === null) {
      this.authService.logout();
    }

    const routePermissions: RolPermissionVM[] = JSON.parse(localStorage.getItem(LocalData.ROUTE_PERMISSIONS));
    const sessionUser = this.authService.SessionUserValue();
    const sessionUserRol: string = sessionUser.Rol;

    let validation: boolean = false;

    // Validate if settings
    if (state.url.includes('settings')) {
      let settingsPermission = routePermissions.find(x => x.Route === '/settings');
      validation = settingsPermission.RolCodes.includes(sessionUserRol);
      if (!validation) {
        console.log(sessionUserRol + ' does not have permission to visit /settings');
      }
    }

    // Validate all route pahts
    let permission = routePermissions.find(x => x.Route === state.url);
    if (permission) {
      validation = permission.RolCodes.includes(sessionUserRol);
      if (!validation) {
        console.log(sessionUserRol + ' does not have permission to visit ' + state.url);
      }
    }

    if (validation)
      return true;

    this.router.navigate(['/home/access-denied'],
      { queryParams: { returnUrl: state.url } });
    return false;

  }

}
