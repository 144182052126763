import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { GenericGridComponent } from '../../../../common/components/generic-grid/generic-grid.component';
import { HeaderDetailsComponent } from '../../../../common/components/header-details/header-details.component';
import { SaleBalanceDto } from '../../../../models/view-models';

@Component({
  selector: 'app-customer-movement-details',
  templateUrl: './customer-movement-details.component.html',
  styleUrls: ['./customer-movement-details.component.scss']
})
export class CustomerMovementDetailsComponent implements OnInit, AfterViewInit {

  @Input("saleBalance") saleBalance!: SaleBalanceDto;
  @Output() onClose: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild("saleBalanceDetails", { read: HeaderDetailsComponent })
  public saleBalanceDetails: HeaderDetailsComponent;

  @ViewChild("detailsGrid", { read: GenericGridComponent, static: true })
  public detailsGrid: GenericGridComponent;

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.setHeaderSaleBalanceDetails();
    }, 100);
    }

  ngOnInit(): void {
this.setDetailsGridOptions();
  }

  setHeaderSaleBalanceDetails() {
    this.saleBalanceDetails.setHeaders([
      { Title: "Total de Compra", Value: this.saleBalance.TotalSale, IsCurrency: true },
      {
        Title: "Total de Pagos",
        Value: this.saleBalance.TotalMovements,
        IsCurrency: true,
        Id: "paid",
        Pointer: true
      },
      { Title: "Pendiente de Pago", Value: this.saleBalance.TotalPending, IsCurrency: true },

    ]);
  }

  setDetailsGridOptions() {
    this.detailsGrid.setData(this.saleBalance.CustomerMovements);
    this.detailsGrid.options.toolbarTitle = "Pagos y abonos de la venta";
    this.detailsGrid.options.hideActionButtons = true;
    this.detailsGrid.options.paging = false;
    this.detailsGrid.columns = [
      { field: "TicketId", title: "Ticket" },
      { field: "CreateDate", title: "Fecha", dateType: true },
      { field: "CreateDate", title: "Hora", timeType: true },
      { field: "MovementTypeName", title: "Tipo de Movimiento" },
      { field: "Amount", title: "Monto", currencyType: true },
      { field: "PaymentMethodName", title: "Método de Pago" },
    ];
    this.detailsGrid.setGridOptions();
  }

  close = () => this.onClose.emit();

}
