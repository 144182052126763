import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { ClientLoginService } from '../services/client-login.service';
import { ClientLoginResponseDto } from '../models/Client';

@Injectable()
export class BearerAuthInterceptor implements HttpInterceptor {

  constructor(private loginService: ClientLoginService) { }

  intercept(request: HttpRequest<any>,
    next: HttpHandler): Observable<HttpEvent<any>> {

    const sessionUser: ClientLoginResponseDto | null = this.loginService.sessionClientValue();

    if (sessionUser && sessionUser.Token) {
      // Upload file
      if (request.url.includes('Upload')) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${sessionUser.Token}`,
            'Content-Disposition': 'mulipart/form-data'
          }
        });

      } else {
        // simple request
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${sessionUser.Token}`
          }
        });
      }
    }
    //alert(JSON.stringify(request.headers));
    //alert(`Bearer ${sessionUser.token}`);
    return next.handle(request);

  }
}
