
export enum MovementType {
  CASH_IN = 'I',
  CASH_OUT = 'O',
  SALE = 'S',
  PAY = 'P'
}

export enum TipoDeMovimiento {
  I = "Entrada de Efectivo",
  O = "Salida de Efectivo",
  S = "Venta",
  P = "Pago",
  R = "Devolución",
}



export enum CustomerMovementType {
  BUY = 'B',
  PAY = 'P',
  DEPOSIT = 'D',
  MULTIPLE_DEPOSIT = 'M',
}

export enum MovimientoCliente {
  B = 'Compra',
  P = 'Pago',
  D = 'Abono'
}
