
export enum LocalData {
  TOGGLE_MENU = "toggleMenu",
  PAYMENT_METHODS = "paymentMethods",
  CURRENCY_EXCHANGE = "currency-exchange",
  ACTIVE_TURN = "activeTurn",
  TICKETS = "tickets",
  PAYMENT_METHOD_CASH = "CASH",
  PAYMENT_METHOD_CARD = "CARD",
  PAYMENT_METHOD_CREDIT = "CRDT",
  PAYMENT_METHOD_RETURN = "RTRN",
  PAYMENT_METHOD_EBANK = "EBANK",
  PAYMENT_METHOD_VOUCHER = "VCHR",
  PAYMENT_METHOD_MULTIPLE = "MULTI",
  ROLES = "Roles",
  ADMIN_ROL = "ADMIN",
  SUPERVISOR_ROL = "SUPERVISOR",
  CASHIER_ROL = "Cajero",
  ACTIVE_SHIFT = 'activeShift',
  SALE_ERROR = 'saleError',
  CUSTOMER_ID = 'customerId',
  ROUTE_PERMISSIONS = 'routePermissions',
  ACTION_PERMISSIONS = 'actionPermissions',
  AUTHORIZE_USER = "authorizeUser",
  AUTHORIZATION_IN_PROCESS = "aip",
  SERVICE_PAY_CREDENTIALS_NAME = "service-pay",
  SERVICE_PAY_CREDENTIALS = "service-pay-credentials",
}
